import {
  BACKGROUND,
  BENEFITS_COMPLETED,
  COI,
  CONTRACT,
  DRUG,
  I9,
  I9_W2_ONLY,
  SECURITY_OB
} from '../../../../constants'

const docsToCreate = [
  {
    label: 'BG',
    docName: 'bgDoc',
    paperworkName: BACKGROUND
  },
  {
    label: 'Benefits Completed',
    docName: 'bcDoc',
    paperworkName: BENEFITS_COMPLETED
  },
  {
    label: 'COI',
    docName: 'coiDoc',
    paperworkName: COI
  },
  {
    label: 'Contract',
    docName: 'contractDoc',
    paperworkName: CONTRACT
  },
  {
    label: 'D',
    docName: 'drugDoc',
    paperworkName: DRUG
  },
  {
    label: 'I9',
    docName: 'i9Doc',
    paperworkName: [I9, I9_W2_ONLY]
  },
  {
    label: 'Security OB',
    docName: 'securityObDoc',
    paperworkName: SECURITY_OB
  }
]

export const completeStatus = { check: true }
export const inCompleteStatus = { emptyCircle: true }
export const notApplicapleStatus = { notApplicable: true }

const getPaperWorkStatus = (paperWork, paperworkJson) => {
  if (paperworkJson) {
    let doc
    if (Array.isArray(paperWork)) {
      for (let i = 0; i < paperWork.length; i++) {
        doc = paperworkJson.find(p => p.title === paperWork[i])
        if (doc) break
      }
    } else {
      doc = paperworkJson.find(p => p.title === paperWork)
    }
    if (doc) {
      return doc.isNotApplicable
        ? notApplicapleStatus
        : doc.isComplete
        ? completeStatus
        : inCompleteStatus
    }
    return false
  } else {
    return null
  }
}

const configPaperworkDocs = paperworkJson => {
  const docObj = {}
  docsToCreate.forEach(({ label, docName, paperworkName }) => {
    docObj[docName] = {
      status: getPaperWorkStatus(paperworkName, paperworkJson),
      label
    }
  })
  return docObj
}

export default configPaperworkDocs
