import React from 'react'

import Skeleton from 'components/shared/Skeleton'

const DepartmentCardSkeleton = () => (
  <Skeleton.Input
    style={{ width: 160, height: 80, marginRight: 20, marginBottom: 12 }}
    active
    size={'small'}
  />
)

const BrandDepartmentSkeleton = ({ cardAmount = 0 }) => {
  const renderCards = () => {
    const list = []
    for (let i = 0; i < cardAmount; i++) {
      list.push(<DepartmentCardSkeleton key={i} />)
    }

    return list
  }
  return (
    <li className='c-brand-department-list__group'>
      <div style={{ marginBottom: 22 }}>
        <Skeleton.Input
          style={{ width: 200, height: 20 }}
          active
          size={'small'}
        />
      </div>
      <div className='c-brand-department-list__cards'>{renderCards()}</div>
    </li>
  )
}

const BrandDepartmentListSkeleton = () => {
  return (
    <ul className='c-brand-department-list'>
      <BrandDepartmentSkeleton cardAmount={7} />
      <BrandDepartmentSkeleton cardAmount={6} />
      <BrandDepartmentSkeleton cardAmount={4} />
      <BrandDepartmentSkeleton cardAmount={2} />
      <BrandDepartmentSkeleton cardAmount={4} />
    </ul>
  )
}

export default BrandDepartmentListSkeleton
