export const assignedAccountsFilterConfig = (
  data,
  companyOptions,
  AMOptions,
  divOptions,
  programOptions,
  shouldShowProgramFilter
) => {
  return [
    {
      filterLabel: 'company',
      type: 'search-select',
      match: 'exact',
      label: 'Company',
      placeholder: 'Select Company',
      notFoundContent: 'No companies found',
      loading: !data.length,
      options: companyOptions,
      filterByLabel: true
    },
    {
      filterLabel: 'accountManager',
      type: 'search-select',
      match: 'exact',
      label: 'Account Manager',
      placeholder: 'Select AM',
      notFoundContent: 'No AMs found',
      loading: !data.length,
      options: AMOptions
    },
    {
      filterLabel: 'division',
      multiSelect: true,
      type: 'search-select',
      match: 'exact',
      label: 'Division',
      notFoundContent: 'No divisions found',
      placeholder: 'Select Division',
      loading: !data.length,
      options: divOptions
    },
    {
      filterLabel: 'program',
      type: 'search-select',
      match: 'exact',
      hidden: !shouldShowProgramFilter,
      label: 'Program',
      notFoundContent: 'No programs found',
      placeholder: 'Select Program',
      loading: !data.length,
      options: programOptions
    }
  ]
}
