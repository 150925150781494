import dayjs from 'dayjs'

const getNearestNextDate = (interviewSlots, today, tomorrow) => {
  return interviewSlots.reduce((finalDate, nextSlot) => {
    const date = nextSlot.date && dayjs.utc(nextSlot.date)
    if (finalDate && finalDate.isSame(tomorrow, 'day')) {
      return finalDate
    }
    if (date && today.isBefore(date, 'day')) {
      if (!finalDate || date.isBefore(finalDate, 'day')) {
        return date
      }
    }
    return finalDate
  }, null)
}

const getFutureDate = jobOrdersActivity => {
  const jobs = jobOrdersActivity
  const today = dayjs()
  const tomorrow = dayjs().add(1, 'days')

  let nearestDateDayjs
  for (let i = 0; i < jobs.length; i++) {
    const job = jobs[i]
    if (job.InterviewSlots.length) {
      const nearestNextDate = getNearestNextDate(
        job.InterviewSlots,
        today,
        tomorrow
      )
      if (
        nearestNextDate &&
        (!nearestDateDayjs || nearestNextDate.isBefore(nearestDateDayjs, 'day'))
      ) {
        nearestDateDayjs = nearestNextDate
        if (nearestDateDayjs.isSame(tomorrow, 'day')) {
          break
        }
      }
    }
  }
  return nearestDateDayjs
}

export default getFutureDate
