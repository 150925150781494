import { put } from '../../helpers/axios'

export default async function updatePaperwork(placementId, paperwork, notes) {
  const url = `/starts/${placementId}/paperwork`
  const payload = {
    paperworkJson: paperwork,
    notes
  }
  const res = await put(url, payload)
  return res
}
