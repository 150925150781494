import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { setCandidateHistoryMenu, setBasicMenuPopover } from 'redux/actions'
import { getCandidateHistory } from '../../../../requests'
import getUserInitial, { getUsersAM } from '../getUserInitial'
import { parseUtcDate } from '../../../../helpers/dateTime'
import LinkButton from 'components/shared/LinkButton'
import { AMRTooltip } from 'components/shared'

const Row = ({ department, placements, bhId, useTouchscreenBoard }) => {
  const dispatch = useDispatch()
  const setMenuProps = useCallback(
    v => {
      dispatch(setCandidateHistoryMenu(v))
    },
    [dispatch]
  )
  const setBasicMenuProps = useCallback(
    v => {
      dispatch(setBasicMenuPopover(v))
    },
    [dispatch]
  )
  let showProgram = department.settings.program_field
  let showDivision = department.settings.division_field
  let placement = placements.find(p => p.bhId === Number(bhId))
  if (!placement) return null
  let {
    dateAdded,
    program,
    division,
    dateBegin,
    billRate,
    payRate,
    employeeType,
    perDiemRate,
    commissions,
    Candidate,
    Candidate: { firstName, lastName },
    JobSubmission,
    JobOrder: { Owner, ClientCorporation },
    url
  } = placement

  const corpName = ClientCorporation ? ClientCorporation.name : 'Client Name!'

  var spread = 0
  var margin = 0

  let perDiem = Number(perDiemRate)
  let dlc = department.settings.direct_labor_cost
  if (employeeType === 'W2') {
    spread = billRate - (payRate * dlc + perDiem)
  } else {
    // include employeeType === "1099"
    spread = billRate - (payRate + perDiem)
  }
  margin =
    (spread || billRate) && billRate !== 0 ? (spread / billRate) * 100 : 'N/A'

  const jobOwnerInfo = getUserInitial(Owner)
  const usersWRoles = getUsersAM(commissions)
  const commissionInitials = usersWRoles.map(user => getUserInitial(user))
  const amInfo = commissionInitials.find(user => user.isAM)
  const amInitial = amInfo
    ? amInfo.initial
    : jobOwnerInfo.isAM
      ? jobOwnerInfo.initial
      : ''
  const amFullName = amInfo
    ? amInfo.fullName
    : jobOwnerInfo.isAM
      ? jobOwnerInfo.fullName
      : ''
  const rcInfo = commissionInitials.find(user => user.initial !== amInitial)
  const rcInitial = rcInfo
    ? rcInfo.initial
    : commissionInitials.length > 1
      ? amInitial
      : ''
  const rcFullName = rcInfo
    ? rcInfo.fullName
    : commissionInitials.length > 1
      ? amFullName
      : ''

  const openCorpHistory = async (e, targetEl) => {
    const request = await getCandidateHistory(Candidate.bhId)
    request &&
      setMenuProps({
        data: request.data,
        name: `History: ${firstName} ${lastName}`,
        jobSubmission: {
          SendingUser: JobSubmission.SendingUser,
          Candidate
        },
        anchorEl: targetEl
      })
  }
  const openCorpPopover = e => {
    const targetEl = e.currentTarget
    setBasicMenuProps({
      anchorEl: targetEl,
      items: [
        !useTouchscreenBoard && {
          url: ClientCorporation && ClientCorporation.url,
          primaryText: 'View on Bullhorn'
        },
        {
          onClick: event => openCorpHistory(event, targetEl),
          primaryText: 'View History'
        }
      ]
    })
  }

  return (
    <div className='element-container' id={bhId}>
      <div
        className='inner-container'
        style={{ color: `#${placement.JobOrder.Owner.color}` || 'black' }}
      >
        <div className='cell' style={{ flex: 0.05 }}>
          <span className='mobile-name'>Added:</span>
          {parseUtcDate(dateAdded, 'MM/DD')}
        </div>
        <div className='cell btn' style={{ flex: showProgram ? 0.15 : 0.15 }}>
          <span className='mobile-name btn'>Name:</span>
          <LinkButton
            style={{ color: 'rgb(0, 0, 238)' }}
            onClick={async e => {
              e.preventDefault()
              const element = e.target
              const request = await getCandidateHistory(Candidate.bhId)
              request &&
                setMenuProps({
                  data: request.data,
                  name: `History: ${firstName} ${lastName}`,
                  jobSubmission: {
                    SendingUser: JobSubmission.SendingUser,
                    Candidate
                  },
                  anchorEl: element
                })
            }}
            className='menu c-btn--responsive'
          >
            {firstName} {lastName}
          </LinkButton>
        </div>
        <div className='cell btn' style={{ flex: showProgram ? 0.18 : 0.28 }}>
          <span className='mobile-name btn'>Company:</span>
          <div
            className='menu c-btn--responsive'
            style={{ cursor: 'pointer' }}
            onClick={openCorpPopover}
          >
            {corpName}
          </div>
        </div>
        <div className='cell' style={{ flex: showProgram ? 0.09 : 0.15 }}>
          <span className='mobile-name'>Placement ID:</span>
          {!useTouchscreenBoard ?
            <a
              onClick={e => e.stopPropagation()}
              target='_blank'
              rel='noopener noreferrer'
              href={url}
            >
              {bhId}
            </a>
            :
            <span>{bhId}</span>
          }

        </div>
        {showProgram ? (
          <div className='cell' style={{ flex: 0.13 }}>
            {program}
          </div>
        ) : null}
        {showDivision ? (
          <div className='cell' style={{ flex: 0.155 }}>
            {division}
          </div>
        ) : null}
        <div className='cell' style={{ flex: showProgram ? 0.07 : 0.1 }}>
          <span className='mobile-name'>Spread:</span>
          {`$${spread.toFixed(2)}`}
        </div>
        <div className='cell' style={{ flex: showProgram ? 0.07 : 0.1 }}>
          <span className='mobile-name'>Per.:</span>
          {`${!isNaN(margin) ? margin.toFixed(0) + '%' : 'N/A'}`}
        </div>
        <div className='cell' style={{ flex: showProgram ? 0.07 : 0.1 }}>
          <span className='mobile-name'>AM/R:</span>
          <AMRTooltip topText={amFullName} bottomText={rcFullName}>
            {`${amInitial}/${rcInitial || ''}`}
          </AMRTooltip>
        </div>
        <div className='cell' style={{ flex: showProgram ? 0.0675 : 0.1 }}>
          <span className='mobile-name'>Start:</span>
          {parseUtcDate(dateBegin, 'MM/DD')}
        </div>
      </div>
    </div>
  )
}

export default Row
