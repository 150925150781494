import dayjs from 'dayjs'

const UTCOffsets = {
  EST: -300,
  CST: -360,
  MST: -420,
  PST: -480,
  AKST: -540,
  HST: -600
}

/**
 * Determines if a candidate is pending or not
 * @param {{ id: number, date: string, timezone: string, InterviewSlotSubmission: * }[]} interviewSlots
 * @returns {Boolean}
 */
export default function candidateIsPending(interviewSlots) {
  // this is ripped directly from the Candidate.js file as it has always existed
  return (
    interviewSlots &&
    interviewSlots.length &&
    dayjs(interviewSlots[0].date)
      .subtract(UTCOffsets[interviewSlots[0].timezone], 'minutes')
      .unix() < dayjs().unix()
  )
}
