import { isOpenInterview } from 'components/boards/activityBoard/helpers/statuses'
import { isValidActivityDate } from 'helpers/dateTime'

/**
 * pulls all times considered "open" from a job along with the time zone
 * @param {*} job - entity from jobOrders websocket
 * @returns {Array<{ date: string, timezone: string }>}
 */
export default function getOpenTimes(job) {
  const openTimes = []

  job?.openInterviewSlots?.forEach?.(v => {
    openTimes.push({
      date: v.datetime,
      timezone: v.timezone
    })
  })
  if (job?.JobSubmissions?.length) {
    job.JobSubmissions.forEach(js => {
      if (js?.interviewSlots?.length) {
        return js.interviewSlots.forEach(slot => {
          if (
            isValidActivityDate(slot.date) &&
            slot.InterviewSlotSubmission &&
            isOpenInterview(slot.InterviewSlotSubmission)
          ) {
            openTimes.push({
              date: slot.date,
              timezone: slot.timezone
            })
          }
        })
      }
      return false
    })
  }
  return openTimes
}
