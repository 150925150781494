import React from 'react'

export default function SidebarToggleIcon({ collapsed }) {
  return (
    <>
      {!collapsed && (
        <svg width='8px' height='13px' viewBox='0 0 8 13' version='1.1'>
          <g id='Symbols' stroke='none' fill='none'>
            <g
              id='Icons-for-Gabriel'
              transform='translate(-828.000000, -243.000000)'
              fill='#010000'
            >
              <g id='back' transform='translate(828.000000, 243.000000)'>
                <polygon
                  id='Path'
                  points='6.0703125 0 0 6.0703125 6.0703125 12.1406245 7.5703125 10.6406245 3 6.0703125 7.5703125 1.5'
                ></polygon>
              </g>
            </g>
          </g>
        </svg>
      )}
      {collapsed && (
        <>
          <svg width='8px' height='13px' viewBox='0 0 8 13' version='1.1'>
            <g id='Symbols' stroke='none' fill='none'>
              <g
                id='Icons-for-Gabriel'
                transform='translate(-668.000000, -243.000000)'
                fill='#010000'
              >
                <g id='forward' transform='translate(668.000000, 243.000000)'>
                  <polygon
                    id='Path'
                    points='1.5 0 0 1.5 4.570312 6.0703125 0 10.6406245 1.5 12.1406245 7.570312 6.0703125'
                  ></polygon>
                </g>
              </g>
            </g>
          </svg>
        </>
      )}
    </>
  )
}
