import React, { useState, useEffect } from 'react'

import ActionBar from './components/ActionBar'
import DeletePiConfirmation from './components/DeletePiConfirmation'
import ManagePiDetailsModal from './components/ManagePiDetailsModal'
import PiDBTable from './components/PiDBTable'

import { getDepartments } from 'requests'

const PiDB = () => {
  const [allDepartments, setAllDepartments] = useState([])
  useEffect(() => {
    getDepartments(true)
      .then(result => {
        if (result?.data?.length) {
          setAllDepartments(result.data)
        }
      })
      .catch(e => {
        console.error('Error fetching departments for dropdown', e)
      })
  }, [setAllDepartments])
  return (
    <div className='c-pidb board-container'>
      <h1 className='c-pidb__header'>PiDB Management</h1>
      <ActionBar allDepartments={allDepartments} />
      <PiDBTable />
      <ManagePiDetailsModal allDepartments={allDepartments} />
      <DeletePiConfirmation />
    </div>
  )
}

export default PiDB
