import React from 'react'

import DealsFiscalDate from './DealsFiscalDate'
import ProgressBarChart from 'components/shared/ProgressBarChart'

const renderHeaderField = (name, data) => {
  return (
    <div className='row'>
      <div className='leftcol'>{name}</div>
      <div className='rightcol'>{data}</div>
    </div>
  )
}

const formatList = (categories, categoryAssignmentId, count) => {
  const defaultColors = ['#7ED321', '#F59436', '#FDB022']
  let list = categories.map((category, index) => {
    return {
      ...category,
      count: count,
      label: category.name,
      color: category.color ? `#${category.color}` : defaultColors[index % 3]
    }
  })
  if (categoryAssignmentId) {
    list = list.filter(({ Id }) => Id === categoryAssignmentId)
  }
  return list
}

const Header = (data, categories, categoryAssignmentId) => {
  let list = formatList(categories, categoryAssignmentId, data.totalDeals)
  const div =
    categoryAssignmentId &&
    categories.find(({ Id }) => Id === categoryAssignmentId)
  // TODO: reuse allCount when the BE returns the correct value
  // const allCount = categories.length ? sumBy(categories, 'count') : 0
  const selectedCount = div ? div.count : data.totalDeals
  return (
    <div className='header-container'>
      <div className='leftbox'>
        <DealsFiscalDate />
        {renderHeaderField('Monthly Deals')}
        <ProgressBarChart
          includeAll={categories.length > 1 && !categoryAssignmentId}
          list={list}
        />
      </div>
      <div className='rightbox'>
        {renderHeaderField('Total Deals:', selectedCount)}
        {renderHeaderField('Total Spread:', `$${data.totalSpread}`)}
        {renderHeaderField('Average Spread:', `$${data.averageSpread}`)}
        {renderHeaderField('Average Margin:', `${data.averageMargin}%`)}
      </div>
    </div>
  )
}

export default Header
