import createRootReducer from './redux/reducers'
import reduxThunk from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { createReduxEnhancer } from '@sentry/react'

// ...

const sentryReduxEnhancer = createReduxEnhancer({
  // Optionally pass options listed below
})

export const history = createBrowserHistory()
export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeWithDevTools(
      applyMiddleware(
        routerMiddleware(history),
        reduxThunk
        // require("redux-immutable-state-invariant").default()
      ),
      sentryReduxEnhancer
    )
  )
  return store
}
