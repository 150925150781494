import React from 'react'
import photo from '../../../../theme/assets/noavatar.png'
import UserMenuDropdown from './UserMenuDropdown'
const size = 32
const containerSize = `${size}px`
const avatarSize = `${size - 4}px`

const UserMenuItem = ({
  user,
  updateUserWSFxn,
  fromColor,
  toColor,
  activeDepartment,
  collapsed
}) => {
  const { avatar, firstName, lastName } = user
  const containerStyle = {
    height: containerSize,
    width: containerSize,
    minHeight: containerSize,
    minWidth: containerSize,
    background: `linear-gradient(45deg, ${fromColor}, ${toColor})`,
    cursor: 'default',
    margin: collapsed ? '0 0 0 -2px' : '0 12px 0 0'
  }
  const style = {
    height: avatarSize,
    width: avatarSize,
    minHeight: avatarSize,
    minWidth: avatarSize,
    margin: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${avatar ? avatar : photo})`,
    cursor: 'default'
  }

  return (
    <div className='c-sidebar__user-menu-item'>
      <div
        style={containerStyle}
        className='department-card-gradient-container'
      >
        <div className='department-card user' style={style} />
      </div>
      <div className='c-sidebar__hide-collapsed'>
        <div className='c-sidebar__user-menu-name'>
          {firstName || lastName ? `${firstName} ${lastName}` : 'Anonymous'}
        </div>
        <div className='c-sidebar__user-menu-department'>
          {activeDepartment}
        </div>
      </div>
      <UserMenuDropdown user={user} updateUserWSFxn={updateUserWSFxn} />
    </div>
  )
}

export default UserMenuItem
