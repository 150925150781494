import { getCandidateHistory } from '../../../../requests'
import BaseWebsocket from '../../../../network/websocket/BaseWebsocket'
import department from 'redux/reducers/department'

const handleCandidateTap = (
  element,
  slot,
  jobSubmission,
  boardType,
  updateSlot,
  setBasicMenu,
  setCandidateHistoryMenu
) => {
  const getHistoryFxn = async () => {
    const request = await getCandidateHistory(jobSubmission.Candidate.bhId)
    setCandidateHistoryMenu({
      data: request.data,
      name: `Candidate Name: ${jobSubmission.Candidate.firstName} ${jobSubmission.Candidate.lastName}`,
      jobSubmission,
      anchorEl: document.querySelector(element)
    })
  }
  const removeCandidateFxn = () => {
    BaseWebsocket.send(boardType, {
      event: 'slot_submission_remove',
      slotId: slot.id,
      jobSubmissionId: jobSubmission.bhId
    })
  }
  const toggleSecurityPrescreenFxn = async () => {
    updateSlot(
      jobSubmission.bhId,
      slot.id,
      'securityPrescreen',
      !jobSubmission.InterviewSlotSubmission.securityPrescreen
    )
  }
  const items = [
    { primaryText: 'View History', onClick: getHistoryFxn },
    { primaryText: 'Remove From Slot', onClick: removeCandidateFxn }
  ]
  if (department && department.security_prescreen) {
    items.push({
      primaryText: 'Toggle Security Prescreen',
      onClick: toggleSecurityPrescreenFxn
    })
  }
  setBasicMenu({ anchorEl: document.querySelector(element), items })
}

export { handleCandidateTap }
