import JobsBoard from '../../boards/jobsBoard'
import DealsBoard from '../../boards/dealsBoard'
import StartsBoard from '../../boards/startsBoard'
import ActivityBoard from '../../boards/activityBoard'
import AssignedAccounts from '../../boards/assignedAccounts'
import DivisionDashBoard from '../../boards/dashBoard'
import StartsMngmt from '../../StartsMngmt'
import PiDB from '../../PiDB'

export const noHeaderBoards = ['/pidb']

const routes = [
  {
    path: '/hotJobOrder',
    component: JobsBoard
  },
  {
    path: '/hotActivity',
    component: ActivityBoard
  },
  {
    path: '/d/:department/:header/boards/dashboard',
    component: DivisionDashBoard
  },
  {
    path: '/d/:department/:header/boards/jobs',
    component: JobsBoard
  },
  {
    path: '/d/:department/:header/boards/deals',
    component: DealsBoard
  },
  {
    path: '/d/:department/:header/boards/starts',
    component: StartsBoard
  },
  {
    path: '/d/:department/:header/boards/activity',
    component: ActivityBoard
  },
  {
    path: '/d/:department/:header/boards/assigned-accounts',
    component: AssignedAccounts
  },
  {
    path: '/p/:header/starts-management',
    component: StartsMngmt
  },
  {
    path: '/pidb',
    component: PiDB
  }
]

export const configuredRoutes = routes.filter(({ hidden }) => !hidden)
