/**
 *
 * @param {Array} data array of pi records
 * @param {Object} filters filter object from store
 * @returns {Array} array of filtered pi records if any
 */
const filterData = (data, filters) => {
  let filtered = data
  const andFilterCount = [
    filters.search,
    filters.board,
    filters.division
  ].filter(v => v).length
  if (filters.search || andFilterCount > 0) {
    filtered = data.filter(v => {
      let shouldReturn = false
      if (v) {
        shouldReturn =
          [
            [filters.search, v.macAddress === filters.search],
            [
              filters.board,
              v.boardName?.toLowerCase() === filters.board?.toLowerCase()
            ],
            [
              filters.division,
              v.departmentName?.toLowerCase() ===
                filters.division?.toLowerCase()
            ]
          ].filter(([filterExists, condition]) => filterExists && condition)
            .length === andFilterCount
      }

      return shouldReturn
    })
  }
  return filtered
}

export default filterData
