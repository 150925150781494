import React from 'react'

const CircleAndCheck = ({
  onClick,
  circleColor,
  checkColor,
  className,
  style,
  width,
  height
}) => {
  return (
    <svg
      onClick={onClick}
      width={width || '20'}
      height={height || '20'}
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      style={style}
    >
      <g fill='none' fillRule='evenodd'>
        <circle
          stroke={circleColor || '#334E9E'}
          strokeWidth='2'
          cx='10'
          cy='10'
          r='9'
        />
        <path
          d='M8.978 12.422l-3.031-3.03a.995.995 0 00-1.412.006l.174-.174a.999.999 0 00.004 1.423l4.265 4.264 9.958-9.958a1 1 0 00-.001-1.418l.174.174a1 1 0 00-1.417 0l-8.714 8.713z'
          fill={checkColor || '#75D168'}
        />
      </g>
    </svg>
  )
}

const Check = ({ color, onClick, className, style, width, height }) => {
  return (
    <svg
      onClick={onClick}
      width={width || '20'}
      height={height || '20'}
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      style={style}
    >
      <path
        d='M6.222 14.778l-3.821-3.822a1.195 1.195 0 00-1.696.006l.146-.146c-.47.47-.471 1.23.001 1.703l5.37 5.37 12.48-12.48c.471-.47.477-1.23.003-1.704l.146.146a1.2 1.2 0 00-1.698-.004l-10.93 10.93z'
        fill={color || '#75D168'}
        fillRule='evenodd'
      />
    </svg>
  )
}

const Circle = ({ color, onClick, className, style, width, height }) => {
  return (
    <svg
      onClick={onClick}
      width={width || '20'}
      height={height || '20'}
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      style={style}
    >
      <circle
        cx='10'
        cy='10'
        r='9'
        stroke={color || '#4A4A4A'}
        strokeWidth='2'
        fill='none'
        fillRule='evenodd'
      />
    </svg>
  )
}

const EmptyCircle = ({ onClick, className, style, width, height }) => {
  return (
    <svg
      onClick={onClick}
      width={width || '20'}
      height={height || '20'}
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      style={style}
    >
      <path
        d='M10 19a9 9 0 100-18 9 9 0 000 18z'
        stroke='#B7B7B7'
        strokeWidth='2'
        fill='none'
        fillRule='evenodd'
        strokeDasharray='4 4'
        strokeLinecap='round'
      />
    </svg>
  )
}

const Dot = ({ onClick, color, className, style, width, height }) => {
  return (
    <svg
      width={width || '20'}
      height={height || '20'}
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      className={className}
      style={style}
    >
      <circle
        cx='10'
        cy='10'
        r='9'
        fill={color || '#D0021B'}
        fillRule='evenodd'
      />
    </svg>
  )
}

const NotApplicable = ({ onClick, color, className, style, width, height }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      fill={color || '#e00005'}
      className={className}
      height={height || '20'}
      width={width || '20'}
      onClick={onClick}
      style={style}
    >
      <path d='M12 2C6.489 2 2 6.489 2 12s4.489 10 10 10 10-4.489 10-10S17.511 2 12 2zm0 2c4.43 0 8 3.57 8 8 0 1.853-.63 3.55-1.682 4.904L7.096 5.682A7.965 7.965 0 0112 4zM5.682 7.096l11.222 11.222A7.965 7.965 0 0112 20c-4.43 0-8-3.57-8-8 0-1.853.63-3.55 1.682-4.904z' />
    </svg>
  )
}

const StatusIndicator = ({
  circle,
  check,
  dot,
  notApplicable,
  onClick,
  className,
  style,
  height,
  width
}) => {
  const circleColor = typeof circle === 'string' ? circle : undefined
  const checkColor = typeof check === 'string' ? check : undefined
  const dotColor = typeof dot === 'string' ? dot : undefined
  const notApplicableColor =
    typeof notApplicable === 'string' ? notApplicable : undefined
  const sharedProps = { style, width, height, className, onClick }
  if (circle && check) {
    return (
      <CircleAndCheck
        circleColor={circleColor}
        checkColor={checkColor}
        {...sharedProps}
      />
    )
  }

  if (circle) {
    return <Circle color={circleColor} {...sharedProps} />
  }

  if (check) {
    return <Check color={checkColor} {...sharedProps} />
  }

  if (dot) {
    return <Dot color={dotColor} {...sharedProps} />
  }

  if (notApplicable) {
    return <NotApplicable color={notApplicableColor} {...sharedProps} />
  }

  return <EmptyCircle {...sharedProps} />
}

export default StatusIndicator
