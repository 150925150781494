import { uniqBy } from 'lodash'

export default (piRows = []) => [
  {
    filterLabel: 'search',
    type: 'search-select',
    match: 'exact',
    placeholder: 'Search',
    showArrow: false,
    options: uniqBy(
      piRows
        .map(row => {
          return {
            value: row.macAddress,
            label: row.macAddress
          }
        })
        .filter(row => row.value || row.name),
      option => option.value
    ),
    filterByLabel: true
  },
  {
    filterLabel: 'board',
    type: 'search-select',
    match: 'exact',
    placeholder: 'All Boards',
    options: [
      {
        value: 'activity',
        label: 'Activity'
      },
      {
        value: 'deals',
        label: 'Deals'
      },
      {
        value: 'jobs',
        label: 'Jobs'
      },
      {
        value: 'starts',
        label: 'Starts'
      }
    ],
    filterByLabel: true
  },
  {
    filterLabel: 'division',
    type: 'search-select',
    match: 'exact',
    placeholder: 'All Divisions',
    options: uniqBy(
      piRows
        .map(row => {
          return {
            value: row.departmentName,
            label: row.departmentName
          }
        })
        .filter(row => row.value || row.name),
      option => option.value
    ),
    filterByLabel: true
  }
]
