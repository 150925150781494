import {
  setPlacements,
  updatePlacement,
  updateCandidate,
  updatePlacementCommissions,
  PLACEMENT_COMMISSION_CREATED_EVENT,
  PLACEMENT_COMMISSION_DELETED_EVENT,
  PLACEMENT_COMMISSION_UPDATED_EVENT
} from '../placements'
import { getCategoryDealsGoals } from '../department'
import { setBoardLoadingObj } from '..'

export const handleDealsData = result => dispatch => {
  switch (result.event) {
    case 'list':
      dispatch(setPlacements(result.list))
      dispatch(getCategoryDealsGoals())
      dispatch(setBoardLoadingObj('deals'))
      // if (this.board) {
      //   this.board.resetOverflowCalc();
      // }
      break
    case 'placement_change':
      dispatch(
        updatePlacement(result.placement, result.updatedFields, result.event)
      )
      // if (this.board) {
      //   this.board.resetOverflowCalc();
      // }
      break
    case PLACEMENT_COMMISSION_CREATED_EVENT:
    case PLACEMENT_COMMISSION_DELETED_EVENT:
    case PLACEMENT_COMMISSION_UPDATED_EVENT:
      dispatch(
        updatePlacementCommissions(result.placementCommission, result.event)
      )
      dispatch(getCategoryDealsGoals())
      break
    case 'candidate_change':
      dispatch(
        updateCandidate(result.placements, result.Candidate, result.event)
      )
      // if (this.board) {
      //   this.board.resetOverflowCalc();
      // }
      break
    default:
      return false
  }
}
