import React from 'react'

const PaperPlane = ({ color }) => (
  <svg
    id='sendouts'
    fill={color || '#7324D8'}
    width='69px'
    height='69px'
    viewBox='0 0 69 69'
  >
    <g stroke='#7324D8' strokeWidth='3' fill='none' fillRule='evenodd'>
      <path
        strokeLinejoin='round'
        d='M67 2L2 23l24.5 14.5L46 23 31.5 42.5l14 24.5z'
      />
      <path strokeLinecap='round' d='M24 44L8 60M26 52l-8 8M16 42l-8 8' />
    </g>
  </svg>
)

export default PaperPlane
