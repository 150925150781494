import dayjs from 'dayjs'
import { store } from '../index'

const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
const duration = require('dayjs/plugin/duration')
dayjs.extend(duration)
const isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)
const weekday = require('dayjs/plugin/weekday')
dayjs.extend(weekday)

export const isInRange = (date, range) => {
  const dateSmartDate = dayjs(date)
  if (
    dateSmartDate.isSame(range[0], 'day') ||
    dateSmartDate.isSame(range[1], 'day')
  ) {
    return true
  }
  return dateSmartDate.isBetween(range[0], range[1], 'day')
}

export const isInCurrentMonth = date => {
  const now = getDate()
  return dayjs(date).isSame(now, 'month')
}

export const isInCurrentFiscalMonth = date => {
  const state = store.getState()
  const settings = state && state.department && state.department.settings
  let range
  if (
    settings &&
    settings.current_fiscal_month_start &&
    settings.current_fiscal_month_end
  ) {
    range = [
      settings.current_fiscal_month_start,
      settings.current_fiscal_month_end
    ]
  }
  if (range) {
    return isInRange(date, range)
  } else {
    return isInCurrentMonth(date)
  }
}

export const isAfterToday = date => {
  const now = getDate()
  return dayjs(date).isAfter(now, 'day')
}

export const isBeforeToday = date => {
  const now = dayjs()
  return dayjs(date).isBefore(now, 'day')
}

export const isValidActivityDate = date => {
  const now = dayjs()
  const startOfWeek = now
    .hour(0)
    .minute(0)
    .weekday(1)
  return (
    dayjs(date).isSame(startOfWeek, 'day') ||
    dayjs(date).isAfter(startOfWeek, 'day')
  )
}

export const sortPlacementByDateAdded = (a, b) => {
  if (b.dateAdded === a.dateAdded) return 0
  const isBefore = dayjs(b.dateAdded).isBefore(dayjs(a.dateAdded))
  return isBefore ? 1 : -1
}

export const sortDate = (aDate, bDate, dateFormat) => {
  if (aDate === bDate) return 0
  const aSmartDate = dayjs(aDate, dateFormat)
  const bSmartDate = dayjs(bDate, dateFormat)
  if (aSmartDate.isBefore(bSmartDate)) {
    return -1
  }
  if (aSmartDate.isAfter(bSmartDate)) {
    return 1
  }
  return 0
}

export const sortDateReverse = (aDate, bDate, dateFormat) => {
  if (aDate === bDate) return 0
  const aSmartDate = dayjs(aDate, dateFormat)
  const bSmartDate = dayjs(bDate, dateFormat)
  if (aSmartDate.isBefore(bSmartDate)) {
    return 1
  }
  if (aSmartDate.isAfter(bSmartDate)) {
    return -1
  }
  return 0
}

export const validateStartsDate = dateToValidate => {
  if (!dateToValidate) return false
  const state = store.getState()
  const dateBegin = state && state.UI && state.UI.startsFromFilter
  if (dateBegin) {
    const dateToValidateSmartDate = dayjs(dateToValidate)
    const dateBeginSmartDate = dayjs(dateBegin).utc()
    if (dateBeginSmartDate.isSame(dateToValidateSmartDate, 'day')) return true
    return dateBeginSmartDate.isBefore(dateToValidateSmartDate, 'day')
  } else {
    return false
  }
}

/**
 * compares two dates and returns true if both dates are the same day, uses the timezone of the first argument
 * @param {*} dayA
 * @param {*} dayB
 * @returns boolean
 */
export const isSameDayByDay = (dayA, dayB) => {
  const dayASmartDate = dayjs.isDayjs(dayA) ? dayA : parseUtcDate(dayA)
  const dayBSmartDate = dayjs.isDayjs(dayB) ? dayB : parseUtcDate(dayB)

  return (
    dayASmartDate.isSame(dayBSmartDate, 'day') &&
    dayASmartDate.format('MM/DD') === dayBSmartDate.format('MM/DD')
  )
}

export const parseUtcDate = (date, format) => {
  if (date) {
    return format ? dayjs.utc(date).format(format) : dayjs.utc(date)
  }
  return null
}

export const parseDate = (date, format) => {
  if (date) {
    return format ? dayjs(date).format(format) : dayjs(date)
  }
  return null
}

export const getDate = format => {
  const date = dayjs()
  if (format) {
    return date.format(format)
  }
  return date
}

export const getDuration = (time, unitOfMeasurement) => {
  if (unitOfMeasurement) {
    return dayjs.duration(time, unitOfMeasurement)
  }
  return dayjs.duration(time)
}

export const getWeekday = () => {
  return dayjs().weekday()
}
