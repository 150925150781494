import { createSelector } from 'reselect'
import {
  sortAssignedAccountsByCompany,
  sortAssignedAccountsByAccountManager
} from '../helpers/sortAssignedAccounts'
import { uniqBy } from 'lodash'

const MAX_OPTIONS = 25

const processOptionsHelper = (options) => {
  const uniqOptions = uniqBy(options, option => option.value)
  const filterOptions = uniqOptions.filter(({ label, value }) => label && value)
  return filterOptions.sort((a, b) => {
    const labelA = a.label && a.label.toLowerCase()
    const labelB = b.label && b.label.toLowerCase()
    if (labelA < labelB) return -1
    if (labelA > labelB) return 1
    return 0
  })
}

const filterDataByInput = (data, input, selected) => {
  const defOptions = data.slice(0, MAX_OPTIONS)
  let displayOptions = []

  if (!input) {
    displayOptions = defOptions
  } else {
    const filterData = data.filter(({ label }) =>
      label.toUpperCase().includes(input.toUpperCase()))
    displayOptions = filterData.length ? filterData.slice(0, MAX_OPTIONS) : defOptions
  }

  selected.forEach((bhId) => {
    const displayOption = displayOptions.find(({ value }) => value === bhId)
    const option = data.find(({ value }) => value === bhId)
    if (!displayOption && option) {
      displayOptions = [option, ...displayOptions]
    }
  })
  return displayOptions
}

export const selectData = state => state.assignedAccounts.data
export const selectFilterBy = state => state.assignedAccounts.meta.filterByCompany
export const selectLoading = state => state.assignedAccounts.meta.loading
export const selectAccountFilters = state => state.assignedAccounts.meta.filters
export const selectCompanyFilter = state => state.assignedAccounts.meta.filters.company
export const selectCompanyInput = state => state.assignedAccounts.meta.filters.companyInput
export const selectAMFilter = state => state.assignedAccounts.meta.filters.accountManager
export const selectAMInput = state => state.assignedAccounts.meta.filters.amInput
export const selectDivisionFilter = state => state.assignedAccounts.meta.filters.division
export const selectProgramFilter = state => state.assignedAccounts.meta.filters.program

const selectDataByAM = createSelector(
  selectData,
  (data) => sortAssignedAccountsByAccountManager(data)
)

const selectDataByCompany = createSelector(
  selectData,
  (data) => sortAssignedAccountsByCompany(data)
)

const selectCompanyFilterData = createSelector(
  selectData,
  (data) => {
    const options = data.map(account => ({
      value: account.clientCorporation.bhId,
      label: account.clientCorporation.name
    }))
    return processOptionsHelper(options)
  }
)

export const selectCompanyOptions = createSelector(
  [selectCompanyFilterData, selectCompanyInput, selectCompanyFilter],
  filterDataByInput
)

export const selectAMFilterData = createSelector(
  selectData,
  (data) => {
    const options = data.map(account => ({
      value: account.user.bhId,
      label: `${account.user.firstName} ${account.user.lastName}`
    }))
    return processOptionsHelper(options)
  }
)

export const selectAMOptions = createSelector(
  [selectAMFilterData, selectAMInput, selectAMFilter],
  filterDataByInput
)

export const selectDivisionOptions = createSelector(
  selectData,
  (data) => {
    const options = data.map(account => ({
      value: account.category.bhId,
      label: account.category.name
    }))
    return processOptionsHelper(options)
  }
)

export const selectProgramOptions = createSelector(
  selectData,
  (data) => {
    const options = data.map(account => ({
      value: account.program,
      label: account.program
    }))
    return processOptionsHelper(options)
  }
)

const selectDataFilterBy = createSelector(
  [selectDataByAM, selectDataByCompany, selectFilterBy],
  (dataAM, dataCompany, filterByCompany) => {
    return filterByCompany ? dataCompany : dataAM
  }
)

const selectDataFilterByCompany = createSelector(
  [selectDataFilterBy, selectCompanyFilter],
  (data, company) => {
    if (!company || !company.length) {
      return data
    }
    const filterData = data.map((group) => {
      return group.filter((account) => company.includes(account.clientCorporation.bhId))
    })
    return filterData.filter((group) => group.length > 0)
  }
)

const selectDataFilterByAM = createSelector(
  [selectDataFilterByCompany, selectAMFilter],
  (data, accountManager) => {
    if (!accountManager || !accountManager.length) {
      return data
    }
    const filterData = data.map((group) => {
      return group.filter((account) => accountManager.includes(account.user.bhId))
    })
    return filterData.filter((group) => group.length > 0)
  }
)

const selectDataFilterByDivision = createSelector(
  [selectDataFilterByAM, selectDivisionFilter],
  (data, division) => {
    if (!division || !division.length) {
      return data
    }
    const filterData = data.map((group) => {
      return group.filter((account) => division.includes(account.category.bhId))
    })
    return filterData.filter((group) => group.length > 0)
  }
)

export const selectVisibleData = createSelector(
  [selectDataFilterByDivision, selectProgramFilter],
  (data, program) => {
    if (!program || !program.length) {
      return data
    }
    const filterData = data.map((group) => {
      return group.filter((account) => program.includes(account.program))
    })
    return filterData.filter((group) => group.length > 0)
  }
)

export const selectSearchStatus = createSelector(
  [selectData, selectVisibleData],
  (data, visibleData) => {
    return data.length && !visibleData.length
  }
)
