import React, { useMemo } from 'react'
import groupDepartments from '../helpers/groupDepartments'

import BrandDepartment from './BrandDepartment'
import BrandDepartmentListSkeleton from './BrandDepartmentListSkeleton'

const BrandDepartmentList = ({ departments, onDepartmentSelect }) => {
  const brandDepartments = useMemo(() => {
    const groupedDepartments = groupDepartments(departments)
    return groupedDepartments.map(v => (
      <BrandDepartment
        key={v.name}
        onDepartmentSelect={onDepartmentSelect}
        {...v}
      />
    ))
  }, [departments, onDepartmentSelect])

  return brandDepartments?.length ? (
    <ul className='c-brand-department-list'>{brandDepartments}</ul>
  ) : (
    <BrandDepartmentListSkeleton />
  )
}

export default BrandDepartmentList
