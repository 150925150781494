import React from 'react'
import CircleCheck from './CircleCheck'
import CirclePercent from './CirclePercent'
import DollarBag from './DollarBag'
import Calendar from './Calendar'
import FireWall from './FireWall'
import PaperPlane from './PaperPlane'

const DealsIcon = props => {
  const { type, ...rest } = props
  switch (type) {
    case 'circle-check':
      return <CircleCheck {...rest} />
    case 'circle-percent':
      return <CirclePercent {...rest} />
    case 'dollar-bag':
      return <DollarBag {...rest} />
    case 'calendar':
      return <Calendar {...rest} />
    case 'fire-wall':
      return <FireWall {...rest} />
    case 'paper-plane':
      return <PaperPlane {...rest} />
    default:
      return null
  }
}

export default DealsIcon
