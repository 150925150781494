import { SET_USERS, CLEAR_USERS, LOAD_USER_SELECTION_BEGIN, LOAD_USER_SELECTION_FAIL } from '../actionTypes'
import { setActiveUser } from './auth'
import { updateJobOrderOwner } from './jobOrders'
import logger from '../../requests/logger'
import { cloneDeep } from 'lodash'
import { getDepartments } from '../../requests'

export const loadUserSelectionBegin = () => {
  return {
    type: LOAD_USER_SELECTION_BEGIN
  }
}
export const loadUserSelectionFail = () => {
  return {
    type: LOAD_USER_SELECTION_FAIL
  }
}

export const loadUserSelection = department => dispatch => {
  dispatch(loadUserSelectionBegin())
  getDepartments()
    .then(({ data }) => {
      let foundDepartment
      if (data && data.length) {
        foundDepartment = data.find(({ name, shortName, displayName }) => {
          return [name, shortName, displayName].includes(department)
        })
      }
      if (!foundDepartment) {
        dispatch(loadUserSelectionFail())
      }
    })
    .catch(error => console.error(error))
}

export const setUsers = users => {
  return {
    type: SET_USERS,
    payload: users
  }
}

export const clearUsers = () => {
  return { type: CLEAR_USERS }
}

export const patchUser = (bhId, payload, event) => {
  return function(dispatch, getState) {
    let {
      users: { users },
      auth: { activeUser }
    } = getState()
    let newUsers = cloneDeep(users)
    let user = newUsers.find(userObj => userObj.bhId === bhId)
    if (bhId !== activeUser.bhId) {
      dispatch(updateJobOrderOwner(bhId, payload))
    }
    if (user) {
      let updatedUser = {
        ...user,
        ...payload
      }
      let userIdx = newUsers.findIndex(userObj => userObj.bhId === bhId)
      newUsers.splice(userIdx, 1, updatedUser)
      dispatch(setUsers(newUsers))
      let newActiveUser = newUsers.find(user => user.bhId === activeUser.bhId)
      if (newActiveUser) {
        dispatch(setActiveUser(newActiveUser))
      }
    } else {
      logger({
        stackError: `User not found for a ${event} event with the user bhId ${bhId}`
      })
    }
  }
}
