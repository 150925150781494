import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'

const DeleteNoteConfirmationDialog = ({ onConfirm, onCancel, open }) => {
  return (
    <Dialog
      className='c-pidb__remove-dialog'
      open={open}
      aria-describedby='confirm-pi-removal'
      sx={{
        '.MuiDialog-paper': { padding: '12px 8px 24px' },
        borderRadius: '0px'
      }}
    >
      <DialogContent>
        <DialogContentText id='confirm-pi-removal' sx={{ color: '#1A1A1A' }}>
          Deleting these notes is irriversible, are you sure you want to
          continue?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <button onClick={onCancel} className='c-btn c-btn--bare c-btn--cancel'>
          Cancel
        </button>
        <button className='c-btn--cta-1 c-btn c-btn--bare' onClick={onConfirm}>
          Confirm
        </button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteNoteConfirmationDialog
