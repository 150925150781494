import {
  SET_USERS,
  CLEAR_USERS,
  LOAD_USER_SELECTION_BEGIN,
  LOAD_USER_SELECTION_FAIL
} from '../actionTypes'

const DEFAULT_STATE = {
  error: false,
  users: [],
  loading: false
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case CLEAR_USERS:
      return DEFAULT_STATE
    case SET_USERS:
      return {
        users: action.payload,
        error: !action.payload.length,
        loading: false
      }
    case LOAD_USER_SELECTION_BEGIN:
      return {
        ...state,
        loading: true
      }
    case LOAD_USER_SELECTION_FAIL:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}
