import {
  SET_PI_DB_DATA,
  SET_PI_DB_DATA_LOADING,
  SET_PI_DB_SEARCH_FILTER,
  SET_PI_DB_BOARD_FILTER,
  SET_PI_DB_DIVISION_FILTER,
  SET_PI_TO_DELETE,
  EDIT_PI,
  REMOVE_PI,
  ADD_PI,
  SET_PI_DB_DETAILS_MODAL
} from '../actionTypes'

const DEFAULT_STATE = {
  data: [],
  loading: false,
  rowToDelete: null,
  detailsModal: {
    open: false,
    data: null,
    mode: 'edit'
  },
  meta: {
    filters: {
      board: '',
      search: '',
      division: ''
    }
  }
}

const piDb = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_PI_DB_DATA:
      return {
        ...state,
        data: action.payload
      }
    case EDIT_PI:
      return {
        ...state,
        data: state.data.map(v => {
          if (v.id === action.payload.id) {
            return action.payload
          } else {
            return v
          }
        })
      }
    case REMOVE_PI:
      return {
        ...state,
        data: state.data.filter(v => {
          return v.id !== action.payload.id
        })
      }
    case ADD_PI:
      return {
        ...state,
        data: [action.payload, ...state.data]
      }
    case SET_PI_DB_DATA_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case SET_PI_TO_DELETE:
      return {
        ...state,
        rowToDelete: action.payload
      }
    case SET_PI_DB_SEARCH_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters,
            search: action.payload
          }
        }
      }
    case SET_PI_DB_BOARD_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters,
            board: action.payload
          }
        }
      }
    case SET_PI_DB_DIVISION_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters,
            division: action.payload
          }
        }
      }
    case SET_PI_DB_DETAILS_MODAL:
      return {
        ...state,
        detailsModal: { ...state.detailsModal, ...action.payload }
      }
    default:
      return state
  }
}

export default piDb
