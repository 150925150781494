import React from 'react'
import JobCardHeader from './JobCardHeader'
import JobCardInfo from './Info'
import TopTabs from './TopTabs'
import Candidates from './Candidates'
import JobActions from './Actions'

const JobCard = props => {
  const {
    jobOrders,
    headerless,
    useTouchscreenBoard,
    bhId,
    isHot,
    showClearance,
    boardType,
    setOpenTimesModalContent,
    openTimesModalVisible,
    auth: { activeUser }
  } = props

  const job = jobOrders.data.find(jo => jo.bhId === Number(bhId))
  if (!job) return null
  const { Owner } = job

  const jobColor = () => {
    if (Owner.bhId === activeUser.bhId) {
      return `#${activeUser.color}`
    }
    return `#${Owner.color}`
  }

  return (
    <div key={bhId} className='element-container' id={bhId}>
      <div className='inner-container'>
        <TopTabs {...props} job={job} jobColor={jobColor} />
        <div className='job-block'>
          <div className={`job-line ${!headerless && 'webapp'}`}>
            <JobCardHeader
              isHot={isHot}
              showClearance={showClearance}
              {...props}
              job={job}
              jobColor={jobColor}
              headerless={headerless}
              setOpenTimesModalContent={setOpenTimesModalContent}
              openTimesModalVisible={openTimesModalVisible}
            />
            <JobCardInfo {...props} job={job} />
            <Candidates
              isHot={isHot}
              {...props}
              job={job}
              jobColor={jobColor}
            />
          </div>
        </div>
        <JobActions
          {...props}
          headerless={headerless}
          useTouchscreenBoard={useTouchscreenBoard}
          job={job}
          jobColor={jobColor}
          boardType={boardType}
        />
      </div>
    </div>
  )
}

export default JobCard
