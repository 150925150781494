import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SmartInput } from './SmartInput'
import { jobsFilterConfig } from '../../../boards/jobsBoard/filterConfig'
import {
  setJobOrdersCompanyFilter,
  setJobOrdersOwnersFilter,
  setJobOrdersProgramFilter,
  setJobOrdersRequiredSkillFilter,
  setJobOrdersTagFilter
} from '../../../../redux/actions'
import { selectJobOrdersFilters } from '../../../../redux/selectors'
import JobsBoardToggles from 'components/boards/jobsBoard/JobsBoardToggles'

const selectDepartmentSettings = state =>
  state.department && state.department.settings
const selectJobOrdersData = state =>
  (state.jobOrders && state.jobOrders.data) || []

const JobsBoardFilters = () => {
  const dispatch = useDispatch()
  const depSettings = useSelector(selectDepartmentSettings)
  const jobOrdersData = useSelector(selectJobOrdersData)
  const jobOrdersFilters = useSelector(selectJobOrdersFilters)
  const handleJobsFilter = useCallback(
    (filter, value) => {
      switch (filter) {
        case 'company':
          return dispatch(setJobOrdersCompanyFilter(value))
        case 'owner':
          return dispatch(setJobOrdersOwnersFilter(value))
        case 'program':
          return dispatch(setJobOrdersProgramFilter(value))
        case 'requiredSkill':
          return dispatch(setJobOrdersRequiredSkillFilter(value))
        case 'tag':
          return dispatch(setJobOrdersTagFilter(value))
        default:
          console.warn('Unknown jobs board filter', filter)
      }
    },
    [dispatch]
  )

  const renderJobsBoardFilters = useCallback(() => {
    const shouldShowProgramFilter = depSettings && depSettings['program_field']
    const jobsFilters = jobsFilterConfig(jobOrdersData, shouldShowProgramFilter)
    return jobsFilters.map(filter => {
      if (filter.hidden) {
        return null
      }
      return (
        <SmartInput
          key={`jobs_${filter.label}_filter`}
          label={filter.label}
          filterLabel={filter.filterLabel}
          value={jobOrdersFilters[filter.filterLabel]}
          dataSource={filter.options}
          multiSelect={filter.multiSelect}
          onChange={value => handleJobsFilter(filter.filterLabel, value)}
          placeholder={filter.placeholder}
        />
      )
    })
  }, [depSettings, handleJobsFilter, jobOrdersData, jobOrdersFilters])

  return (
    <>
      {renderJobsBoardFilters()}
      <JobsBoardToggles includeLabelSpacer />
    </>
  )
}

export default JobsBoardFilters
