import { createSelector } from 'reselect'

export const selectSelectedDepartment = state => state.department.selectedDepartment
export const selectDirectLaborCost = state => state.department.settings.direct_labor_cost
export const selectDepartmentSettings = state => state.department.settings
export const selectCategories = state => state.department.categories
export const selectStyleGuideUrl = state => state.department.settings.style_guide_url

export const selectCurrentFiscalMonthRange = createSelector(
  selectDepartmentSettings,
  settings => {
    if (settings && settings.current_fiscal_month_start && settings.current_fiscal_month_end) {
      return [settings.current_fiscal_month_start, settings.current_fiscal_month_end]
    } else {
      return null
    }
  }
)
