export default (placements, dlc) => {
  let totalDeals = placements.length

  let totalBillRateValue = 0
  let totalSpredValue = 0

  placements.forEach(placement => {

    let { employeeType, billRate, payRate, perDiemRate } = placement

    let spread = 0

    let perDiem = Number(perDiemRate)
    if (employeeType === 'W2') {
      spread = billRate - (payRate * dlc + perDiem)
    } else { // include employeeType === "1099"
      spread = billRate - (payRate + perDiem)
    }

    totalBillRateValue += billRate
    totalSpredValue += spread
  })

  let averageSpread = totalSpredValue / placements.length

  let averageMargin = (totalSpredValue / totalBillRateValue) * 100

  return {
    totalDeals: isNaN(totalDeals.toFixed(0)) ? '0' : totalDeals.toFixed(0),
    totalSpread: isNaN(totalSpredValue.toFixed(2)) ? '0' : totalSpredValue.toFixed(2),
    averageMargin: isNaN(averageMargin.toFixed(2)) ? '0' : averageMargin.toFixed(2),
    averageSpread: isNaN(averageSpread.toFixed(2)) ? '0' : averageSpread.toFixed(2)
  }
}
