import React from 'react'

const EmptyListElement = React.memo(({ title }) => {
  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {title}
    </div>
  )
})

EmptyListElement.displayName = 'EmptyListElement'

export default EmptyListElement
