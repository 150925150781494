import {
  SET_STARTS_MNGMT_BRAND_FILTER,
  SET_STARTS_MNGMT_DIVISION_FILTER,
  SET_STARTS_MNGMT_EMP_TYPE_FILTER,
  SET_STARTS_MNGMT_DATE_RANGE_FILTER,
  SET_STARTS_MNGMT_PLACEMENTS,
  SET_STARTS_MNGMT_NAME_FILTER,
  SET_STARTS_MNGMT_STATUS_FILTER,
  SET_STARTS_MNGMT_OWNER_FILTER,
  SET_STARTS_MNGMT_INCOMPLETE_PPW_FILTER,
  UPDATE_ONBOARDING_PAPERWORK_SUCCESS,
  SET_SELECTED_PLACEMENT,
  SAVE_ONBOARDING_PAPERWORK_NOTES_SUCCESS,
  UPDATE_STARTS_PLACEMENT_IS_CLEARED_FLAG,
  UPDATE_STARTS_MNGMT_PLACEMENT,
  REMOVE_STARTS_PLACEMENT_V2,
  APPROVE_STARTS_CANDIDATE,
  SET_STARTS_MNGMT_APPROVED_VIEW,
  REVERT_APPROVE_STARTS_CANDIDATE
} from '../actionTypes'

const DEFAULT_STATE = {
  data: [],
  selectedPlacement: null,
  meta: {
    filters: {
      brand: '',
      division: '',
      empType: '',
      startDateRange: [],
      name: '',
      status: '',
      owner: [],
      incompletePpw: [],
      approvedView: false
    }
  }
}

const placementsStarts = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_STARTS_MNGMT_PLACEMENTS:
      return {
        ...state,
        data: action.payload
      }
    case SET_STARTS_MNGMT_BRAND_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters,
            brand: action.payload
          }
        }
      }
    case SET_STARTS_MNGMT_DIVISION_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters,
            division: action.payload
          }
        }
      }
    case SET_STARTS_MNGMT_EMP_TYPE_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters,
            empType: action.payload
          }
        }
      }
    case SET_STARTS_MNGMT_DATE_RANGE_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters,
            startDateRange: action.payload
          }
        }
      }
    case SET_STARTS_MNGMT_NAME_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters,
            name: action.payload
          }
        }
      }
    case SET_STARTS_MNGMT_STATUS_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters,
            status: action.payload
          }
        }
      }
    case SET_STARTS_MNGMT_OWNER_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters,
            owner: action.payload
          }
        }
      }
    case SET_STARTS_MNGMT_INCOMPLETE_PPW_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters,
            incompletePpw: action.payload
          }
        }
      }
    case SET_STARTS_MNGMT_APPROVED_VIEW:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters,
            approvedView: action.payload
          }
        }
      }
    case SET_SELECTED_PLACEMENT:
      return {
        ...state,
        selectedPlacement: action.payload
      }
    case SAVE_ONBOARDING_PAPERWORK_NOTES_SUCCESS:
    case UPDATE_ONBOARDING_PAPERWORK_SUCCESS:
      return {
        ...state,
        selectedPlacement: action.payload,
        data: state.data.map(pl => {
          if (pl.id === action.payload.id) {
            return action.payload
          }
          return pl
        })
      }
    case UPDATE_STARTS_MNGMT_PLACEMENT:
    case UPDATE_STARTS_PLACEMENT_IS_CLEARED_FLAG: {
      const updatedPlacement = action.payload
      if (!state.data.find(p => p.id === updatedPlacement.id)) {
        return {
          ...state,
          data: [...state.data, updatedPlacement]
        }
      }
      return {
        ...state,
        selectedPlacement:
          state.selectedPlacement &&
          state.selectedPlacement.id === updatedPlacement.id
            ? updatedPlacement
            : state.selectedPlacement,
        data: state.data.map(p => {
          if (p.id === updatedPlacement.id) return updatedPlacement
          return p
        })
      }
    }
    case REMOVE_STARTS_PLACEMENT_V2:
      return {
        ...state,
        data: state.data.filter(p => p.id === action.payload)
      }
    case APPROVE_STARTS_CANDIDATE:
    case REVERT_APPROVE_STARTS_CANDIDATE:
      return {
        ...state,
        selectedPlacement: action.payload,
        data: state.data.map(pl => {
          if (pl.id === action.payload.id) {
            return action.payload
          }
          return pl
        })
      }
    default:
      return state
  }
}

export default placementsStarts
