import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import toast from '../../../../helpers/toastSettings'
import {
  saveOnboardingPaperworkNotes,
  updateOnboardingPaperwork
} from '../../../../redux/actions'
import sortOnboardingPaperworkModal from '../../../boards/startsBoard/helpers/sortOnboardingPaperworkModal'
import { Paperwork } from './components'

const OnboardingPaperwork = ({ placement }) => {
  const dispatch = useDispatch()
  const [notes, setNotes] = useState(
    placement && placement.notes ? placement.notes : ''
  )
  const [saveStatus, setSaveStatus] = useState(null)
  const onToggle = useCallback(
    (item, updateItem, updateValue) => {
      if (!placement.approved) {
        dispatch(
          updateOnboardingPaperwork(placement, item, updateItem, updateValue)
        )
      } else {
        toast.error(
          `${placement.candidateName} has been approved and can no longer be updated.`
        )
      }
    },
    [placement, dispatch]
  )
  const onSaveNotes = useCallback(async () => {
    if (!placement.approved) {
      setSaveStatus('saving')
      const saved = await dispatch(
        saveOnboardingPaperworkNotes(placement, notes)
      )
      if (saved) setSaveStatus('saved')
      setTimeout(() => {
        setSaveStatus(null)
      }, 5 * 1000)
    } else {
      toast.error(
        `${placement.candidateName} has been approved and can no longer be updated.`
      )
    }
  }, [notes, placement, dispatch, setSaveStatus])
  if (!placement) return null
  const {
    employeeType,
    candidateName,
    paperworkJson,
    formattedPaperworkJson
  } = placement
  return (
    <div className='c-onboarding-paperwork c-onboarding-paperwork--fill-width'>
      <div className='c-onboarding-paperwork__header'>
        <span className='c-onboarding-paperwork__header-text'>
          Onboarding Paperwork
        </span>
      </div>
      <div className='c-onboarding-paperwork__body'>
        <Paperwork
          title={'Consultant Name'}
          type='text'
          value={candidateName ? candidateName : 'N/A'}
        />
        <Paperwork title={'Employee Type'} type='text' value={employeeType} />
        {paperworkJson && paperworkJson.length
          ? (formattedPaperworkJson || paperworkJson)
              .sort(sortOnboardingPaperworkModal)
              .map((paperwork, index) => (
                <Paperwork
                  key={paperwork.title || index}
                  onToggle={onToggle}
                  data={paperwork}
                  {...paperwork}
                />
              ))
          : null}
        <div className='c-onboarding-paperwork__row'>
          <div className='c-onboarding-paperwork__item'>
            <div className='c-onboarding-paperwork__col c-onboarding-paperwork__col--left'>
              Comments
            </div>
            <div className='c-onboarding-paperwork__col c-onboarding-paperwork__col--right c-onboarding-paperwork__col--comments'>
              <textarea
                value={notes}
                onChange={e => setNotes(e.currentTarget.value)}
                rows={4}
                className='c-onboarding-paperwork__notes'
                placeholder='Give short insights or details about the onboarding'
              />
              <div className='c-onboarding-paperwork__save-notes-wrapper'>
                <button
                  className='c-btn--cta-1 c-btn c-btn--bare'
                  onClick={onSaveNotes}
                >
                  Save Notes
                </button>
                {saveStatus && (
                  <div
                    className={`c-onboarding-paperwork__status ${
                      saveStatus
                        ? `c-onboarding-paperwork__status--${saveStatus}`
                        : ''
                    }`}
                  >
                    {saveStatus}
                    {saveStatus === 'saved' ? '✓' : ''}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OnboardingPaperwork
