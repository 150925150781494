import {
  SAVE_FISCAL_GOAL,
  CLEAR_PREVIOUS_DPEARTMENT_DATA,
  SET_DEALS_CUSTOM_FISCAL_DATE,
  SET_DEALS_GO_LIVE
} from '../actionTypes'

const DEFAULT_STATE = {
  goal: 0,
  count: 0,
  customFiscalDate: null,
  goLive: true
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case CLEAR_PREVIOUS_DPEARTMENT_DATA:
      return DEFAULT_STATE
    case SAVE_FISCAL_GOAL:
      return {
        ...state,
        goal: action.payload
      }
    case SET_DEALS_CUSTOM_FISCAL_DATE:
      return {
        ...state,
        customFiscalDate: action.payload
      }
    case SET_DEALS_GO_LIVE:
      return {
        ...state,
        goLive: action.payload
      }
    default:
      return state
  }
}
