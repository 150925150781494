import React from 'react'
import {
  CardTitle,
  UpdatedDate,
  DealProgressChart,
  FiscalDate
} from './components'
import DealsThisWeekMonth from './DealsThisWeekMonth'

const DealsDivisionBoard = ({ headerless }) => {
  return (
    <div
      className={`c-dashboard c-dashboard--deals${
        headerless ? ' c-dashboard--headerless' : ' c-dashboard--header'
      }`}
    >
      {headerless && <UpdatedDate tight />}
      <DealsThisWeekMonth />
      <div className='c-dashboard__dealprogress'>
        <CardTitle id='DealProgressMonth'>
          Deal Progress This Month By Division <FiscalDate />
        </CardTitle>
        <DealProgressChart headerless={headerless} />
      </div>
    </div>
  )
}

export default DealsDivisionBoard
