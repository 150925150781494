import React, { useCallback, useRef } from 'react'
import dayjs from 'dayjs'
import { useDispatch } from 'react-redux'
import { setBasicMenuPopover } from '../../../../../../redux/actions'

const CardSection = ({ label, children, borderLeft, colorStyle }) => (
  <div
    className={`c-rd-card__section${
      borderLeft ? ' c-rd-card__section--border-left' : ''
    } ${label?.toLowerCase()}`}
  >
    <div className='c-rd-card__section-label'>{label}</div>
    <div className='c-rd-card__section-content' style={colorStyle}>
      {children}
    </div>
  </div>
)

const CardHeader = ({ totalCount, index, variant }) => {
  const tickers = []
  for (let i = 0; i < totalCount; i++) {
    tickers.push(
      <span
        key={i}
        className={`c-rd-card__ticker 
          ${i === index ? 'c-rd-card__ticker--active' : ''}`}
      />
    )
  }
  return (
    <div
      className={`c-rd-card__section c-rd-card__section--counter${
        variant ? ` ${variant}` : ''
      }`}
    >
      <div className='c-rd-card__section-label c-rd-card__section-label--counter'>
        <span className='c-rd-card__checkmark' />
        Recent Deals
      </div>
      <div>{tickers}</div>
    </div>
  )
}

const RecentDealsCard = ({ deal, index, totalCount, headerless }) => {
  const companyRef = useRef()
  const dispatch = useDispatch()
  const setBasicMenu = useCallback(
    v => {
      dispatch(setBasicMenuPopover(v))
    },
    [dispatch]
  )
  const {
    recruiter,
    accountManager,
    spread,
    margin,
    companyName,
    companyUrl,
    division,
    dateAdded
  } = deal || {}
  const formattedDate =
    dateAdded && dayjs(dateAdded).format('MM/DD/YY - hh:mma')
  const amName =
    accountManager && `${accountManager.firstName} ${accountManager.lastName}`
  const rcName = recruiter && `${recruiter.firstName} ${recruiter.lastName}`
  const colorStyle = {
    color: `#${(accountManager && accountManager.color) ||
      (recruiter && recruiter.color)}`
  }
  const tickers = []
  for (let i = 0; i < totalCount; i++) {
    tickers.push(
      <span
        key={i}
        className={`c-rd-card__ticker 
          ${i === index ? 'c-rd-card__ticker--active' : ''}`}
      />
    )
  }
  const openCompanyMenu = useCallback(() => {
    if (headerless) return
    setBasicMenu({
      anchorEl: companyRef.current,
      items: [{ primaryText: 'View on Bullhorn', url: companyUrl }]
    })
  }, [setBasicMenu, companyUrl, headerless])
  return (
    <>
      <CardHeader totalCount={totalCount} index={index} variant='mobile' />
      <div key={index} className='g-boardcard c-rd-card'>
        <CardHeader totalCount={totalCount} index={index} variant='desktop' />
        <div className='c-rd-card__info'>
          <CardSection label='Date Added'>{formattedDate || 'N/A'}</CardSection>
          <CardSection borderLeft label='Division' colorStyle={colorStyle}>
            {division || 'N/A'}
          </CardSection>
          <CardSection borderLeft label='Company Name' colorStyle={colorStyle}>
            <span
              className='c-menu__popover-pointer'
              ref={companyRef}
              onClick={openCompanyMenu}
            >
              {companyName || 'N/A'}
            </span>
          </CardSection>
          <CardSection borderLeft label='Spread'>
            {`$${spread || 0}`}
          </CardSection>
          <CardSection borderLeft label='Margin'>
            {`${(margin && margin.toFixed(2)) || 0}%`}
          </CardSection>
          <CardSection
            borderLeft
            label='Account Manager'
            colorStyle={colorStyle}
          >
            {amName && amName.length > 1 ? amName : 'N/A'}
          </CardSection>
          <CardSection label='Recruiter' colorStyle={colorStyle}>
            {rcName && rcName.length > 1 ? rcName : 'N/A'}
          </CardSection>
        </div>
      </div>
    </>
  )
}

export default RecentDealsCard
