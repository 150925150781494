import { Skeleton } from 'components/shared'
import React from 'react'

const CardSkeleton = () => {
  return (
    <Skeleton.Input style={{ width: 110, height: 138, margin: 15 }} active />
  )
}

const renderCards = () => {
  const list = []
  for (let i = 0; i < 17; i++) list.push(<CardSkeleton key={i} />)
  return list
}

const UserSelectionSkeleton = () => {
  return <div className='user-selection-inner'>{renderCards()}</div>
}

export default UserSelectionSkeleton
