import React from 'react'

const DollarBag = ({ month, bag }) => {
  const fill = month
    ? bag === 'add'
      ? '#FF9135'
      : '#35C0FF'
    : bag === 'add'
    ? '#4BB17F'
    : '#4AC0CB'
  return (
    <svg width='80px' height='80px' viewBox='0 0 1200 1200'>
      <g transform='translate(600 600) scale(0.83 0.83) rotate(0) translate(-600 -600)'>
        <svg
          fill={fill}
          xmlns='http://www.w3.org/2000/svg'
          data-name='Layer 1'
          viewBox='0 0 100 100'
          x='0px'
          y='0px'
        >
          <path d='M50,75.68876c-2.757,0-5.00031-1.92639-5.00031-4.2947H40.99945a8.48557,8.48557,0,0,0,7.00043,8.08131v3.625h4.00024V79.52066c4.154-.70317,7.00043-3.59171,7.00043-7.38583,0-4.32448-4.26588-6.69377-8.0293-8.78374-2.93573-1.63047-5.97156-3.31661-5.97156-5.28695,0-2.45523,2.51139-3.55393,5.00031-3.55393,2.757,0,5.00031,1.92639,5.00031,4.29421h4.00024a8.48536,8.48536,0,0,0-7.00043-8.08081V47.09814H47.99988v3.58011c-4.154.70317-7.00043,3.59178-7.00043,7.38589,0,4.32449,4.26588,6.69377,8.0293,8.78374,2.93573,1.63048,5.97156,3.31661,5.97156,5.28695C55.00031,74.59006,52.48892,75.68876,50,75.68876Z'></path>
          <path d='M29.7429,95H70.2571A17.25921,17.25921,0,0,0,85.99635,70.625C78.276,53.66232,71.43485,42.87529,65.28548,38.01171a5.98618,5.98618,0,0,0-.15949-10.128c2.7755-4.23475,7.87542-12.60508,7.87542-16.9367a5.55945,5.55945,0,0,0-2.92108-5.28744c-3.3669-1.70811-8.88091-.062-15.12885,4.514-4.69316,3.43674-6.45743,4.23952-9.1031,2.93182-1.2408-.61185-12.24831-5.90075-16.80474-3.06854a4.40663,4.40663,0,0,0-2.045,3.91039c0,3.54667,4.7039,10.19075,7.68895,14.04987a5.99067,5.99067,0,0,0,.027,10.01466C28.56515,42.87529,21.724,53.66232,14.00365,70.625A17.25921,17.25921,0,0,0,29.7429,95Zm1.4122-81.56554c1.712-1.06306,8.795,1.2198,12.92169,3.25752,5.223,2.5817,9.1153-.27248,13.23811-3.29073,5.39389-3.94946,9.46347-4.93048,10.95575-4.17408.19972.10157.73051.37062.73051,1.71983,0,3.01379-4.74754,11.0965-8.09,16.04883H38.99347C35.048,22.07671,30.99884,15.9,30.99884,13.94718,30.99884,13.53163,31.0965,13.47059,31.1551,13.43446Zm6.84416,21.56186a2.00012,2.00012,0,0,1,0-4.00024H62.00074a2.00012,2.00012,0,0,1,0,4.00024ZM17.6445,72.2823c13.6732-30.04237,21.58726-33.28573,24.355-33.28573h16.001c2.76775,0,10.68181,3.24336,24.355,33.28573A13.25892,13.25892,0,0,1,70.2571,90.99975H29.7429A13.25892,13.25892,0,0,1,17.6445,72.2823Z'></path>
        </svg>
      </g>
    </svg>
  )
}

export default DollarBag
