import React, { useCallback, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'

import SendoutsDashboard from 'components/boards/dashBoard/SendoutsDashboard'
import DealsDivisionBoard from 'components/boards/dashBoard/DealsDivisionBoard'
import DealsDivisionRankTable from 'components/boards/dashBoard/DealsDivisionRankTable'
import BoardContainer from './BoardContainer'
import { getDashboardSendouts, getRecentDeals } from '../../../../redux/actions'
import { getAccessToken, getSearchParamsFromLocations } from 'helpers/Util'
import { store } from 'index'
import {
  selectDashboardCustomFiscalDate,
  selectDashboardGoLive
} from 'redux/selectors/dashboard'

const WhereWeAt = ({
  selectedDepartment,
  getDashboardSendouts,
  refreshSeconds,
  getRecentDeals,
  location,
  isPaused,
  handleInternalPagination,
  boardPage,
  setBoardPage
}) => {
  const { view } = getSearchParamsFromLocations(location)
  const fiscalMonth = useSelector(selectDashboardCustomFiscalDate)
  const goLive = useSelector(selectDashboardGoLive)
  const loadDashboard = useCallback(() => {
    getAccessToken()
      .then(token => {
        const headerlessToken = store?.getState()?.auth?.credential?.accessToken
        if (token || headerlessToken) {
          getDashboardSendouts(selectedDepartment, fiscalMonth, goLive)
          getDashboardSendouts(selectedDepartment)
          getRecentDeals(selectedDepartment)
        }
      })
      .catch(error => {
        console.warn(error)
      })
  }, [
    selectedDepartment,
    getDashboardSendouts,
    getRecentDeals,
    fiscalMonth,
    goLive
  ])

  useEffect(() => {
    loadDashboard()
  }, [loadDashboard])

  useEffect(() => {
    let interval

    if (refreshSeconds) {
      interval = setInterval(() => {
        loadDashboard()
      }, refreshSeconds * 1000)
    }
    return () => {
      if (interval) clearInterval(interval)
    }
  }, [refreshSeconds, loadDashboard])

  if (view === 'stack-rank') {
    return (
      <BoardContainer
        headerless
        render={page => <DealsDivisionRankTable headerless />}
      />
    )
  }

  return (
    <BoardContainer
      headerless
      maxPageNum={2}
      isPaused={isPaused}
      render={page =>
        page === 1 ? (
          <DealsDivisionBoard headerless />
        ) : page === 2 ? (
          <DealsDivisionRankTable headerless />
        ) : (
          <SendoutsDashboard headerless useTouchscreenBoard />
        )
      }
      handleInternalPagination={handleInternalPagination}
      boardPage={boardPage}
      setBoardPage={setBoardPage}
    />
  )
}

const mapStateToProps = ({ dashboard, department }) => ({
  refreshSeconds: department.settings.dashboard_refresh_seconds
})

const mapDispatchToProps = {
  getDashboardSendouts,
  getRecentDeals
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WhereWeAt)
