import React, { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { BarChart, Bar, Cell, XAxis, LabelList, ReferenceLine } from 'recharts'
import { sumBy } from 'lodash'
import groupDepartments from '../../../../DepartmentSelection/helpers/groupDepartments'
import DealProgressChartVertical from '../DealProgressChartVertical'

const defBgColor = '#EBEBEB'
const textColor = '#645363'

const translate = [
  [[-18]],
  [[-26, -12]],
  [[10, 8, 4], [-7, -4, -1]],
  [[4, 3, 3, 2], [42, 32, 22, 12], [22, 17, 12, 7], [11, 8, 6, 4]],
  [
    [23, 19, 14, 10, 5],
    [12, 11, 9, 6, 4],
    [30, 25, 20, 15, 10],
    [12, 10, 8, 6, 4],
    [22, 18, 14, 10, 6]
  ]
]

const getYAxisTranslate = ypoints => {
  const i = ypoints.length && ypoints.length - 1
  const yTranslate = translate[i]
  const j = (ypoints.length ? parseInt(ypoints[0]) : 5) / ((i + 1) * 5)
  if (j <= yTranslate.length) {
    return yTranslate[j - 1]
  } else {
    return yTranslate[yTranslate.length - (((j + 1) % 2) + 1)]
  }
}

const getYAxisOffset = (offsets, index) => {
  if (index < 0 || index >= offsets.length) {
    return 0
  }
  return offsets[index]
}

const getYAxisPoints = entry => {
  if (!entry.length) return []
  const maxY = Math.max(
    ...entry
      .filter(({ value, target }) => value || target)
      .map(({ value, target }) => value + target)
  )
  let step = 5
  let point = 5
  while (maxY > step * 5) {
    step = step + 5
    point = `${step}`
  }
  const ypoints = [point]
  let cur = step * 2
  while (cur - maxY < step) {
    ypoints.unshift(`${cur}`)
    cur += step
  }
  return ypoints
}

const CustomizedAxisTick = React.memo(props => {
  const { x, y, payload } = props
  const textAttr = {
    x: 0,
    y: 0,
    fontSize: 14,
    dy: 10,
    textAnchor: 'start',
    fill: '#696969',
    transform: 'rotate(45)'
  }

  const name = payload.value
  const transform = `translate(${x},${y})`

  return (
    <g transform={transform}>
      <text {...textAttr}>{name}</text>
    </g>
  )
})

CustomizedAxisTick.displayName = 'CustomizedAxisTick'

const CustomizedLabel = React.memo(props => {
  const { x, y, name, color, data } = props
  const deal = data.find(div => div.name === name)
  if (!deal) {
    return null
  }
  const { dealCount, dealGoal } = deal
  const textAttr = { x, y }
  return (
    <g>
      <text {...textAttr} dx={dealCount < 10 ? 5 : 0} dy={-26} fill={color}>
        {dealCount || 0}
      </text>
      <text {...textAttr} dx={dealGoal < 10 ? 5 : 0} dy={-6} fill={textColor}>
        {dealGoal || 0}
      </text>
    </g>
  )
})

CustomizedLabel.displayName = 'CustomizedLabel'

const selectDivisionDealsGoals = state =>
  state.dashboard.sendouts.divisionDealsGoals
const selectDealsGoals = createSelector(
  selectDivisionDealsGoals,
  dealsGoals => {
    const sorted = groupDepartments(dealsGoals).reduce(
      (arr, { departments }) => [...arr, ...departments],
      []
    )

    const data =
      dealsGoals && dealsGoals.length
        ? sorted.map(({ color, dealGoal, dealCount, divisionShortName }) => {
            const div = { name: divisionShortName, color: `#${color}` }
            const overage = dealCount > dealGoal
            const equal = dealCount === dealGoal
            const value = overage ? dealGoal : equal ? 0 : dealCount
            const target = equal ? dealCount : Math.abs(dealGoal - dealCount)
            Object.assign(div, {
              value,
              target,
              dealCount,
              dealGoal,
              overage,
              equal
            })
            return div
          })
        : []
    return data
  }
)

const DealProgressChart = ({ headerless }) => {
  const node = useRef()
  const data = useSelector(selectDealsGoals)
  const ypoints = getYAxisPoints(data)
  const total = sumBy(data, 'dealCount')

  const top = (ypoints.length ? parseInt(ypoints[0]) : 5) * 1.15
  const ypointsOffset = getYAxisTranslate(ypoints)
  const list = [...data, { value: top, target: 0 }]

  useEffect(() => {
    if (headerless) {
      const scrollWidth = node.current.scrollWidth
      const clientWidth = node.current.clientWidth
      const width = scrollWidth - clientWidth

      let pos = 0
      let end = width
      let flag = 1
      const step = 200
      const interval = setInterval(() => {
        if ((pos + step * flag - end) * flag <= 0) {
          pos += step * flag
        } else if ((pos - end) * flag <= 0) {
          pos = end
          flag = -1 * flag
          end = flag > 0 ? width : 0
        }
        node?.current?.scrollTo({
          top: 0,
          left: pos,
          behavior: 'smooth'
        })
      }, 5000)
      return () => clearInterval(interval)
    }
  }, [headerless])

  return (
    <>
      <div className='c-dealprogress'>
        <div className='c-dealprogress__yaxis'>
          {['99', ...ypoints, '00'].map((y, index) => (
            <div
              key={index}
              style={{
                transform: `translateY(${getYAxisOffset(
                  ypointsOffset,
                  index - 1
                )}px)`
              }}
            >
              {y}
            </div>
          ))}
        </div>
        <div
          className={`c-dealprogress__barchart ${
            headerless ? 'c-dealprogress__barchart--headerless' : ''
          }`}
          ref={node}
        >
          <div className='c-dealprogress__total'>
            <div>{total}</div>
            <div>Total deals for ALKU</div>
          </div>
          {data.length > 0 && (
            <BarChart
              width={list.length * 95}
              height={350}
              data={list}
              margin={{ top: 25 }}
              layout='horizontal'
            >
              <XAxis
                dataKey='name'
                padding={{ left: 0, right: 0 }}
                width={1500}
                height={100}
                tickLine={false}
                tick={<CustomizedAxisTick />}
                interval={0}
              />
              {ypoints.map(p => (
                <ReferenceLine
                  isFront={false}
                  key={p}
                  y={p}
                  stroke={'#c7c7c7'}
                  strokeWidth={0.5}
                  strokeDasharray='4 6'
                />
              ))}
              <Bar dataKey='value' barSize={20} stackId='a'>
                {list.map((entry, index) => (
                  <Cell
                    key={index}
                    fill={entry.value === top ? '#ffffff00' : entry.color}
                  />
                ))}
              </Bar>
              <Bar
                dataKey='target'
                barSize={20}
                stackId='a'
                radius={[10, 10, 0, 0]}
              >
                <LabelList
                  position='top'
                  content={
                    <CustomizedLabel data={data} dataKey='target' top={top} />
                  }
                />
                {list.map((entry, index) => (
                  <Cell
                    key={index}
                    fill={
                      entry.overage
                        ? `${entry.color}40`
                        : entry.equal
                        ? entry.color
                        : defBgColor
                    }
                  />
                ))}
              </Bar>
            </BarChart>
          )}
        </div>
      </div>
      <DealProgressChartVertical data={data} ypoints={ypoints} />
    </>
  )
}

export default React.memo(DealProgressChart)
