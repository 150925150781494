export const DELIMITER = '¶'

/**
 *
 * @param {Array} stringArray - array of strings to compile into one
 * @returns {String} compiled string
 */
export const createMultiValueString = (stringArray = []) => {
  return stringArray && stringArray.join(DELIMITER)
}

/**
 *
 * @param {String} multiValueString - string to parse
 * @returns {Array|String} if string contains delimter, returns split array, else returns original string
 */
export const parseMultiValueString = (multiValueString = '') => {
  if (multiValueString && multiValueString.includes(DELIMITER)) {
    return multiValueString.split(DELIMITER)
  }
  return multiValueString
}
