import React, { Component } from 'react'
import { connect } from 'react-redux'
import Icon from '@mui/material/Icon'

import { CustomTable, TableRow } from '../../shared/table'
import * as actions from '../../../redux/actions'
import filterProgram from '../../../helpers/filterProgram'
import BaseWebsocket from '../../../network/websocket/BaseWebsocket'
import { returnGradient } from '../../../theme/returnGradient'
import LinkButton from '../LinkButton'

class HiddenSubmissions extends Component {
  handleToggle = (event, hidden, submissionId) => {
    event.preventDefault()
    BaseWebsocket.send(this.props.boardType, {
      event: 'submission_change',
      jobSubmissionId: submissionId,
      field: 'hidden',
      value: hidden ? false : true
    })
  }

  render() {
    const {
      client: { name, state, city },
      title,
      job
    } = this.props
    let JOs = filterProgram(
      this.props.jobOrders,
      this.props.auth.selectedCategoryName
    )

    let submissions = JOs.find(jo => jo.bhId === job.bhId)
    if (!submissions) {
      console.warn('No submissions found!')
      return null
    } else {
      submissions = submissions.JobSubmissions
    }

    const tableHeaders = [
      {
        value: 'Candidate',
        width: '70%'
      },
      {
        value: 'Actions',
        width: '30%'
      }
    ]

    return (
      <div
        style={returnGradient(`#${this.props.auth.activeUser.color}`, 90)}
        className='modal-inner hidden-records-dialog'
      >
        <div className='hidden-records-title'>
          Hidden Candidates for {name} - {city}, {state}
        </div>
        <div className='hidden-records-subtitle'>{title}</div>

        <CustomTable className='' headers={tableHeaders} color={'black'}>
          {submissions
            .sort((a, b) =>
              a.Candidate.firstName.localeCompare(b.Candidate.firstName)
            )
            .map(
              ({
                Candidate: {
                  firstName,
                  lastName,
                  Owner: { color }
                },
                hidden,
                leftMessage,
                managerLikes,
                status,
                bhId,
                jobOrder,
                candidate
              }) => {
                return (
                  <TableRow
                    color={'#51B61F'}
                    className='deal-row'
                    key={`deal-${bhId}`}
                  >
                    <td style={{ width: '70%' }}>
                      <span className='candidate-name'>
                        {firstName} {lastName}
                      </span>
                    </td>
                    <td style={{ width: '30%' }}>
                      <LinkButton
                        title='Unhide'
                        onClick={e => this.handleToggle(e, hidden, bhId)}
                      >
                        <Icon
                          baseClassName='fas'
                          className={hidden ? 'fa-eye' : 'fa-eye-slash'}
                          style={{ width: 'auto', fontSize: 24 }}
                        />
                      </LinkButton>
                    </td>
                  </TableRow>
                )
              }
            )}
        </CustomTable>
      </div>
    )
  }
}

const mapStateToProps = ({ jobOrders: { data: jobOrders }, auth }) => {
  return {
    jobOrders,
    auth
  }
}

const HiddenSubmissionsDialog = connect(
  mapStateToProps,
  actions
)(HiddenSubmissions)

export default HiddenSubmissionsDialog
