import React from 'react'

const getValueFromArrayDataIndex = (data, dataIndex) => {
  return dataIndex.reduce((val, nextDataIndex, index) => {
    if (index === 0 && data[nextDataIndex]) return data[nextDataIndex]
    if (val && val[nextDataIndex]) return val[nextDataIndex]
    return val
  }, undefined)
}

const VerticalTable = ({ className, rowKey, columns, dataSource }) => {
  return (
    <div className={`c-vertical-table ${className}`}>
      {dataSource.map((data, rowIndex) => (
        <div
          key={typeof rowKey === 'function' ? rowKey(data) : rowKey}
          className='c-vertical-table__row'
        >
          {columns.map(config => {
            const value = Array.isArray(config.dataIndex)
              ? getValueFromArrayDataIndex(data, config.dataIndex)
              : data[config.dataIndex]
            return (
              <div className='c-vertical-table__data-row' key={config.key}>
                <div className='c-vertical-table__header'>{config.title}</div>
                <div className='c-vertical-table__data'>
                  {config.render ? config.render(value, data, rowIndex) : value}
                </div>
              </div>
            )
          })}
        </div>
      ))}
    </div>
  )
}

export default VerticalTable
