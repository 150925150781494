export const SET_PLACEMENTS = 'SET_PLACEMENTS'
export const SET_JOB_ORDERS = 'SET_JOB_ORDERS'
export const SET_JOB_ORDERS_IS_PRESORTED = 'SET_JOB_ORDERS_IS_PRESORTED'
export const SET_JOB_ORDERS_COMPANY_FILTER = 'SET_JOB_ORDERS_COMPANY_FILTER'
export const SET_JOB_ORDERS_OWNERS_FILTER = 'SET_JOB_ORDERS_OWNERS_FILTER'
export const SET_JOB_ORDERS_PROGRAM_FILTER = 'SET_JOB_ORDERS_PROGRAM_FILTER'
export const SET_JOB_ORDERS_REQUIRED_SKILL_FILTER =
  'SET_JOB_ORDERS_REQUIRED_SKILL_FILTER'
export const SET_JOB_ORDERS_TAG_FILTER = 'SET_JOB_ORDERS_TAG_FILTER'
export const SET_JOB_ORDERS_SORT = 'SET_JOB_ORDERS_SORT'

export const ADD_PLACEMENT = 'ADD_PLACEMENT'
export const REMOVE_PLACEMENT = 'REMOVE_PLACEMENT'
export const SET_POPOVER_STATE = 'SET_POPOVER_STATE'
export const SET_STARTS_FROM_FILTER = 'SET_STARTS_FROM_FILTER'
export const SET_CANDIDATE_HISTORY_MENU = 'SET_CANDIDATE_HISTORY_MENU'
export const SET_BASIC_MENU_POPOVER = 'SET_BASIC_MENU_POPOVER'
export const OPEN_DATE_PICKER = 'OPEN_DATE_PICKER'
export const OPEN_TIME_PICKER = 'OPEN_TIME_PICKER'
export const CLOSE_DATE_PICKER = 'CLOSE_DATE_PICKER'
export const CLOSE_TIME_PICKER = 'CLOSE_TIME_PICKER'
export const SET_BOARD_LOADING_OBJ = 'SET_BOARD_LOADING_OBJ'
export const LOAD_FISCAL_MONTH_DATA_BEGIN = 'LOAD_FISCAL_MONTH_DATA_BEGIN'
export const LOAD_FISCAL_MONTH_DATA_SUCCESS = 'LOAD_FISCAL_MONTH_DATA_SUCCESS'
export const LOAD_FISCAL_MONTH_DATA_FAIL = 'LOAD_FISCAL_MONTH_DATA_FAIL'

export const SET_USER = 'SET_USER'
export const CLEAR_USERS = 'CLEAR_USERS'

export const SET_HEADERLESS_CREDENTIAL = 'SET_HEADERLESS_CREDENTIAL'
export const REFRESH_TOKEN_BEGIN = 'REFRESH_TOKEN_BEGIN'
export const REFRESH_HEADERLESS_TOKEN = 'REFRESH_HEADERLESS_TOKEN'
export const UPDATE_HEADERLESS_TOKEN = 'UPDATE_HEADERLESS_TOKEN'
export const CLEAR_HEADERLESS_CREDENTIAL = 'CLEAR_HEADERLESS_CREDENTIAL'
export const SET_IS_DISCONNECTED = 'SET_IS_DISCONNECTED'
export const UPDATE_SELECTED_DEPARTMENT_CATEGORY_ASSIGNMENT_ID =
  'UPDATE_SELECTED_DEPARTMENT_CATEGORY_ASSIGNMENT_ID'
export const UPDATE_SELECTED_DEPARTMENT_CATEGORY_ASSIGNMENT_NAME =
  'UPDATE_SELECTED_DEPARTMENT_CATEGORY_ASSIGNMENT_NAME'
export const SET_SIDEBAR_IS_COLLAPSED = 'SET_SIDEBAR_IS_COLLAPSED'
export const SET_TOUCHSCREEN_PAGINATION_TYPE = 'SET_TOUCHSCREEN_PAGINATION_TYPE'

export const LOGOUT = 'LOGOUT'
export const SET_MODAL_STATE = 'SET_MODAL_STATE'
export const LOAD_USER_SELECTION_BEGIN = 'LOAD_USER_SELECTION_BEGIN'
export const LOAD_USER_SELECTION_FAIL = 'LOAD_USER_SELECTION_FAIL'
export const SET_USERS = 'SET_USERS'
export const SET_SELECTED_AMS = 'SET_SELECTED_AMS'
export const SET_JOB_ORDERS_ACTIVITY = 'SET_JOB_ORDERS_ACTIVITY'
export const SET_JOB_ORDER_ACTIVITY_HAS_INTERVIEW_PREP_DETAILS =
  'SET_JOB_ORDER_ACTIVITY_HAS_INTERVIEW_PREP_DETAILS'
export const SET_BACKGROUND_STRING = 'SET_BACKGROUND_STRING'
export const SET_JOB_ORDERS_ACTIVITY_OPTIONS_TAB =
  'SET_JOB_ORDERS_ACTIVITY_OPTIONS_TAB'
export const SET_JOB_ORDERS_OPEN_TIMES_TOGGLE =
  'SET_JOB_ORDERS_OPEN_TIMES_TOGGLE'
export const SET_MULTI_DIVISIONS_TOGGLE = 'SET_MULTI_DIVISIONS_TOGGLE'

export const SET_DEPARTMENT_SETTINGS = 'SET_DEPARTMENT_SETTINGS'
export const CLEAR_DEPARTMENTS = 'CLEAR_DEPARTMENTS'
export const SET_DEPARTMENT_ERROR = 'SET_DEPARTMENT_ERROR'
export const SET_DEPARTMENT_FETCH_ERROR = 'SET_DEPARTMENT_FETCH_ERROR'
export const SET_SELECTED_DEPARTMENT = 'SET_SELECTED_DEPARTMENT'
export const SET_SELECTED_BRAND = 'SET_SELECTED_BRAND'
export const CLEAR_PREVIOUS_DPEARTMENT_DATA = 'CLEAR_PREVIOUS_DPEARTMENT_DATA'
export const SET_ACTIVE_DEPARTMENTS = 'SET_ACTIVE_DEPARTMENTS'
export const SET_DEPARTMENT_CATEGORIES = 'SET_DEPARTMENT_CATEGORIES'
export const SET_RECENT_DEALS = 'SET_RECENT_DEALS'

export const SET_STARTS_PLACEMENTS = 'SET_STARTS_PLACEMENTS'
export const ADD_STARTS_PLACEMENT = 'ADD_STARTS_PLACEMENT'
export const REMOVE_STARTS_PLACEMENT = 'REMOVE_STARTS_PLACEMENT'
export const SET_STARTS_PLACEMENTS_COMPANY_FILTER =
  'SET_STARTS_PLACEMENTS_COMPANY_FILTER'
export const SET_STARTS_PLACEMENTS_OWNER_FILTER =
  'SET_STARTS_PLACEMENTS_OWNER_FILTER'
export const SET_STARTS_PLACEMENTS_DATE_RANGE_FILTER =
  'SET_STARTS_PLACEMENTS_DATE_RANGE_FILTER'
export const SET_STARTS_PLACEMENTS_VIEW_MODE = 'SET_STARTS_PLACEMENTS_VIEW_MODE'
export const SET_STARTS_PLACEMENTS_PROGRAM_FILTER =
  'SET_STARTS_PLACEMENTS_PROGRAM_FILTER'
export const SET_STARTS_PLACEMENTS_STATUS_FILTER =
  'SET_STARTS_PLACEMENTS_STATUS_FILTER'
export const UPDATE_STARTS_PLACEMENT_IS_CLEARED_FLAG =
  'UPDATE_STARTS_PLACEMENT_IS_CLEARED_FLAG'
export const UPDATE_STARTS_PLACEMENT = 'UPDATE_STARTS_PLACEMENT'
export const REMOVE_STARTS_PLACEMENT_V2 = 'REMOVE_STARTS_PLACEMENT_V2'

export const SAVE_FISCAL_GOAL = 'SAVE_FISCAL_GOAL'
export const SET_CATEGORY_GOAL = 'SET_CATEGORY_GOAL'
export const UPDATE_CATEGORIES_GOAL = 'UPDATE_CATEGORIES_GOAL'
export const UPDATE_DEPARTMENT_CATEGORY_GOAL = 'UPDATE_DEPARTMENT_CATEGORY_GOAL'
export const SET_DEALS_CUSTOM_FISCAL_DATE = 'SET_DEALS_CUSTOM_FISCAL_DATE'
export const SET_DEALS_GO_LIVE = 'SET_DEALS_GO_LIVE'

export const UPDATE_WEBSOCKET_STATUS = 'UPDATE_WEBSOCKET_STATUS'
export const REMOVE_WEBSOCKET = 'REMOVE_WEBSOCKET'

export const TOGGLE_ASSIGNED_ACCOUNTS_FILTER = 'TOGGLE_ASSIGNED_ACCOUNTS_FILTER'

export const GET_ASSIGNED_ACCOUNTS_BEGIN = 'GET_ASSIGNED_ACCOUNTS_BEGIN'
export const GET_ASSIGNED_ACCOUNTS_SUCCESS = 'GET_ASSIGNED_ACCOUNTS_SUCCESS'
export const GET_ASSIGNED_ACCOUNTS_ERROR = 'GET_ASSIGNED_ACCOUNTS_ERROR'

export const SET_ASSIGNED_ACCOUNTS_COMPANY_FILTER =
  'SET_ASSIGNED_ACCOUNTS_COMPANY_FILTER'
export const SET_ASSIGNED_ACCOUNTS_ACCOUNT_MANAGER_FILTER =
  'SET_ASSIGNED_ACCOUNTS_ACCOUNT_MANAGER_FILTER'
export const SET_ASSIGNED_ACCOUNTS_DIVISION_FILTER =
  'SET_ASSIGNED_ACCOUNTS_DIVISION_FILTER'
export const SET_ASSIGNED_ACCOUNTS_PROGRAM_FILTER =
  'SET_ASSIGNED_ACCOUNTS_PROGRAM_FILTER'

export const SET_ASSIGNED_ACCOUNTS_COMPANY_INPUT =
  'SET_ASSIGNED_ACCOUNTS_COMPANY_INPUT'
export const SET_ASSIGNED_ACCOUNTS_AM_INPUT = 'SET_ASSIGNED_ACCOUNTS_AM_INPUT'

export const GET_DASHBOARD_SENDOUTS_BEGIN = 'GET_DASHBOARD_SENDOUTS_BEGIN'
export const GET_DASHBOARD_SENDOUTS_SUCCESS = 'GET_DASHBOARD_SENDOUTS_SUCCESS'
export const GET_DASHBOARD_SENDOUTS_ERROR = 'GET_DASHBOARD_SENDOUTS_ERROR'
export const SET_DASHBOARD_CUSTOM_FISCAL_DATE =
  'SET_DASHBOARD_CUSTOM_FISCAL_DATE'
export const SET_DASHBOARD_GO_LIVE = 'SET_DASHBOARD_GO_LIVE'

export const SET_STARTS_MNGMT_BRAND_FILTER = 'SET_STARTS_MNGMT_BRAND_FILTER'
export const SET_STARTS_MNGMT_DIVISION_FILTER =
  'SET_STARTS_MNGMT_DIVISION_FILTER'
export const SET_STARTS_MNGMT_EMP_TYPE_FILTER =
  'SET_STARTS_MNGMT_EMP_TYPE_FILTER'
export const SET_STARTS_MNGMT_DATE_RANGE_FILTER =
  'SET_STARTS_MNGMT_DATE_RANGE_FILTER'
export const SET_STARTS_MNGMT_PLACEMENTS = 'SET_STARTS_MNGMT_PLACEMENTS'
export const SET_STARTS_MNGMT_NAME_FILTER = 'SET_STARTS_MNGMT_NAME_FILTER'
export const SET_STARTS_MNGMT_STATUS_FILTER = 'SET_STARTS_MNGMT_STATUS_FILTER'
export const SET_STARTS_MNGMT_OWNER_FILTER = 'SET_STARTS_MNGMT_OWNER_FILTER'
export const UPDATE_ONBOARDING_PAPERWORK_SUCCESS =
  'UPDATE_ONBOARDING_PAPERWORK_SUCCESS'
export const SET_SELECTED_PLACEMENT = 'SET_SELECTED_PLACEMENT'
export const SAVE_ONBOARDING_PAPERWORK_NOTES_SUCCESS =
  'SAVE_ONBOARDING_PAPERWORK_NOTES_SUCCESS'
export const UPDATE_STARTS_MNGMT_PLACEMENT = 'UPDATE_STARTS_MNGMT_PLACEMENT'
export const APPROVE_STARTS_CANDIDATE = 'APPROVE_STARTS_CANDIDATE'
export const SET_STARTS_MNGMT_INCOMPLETE_PPW_FILTER =
  'SET_STARTS_MNGMT_INCOMPLETE_PPW_FILTER'
export const SET_STARTS_MNGMT_APPROVED_VIEW = 'SET_STARTS_MNGMT_APPROVED_VIEW'
export const REVERT_APPROVE_STARTS_CANDIDATE = 'REVERT_APPROVE_STARTS_CANDIDATE'

export const SET_PI_DB_DATA = 'SET_PI_DB_DATA'
export const SET_PI_DB_DATA_LOADING = 'SET_PI_DB_DATA_LOADING'
export const SET_PI_DB_SEARCH_FILTER = 'SET_PI_DB_SEARCH_FILTER'
export const SET_PI_DB_BOARD_FILTER = 'SET_PI_DB_BOARD_FILTER'
export const SET_PI_DB_DIVISION_FILTER = 'SET_PI_DB_DIVISION_FILTER'
export const SET_PI_TO_DELETE = 'SET_PI_TO_DELETE'
export const EDIT_PI = 'EDIT_PI'
export const REMOVE_PI = 'REMOVE_PI'
export const ADD_PI = 'ADD_PI'
export const SET_PI_DB_DETAILS_MODAL = 'SET_PI_DB_DETAILS_MODAL'
