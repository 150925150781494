import React from 'react'
import DepartmentCard from './DepartmentCard'

const BrandDepartment = ({ name, departments, onDepartmentSelect }) => {
  const departmentCards = departments
    .sort((a, b) => {
      return a.displayName.localeCompare(b.displayName)
    })
    .map(dep => (
      <DepartmentCard
        key={dep.displayName}
        {...dep}
        onClick={() => onDepartmentSelect(dep.displayName)}
      />
    ))

  return (
    <li className='c-brand-department-list__group'>
      <h2 className='c-brand-department-list__title'>{name}</h2>
      <div className='c-brand-department-list__cards'>{departmentCards}</div>
    </li>
  )
}

export default BrandDepartment
