import React, { useMemo } from 'react'
import BoardCard from '../BoardCard'
import CardTitle from '../CardTitle'
import WeeklyLineChart from '../WeeklyLineChart'
import useWindowWidth from './useWindowWidth'
import { sumBy, keyBy, mapValues } from 'lodash'
import { getWeekday } from '../../../../../../helpers/dateTime'

const WEEK_DAYS = [
  { name: 'MON', key: 'MONDAY' },
  { name: 'TUE', key: 'TUESDAY' },
  { name: 'WED', key: 'WEDNESDAY' },
  { name: 'THUR', key: 'THURSDAY' },
  { name: 'FRI', key: 'FRIDAY' }
]

const keyByToUpperCase = o => o.weekDay?.toUpperCase()

const caclChartWidth = (width, headerless) => {
  if (headerless) {
    return (width / 2 - 107) * 0.6 - 40
  }
  // 4k Breakpoint
  if (width >= 3840) {
    return (width / 4 - 107) * 0.6 - 40
  }
  // Desktop
  if (width >= 1280) {
    return (width / 2 - 107) * 0.6 - 40
  }
  // Tablets
  if (width >= 768) {
    return (width - 64 - 20) * 0.58
  }
  // default
  return width - 64 - 19
}

const JobsManagement = ({
  division,
  brand,
  total,
  headerless,
  subBrand,
  hasSubBrand
}) => {
  const width = useWindowWidth()
  const chartWidth = caclChartWidth(width, headerless)

  const list = useMemo(() => {
    return [
      { label: 'Division', value: sumBy(division, 'count'), color: 'division' },
      hasSubBrand
        ? {
            label: 'Sub-Brand',
            value: sumBy(subBrand, 'count'),
            color: 'sub-brand'
          }
        : null,
      { label: 'Brand', value: sumBy(brand, 'count'), color: 'brand' },
      { label: 'ALKU', value: sumBy(total, 'count'), color: 'alku' }
    ].filter(v => v)
  }, [brand, division, hasSubBrand, subBrand, total])

  const divisionDays = mapValues(
    keyBy(division, keyByToUpperCase),
    ({ count }) => count
  )
  const subBrandDays = mapValues(
    keyBy(subBrand, keyByToUpperCase),
    ({ count }) => count
  )
  const brandDays = mapValues(
    keyBy(brand, keyByToUpperCase),
    ({ count }) => count
  )
  const totalDays = mapValues(
    keyBy(total, keyByToUpperCase),
    ({ count }) => count
  )

  const weekday = getWeekday()
  const data = WEEK_DAYS.map(({ name, key }, index) => {
    const job = { name }
    if (index + 1 <= weekday) {
      job.Division = divisionDays[key] || 0
      job['Sub-Brand'] = subBrandDays[key] || 0
      job.Brand = brandDays[key] || 0
      job.ALKU = totalDays[key] || 0
    }
    return job
  })

  return (
    <div className='c-jobsmgt'>
      <CardTitle id='JobsManagement'>Jobs Management</CardTitle>
      <BoardCard className='c-jobsmgt__card'>
        <div className='c-dashboard__title c-submittal__mgt__title'>
          Jobs Added
        </div>
        <div className='c-jobsmgt__content'>
          <div className='g-sendouts__data'>
            {list.map(({ label, value, color }) => (
              <div key={label}>
                <div
                  className={`g-sendouts__number c-dashboard__color--${color}`}
                >
                  {value}
                </div>
                <div className='g-sendouts__label'>{label}</div>
              </div>
            ))}
          </div>
          <div className='c-jobsmgt__chart'>
            <WeeklyLineChart
              width={chartWidth}
              data={data}
              hasSubBrand={hasSubBrand}
            />
          </div>
        </div>
      </BoardCard>
    </div>
  )
}

export default JobsManagement
