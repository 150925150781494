import React, { useCallback, useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import BoardManager from 'components/hocs/BoardManager'
import dayjs from 'dayjs'

import calcTotAndAvg from 'components/boards/dealsBoard/calcTotAndAvg'
// import filterProgram from 'helpers/filterProgram'
import {
  renderHeader,
  RowsHeader,
  Row,
  DealsFilters
} from 'components/boards/dealsBoard/subComponents'
import {
  handleDealsData as handleDealsDataSocket,
  handleFiscalData as handleFiscalDataSocket
} from 'redux/actions'
import { SOCKET_BOARDS } from 'constants'

// ws
import BaseWebsocket from 'network/websocket/BaseWebsocket'
import verifyConnectionState from 'network/websocket/helpers/verifyConnectionState'

import { selectDealsGoLive } from 'redux/selectors/deals'

import Skeleton from 'components/shared/Skeleton'
import DealsBoardSkeleton from 'components/boards/dealsBoard/DealsBoardSkeleton'
import { useInterval } from 'components/hocs/hooks'

const Deals = props => {
  const {
    selectedDepartment,
    isPaused,
    useTouchscreenBoard,
    handleInternalPagination,
    boardPage,
    setBoardPage
  } = props
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const deals = useSelector(state => state.deals)
  const customFiscalDate = useSelector(state => state?.deals?.customFiscalDate)
  const goLive = useSelector(selectDealsGoLive)
  const { department, header } = useParams()
  const isHeaderless = useTouchscreenBoard
    ? true
    : !header || header === 'headerless'
  const departmentSettings = useSelector(state => state.department)
  const placements = useSelector(state => state.placements)
  const isLoading = useSelector(state => state.UI.boardLoadingObj.deals)
  const board = useRef()

  const handleDealsData = useCallback(
    msg => {
      dispatch(handleDealsDataSocket(msg))
    },
    [dispatch]
  )
  const handleFiscalData = useCallback(
    msg => {
      dispatch(handleFiscalDataSocket(msg))
    },
    [dispatch]
  )

  const connectSockets = useCallback(() => {
    if (!verifyConnectionState(BaseWebsocket.sockets.DEALS_HUB)) {
      BaseWebsocket.openSignalRSocket(
        SOCKET_BOARDS.DEALS_HUB,
        handleDealsData,
        department,
        customFiscalDate
      )
    }
    if (!verifyConnectionState(BaseWebsocket.sockets.FISCAL_MONTH_HUB)) {
      BaseWebsocket.openSignalRSocket(
        SOCKET_BOARDS.FISCAL_MONTH_HUB,
        handleFiscalData,
        department
      )
    }
  }, [customFiscalDate, department, handleDealsData, handleFiscalData])

  useEffect(() => {
    // touchscreen handles websockets in BoardManager as opposed to the regular routes
    if (!useTouchscreenBoard) {
      connectSockets()
    }

    return () => {
      if (!useTouchscreenBoard) {
        BaseWebsocket.closeSignalRSocket(SOCKET_BOARDS.DEALS_HUB)
        BaseWebsocket.closeSignalRSocket(SOCKET_BOARDS.FISCAL_MONTH_HUB)
      }
    }
  }, [
    department,
    handleDealsData,
    handleFiscalData,
    useTouchscreenBoard,
    customFiscalDate,
    connectSockets
  ])

  useInterval(connectSockets, 60 * 1000)

  // TODO: Not sure why this is unused but need to do some testing before I delete it
  // const handleFieldChange = (event, submissionId, field, value) => {
  //   event.preventDefault()
  //   BaseWebsocket.send('deals', {
  //     event: 'placement_change',
  //     placementId: submissionId,
  //     field: field,
  //     value: value
  //   })
  // }

  const renderSoloOrArray = useCallback(
    datum => {
      if (!Array.isArray(datum)) {
        return (
          <Row
            department={departmentSettings}
            placements={placements}
            bhId={datum}
            key={datum}
          />
        )
      } else {
        return datum.map((arr2, idx) => (
          <div key={`masonry-col-${idx}`} className='masonry-col'>
            {arr2.map(o => {
              return (
                <Row
                  key={o.id}
                  department={departmentSettings}
                  placements={placements}
                  bhId={o.id}
                  useTouchscreenBoard
                />
              )
            })}
          </div>
        ))
      }
    },
    [departmentSettings, placements]
  )

  deals.department = selectedDepartment
  const formattedPlacements = useMemo(() => {
    let formatted = placements
    // go live deals are included by default so we filter them out using the isGoLiveJob bool if the toggle is off
    if (!goLive) {
      formatted = formatted.filter(v => !v.isGoLiveJob)
    }
    // Disabled because there is no need to filter by categories at the moment
    // https://alkutech.atlassian.net/browse/MAINT-46
    // formatted = filterProgram(
    //   formatted,
    //   selectedDepartment?.split('-').join(' ')
    // )
    formatted = formatted.sort(function(left, right) {
      return dayjs(right.dateAdded)
        .utc()
        .diff(dayjs(left.dateAdded).utc())
    })

    return formatted
  }, [placements, goLive])
  const data = calcTotAndAvg(
    formattedPlacements,
    departmentSettings.settings.direct_labor_cost
  )
  return (
    <Skeleton.Board
      loading={!isLoading}
      skeletonChildren={<DealsBoardSkeleton />}
    >
      <div
        id='deals-board'
        className={`board-container ${isHeaderless ? 'headerless' : ''}`}
      >
        {useTouchscreenBoard || !isHeaderless ? <DealsFilters /> : null}
        {renderHeader(
          data,
          departmentSettings.categories,
          auth.activeUser.departmentCategoriesDealsAssignmentId
        )}
        <RowsHeader department={departmentSettings} />
        <BoardManager
          useTouchscreenBoard={useTouchscreenBoard}
          customOffset={270}
          elementMargins={12}
          ref={board}
          key='dealsboard'
          department={selectedDepartment}
          data={formattedPlacements}
          headerless={isHeaderless}
          renderElement={renderSoloOrArray}
          numberOfColumns={1}
          isPaused={isPaused}
          handleInternalPagination={handleInternalPagination}
          boardPage={boardPage}
          setBoardPage={setBoardPage}
        />
      </div>
    </Skeleton.Board>
  )
}

export default Deals
