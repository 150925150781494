import { isAfterToday } from '../../../helpers/dateTime'

export default function getApprovedButtonDisabledState(selectedStart) {
  if (!selectedStart) return true
  const { paperworkPercentageComplete, dateBegin } = selectedStart
  if (!paperworkPercentageComplete || paperworkPercentageComplete < 1) {
    return true
  }
  if (!dateBegin || isAfterToday(dateBegin)) {
    return true
  }
  return false
}
