import React from 'react'
import { connect } from 'react-redux'
import { NumberCard, JobsManagement, LeadsManagement } from '../subComponents'
import { defaultWeekDays } from '../../helper'

const SubmittalMgt = ({ sendouts, headerless }) => {
  const {
    jobsWithoutSubmissions,
    unscreenedCandidates,
    candidatesWithVoicemail,
    candidatesScreenedNotSent,
    leadsManagement,
    weeklyJobsAdded
  } = sendouts
  return (
    <div className='c-submittal'>
      <div className='c-submittal__numbers'>
        <NumberCard
          value={jobsWithoutSubmissions || 0}
          title={'Jobs Without Subs'}
          division
          color={'red'}
        />
        <NumberCard
          value={unscreenedCandidates || 0}
          title={'Unscreened Candidates'}
          division
          color={'skyblue'}
        />
      </div>
      <div className='c-submittal__numbers'>
        <NumberCard
          value={candidatesWithVoicemail || 0}
          title={'Candidates With Voicemail'}
          division
          color={'red'}
        />
        <NumberCard
          value={candidatesScreenedNotSent || 0}
          title={'Screened Not Sent'}
          division
          color={'skyblue'}
        />
      </div>
      <div className='c-submittal__mgt'>
        <JobsManagement
          headerless={headerless}
          {...(weeklyJobsAdded || defaultWeekDays)}
        />
        <LeadsManagement {...(leadsManagement || {})} />
      </div>
    </div>
  )
}

const mapStateToProps = ({ dashboard }) => ({
  sendouts: dashboard.sendouts
})

export default connect(mapStateToProps)(SubmittalMgt)
