import React from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'

const FISCAL_DATE_FORMAT = 'M/DD/YY'

export const formatFiscalDisplayDate = (startDate, endDate) => {
  const startDisplay = dayjs.utc(startDate).format(FISCAL_DATE_FORMAT)
  const endDisplay = dayjs.utc(endDate).format(FISCAL_DATE_FORMAT)
  return `${startDisplay} - ${endDisplay}`
}

const selectCurrentFiscalMonthStart = state =>
  state?.department?.settings?.['current_fiscal_month_start']
const selectCurrentFiscalMonthEnd = state =>
  state?.department?.settings?.['current_fiscal_month_end']
const selectDealsCustomFiscalDate = state => state?.deals?.customFiscalDate

const DealsFiscalDate = React.memo(() => {
  const customFiscalDate = useSelector(selectDealsCustomFiscalDate)
  const currentStartDate = useSelector(selectCurrentFiscalMonthStart)
  const currentEndDate = useSelector(selectCurrentFiscalMonthEnd)
  const dateDisplay = customFiscalDate
    ? formatFiscalDisplayDate(
        customFiscalDate.startDate,
        customFiscalDate.endDate
      )
    : currentStartDate && currentEndDate
    ? formatFiscalDisplayDate(currentStartDate, currentEndDate)
    : null
  return (
    <div className='c-deals-fiscal-date'>
      Fiscal Date
      <div className='c-deals-fiscal-date__date'>{dateDisplay || ''}</div>
    </div>
  )
})

DealsFiscalDate.displayName = 'DealsFiscalDate'

export default DealsFiscalDate
