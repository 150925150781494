import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// redux
import { setDashboardCustomFiscalDate, setDashboardGoLive } from 'redux/actions'
import { selectDashboardGoLive } from 'redux/selectors/dashboard'

// components
import {
  CardTitle,
  UpdatedDate,
  SubmittalMgt,
  SendoutManagement,
  RecentDeals,
  MobileDashboardNav
} from './components'
import DealsThisWeekMonth from './DealsThisWeekMonth'
import { FiscalMonthDropdown, ToggleBtn } from 'components/shared'

const SendoutsDashboard = ({
  headerless = false,
  useTouchscreenBoard = false
}) => {
  const dispatch = useDispatch()
  const goLive = useSelector(selectDashboardGoLive)
  const handleCustomFiscalDateChange = useCallback(
    data => {
      if (data.isCurrent) {
        dispatch(setDashboardCustomFiscalDate(null))
      } else {
        dispatch(
          setDashboardCustomFiscalDate({
            startDate: data.startDate,
            endDate: data.endDate
          })
        )
      }
    },
    [dispatch]
  )
  const handleGoLiveToggle = useCallback(
    val => {
      dispatch(setDashboardGoLive(val))
    },
    [dispatch]
  )
  return (
    <div
      className={`c-dashboard c-dashboard--sendouts${
        headerless ? ' c-dashboard--headerless' : ' c-dashboard--header'
      }`}
    >
      <div className='c-dashboard__update-date'>
        <UpdatedDate />
        {useTouchscreenBoard || !headerless ? (
          <div className='c-filters'>
            <div className='c-filters__item'>
              <FiscalMonthDropdown onChange={handleCustomFiscalDateChange} />
            </div>
            <div className='c-go-live c-filters__item'>
              <ToggleBtn
                activeColor={'#e00005'}
                onChange={handleGoLiveToggle}
                checked={goLive}
              />
              Include Go Live Activity
            </div>
          </div>
        ) : null}
      </div>
      <RecentDeals headerless={headerless} />
      <MobileDashboardNav />
      <div className='c-dashboard__content c-dashboard__content--sendouts'>
        <div>
          <CardTitle id='SendoutManagement'>Sendout Management</CardTitle>
          <div className='c-dashboard__content__left'>
            <SendoutManagement />
          </div>
        </div>
        <div>
          <CardTitle
            id='SubmittalManagement'
            className='c-dashboard__content__right'
          >
            Submittal Management
          </CardTitle>
          <div className='c-dashboard__content__right'>
            <SubmittalMgt headerless={headerless} />
          </div>
        </div>
      </div>
      {!headerless && <DealsThisWeekMonth idPrefix='4k-' />}
    </div>
  )
}

export default SendoutsDashboard
