import React from 'react'

const Close = ({ size = '13', fill = '#FFF' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 13 13'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M2.163 12.5l4.56-4.561 4.318 4.317 1.431-1.432-4.316-4.317 4.529-4.53L11.136.43 6.607 4.96 2.311.662.88 2.095 5.175 6.39l-4.56 4.561z'
      fill={fill}
      fillRule='nonzero'
    />
  </svg>
)

export default Close
