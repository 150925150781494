import React, { useRef } from 'react'
import UserCard from '../../../shared/userCard'
import { Tooltip } from 'antd'

const CardHeader = React.memo(
  ({ cardTitleData, filterByCompany, renderCompanyPopover }) => {
    const companyRef = useRef()
    const renderPop = event => {
      event.stopPropagation()
      renderCompanyPopover(companyRef)
    }
    return (
      <div className='ant-collapse-header-inner'>
        {filterByCompany ? (
          <div className='assigned-accounts-card-table-title'>
            <span
              className='c-menu__popover-pointer'
              ref={companyRef}
              onClick={renderPop}
            >
              {cardTitleData.name}
            </span>
          </div>
        ) : (
          <UserCard
            showNameRight
            size={40}
            fontSize={18}
            bhId={cardTitleData.bhId}
            color={cardTitleData.color}
            avatar={cardTitleData.avatar}
            firstName={cardTitleData.firstName}
            lastName={cardTitleData.lastName}
          />
        )}
        {filterByCompany ? (
          <Tooltip
            placement='left'
            title={
              <div className='antd-tooltip-text-style'>
                Total ALKU Consultants Currently On Billing
              </div>
            }
          >
            {returnSubtitle(filterByCompany, cardTitleData)}
          </Tooltip>
        ) : (
          returnSubtitle(filterByCompany, cardTitleData)
        )}
      </div>
    )
  }
)

const returnSubtitle = (filterByCompany, cardTitleData) => (
  <div className='assigned-accounts-card-table-active-placements-container'>
    <div className='assigned-accounts-card-table-active-placements-title'>
      {filterByCompany ? 'Active Placements:' : 'Total Accounts:'}
    </div>
    <div className='assigned-accounts-card-table-active-placements-number'>
      {filterByCompany
        ? cardTitleData.activePlacements || 0
        : cardTitleData.totalAccounts || 0}
    </div>
  </div>
)

CardHeader.displayName = 'CardHeader'

export default CardHeader
