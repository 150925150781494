import * as React from 'react'
import { Skeleton as AntSkeleton } from 'antd'

import SkeletonInput from './SkeletonInput'
import SkeletonBoard from './SkeletonBoard'

function Skeleton(props) {
  return <AntSkeleton {...props} />
}

Skeleton.Input = SkeletonInput
Skeleton.Board = SkeletonBoard

export default Skeleton
