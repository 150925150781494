import React from 'react'

// this component is intended to replace components using anchor tags to emulate buttons
const LinkButton = ({ className, children, ...restProps }) => {
  return (
    <button
      className={`c-link-button c-btn--bare${className ? ` ${className}` : ''}`}
      {...restProps}
    >
      {children}
    </button>
  )
}

export default LinkButton
