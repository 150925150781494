import {
  SET_JOB_ORDERS,
  SET_JOB_ORDERS_IS_PRESORTED,
  SET_JOB_ORDERS_COMPANY_FILTER,
  SET_JOB_ORDERS_OWNERS_FILTER,
  SET_JOB_ORDERS_REQUIRED_SKILL_FILTER,
  SET_JOB_ORDERS_PROGRAM_FILTER,
  CLEAR_PREVIOUS_DPEARTMENT_DATA,
  SET_JOB_ORDERS_TAG_FILTER,
  SET_JOB_ORDERS_SORT,
  SET_JOB_ORDERS_OPEN_TIMES_TOGGLE,
  SET_MULTI_DIVISIONS_TOGGLE
} from '../actionTypes'

const DEFAULT_STATE = {
  meta: {
    filters: {
      company: '',
      owner: [],
      requiredSkill: '',
      program: '',
      tag: [],
      openTimes: false,
      multiDivisions: false
    },
    sort: {
      value: undefined,
      direction: null
    },
    isPresorted: false
  },
  data: []
}

const jobOrders = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case CLEAR_PREVIOUS_DPEARTMENT_DATA:
      return DEFAULT_STATE
    case SET_JOB_ORDERS:
      return {
        ...state,
        data: action.jobOrders
      }
    case SET_JOB_ORDERS_IS_PRESORTED:
      return {
        ...state,
        data: action.payload.jobOrders,
        meta: {
          ...state.meta,
          isPresorted: action.payload.isPresorted
        }
      }
    case SET_JOB_ORDERS_COMPANY_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters,
            company: action.payload
          }
        }
      }
    case SET_JOB_ORDERS_OWNERS_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters,
            owner: action.payload
          }
        }
      }
    case SET_JOB_ORDERS_PROGRAM_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters,
            program: action.payload
          }
        }
      }
    case SET_JOB_ORDERS_REQUIRED_SKILL_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters,
            requiredSkill: action.payload
          }
        }
      }
    case SET_JOB_ORDERS_TAG_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters,
            tag: action.payload
          }
        }
      }
    case SET_JOB_ORDERS_SORT:
      return {
        ...state,
        meta: {
          ...state.meta,
          sort: action.payload
        }
      }
    case SET_JOB_ORDERS_OPEN_TIMES_TOGGLE:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters,
            openTimes: action.payload
          }
        }
      }
    case SET_MULTI_DIVISIONS_TOGGLE:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters,
            multiDivisions: action.payload
          }
        }
      }
    default:
      return state
  }
}

export default jobOrders
