import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SmartInput } from './SmartInput'
import { assignedAccountsFilterConfig } from '../../../boards/assignedAccounts/config/filterConfig'
import {
  toggleAssignedAccountsFilter,
  setAssignedAccountsCompanyFilter,
  setAssignedAccountsAccountManagerFilter,
  setAssignedAccountsDivisionFilter,
  setAssignedAccountsProgramFilter,
  setAssignedAccountsCompanyInput,
  setAssignedAccountsAMInput,
  updateSelectedDepartmentCategoryAssignmentId
} from '../../../../redux/actions'
import {
  selectAccountFilters,
  selectCompanyOptions,
  selectAMOptions,
  selectFilterBy,
  selectDivisionOptions,
  selectProgramOptions,
  selectData
} from '../../../boards/assignedAccounts/selectors'
import AssignedAccountsToggle from '../../../boards/assignedAccounts/subComponents/AssignedAccountsToggle'
import StartsFilters from './StartsFilters'
import { StartsToggle } from '../../../boards/startsBoard/components'
import StartsMngmtFilters, {
  StartsMngmtNameSearch,
  ViewApprovedBtn
} from './StartsMngmtFilters'
import CategoryFilter from '../../../shared/CategoryFilter'
import FilterToggleBtn from '../../../shared/FilterToggleBtn'
import JobsBoardSort from '../../../boards/jobsBoard/JobsBoardSort'
import ActivityOptionTabs from '../../../boards/activityBoard/subComponents/ActivityOptionTabs'
import CollapseHeader from './CollapseHeader'
import JobsBoardFilters from './JobsBoardFilters'
import { Funnel } from 'components/shared/Icons'

class FilterHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownActive: false,
      mobileHidden: true
    }
  }

  toggleMobileHidden = () => {
    this.setState({
      mobileHidden: !this.state.mobileHidden
    })
  }

  componentWillUnmount() {
    this.setState({
      mobileHidden: true
    })
  }

  renderFilters = () => {
    const { board, filterByCompany, toggleAssignedAccountsFilter } = this.props

    switch (board) {
      case 'jobs':
        return (
          <>
            <div className='filter-header-input-list filter-header-input-list--jobs'>
              <JobsBoardFilters />
            </div>
            <div className='filter-header-input-list filter-header-input-list--jobs filter-header-input-list--right'>
              <JobsBoardSort />
            </div>
          </>
        )
      case 'assigned-accounts':
        return (
          <>
            <div className='filter-header-input-list filter-header-input-list--assigned-accounts'>
              {this.renderAssignedAccountsBoardFilters()}
            </div>
            <AssignedAccountsToggle
              filterByCompany={filterByCompany}
              toggleAssignedAccountsFilter={toggleAssignedAccountsFilter}
            />
          </>
        )
      case 'starts':
        return (
          <>
            <div className='filter-header-input-list filter-header-input-list--starts'>
              <StartsFilters />
              <div className='filter-header-input starts-toggle'>
                <StartsToggle />
              </div>
            </div>
          </>
        )
      case 'starts-management':
        return (
          <>
            <div className='filter-header-input-search'>
              <StartsMngmtNameSearch />
              <ViewApprovedBtn />
            </div>
            <button
              className='filter-header__mobile-toggle'
              onClick={() => this.toggleMobileHidden()}
            >
              {this.state.mobileHidden ? 'Show' : 'Hide'} Filters <Funnel />
            </button>
            <div className='filter-header-input-list filter-header-input-list--starts-mngmt'>
              <ViewApprovedBtn />
              <FilterToggleBtn
                onClick={() =>
                  this.setState({ dropdownActive: !this.state.dropdownActive })
                }
                active={this.state.dropdownActive}
              />
            </div>
          </>
        )
      case 'activity':
        return (
          <div className='filter-header-input-list'>
            <ActivityOptionTabs />
          </div>
        )
      default:
        // console.warn(
        //   "Unknown board and/or department in FilterHeader",
        //   department,
        //   board
        // );
        return null
    }
  }

  handleAssignedAccountsFilter = (filter, value) => {
    const {
      setAssignedAccountsCompanyFilter,
      setAssignedAccountsAccountManagerFilter,
      setAssignedAccountsDivisionFilter,
      setAssignedAccountsProgramFilter
    } = this.props
    switch (filter) {
      case 'company':
        return setAssignedAccountsCompanyFilter(value)
      case 'accountManager':
        return setAssignedAccountsAccountManagerFilter(value)
      case 'division':
        return setAssignedAccountsDivisionFilter(value)
      case 'program':
        return setAssignedAccountsProgramFilter(value)
      default:
        console.warn('Unknown assigned accounts board filter', filter)
    }
  }

  renderAssignedAccountsBoardFilters = () => {
    const {
      setAssignedAccountsCompanyInput,
      setAssignedAccountsAMInput,
      accountsData,
      accountsFilters,
      companyOptions,
      AMOptions,
      divOptions,
      programOptions,
      department,
      board,
      depSettings
    } = this.props
    const shouldShowProgramFilter = depSettings && depSettings['program_field']
    const assignedAccountsFilters = assignedAccountsFilterConfig(
      accountsData,
      companyOptions,
      AMOptions,
      divOptions,
      programOptions,
      shouldShowProgramFilter
    )
    const getOnSearch = {
      company: setAssignedAccountsCompanyInput,
      accountManager: setAssignedAccountsAMInput
    }
    return assignedAccountsFilters.map(filter => {
      if (filter.hidden) {
        return null
      }

      const options = filter.options

      return (
        <SmartInput
          key={`${department}_${board}_${filter.label}_filter`}
          multiSelect
          showArrow
          disabled={!options.length}
          placeholder={
            options.length ? filter.placeholder : filter.notFoundContent
          }
          notFoundContent={filter.notFoundContent}
          loading={filter.loading}
          label={filter.label}
          filterLabel={filter.filterLabel}
          value={accountsFilters[filter.filterLabel]}
          dataSource={options}
          onChange={value =>
            this.handleAssignedAccountsFilter(filter.filterLabel, value)
          }
          onSearch={getOnSearch[filter.filterLabel]}
        />
      )
    })
  }

  handleCategoryChange = async selectedId => {
    const { updateSelectedDepartmentCategoryAssignmentId } = this.props
    updateSelectedDepartmentCategoryAssignmentId(selectedId)
  }

  renderCategoryFilter = () => (
    <>
      <ul className='filter-header-custom-list'>
        <li>
          <div className='filter-header-custom-label'>FILTER VIEW</div>
          <CategoryFilter handleCategoryChange={this.handleCategoryChange} />
        </li>
      </ul>
      {this.shouldRenderDivisor() && <span className='filter-header-divisor' />}
    </>
  )

  shouldRenderCategoryFilter = () => {
    const { board, categories } = this.props
    if (categories.length < 2) return false
    if (board && board.match(/assigned-accounts/)) return false
    return true
  }

  shouldRenderDivisor = () => {
    const { board } = this.props
    if (board && board.match(/jobs|starts/)) return true
    return false
  }

  shouldRenderCollapse = () => {
    const { board } = this.props
    if (board && board.match(/starts-management/)) return true
    return false
  }

  shouldRenderFilterToggle = () => {
    const { board } = this.props
    if (board && board.match(/jobs|^starts$|assigned-accounts/)) return true
    return false
  }

  render() {
    const shouldRenderCategoryFilter = this.shouldRenderCategoryFilter()
    const shouldRenderCollapse = this.shouldRenderCollapse()
    const shouldRenderFilterToggle = this.shouldRenderFilterToggle()
    return (
      <>
        <header
          className={`filter-header ${this.props.board}${
            this.state.mobileHidden ? ' mobile-hidden' : ''
          }`}
        >
          {shouldRenderFilterToggle ? (
            <button
              className='filter-header__mobile-toggle'
              onClick={() => this.toggleMobileHidden()}
            >
              {this.state.mobileHidden ? 'Show' : 'Hide'} Filters <Funnel />
            </button>
          ) : null}
          {shouldRenderCategoryFilter && this.renderCategoryFilter()}
          {this.renderFilters()}
        </header>
        {shouldRenderCollapse && (
          <CollapseHeader isCollapsed={!this.state.dropdownActive}>
            <StartsMngmtFilters />
          </CollapseHeader>
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    accountsData: selectData(state),
    accountsFilters: selectAccountFilters(state),
    filterByCompany: selectFilterBy(state),
    companyOptions: selectCompanyOptions(state),
    AMOptions: selectAMOptions(state),
    divOptions: selectDivisionOptions(state),
    programOptions: selectProgramOptions(state),
    depSettings: state.department && state.department.settings
  }
}

const mapDispatchToProps = {
  toggleAssignedAccountsFilter,
  setAssignedAccountsCompanyFilter,
  setAssignedAccountsAccountManagerFilter,
  setAssignedAccountsDivisionFilter,
  setAssignedAccountsProgramFilter,
  setAssignedAccountsCompanyInput,
  setAssignedAccountsAMInput,
  updateSelectedDepartmentCategoryAssignmentId
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterHeader)
