import ApolloClient, { gql } from 'apollo-boost'
import { getAccessToken } from 'helpers/Util'
import errorHandler from '../../helpers/errorHandler'
import logger from '../../requests/logger'

const client = new ApolloClient({
  uri: `${window.location.origin}/api/graphql`,
  request: async operation => {
    const token = await getAccessToken()
    operation.setContext({
      headers: {
        Authorization: token ? `Bearer ${token}` : ''
      }
    })
  },
  onError: error => {
    errorHandler(error, {
      origin: 'graphQL',
      notifySentry: false,
      notifyUser: false
    })
  }
})

export const sendGraphQLQuery = query =>
  client
    .query({
      query: gql`
        ${query}
      `,
      fetchPolicy: 'no-cache'
    })
    .then(response => response)
    .catch(error => {
      logger(error)
    })

export default client
