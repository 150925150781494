import axios from 'axios'
import { APIBase } from '../config'
import errorHandler from './errorHandler'
import { logoutAdal, getAccessToken } from './Util'

axios.interceptors.response.use(
  response => response,
  e => {
    logoutAdal(e)
    errorHandler(e, {
      origin: 'axios',
      notifySentry: e.request.status !== 401,
      notifyUser: false
    })
  }
)

const axiosRequest = (
  httpAction,
  endpoint,
  payload,
  contentType,
  department = ''
) => {
  return new Promise((resolve, reject) => {
    try {
      department =
        department === '' ? window.location.pathname.split('/')[2] : department
    } catch (error) {
      console.warn(
        'Could not find department at window location',
        window.location.pathname
      )
      reject(error)
    }
    resolve(newRequest(httpAction, endpoint, payload, contentType, department))
  })
}

const newRequest = async (
  httpAction,
  endpoint,
  payload,
  contentType,
  department
) => {
  // return adalApiFetch(axios, `/${department}${APIBase}${endpoint}`, {
  const header = {
    'Content-Type': contentType || 'application/json',
    'cache-control': 'no-cache'
  }

  const token = await getAccessToken()
  header['Authorization'] = `Bearer ${token}`

  const url =
    endpoint === '/board-logs'
      ? `${APIBase}/${department}${endpoint}`
      : !department
      ? `${APIBase}${endpoint}`
      : `/${department}${APIBase}${endpoint}`

  return axios(url, {
    method: httpAction,
    headers: header,
    data: payload
  })
}

const API_GATEWAY_HOST = process.env.REACT_APP_API_HOST
const apiGateWayOptions =
  process.env.NODE_ENV === 'development' ? {} : { baseURL: API_GATEWAY_HOST }

const apiGateWayRequest = async (action, endpoint, data, options = {}) => {
  const headers = options.headers || { 'Content-Type': 'application/json' }
  const token = await getAccessToken()
  headers['Authorization'] = `Bearer ${token}`

  // Because not all REST calls are going through the api gateway, setupProxy is set up to route all calls starting with '/dev/api/'
  // to the dev api gateway on a local environment. This setup will work as long as there is no alku division/department named 'dev'.
  // Should there be a 'dev' department, calls that are supposed to go to http://www.alku.adkalpha.com/dev/api will not be routed properly
  // This is only a concern for a local environment and prod will not be affected.
  const url =
    process.env.NODE_ENV === 'development'
      ? `/dev/api${endpoint}`
      : `/api${endpoint}`
  return axios(url, {
    ...apiGateWayOptions,
    method: action,
    data,
    ...options,
    headers
  })
}

const get = (url, config) => {
  return apiGateWayRequest('get', url, null, config)
}

const put = (url, data, config) => {
  return apiGateWayRequest('put', url, data, config)
}

const post = (url, data, config) => {
  return apiGateWayRequest('post', url, data, config)
}

const patch = (url, data, config) => {
  return apiGateWayRequest('patch', url, data, config)
}

export { axiosRequest, newRequest, get, put, post, patch }
