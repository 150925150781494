import { I9_W2_ONLY, I9 } from 'constants'

const INVALID_GROUP_NAMES = [I9_W2_ONLY, I9]

const paperworkIsValidIncompleteApplicable = paperwork => {
  if (paperwork.isNotApplicable || paperwork.isComplete) return false
  if (!INVALID_GROUP_NAMES.includes(paperwork.groupName)) return true
}

const calculateOnboardingPaperworkComplete = paperworkJson => {
  if (paperworkJson) {
    const hasValidIncompleteApplicablePaperwork = !!paperworkJson.find(
      paperworkIsValidIncompleteApplicable
    )
    // if a single item is applicable and incomplete then onboarding paperwork status is incomplete
    return !hasValidIncompleteApplicablePaperwork
  }
  return false
}

export default calculateOnboardingPaperworkComplete
