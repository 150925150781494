import React from 'react'

import Skeleton from 'components/shared/Skeleton'
import SkeletonHeaderFilter from '../shared/SkeletonHeaderFilter'
import SkeletonFullWidthCard from '../shared/SkeletonFullWidthCard'

const DealsBoardSkeleton = () => {
  return (
    <React.Fragment>
      <div style={{ marginBottom: 32 }}>
        <SkeletonHeaderFilter />
      </div>
      <div
        style={{ marginBottom: 32 }}
        className='c-skeleton-board__half-width-inputs'
      >
        <div style={{ marginRight: 20 }}>
          <Skeleton.Input style={{ height: 195 }} />
        </div>
        <div>
          <Skeleton.Input style={{ height: 195 }} />
        </div>
      </div>
      <SkeletonFullWidthCard height={50} marginBottom={12} />
      <SkeletonFullWidthCard height={50} marginBottom={12} />
      <SkeletonFullWidthCard height={50} marginBottom={12} />
      <SkeletonFullWidthCard height={50} marginBottom={12} />
    </React.Fragment>
  )
}

export default DealsBoardSkeleton
