import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MuiTimePicker from '@mui/lab/MobileTimePicker'
import TextField from '@mui/material/TextField'

import { getDate } from 'helpers/dateTime'
import { selectTimePickerOpen } from 'redux/selectors'
import { closeTimePicker } from 'redux/actions'

const TimePicker = ({ onChange, onAccept }) => {
  const dispatch = useDispatch()
  const [value, setValue] = React.useState(getDate())
  const open = useSelector(selectTimePickerOpen)
  const onClose = useCallback(() => {
    dispatch(closeTimePicker())
  }, [dispatch])
  const onChangeFn = useCallback(
    newValue => {
      setValue(newValue)
      onChange && onChange(newValue)
    },
    [setValue, onChange]
  )
  const onAcceptFn = useCallback(() => {
    if (onAccept) {
      onAccept(value)
    }
    closeTimePicker()
  }, [onAccept, value])

  return (
    <MuiTimePicker
      open={open}
      onClose={onClose}
      value={value}
      onChange={onChangeFn}
      onAccept={onAcceptFn}
      renderInput={params => (
        <TextField style={{ visibility: 'hidden' }} {...params} />
      )}
    />
  )
}

export default TimePicker
