import React from 'react'

const Warning = ({ width = '21', height = '18' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 21 18'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.464 0c-.563 0-1.126.262-1.441.787L.226 15.422c-.648 1.078.181 2.549 1.44 2.549H19.26c1.258 0 2.09-1.471 1.441-2.55L11.906.788C11.59.262 11.027 0 10.464 0zm0 2.27l8.237 13.7H2.228l8.236-13.7zm-1 3.7v5h2v-5h-2zm0 7v2h2v-2h-2z'
        fill='#E00005'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default Warning
