import React, { Component } from 'react'
import { toHSL } from '../../../../../helpers/Util'
import JobHeader from './JobHeader'
import JobSlotHeader from './JobSlotHeader'
import JobSlotSubmission from './JobSlotSubmission'
import { getSlotColor, isSlotReady } from '../../helpers'
import ActivityPaneHeader from './ActivityPaneHeader'
import BaseWebsocket from 'network/websocket/BaseWebsocket'
import { isSameDayByDay } from 'helpers/dateTime'
import { connect } from 'react-redux'

class ActivityPane extends Component {
  shouldComponentUpdate(prevProps, prevState) {
    // this is very bad but in the interest of time and in minimizing risk
    // of breaking many of the children functions of this component who rely
    // on {...this.props} being drilled down, we are going to have a very ugly
    // shouldComponentUpdate in this component. This should absolutely be
    if (
      JSON.stringify(prevProps.jobs) !== JSON.stringify(this.props.jobs) ||
      JSON.stringify(prevProps.auth?.activeUser) !==
      JSON.stringify(this.props.auth?.activeUser) ||
      JSON.stringify(prevProps.users) !== JSON.stringify(this.props.users) ||
      prevProps.bhId !== this.props.bhId ||
      prevProps.isHot !== this.props.isHot ||
      prevProps.headerless !== this.props.headerless ||
      JSON.stringify(prevProps.filterByDate) !==
      JSON.stringify(this.props.filterByDate)
    ) {
      return true
    }
    return false
  }

  renderJob = (job, user) => {
    return (
      <div className='job' key={`jobDetails-${job.bhId}`}>
        <JobHeader {...this.props} job={job} user={user} />
        {job.InterviewSlots.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        ).map((slot, slotIndex) => {
          const slotColor = getSlotColor(slot.faceToFace, slot.date)
          return (
            <div key={`jobContainer-${slot.id}`} className='job-slot-container'>
              <JobSlotHeader
                key={`jobContainer-header-${slot.id}`}
                {...this.props}
                slotColor={slotColor}
                slot={slot}
                job={job}
              />
              {slot.jobSubmissions.map(jobSubmission => {
                return (
                  <JobSlotSubmission
                    key={`jobContainer-slot-submission-${slot.id}-${jobSubmission.bhId
                      }-${jobSubmission.candidate
                        ? jobSubmission.candidate.bhId
                        : ''
                      }`}
                    updateSlot={this.updateSlot}
                    setInterviewSlotMarginSpreadData={
                      this.props.setInterviewSlotMarginSpreadData
                    }
                    isHot={this.props.isHot}
                    slotColor={slotColor}
                    slot={slot}
                    jobSubmission={jobSubmission}
                    {...this.props}
                  />
                )
              })}
              {slotIndex !== job.InterviewSlots.length - 1 ? (
                <div className='job-slot-break' />
              ) : null}
            </div>
          )
        })}
      </div>
    )
  }

  updateSlot = (jobSubmissionId, slotId, field, value) => {
    BaseWebsocket.send(this.props.boardType, {
      event: 'slot_submission_change',
      jobSubmissionId,
      slotId,
      field,
      value
    })
  }

  renderJobs = (jobs, user, splitFound) => {
    const leftColor = toHSL(user.color, 0)
    const rightColor = toHSL(user.color, 25)
    let filtered = jobs.filter(j => j.InterviewSlots.length > 0)
    if (splitFound) {
      let jobIndexes = splitFound.split('/')
      filtered = filtered.slice(jobIndexes[0], jobIndexes[1])
    }

    if (filtered.length) {
      return (
        <div className='jobs-container'>
          {filtered.map((job, index) => {
            if (index !== filtered.length - 1) {
              return (
                <React.Fragment key={`job-${job.bhId}-${user.bhId}`}>
                  {this.renderJob(job, user)}
                  <div
                    className='job-footer'
                    style={{
                      backgroundImage: `linear-gradient(to right, ${leftColor} , ${rightColor})`,
                      height: '5px',
                      margin: '25px 0px',
                      marginLeft: '-32px',
                      width: 'calc(100% + 64px)'
                    }}
                  />
                </React.Fragment>
              )
            } else {
              return this.renderJob(job, user)
            }
          })}
        </div>
      )
    } else {
      return null
    }
  }

  render() {
    const { jobs: jobsData, filterByDate, headerless, isHot } = this.props

    let jobs = jobsData

    let user = this.props.users?.find(user => user.bhId === this.props.bhId)
    // this splitFound business is checking if the data has been formatted into the way masonryPagination
    // needs for multiple columns of repeating account managers and slicing their jobs.

    // if it has, it goes: "<accountManagerId-<startingJobIndex>/<endJobIndex>"
    // the jobs are sliced off in renderJobs if there is a splitFound.
    let splitFound = null

    if (!user) {
      let split = this.props.bhId.split('-')
      if (split.length > 1) {
        user = this.props.users?.find(
          user => user.bhId === Number(this.props.bhId.split('-')[0])
        )
        splitFound = split[1]
      }
      if (!user) {
        return null
      }
    }

    if (!jobs.length) {
      return null
    }

    if (filterByDate) {
      jobs = jobs.reduce((jobsArr, nextJob) => {
        if (nextJob.InterviewSlots.length) {
          const filteredSlots = nextJob.InterviewSlots.filter(slot =>
            isSameDayByDay(filterByDate, slot.date)
          )
          if (filteredSlots.length) {
            jobsArr.push({
              ...nextJob,
              isNotReady: isSlotReady(nextJob),
              InterviewSlots: filteredSlots.map(slot => ({
                ...slot,
                isNotReady: !isSlotReady(slot)
              }))
            })
          }
        }
        return jobsArr
      }, [])
      if (!jobs.length) {
        return null
      }
    }

    return (
      <div
        key={`activity-pane-${user.bhId}`}
        id={user.bhId}
        className={`element-container ${headerless ? 'headerless' : 'has-header'
          }`}
      >
        <div className={'activity-pane'}>
          <ActivityPaneHeader
            isHot={isHot}
            user={user}
            jobs={jobs}
            {...this.props}
          />
          {this.renderJobs(jobs, user, splitFound)}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ users: { users }, auth, department }) => {
  return {
    users,
    auth,
    department
  }
}

export default connect(mapStateToProps)(ActivityPane)
