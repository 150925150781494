import React, { Component } from 'react'

export default class Modal extends Component {
  render() {
    return (
      <div className={'modal-wrapper'}>
        <div
          className={
            this.props.UI.modal.open
              ? 'modal-background active'
              : 'modal-background'
          }
          onClick={() => {
            this.props.setModalState(false, null)
          }}
        />
        <div className={'modal-content'}>
          {// this is so redux stuff is passed into the content seamlessly
          this.props.UI.modal.content
            ? React.cloneElement(this.props.UI.modal.content)
            : null}
        </div>
      </div>
    )
  }
}
