import pkg from '../../package.json'
import * as Sentry from '@sentry/react'
// import { Integrations } from "@sentry/tracing";
// import { history } from "../store";

const env = process.env.REACT_APP_ENVIRONMENT

const IGNORE_MESSAGES = [
  'ClientAuthError: Token calls are blocked in hidden iframes'
]

if (env && env !== 'development') {
  Sentry.init({
    dsn:
      'https://7a1343b443444a3cb33f44642bcf31e4@o416864.ingest.sentry.io/5519095',
    release: `AE@${pkg.version}`,
    environment: env,
    // integrations: [
    //   new Integrations.BrowserTracing({
    //     routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
    //   })
    // ],
    // https://docs.sentry.io/platforms/javascript/performance/sampling/
    tracesSampleRate: 0.2,
    autoSessionTracking: false,
    ignoreErrors: IGNORE_MESSAGES
  })
}
