import React from 'react'

const ArrowLeft = ({ fill }) => {
  return (
    <svg width='19' height='15' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.07 14.14l1.5-1.5L4 8.07h14.07v-2H4L8.57 1.5 7.07 0 0 7.07z'
        fill={fill || '#FF5353'}
        fillRule='nonzero'
      />
    </svg>
  )
}

export default ArrowLeft
