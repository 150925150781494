export const sortActivePlacements = (a, b) => {
  if (a.placementCount < b.placementCount) return -1
  if (a.placementCount > b.placementCount) return 1
  return 0
}

export const sortContacts = (a, b) => {
  if (a.contactCount < b.contactCount) return -1
  if (a.contactCount > b.contactCount) return 1
  return 0
}

export const sortProgram = (a, b) => {
  if (a.program < b.program) return -1
  if (a.program > b.program) return 1
  return 0
}

export const sortDivision = (a, b) => {
  if (a.category.name < b.category.name) return -1
  if (a.category.name > b.category.name) return 1
  return 0
}

export const sortAMName = (a, b) => {
  if (
    `${a.user.firstName} ${a.user.lastName}` <
    `${b.user.firstName} ${b.user.lastName}`
  )
    return -1
  if (
    `${a.user.firstName} ${a.user.lastName}` >
    `${b.user.firstName} ${b.user.lastName}`
  )
    return 1
  return 0
}

export const sortAssignedDate = (a, b) => {
  if (a.assignedDate < b.assignedDate) return -1
  if (a.assignedDate > b.assignedDate) return 1
  return 0
}

export const sortCompanyName = (a, b) => {
  if (a.clientCorporation.name < b.clientCorporation.name) return -1
  if (a.clientCorporation.name > b.clientCorporation.name) return 1
  return 0
}
