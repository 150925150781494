import React from 'react'
import { sumBy } from 'lodash'
import invertColor from '../../../helpers/invertColor'
import ProgressBar from './components/ProgressBar'

const ProgressBarChart = ({ list, includeAll }) => {
  const defaultColors = ['#7ED321', '#F59436', '#FDB022']

  if (includeAll) {
    const totalGoal = sumBy(list, 'goal')
    const totalCount = sumBy(list, 'count')

    list.unshift({
      label: 'All',
      count: totalCount,
      goal: totalGoal,
      color: '#009F00'
    })
  }

  return (
    <div className='c-progress-bar-chart'>
      {list.map(({ count, goal, color, label }, index) => (
        <div className='c-progress-bar-chart__row' key={index}>
          <div className='c-progress-bar-chart__left-col'>
            <ProgressBar
              includeBg
              percent={goal ? (count / goal) * 100 : goal < count ? 100 : 0}
              count={count}
              goal={goal}
              color={
                color
                  ? color
                  : defaultColors[
                      (includeAll ? index + 1 : index) % defaultColors.length
                    ]
              }
              textColor={color && invertColor(color, true)}
            />
          </div>
          <div className='c-progress-bar-chart__right-col'>
            <span className='c-progress-bar-chart__label'>{label}</span>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ProgressBarChart
