import React from 'react'
import Icon from '@mui/material/Icon'

const SimpleSearch = ({ className = '', style, ...props }) => {
  return (
    <div className={`c-simple-search ${className}`} style={style}>
      <div className='c-simple-search__container'>
        <span className='c-simple-search__icon'>
          <Icon baseClassName='fas' className='fa-search' />
        </span>
        <div className='c-simple-search__input-container'>
          <input className='c-simple-search__input' {...props} />
        </div>
      </div>
      <div className='c-simple-search__border-bottom' />
    </div>
  )
}

export default SimpleSearch
