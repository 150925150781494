import React from 'react'

const ArrowRight = ({ fill }) => {
  return (
    <svg width='19' height='15' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <linearGradient
          x1='97.048%'
          y1='24.49%'
          x2='0%'
          y2='80.618%'
          id='prefix__--arrow-right'
        >
          <stop stopColor={fill || '#FF5353'} offset='0%' />
          <stop stopColor={fill || '#D80808'} offset='100%' />
        </linearGradient>
      </defs>
      <path
        d='M11 0L9.5 1.5l4.57 4.57H0v2h14.07L9.5 12.641l1.5 1.5 7.07-7.071z'
        fill='url(#prefix__--arrow-right)'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default ArrowRight
