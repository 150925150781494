import React, { Component } from 'react'
import { connect } from 'react-redux'

import { setBasicMenuPopover, setPopoverState } from 'redux/actions'
import { getInterviews, getHiringManagerHistory } from '../../../../../requests'
import formatClientInformation from '../../../../../helpers/formatClientInformation'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import CLModal from '../../../../shared/CLModal'
import { renderConferenceLine } from '../../helpers'
import { Edit } from 'components/shared/Icons'
import InterviewprepDetailsButton from './InterviewprepDetailsButton'

class JobHeader extends Component {
  constructor(props) {
    super(props)
    this.companyRef = React.createRef()
  }
  state = {
    clModalOpen: false
  }

  closeClModal = () => {
    this.setState({
      clModalOpen: false,
      clModalProps: {}
    })
  }

  handleCLTap = (element, jobOrder) => {
    if (this.props.headerless) return
    const {
      updateJobOrderFxn,
      auth,
      auth: {
        activeUser: { color, conferenceLine }
      }
    } = this.props
    this.setState({
      clModalOpen: true,
      clModalProps: {
        bhId: jobOrder.bhId,
        updateJobOrderFxn,
        color,
        auth,
        conferenceLine: jobOrder.conferenceLine
          ? jobOrder.conferenceLine
          : conferenceLine
      }
    })
  }

  openCorpPopover = companyUrl =>
    this.props.setBasicMenuPopover({
      anchorEl: this.companyRef.current,
      items: [{ url: companyUrl, primaryText: 'View on Bullhorn' }]
    })

  render() {
    const { job, setInterviewPrepDetailsJob } = this.props
    const settings = this.props.department.settings

    const corpDiv = (
      <div ref={this.companyRef} className='job__corp-name'>
        <span
          className='c-menu__popover-pointer'
          onClick={() => !this.props.headerless && this.openCorpPopover(job.ClientCorporation.url)}
        >
          {job.ClientCorporation.name}
        </span>
      </div>
    )
    const jobConfLine = (
      <div
        className={`job-conf-line ${this.props.headerless && 'headerless'}`}
        onClick={e => this.handleCLTap(e.target, job)}
      >
        {isNaN(job.conferenceLine) ? null : '#'}
        {(job.conferenceLine && renderConferenceLine(job.conferenceLine)) ||
          job.Owner.conferenceLine}{' '}
        {this.props.headerless ? null : (
          <button className='c-btn--responsive c-btn--pointer-cursor c-btn--bare'>
            <Edit />
          </button>
        )}
      </div>
    )

    return (
      <React.Fragment>
        {corpDiv}
        <div
          className={`job-header ${this.props.headerless ? 'headerless' : ''}`}
        >
          <div
            id={`activity-header-${job.bhId}`}
            className='job-header-leftbox'
          >
            {this.props.headerless ? null : corpDiv}
            <div className='job-header__job-title-container'>
              <div
                className='job-header__job-title'
                onClick={async e => {
                  const interviewRequest = await getInterviews(job.bhId)
                  const historyRequest = await getHiringManagerHistory(
                    job.clientContact
                  )
                  this.props.setPopoverState({
                    open: true,
                    content: formatClientInformation(
                      job,
                      interviewRequest.data,
                      historyRequest.data
                    ),
                    sx: {},
                    targetEl: document.querySelector(
                      `#activity-header-${job.bhId}`
                    )
                  })
                }}
              >
                {job.Openings ? (
                  <span
                    style={{ marginRight: '4px' }}
                    className='openings'
                  >{`x${job.Openings ? job.Openings : 1}`}</span>
                ) : null}
                {job.title} |{' '}
                <div className='job-id'>
                  ID
                  {this.props.headerless ?
                    <b>{job.bhId}</b>
                    :
                    <a
                      onClick={e => e.stopPropagation()}
                      href={job.url}
                      rel='noopener noreferrer'
                      target='_blank'
                      className='c-link'
                      style={{ marginLeft: '3px' }}
                    >
                      {job.bhId}
                    </a>
                  }
                  {!this.props.headerless &&
                    <InterviewprepDetailsButton
                      hasInterviewPrepDetail={job.hasInterviewPrepDetail}
                      onClick={() => setInterviewPrepDetailsJob(job)}
                    />
                  }
                </div>
              </div>
            </div>
            {settings.program_field ? <div>{job.program}</div> : null}
          </div>
          <div className='job-header-rightbox'>
            {this.props.headerless && !this.props.useTouchscreenBoard ? null : (
              <button
                className='add-slot c-btn--responsive c-btn--pointer-cursor c-btn--bare'
                onClick={() => {
                  this.props.setSelectedJO(job.bhId)
                }}
              >
                Add Interview Slot <AddCircleOutlineIcon />
              </button>
            )}
            {jobConfLine}
          </div>
        </div>
        {this.props.headerless ? null : jobConfLine}
        <div className='job-line-break' />
        <CLModal
          visible={this.state.clModalOpen}
          closeModal={this.closeClModal}
          {...this.state.clModalProps}
        />
      </React.Fragment>
    )
  }
}

export default connect(
  null,
  {
    setPopoverState,
    setBasicMenuPopover
  }
)(JobHeader)
