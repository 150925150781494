import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import {
  setModalState as setModalStateAction,
  setPopoverState as setPopoverStateAction
} from 'redux/actions'
import CandidateHistoryIcon from 'components/shared/Icons/CandidateHistoryIcon'
import CandidateToggleIcon from 'components/shared/Icons/CandidateToggleIcon'
import CandidateVisibilityIcon from 'components/shared/Icons/CandidateVisibilityIcon'
import { getInterviews, getHiringManagerHistory } from '../../../../../requests'
import formatClientInformation from '../../../../../helpers/formatClientInformation'
import HiddenSubmissionsDialog from '../../../../shared/hiddenRecords/HiddenSubmissions'
import BaseWebsocket from '../../../../../network/websocket/BaseWebsocket'
import LinkButton from 'components/shared/LinkButton'
import { parseUtcDate } from 'helpers/dateTime'

const JobsCardActions = props => {
  const {
    boardType,
    headerless,
    useTouchscreenBoard,
    job: { ClientCorporation, title, bhId, isRTC, dateAdded, lastSubmittalDate }
  } = props
  const dispatch = useDispatch()
  const setPopoverState = useCallback(
    (...args) => {
      dispatch(setPopoverStateAction(...args))
    },
    [dispatch]
  )
  const setModalState = useCallback(
    (...args) => {
      dispatch(setModalStateAction(...args))
    },
    [dispatch]
  )
  const dateOpenedParsed = parseUtcDate(dateAdded, 'MM/DD/YYYY')
  const lastSubmittalParsed = parseUtcDate(lastSubmittalDate, 'MM/DD/YYYY')
  return (
    <div className='job-block-actions'>
      <div className='job-block-actions__timestamps'>
        <span>Date Opened: {dateOpenedParsed}</span>
        {lastSubmittalDate ? (
          <span>Last Submittal: {lastSubmittalParsed}</span>
        ) : null}
      </div>
      {!headerless || useTouchscreenBoard ? (
        <div className='hide-button'>
          <LinkButton
            className={`view-history-${bhId}`}
            onClick={async e => {
              const interviewRequest = await getInterviews(bhId)
              const historyRequest = await getHiringManagerHistory(
                props.job.clientContact
              )
              setPopoverState({
                open: true,
                content: formatClientInformation(
                  props.job,
                  interviewRequest.data,
                  historyRequest.data
                ),
                sx: {},
                targetEl: document.getElementsByClassName(
                  `view-history-${bhId}`
                )[0]
              })
            }}
          >
            <CandidateHistoryIcon title='VIEW JOB HISTORY' />
          </LinkButton>
          <LinkButton
            title='Hide'
            onClick={async () => {
              BaseWebsocket.send(boardType, {
                event: 'job_order_change',
                jobOrderId: bhId,
                field: 'isRTC',
                value: !isRTC
              })
              setPopoverState(null)
            }}
          >
            <CandidateToggleIcon title='TOGGLE TC' isRTC={isRTC} />
          </LinkButton>
          <LinkButton
            title='Hide'
            onClick={() => {
              setModalState(
                true,
                <HiddenSubmissionsDialog
                  job={props.job}
                  boardType={boardType}
                  client={ClientCorporation}
                  title={title}
                />
              )
            }}
          >
            <CandidateVisibilityIcon title='SHOW/WIPE CANDIDATES' />
          </LinkButton>
        </div>
      ) : null}
    </div>
  )
}

export default JobsCardActions
