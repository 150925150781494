import React from 'react'

import { Skeleton } from 'components/shared'

const StartsMngmtSkeleton = () => {
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Skeleton.Input
          style={{ width: 328, height: 40, marginRight: 20, marginBottom: 25 }}
          active
        />
        <Skeleton.Input style={{ width: 142, height: 40 }} active />
      </div>
      <Skeleton.Input
        style={{ width: 223, height: 32, marginBottom: 23 }}
        active
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Skeleton.Input
          style={{ width: 582, height: 277, marginRight: 46, marginBottom: 23 }}
          active
        />
        <Skeleton.Input
          style={{ width: 582, height: 277, marginBottom: 23 }}
          active
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Skeleton.Input
          style={{ width: 582, height: 277, marginRight: 46, marginBottom: 23 }}
          active
        />
        <Skeleton.Input
          style={{ width: 582, height: 277, marginBottom: 23 }}
          active
        />
      </div>
    </div>
  )
}

export default StartsMngmtSkeleton
