import React from 'react'

const KebabMenu = () => {
  return (
    <svg width='4' height='20' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2 16a2 2 0 110 4 2 2 0 010-4zm0-8a2 2 0 110 4 2 2 0 010-4zm0-8a2 2 0 110 4 2 2 0 010-4z'
        fill='#1A1A1A'
        fillRule='evenodd'
      />
    </svg>
  )
}

export default KebabMenu
