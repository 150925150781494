import React from 'react'

const CompanyIcon = ({ onClick, active }) => {
  return (
    <div
      onClick={onClick}
      className={`react-switch-icon-container ${active ? 'active' : null}`}
    >
      <svg
        className={`react-switch-icon-container-icon ${
          active ? 'active' : null
        }`}
        width='15px'
        height='15px'
        viewBox='0 0 24 24'
      >
        <g>
          <path d='M 12 2 L 3 6 L 3 21 L 21 21 L 21 6 Z M 9 18 L 7 18 L 7 16 L 9 16 Z M 9 14 L 7 14 L 7 12 L 9 12 Z M 9 10 L 7 10 L 7 8 L 9 8 Z M 13 18 L 11 18 L 11 16 L 13 16 Z M 13 14 L 11 14 L 11 12 L 13 12 Z M 13 10 L 11 10 L 11 8 L 13 8 Z M 17 18 L 15 18 L 15 16 L 17 16 Z M 17 14 L 15 14 L 15 12 L 17 12 Z M 17 10 L 15 10 L 15 8 L 17 8 Z M 17 10 ' />
        </g>
      </svg>
      <div
        className={`react-switch-icon-container-text ${
          active ? 'active' : null
        }`}
      >
        Company
      </div>
    </div>
  )
}

const AccountManagerIcon = ({ onClick, active }) => {
  return (
    <div
      onClick={onClick}
      className={`react-switch-icon-container ${active ? 'active' : null}`}
    >
      <svg
        className={`react-switch-icon-container-icon ${
          active ? 'active' : null
        }`}
        width='15px'
        height='15px'
        viewBox='0 0 24 24'
        // fill={active ? "#fff" : "rgba(0,0,0,0.4)"}
      >
        <g>
          <path d='M 12 3 C 9.789062 3 8 4.789062 8 7 C 8 9.210938 9.789062 11 12 11 C 14.210938 11 16 9.210938 16 7 C 16 4.789062 14.210938 3 12 3 Z M 9.4375 14.265625 C 6.457031 14.816406 3 16.226562 3 18.5 L 3 21 L 21 21 L 21 18.5 C 21 16.226562 17.542969 14.8125 14.5625 14.265625 L 12 16.828125 Z M 9.4375 14.265625 ' />
        </g>
      </svg>

      <div
        className={`react-switch-icon-container-text ${
          active ? 'active' : null
        }`}
      >
        Account Manager
      </div>
    </div>
  )
}

const AssignedAccountsToggle = React.memo(
  ({ filterByCompany, toggleAssignedAccountsFilter }) => {
    return (
      <div className='assigned-accounts-toggle-container'>
        <div className='filter-header-input-label hidden'>Filter</div>
        <div className='assigned-accounts-toggle'>
          <CompanyIcon
            active={filterByCompany}
            onClick={() => toggleAssignedAccountsFilter(true)}
          />
          <AccountManagerIcon
            active={!filterByCompany}
            onClick={() => toggleAssignedAccountsFilter(false)}
          />
        </div>
      </div>
    )
  }
)

AssignedAccountsToggle.displayName = 'AssignedAccountsToggle'

export default AssignedAccountsToggle
