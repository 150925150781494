import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import UI from './UI'
import auth from './auth'
import deals from './deals'
import users from './users'
import jobOrders from './jobOrders'
import websockets from './websockets'
import department from './department'
import placements from './placements'
import assignedAccounts from './assignedAccounts'
import placementsStarts from './placementsStarts'
import jobOrdersActivity from './jobOrdersActivity'
import dashboard from './dashboard'
import startsMngmt from './startsMngmt'
import piDb from './piDb'

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth']
}

const assignedAccountsPersistConfig = {
  key: 'assignedAccounts',
  storage,
  blacklist: ['data', 'updatedAt']
}

const uiPersistConfig = {
  key: 'UI',
  storage,
  whitelist: ['sideBarIsCollapsed', 'touchscreenPaginationType']
}

export const persistedAuthReducer = persistReducer(rootPersistConfig, auth)
export const persistedAssignedAccountsReducer = persistReducer(
  rootPersistConfig,
  assignedAccounts
)

const createRootReduceer = history => {
  return combineReducers({
    router: connectRouter(history),
    placements,
    UI: persistReducer(uiPersistConfig, UI),
    assignedAccounts: persistReducer(
      assignedAccountsPersistConfig,
      assignedAccounts
    ),
    auth,
    jobOrders,
    users,
    jobOrdersActivity,
    department,
    placementsStarts,
    deals,
    websockets,
    dashboard,
    startsMngmt,
    piDb
  })
}

const createReducer = history => {
  const rootReducer = createRootReduceer(history)
  const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

  return persistedReducer
}

export default createReducer
