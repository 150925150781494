import { newRequest } from '../helpers/axios'

export default function getDepartmentCategoryAssignmentDealGoals(categoryAssignmentIds, time) {
  return newRequest(
    'GET',
    `/division-fiscal-months?filter[fiscal_month.start_date]=le:${time}&filter[fiscal_month.end_date]=gt:${time}&[category_departments.id]=in:${categoryAssignmentIds.join(',')}`,
    null,
    null
  )
}
