import { useCallback } from 'react'
import BaseWebsocket from 'network/websocket/BaseWebsocket'
import { SOCKET_BOARDS } from 'constants'
import {
  handleUserHubSocket,
  handleJobsData as handleJobsDataSocket,
  handleDealsData as handleDealsDataSocket,
  handleFiscalData as handleFiscalDataSocket,
  handleActivityBoardData as handleActivityBoardDataSocket,
  handleStartshubSocket
} from 'redux/actions'
import { useDispatch, useSelector } from 'react-redux'

export default function useInitializeBoardSockets(selectedDepartment) {
  const dispatch = useDispatch()
  const dealsCustomFiscalDate = useSelector(
    state => state?.deals?.customFiscalDate
  )
  const handleStartshub = useCallback(
    msg => {
      dispatch(handleStartshubSocket(msg))
    },
    [dispatch]
  )
  const handleUserHub = useCallback(
    msg => {
      dispatch(handleUserHubSocket(msg))
    },
    [dispatch]
  )
  const handleJobsData = useCallback(
    msg => {
      dispatch(handleJobsDataSocket(msg))
    },
    [dispatch]
  )
  const handleDealsData = useCallback(
    msg => {
      dispatch(handleDealsDataSocket(msg))
    },
    [dispatch]
  )
  const handleFiscalData = useCallback(
    msg => {
      dispatch(handleFiscalDataSocket(msg))
    },
    [dispatch]
  )
  const handleActivityBoardData = useCallback(
    msg => {
      dispatch(handleActivityBoardDataSocket(msg))
    },
    [dispatch]
  )

  /**
   * starts all sockets, if value only start that sockeet
   */
  const startSockets = useCallback(
    socket => {
      if (selectedDepartment) {
        if (!BaseWebsocket.getSocketInstance(SOCKET_BOARDS.USER_HUB)) {
          BaseWebsocket.openSignalRSocket(
            SOCKET_BOARDS.USER_HUB,
            handleUserHub,
            selectedDepartment
          )
        }
        if (typeof socket !== 'string' || socket === SOCKET_BOARDS.JOBS_HUB) {
          BaseWebsocket.openSignalRSocket(
            SOCKET_BOARDS.JOBS_HUB,
            handleJobsData,
            selectedDepartment
          )
        }
        if (typeof socket !== 'string' || socket === SOCKET_BOARDS.DEALS_HUB) {
          BaseWebsocket.openSignalRSocket(
            SOCKET_BOARDS.DEALS_HUB,
            handleDealsData,
            selectedDepartment,
            dealsCustomFiscalDate
          )
        }
        if (
          typeof socket !== 'string' ||
          socket === SOCKET_BOARDS.FISCAL_MONTH_HUB
        ) {
          BaseWebsocket.openSignalRSocket(
            SOCKET_BOARDS.FISCAL_MONTH_HUB,
            handleFiscalData,
            selectedDepartment
          )
        }
        if (
          typeof socket !== 'string' ||
          socket === SOCKET_BOARDS.ACTIVITY_HUB
        ) {
          BaseWebsocket.openSignalRSocket(
            SOCKET_BOARDS.ACTIVITY_HUB,
            handleActivityBoardData,
            selectedDepartment
          )
        }
        if (typeof socket !== 'string' || socket === SOCKET_BOARDS.STARTS_V2) {
          BaseWebsocket.openSignalRSocket(
            SOCKET_BOARDS.STARTS_V2,
            handleStartshub
          )
        }
      }
    },
    [
      handleActivityBoardData,
      handleDealsData,
      handleFiscalData,
      handleJobsData,
      handleStartshub,
      handleUserHub,
      selectedDepartment,
      dealsCustomFiscalDate
    ]
  )
  const closeSockets = useCallback(() => {
    BaseWebsocket.closeSockets()
  }, [])
  const reOpenSockets = useCallback(() => {
    Object.entries(BaseWebsocket.sockets).forEach(hub => {
      if (hub[1]['_connectionState']?.toLowerCase() !== 'connected') {
        console.log(`${hub[0]} disconnected`)
        startSockets(hub[0])
      }
    })
  }, [startSockets])
  return { startSockets, closeSockets, reOpenSockets }
}
