import { createTheme } from '@mui/material/styles'

const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#0D1E34'
    }
  },
  typography: {
    fontFamily: ['Lato, sans-serif']
  }
})

// const oldTheme = {
//   fontFamily: 'Lato, sans-serif',
//   palette: {
//     primary1Color: '#0D1E34',
//     primary2Color: '#4285F4',
//     primary3Color: '#1D51A7',
//     accent2Color: '#e0e0e0',
//     accent1Color: '#0d47a1',
//     textColor: '#0D1E34',
//     accent3Color: '#E3531F',
//     secondaryTextColor: '#4285F4',
//     alternateTextColor: '#ffffff',
//     canvasColor: '#ffffff',
//     borderColor: '#EEF5FB',
//     disabledColor: '#0D1E34',
//     pickerHeaderColor: '#EEF5FB',
//     clockCircleColor: '#1D51A7'
//   },
//   button: {
//     height: 40,
//     minWidth: 150,
//     iconButtonSize: 50
//   },
//   appBar: {
//     height: 55,
//     titleFontWeight: 300,
//     padding: 50
//   },
//   card: {
//     titleColor: 'rgba(61, 90, 254, 0.87)',
//     fontWeight: 400,
//     subtitleColor: '#0D1E34',
//     cursor: 'pointer'
//   },
//   checkbox: {
//     requiredColor: '#e64a19',
//     disabledColor: 'rgba(41, 121, 255, 0.3)',
//     labelColor: '#0D1E34',
//     labelDisabledColor: 'rgba(26, 35, 126, 0.5)',
//     boxColor: '#4285F4',
//     checkedColor: '#4285F4'
//   },
//   dropDownMenu: {
//     accentColor: '#4285F4'
//   },
//   radioButton: {
//     borderColor: '#4285F4',
//     checkedColor: '#4285F4',
//     disabledColor: 'rgba(41, 121, 255, 0.3)',
//     size: 20,
//     labelDisabledColor: 'rgba(26, 35, 126, 0.3)'
//   },
//   raisedButton: {
//     color: '#4285F4',
//     textColor: '#ffffff',
//     fontWeight: 400,
//     disabledColor: 'rgba(13, 71, 161, 0.5)',
//     disabledTextColor: '#ffffff'
//   },
//   table: {
//     backgroundColor: '#ffffff'
//   },
//   tableHeader: {
//     borderColor: '#e0e0e0'
//   },
//   tableHeaderColumn: {
//     height: 50,
//     spacing: 20,
//     textColor: '#0D1E34'
//   },
//   tableRow: {
//     selectedColor: '#ffffff',
//     stripeColor: 'rgba(227, 242, 253, 0.4)',
//     height: 60,
//     hoverColor: 'rgba(41, 121, 255, 0.3)'
//   },
//   tableRowColumn: {
//     spacing: 20,
//     height: 60
//   },
//   tabs: {
//     backgroundColor: 'transparent',
//     selectedTextColor: '#4285F4',
//     textColor: '#0D1E34'
//   },
//   textField: {
//     textColor: '#0D1E34',
//     hintColor: '#0D1E34',
//     floatingLabelColor: '#4285F4',
//     disabledTextColor: 'rgba(26, 35, 126, 0.3)',
//     errorColor: '#d84315'
//   },
//   toolbar: {
//     backgroundColor: '#ffffff'
//   },
//   tooltip: {
//     rippleBackgroundColor: '#e0e0e0',
//     color: '#0D1E34'
//   },
//   spacing: {
//     desktopGutter: 25,
//     desktopGutterMore: 50,
//     desktopGutterLess: 20,
//     desktopGutterMini: 10
//   },
//   timePicker: {
//     clockColor: '#4285F4',
//     headerColor: '#0d47a1',
//     clockCircleColor: '#eeeeee'
//   },
//   datePicker: {
//     color: '#4285F4',
//     textColor: '#0d1e34',
//     calendarTextColor: '#0D1E34',
//     selectColor: '#EEF5FB',
//     selectTextColor: '#4285F4',
//     calendarYearBackgroundColor: '#fff'
//   }
// }

export default muiTheme
