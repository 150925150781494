import React from 'react'

const DetailsModalCloseIcon = () => {
  return (
    <svg width='18' height='17' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.74 16.74l6.348-6.348 6.009 6.008 1.993-1.993L11.082 8.4l6.303-6.303L15.23-.06 8.926 6.244 2.948.265.954 2.258l5.98 5.979-6.349 6.348z'
        fill='#555762'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default DetailsModalCloseIcon
