import { createSelector } from 'reselect'
import { selectCategories } from './department'

export const selectUserDepartmentCategoriesDealsAssignmentId = state =>
  state.auth.activeUser.departmentCategoriesDealsAssignmentId
export const selectActiveUser = state => state.auth.activeUser

export const selectUserCategoryName = createSelector(
  [selectUserDepartmentCategoriesDealsAssignmentId, selectCategories],
  (assignmentId, categories) => {
    const selectedCategory = categories.find(category => category.Id === assignmentId)
    return selectedCategory && selectedCategory.name
  }
)
