import React from 'react'

import Skeleton from 'components/shared/Skeleton'

const SkeletonFullWidthCard = ({ height, marginBottom = 32 }) => {
  return (
    <div className='c-skeleton__input--full-width' style={{ marginBottom }}>
      <Skeleton.Input style={{ height }} active />
    </div>
  )
}

export default SkeletonFullWidthCard
