/* eslint-disable react/no-string-refs */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ActivityPane from './subComponents/ActivityPane'
import _ from 'lodash'
import {
  grossSorting,
  hotSort,
  shiftPickerDateToTZDate,
  getFutureDate
} from './helpers'
import {
  handleActivityBoardData as handleActivityBoardDataSocket,
  openDatePicker,
  openTimePicker,
  setModalState
} from '../../../redux/actions'
import filterProgram from '../../../helpers/filterProgram'
import { ACTIVITY_TABS_ENUM, SOCKET_BOARDS } from '../../../constants'
import { getDate } from 'helpers/dateTime'
import { DatePicker, TimePicker, Skeleton } from 'components/shared'
import ActivityBoardSkeleton from './ActivityBoardSkeleton'
import InterviewPrepDetailsModal from './subComponents/InterviewPrepDetailsModal'
import InterviewSlotRateFieldModal from './subComponents/InterviewSlotRateFieldModal'
import BoardManager from 'components/hocs/BoardManager'
import { useInterval } from 'components/hocs/hooks'

//ws
import BaseWebsocket from 'network/websocket/BaseWebsocket'
import verifyConnectionState from 'network/websocket/helpers/verifyConnectionState'

const DEFAULT_STATE = {
  selectedJO: null,
  dateToAdd: null,
  timeToAdd: null,
  optSlotId: null,
  faceToFace: null,
  timezone: null,
  intervalId: null,
  timeoutID: null,
  paused: false,
  timeFrameDate: null,
  interviewSlotMarginSpreadData: null,
  interviewPrepDetailsJob: null
}
const Activity = props => {
  const {
    selectedDepartment,
    isPaused,
    useTouchscreenBoard,
    handleInternalPagination,
    boardPage,
    setBoardPage
  } = props
  const dispatch = useDispatch()
  const { department, header } = useParams()
  const [state, setState] = useState(DEFAULT_STATE)
  const auth = useSelector(state => state.auth)
  const users = useSelector(state => state.users.users)
  const jobOrdersActivity = useSelector(state => state.jobOrdersActivity.data)
  const activeTab = useSelector(state => state.jobOrdersActivity.activeTab)
  const board = useRef()
  const isLoading = useSelector(state => state.UI.boardLoadingObj.activity)
  const isHeaderless = useTouchscreenBoard
    ? true
    : !header || header === 'headerless'
  const isHot = useTouchscreenBoard ? false : !header && !department
  const boardType = !isHot
    ? SOCKET_BOARDS.ACTIVITY_HUB
    : SOCKET_BOARDS.HOT_ACTIVITY_HUB
  let AMs
  if (!isHot) {
    AMs = grossSorting(users, jobOrdersActivity)
  } else {
    AMs = hotSort(users)
  }

  const handleActivityBoardData = useCallback(
    msg => {
      dispatch(handleActivityBoardDataSocket(msg))
    },
    [dispatch]
  )

  const openSockets = useCallback(() => {
    if (isHot) {
      if (!verifyConnectionState(BaseWebsocket.sockets.HOT_ACTIVITY_HUB)) {
        if (boardType === SOCKET_BOARDS.HOT_ACTIVITY) {
          BaseWebsocket.openSocket(
            boardType,
            department,
            handleActivityBoardData
          )
        } else {
          BaseWebsocket.openSignalRSocket(
            boardType,
            handleActivityBoardData,
            department
          )
        }
      }
    } else {
      if (!verifyConnectionState(BaseWebsocket.sockets.ACTIVITY_HUB)) {
        if (boardType === SOCKET_BOARDS.HOT_ACTIVITY) {
          BaseWebsocket.openSocket(
            boardType,
            department,
            handleActivityBoardData
          )
        } else {
          BaseWebsocket.openSignalRSocket(
            boardType,
            handleActivityBoardData,
            department
          )
        }
      }
    }
  }, [boardType, department, handleActivityBoardData, isHot])

  useEffect(() => {
    if (!useTouchscreenBoard) {
      openSockets()
      if (!isHeaderless) {
        resizeGridItems()
      }
    }

    return () => {
      if (!useTouchscreenBoard) BaseWebsocket.closeSignalRSocket(boardType)
    }
  }, [
    boardType,
    department,
    handleActivityBoardData,
    isHeaderless,
    useTouchscreenBoard,
    openSockets
  ])

  useEffect(() => {
    switch (activeTab) {
      case ACTIVITY_TABS_ENUM.TODAY:
        setState(state => ({ ...state, timeFrameDate: getDate() }))
        break
      case ACTIVITY_TABS_ENUM.NEXT_DAY:
        {
          const futureDate = getFutureDate(jobOrdersActivity)
          setState(state => ({
            ...state,
            timeFrameDate: futureDate
          }))
        }
        break
      default:
        setState(state => ({ ...state, timeFrameDate: null }))
        break
    }
  }, [activeTab, jobOrdersActivity])

  useInterval(openSockets, 60 * 1000)

  const resizeGridItems = () => {
    let grid = document.getElementById('activity-board')
    if (grid) {
      let rowHeight = parseInt(
        window.getComputedStyle(grid).getPropertyValue('grid-auto-rows')
      )
      let rowGap = parseInt(
        window.getComputedStyle(grid).getPropertyValue('grid-row-gap')
      )
      Array.from(document.getElementsByClassName('element-container')).forEach(
        item => {
          let rowSpan = Math.ceil(
            (item.querySelector('.activity-pane').getBoundingClientRect()
              .height +
              rowGap) /
              (rowHeight + rowGap)
          )
          item.style.gridRowEnd = 'span ' + rowSpan
        }
      )
    }
  }

  const slotChange = useCallback(
    (field, value, slotId) => {
      BaseWebsocket.send(boardType, {
        event: 'slot_change',
        slotId,
        field,
        value
      })
    },
    [boardType]
  )

  // NOTE: if you have optSlotId set, its going to do an edit.
  // Otherwise it adds a new time slot.
  const submitNewSlot = useCallback(() => {
    setState(state => {
      const { timeToAdd, optSlotId, faceToFace, timezone, selectedJO } = state
      if (optSlotId) {
        slotChange('date', timeToAdd, optSlotId)
        slotChange('faceToFace', faceToFace, optSlotId)
        slotChange('timezone', timezone, optSlotId)
      } else {
        BaseWebsocket.send(boardType, {
          event: 'add_interview_slot',
          jobOrderId: selectedJO,
          datetime: timeToAdd,
          faceToFace,
          timezone
        })
      }
      return DEFAULT_STATE
    })
  }, [boardType, slotChange])

  const setSelectedJO = (bhId, slotId = null) => {
    setState(state => ({
      ...state,
      selectedJO: bhId,
      optSlotId: slotId
    }))
    dispatch(openDatePicker())
  }

  const setDateToAdd = date => {
    setState(state => ({
      ...state,
      dateToAdd: date
    }))
    dispatch(openTimePicker())
  }

  const setFaceToFace = useCallback(
    faceToFaceBool => {
      setState(state => ({
        ...state,
        faceToFace: faceToFaceBool
      }))
      submitNewSlot()
      dispatch(setModalState(false, null))
    },
    [dispatch, submitNewSlot]
  )

  const setTimeToAdd = time => {
    let utcTime = shiftPickerDateToTZDate(time, state.dateToAdd)
    setState(state => ({
      ...state,
      timeToAdd: utcTime
    }))
    handleTimezoneChange()
  }

  const handleFaceToFace = () => {
    dispatch(
      setModalState(
        true,
        <div className='settings-wrapper'>
          <div className='face2face-dialog'>
            <div className='dialog-btn' onClick={() => setFaceToFace(true)}>
              Face To Face
            </div>
            <div className='dialog-btn' onClick={() => setFaceToFace(false)}>
              Phone
            </div>
          </div>
        </div>
      )
    )
  }

  const handleTimezoneChange = (slotId = null) => {
    const timezones = ['EST', 'CST', 'MST', 'PST', 'AKST', 'HST']

    dispatch(
      setModalState(
        true,
        <div className='settings-wrapper'>
          <div className='face2face-dialog'>
            {timezones.map(tz => (
              <div
                key={tz}
                className='dialog-btn'
                onClick={() => {
                  setState(state => ({ ...state, timezone: tz }))
                  dispatch(setModalState(false, null))
                  console.log('handletimezonechange args: ', tz, slotId)
                  if (slotId === null) {
                    handleFaceToFace()
                  } else {
                    slotChange('timezone', tz, slotId)
                  }
                }}
              >
                {tz}
              </div>
            ))}
          </div>
        </div>
      )
    )
  }

  const addJOSubmissionFxn = jobId => {
    setState(state => ({ ...state, selectedJO: jobId }))
    dispatch(openDatePicker())
  }

  const addSubmissionToSlot = (jobSubmissionId, slotId) => {
    BaseWebsocket.send(boardType, {
      event: 'slot_submission',
      jobSubmissionId,
      slotId
    })
  }

  const updateJobOrder = (bhId, field, value) => {
    BaseWebsocket.send(boardType, {
      event: 'job_order_change',
      jobOrderId: bhId,
      field,
      value
    })
  }

  const changeSubbmissionState = (
    jobSubmissionId,
    slotId,
    available,
    clientConfirmed,
    prepped
  ) => {
    let payload = {}

    if (!available && !clientConfirmed && !prepped) {
      payload = {
        available: true,
        clientConfirmed: false
      }
    } else if (available && !clientConfirmed && !prepped) {
      payload = {
        clientConfirmed: true,
        available: false
      }
    } else if (!available && !prepped && clientConfirmed) {
      payload = { available: true }
    } else if (available && !prepped && clientConfirmed) {
      payload = {
        available: true,
        clientConfirmed: false,
        prepped: true
      }
    } else if (available && !clientConfirmed && prepped) {
      payload = {
        clientConfirmed: true
      }
    } else {
      payload = {
        available: false,
        clientConfirmed: false,
        prepped: false
      }
    }

    _.forEach(payload, (value, key) => {
      BaseWebsocket.send(boardType, {
        event: 'slot_submission_change',
        field: key,
        jobSubmissionId,
        slotId,
        value
      })
    })
  }

  const renderActivityCard = datum => {
    const filterByDate =
      activeTab !== ACTIVITY_TABS_ENUM.ALL ? state.timeFrameDate : false
    // if filterByDate is not found(this is mostly for the Next Day tab), hides all ActivityPane components
    if (activeTab !== ACTIVITY_TABS_ENUM.ALL && !filterByDate) {
      return null
    }

    if (!Array.isArray(datum)) {
      const jobs = filterProgram(
        jobOrdersActivity,
        auth.selectedCategoryName
      ).filter(job => job.owner === datum)

      return (
        <ActivityPane
          key={datum}
          isHot={isHot}
          jobs={jobs}
          boardType={boardType}
          updateJobOrderFxn={updateJobOrder}
          addSubmissionToSlotFxn={addSubmissionToSlot}
          addJOSubmissionFxn={addJOSubmissionFxn}
          setSelectedJO={setSelectedJO}
          handleTimezoneChange={handleTimezoneChange}
          bhId={datum}
          headerless={isHeaderless}
          useTouchscreenBoard={useTouchscreenBoard}
          changeSubbmissionStateFxn={changeSubbmissionState}
          filterByDate={filterByDate}
          setInterviewSlotMarginSpreadData={setInterviewSlotMarginSpreadData}
          setInterviewPrepDetailsJob={setInterviewPrepDetailsJob}
        />
      )
    } else {
      return datum.map((arr2, idx) => (
        <div key={`masonry-col-${idx}`} className='masonry-col'>
          {arr2.map(o => {
            const jobs = filterProgram(
              jobOrdersActivity,
              auth.selectedCategoryName
            ).filter(job => job.owner === Number(o.id.split('-')[0]))

            return (
              <ActivityPane
                key={o.id}
                isHot={isHot}
                jobs={jobs}
                boardType={boardType}
                updateJobOrderFxn={updateJobOrder}
                addSubmissionToSlotFxn={addSubmissionToSlot}
                addJOSubmissionFxn={addJOSubmissionFxn}
                setSelectedJO={setSelectedJO}
                handleTimezoneChange={handleTimezoneChange}
                bhId={o.id}
                headerless={isHeaderless}
                useTouchscreenBoard={useTouchscreenBoard}
                changeSubbmissionStateFxn={changeSubbmissionState}
                filterByDate={filterByDate}
                setInterviewSlotMarginSpreadData={
                  setInterviewSlotMarginSpreadData
                }
                setInterviewPrepDetailsJob={setInterviewPrepDetailsJob}
              />
            )
          })}
        </div>
      ))
    }
  }

  const renderTimeFrameTitle = timeFrame => {
    let displayValue = '"All"'
    switch (timeFrame) {
      case ACTIVITY_TABS_ENUM.TODAY:
        displayValue = `"Today" ${getDate('ddd MMM D')}`
        break
      case ACTIVITY_TABS_ENUM.NEXT_DAY:
        {
          const date = state.timeFrameDate
            ? state.timeFrameDate.format('ddd MMM D')
            : ''
          displayValue = `"Next Day" ${date}`
        }
        break
      default:
        break
    }
    return (
      <div className='activity-board__results-header'>
        Seeing results for <span>{displayValue}</span>
      </div>
    )
  }

  const setInterviewSlotMarginSpreadData = (jobSubmission, slotId) => {
    if (jobSubmission && slotId) {
      setState(state => ({
        ...state,
        interviewSlotMarginSpreadData: { jobSubmission, slotId }
      }))
    } else {
      setState(state => ({
        ...state,
        interviewSlotMarginSpreadData: null
      }))
    }
  }

  const setInterviewPrepDetailsJob = job => {
    if (job) {
      setState(state => ({
        ...state,
        interviewPrepDetailsJob: job
      }))
    } else {
      setState(state => ({
        ...state,
        interviewPrepDetailsJob: null
      }))
    }
  }

  return (
    <Skeleton.Board
      loading={!isLoading}
      skeletonChildren={<ActivityBoardSkeleton />}
    >
      {useTouchscreenBoard || !isHeaderless
        ? renderTimeFrameTitle(activeTab)
        : null}
      <div
        id='activity-board'
        className={`board-container ${
          isHeaderless ? 'headerless' : 'board-container--header'
        }`}
      >
        <BoardManager
          useTouchscreenBoard={useTouchscreenBoard}
          useMasonryAfterCalculation
          splitElements
          department={selectedDepartment}
          ref={board}
          key='activityboard'
          data={AMs}
          renderElement={renderActivityCard}
          numberOfColumns={isHeaderless ? 3 : 2}
          headerless={isHeaderless}
          handleInternalPagination={handleInternalPagination}
          isPaused={isPaused}
          boardPage={boardPage}
          setBoardPage={setBoardPage}
        />
      </div>
      <DatePicker
        onAccept={date => {
          setDateToAdd(date)
        }}
      />
      <TimePicker
        onAccept={time => {
          setTimeToAdd(time)
        }}
      />
      <InterviewSlotRateFieldModal
        interviewSlotMarginSpreadData={state.interviewSlotMarginSpreadData}
        boardType={boardType}
        closeModal={() => setInterviewSlotMarginSpreadData(null)}
      />
      <InterviewPrepDetailsModal
        job={state.interviewPrepDetailsJob}
        closeModal={() => setInterviewPrepDetailsJob(null)}
      />
    </Skeleton.Board>
  )
}

export default Activity
