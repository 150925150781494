import React from 'react'
import DealsCard from '../DealsCard'

const DealsCardsGrid = ({ deals, month = false }) => {
  return (
    <div className='c-dealsgrid'>
      {deals.map((row, rowIndex) =>
        <div key={rowIndex}>
          {row.map((deal, index) => (
            <DealsCard key={index} {...deal} month={month} />
          ))}
        </div>
      )}
    </div>
  )
}

export default DealsCardsGrid
