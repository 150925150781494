import React, { useCallback, useMemo } from 'react'
import { SmartInput } from './SmartInput'
import { useSelector, useDispatch } from 'react-redux'
import startsMngmtConfig from '../../../StartsMngmt/helpers/filterConfig'
import {
  selectStartsMngmtPlacements,
  selectStartsMngmtPlacementFilters,
  selectStartsMngmtApprovedView
} from '../../../../redux/selectors/startsMngmt'
import {
  setStartsMngmtBrandFilter,
  setStartsMngmtDivisionFilter,
  setStartsMngmtEmpTypeFilter,
  setStartsMngmtDateRangeFilter,
  setStartsMngmntNameFilter,
  setStartsMngmntStatusFilter,
  setStartsMngmntOwnerFilter,
  setStartsMngmtIncompletePpwFilter,
  setStartsMngmtApprovedView,
  loadStartsMngmtData,
  setStartsMngmtPlacements
} from '../../../../redux/actions'
import SimpleSearch from './SimpleSearch'
import debounce from '../../../../helpers/debounce'
import { selectDepartmentSettings } from '../../../../redux/selectors/department'

const StartsMngmtFilters = () => {
  const dispatch = useDispatch()
  const data = useSelector(selectStartsMngmtPlacements)
  const filters = useSelector(selectStartsMngmtPlacementFilters)
  const depSettings = useSelector(selectDepartmentSettings)
  const shouldShowProgramFilter = depSettings && depSettings['program_field']
  const startsMngmtFilter = startsMngmtConfig(data, shouldShowProgramFilter)
  const handleStartsMngmt = (filter, value) => {
    switch (filter) {
      case 'brand':
        return dispatch(setStartsMngmtBrandFilter(value))
      case 'division':
        return dispatch(setStartsMngmtDivisionFilter(value))
      case 'empType':
        return dispatch(setStartsMngmtEmpTypeFilter(value))
      case 'startDateRange':
        return dispatch(setStartsMngmtDateRangeFilter(value))
      case 'status':
        return dispatch(setStartsMngmntStatusFilter(value))
      case 'owner':
        return dispatch(setStartsMngmntOwnerFilter(value))
      case 'incompletePpw':
        return dispatch(setStartsMngmtIncompletePpwFilter(value))
      default:
        console.warn('Unknown starts management filter', filter)
    }
  }
  return startsMngmtFilter.map(filter => {
    if (filter.hidden) {
      return null
    }
    return (
      <SmartInput
        key={`starts_management_${filter.label}_filter`}
        label={filter.label}
        filterLabel={filter.filterLabel}
        value={filters[filter.filterLabel]}
        dataSource={filter.options}
        multiSelect={filter.multiSelect}
        onChange={value => handleStartsMngmt(filter.filterLabel, value)}
        placeholder={filter.placeholder}
        dateRange={filter.dateRange}
        placement={filter.placement}
      />
    )
  })
}

export const StartsMngmtNameSearch = () => {
  const dispatch = useDispatch()
  const debouncedSearch = useMemo(
    () => debounce(input => dispatch(setStartsMngmntNameFilter(input)), 400),
    [dispatch]
  )
  const handleSearch = useCallback(
    event => {
      debouncedSearch(event.currentTarget.value.trim().toLowerCase())
    },
    [debouncedSearch]
  )
  return <SimpleSearch placeholder='Search by name' onChange={handleSearch} />
}

export const ViewApprovedBtn = () => {
  const dispatch = useDispatch()
  const active = useSelector(selectStartsMngmtApprovedView)
  const toggleApprovedView = useCallback(() => {
    dispatch(setStartsMngmtApprovedView(!active))
    dispatch(setStartsMngmtPlacements([]))
    dispatch(loadStartsMngmtData(!active))
  }, [dispatch, active])

  return (
    <button
      onClick={toggleApprovedView}
      className={`c-btn c-btn--bare c-filter-toggle-btn c-filter-toggle-btn--invert ${
        active ? 'active' : ''
      }`}
    >
      View Approved
    </button>
  )
}

export default StartsMngmtFilters
