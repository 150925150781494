import React, { useCallback, useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { useDispatch, useSelector } from 'react-redux'

import { setPiDToDelete, removePi } from 'redux/actions/piDB'
import { deletePi } from 'requests'
import toastSettings from 'helpers/toastSettings'

const selectPiToDelete = state => state.piDb.rowToDelete

const DeletePiConfirmation = () => {
  const dispatch = useDispatch()
  const [isDeleting, setIsDeleting] = useState(false)
  const piToDelete = useSelector(selectPiToDelete)
  const open = !!piToDelete
  const closeFn = useCallback(() => {
    dispatch(setPiDToDelete(null))
  }, [dispatch])
  const removeFn = useCallback(async () => {
    if (piToDelete) {
      setIsDeleting(true)
      const deleted = await deletePi(piToDelete)
      if (deleted) {
        dispatch(removePi(piToDelete))
        toastSettings.success(`${piToDelete.macAddress} removed.`)
        closeFn()
      } else {
        toastSettings.error('Error removing pi.')
      }
      setIsDeleting(false)
    }
  }, [piToDelete, setIsDeleting, closeFn, dispatch])
  const macAddress = piToDelete?.macAddress

  useEffect(() => {
    return () => {
      setIsDeleting(false)
    }
  }, [setIsDeleting])

  return (
    <Dialog
      className='c-pidb__remove-dialog'
      open={open}
      onClose={closeFn}
      aria-describedby='confirm-pi-removal'
      sx={{
        '.MuiDialog-paper': { padding: '12px 8px 24px' },
        borderRadius: '0px'
      }}
    >
      <DialogContent>
        <DialogContentText id='confirm-pi-removal' sx={{ color: '#1A1A1A' }}>
          Are you sure you want to remove{' '}
          <span style={{ fontWeight: 'bold' }}>{macAddress}</span>?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <button
          onClick={closeFn}
          className='c-btn c-btn--bare c-btn--cancel'
          disabled={isDeleting}
        >
          Cancel
        </button>
        <button
          className='c-btn--cta-1 c-btn c-btn--bare'
          disabled={isDeleting}
          onClick={removeFn}
        >
          {isDeleting ? 'Removing...' : 'Remove'}
        </button>
      </DialogActions>
    </Dialog>
  )
}

export default DeletePiConfirmation
