import {
  setJobOrdersIsPresorted,
  addJOSubmission,
  updateJOSubmission,
  updateCandidateInJO,
  jobOrderChange,
  jobOrderSubmissionChange,
  setBoardLoadingObj
} from '../../../redux/actions'
import transformJobsData from './helpers/transformJobsData'

const toggleFirstLetter = s => {
  if (typeof s !== 'string') return ''
  const first = s.charAt(0)
  const letter =
    first >= 'a' && first <= 'z' ? first.toUpperCase() : first.toLowerCase()
  return letter + s.slice(1)
}

export const processUpdateFields = (fields, object) => {
  const notfound = fields.filter(field => object[field] === undefined)
  if (notfound.length) {
    const res = notfound.map(toggleFirstLetter)
    return [...fields, ...res]
  }
  return fields
}

export const handleJobsData = result => dispatch => {
  switch (result.event) {
    case 'list': {
      // show header because the expected data is now here
      dispatch(setBoardLoadingObj('jobs'))
      const transformedData = transformJobsData(result.jobOrders)
      return dispatch(
        setJobOrdersIsPresorted(transformedData, result.isPresorted)
      )
      // if (this.board) {
      //   this.board.resetOverflowCalc();
      // }
    }
    case 'submission_added':
      return dispatch(addJOSubmission(result.jobSubmission, result.event))
    // if (this.board) {
    //   this.board.resetOverflowCalc();
    // }
    case 'submission_change': {
      let payload = {}
      result.updatedFields.forEach(key => {
        payload[key] = result.JobSubmission[key]
      })
      return dispatch(
        updateJOSubmission(
          result.JobSubmission.jobOrder,
          result.JobSubmission.bhId,
          payload,
          result.event
        )
      )
    }
    case 'candidate_change':
      return dispatch(
        updateCandidateInJO(result.instances, result.Candidate, result.event)
      )
    case 'job_order_change': {
      const payload = {}
      const updatedFields = processUpdateFields(
        result.updatedFields,
        result.jobOrder
      )
      updatedFields.forEach(field => {
        payload[field] = result.jobOrder[field]
      })
      return dispatch(
        jobOrderChange(result.jobOrder.bhId, payload, result.event)
      )
    }
    case 'slot_submission_change':
      if (result.interviewSlots) {
        dispatch(
          jobOrderSubmissionChange(
            result.jobOrder,
            result.jobSubmission,
            'interviewSlots',
            result.interviewSlots,
            result.event
          )
        )
      }
      break
    default:
      return false
  }
}
