import React from 'react'

const MobileStackRankTable = ({ data, config }) => {
  if (!data || !data.length) return null
  return (
    <div>
      {data.map(({ color, rank, divisionName, ...rest }, index) => (
        <div className='c-deal-division-table__row mobile' key={index}>
          <div>
            <div className='g-numbercard g-numbercard--xs'>
              <div
                className='g-numbercard__value g-numbercard__value--xs'
                style={{ borderColor: `#${color}` }}
              >
                <div>{rank}</div>
              </div>
            </div>
          </div>
          <div>
            <div className='c-deal-division-table__cell-division mobile'>
              {divisionName}
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: 16 }}>
                {config.map(({ label }) => (
                  <div
                    key={label}
                    className='c-deal-division-table__cell-label mobile'
                  >
                    {label}:
                  </div>
                ))}
              </div>
              <div>
                {config.map(({ value, defaultValue, fixLevel, label }) => (
                  <div
                    key={label}
                    className='c-deal-division-table__cell-value mobile'
                  >
                    {(rest[value] &&
                      (rest[value].toFixed && fixLevel
                        ? rest[value].toFixed(fixLevel)
                        : rest[value])) ||
                      defaultValue}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default MobileStackRankTable
