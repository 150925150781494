import { axiosRequest } from '../helpers/axios'

export default function getHiringManagerHistory(contactId) {
  return axiosRequest(
    'GET',
    `/clients/contacts/${contactId}/history`,
    null,
    null
  )
}
