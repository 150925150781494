import React from 'react'
import {
  Activity,
  AssignedAccounts,
  Deals,
  Jobs,
  Starts,
  StyleGuide,
  WhereWeAt
} from '../../../shared/Icons'

const menuConfig = (activeDepartment, styleGuideUrl) =>
  [
    {
      label: 'Deals',
      linkTo: !activeDepartment
        ? '/'
        : `/d/${activeDepartment}/header/boards/deals`,
      icon: <Deals />,
      key: 'deals',
      isBoardLink: true
    },
    {
      label: 'Jobs',
      linkTo: !activeDepartment
        ? '/'
        : `/d/${activeDepartment}/header/boards/jobs`,
      icon: <Jobs />,
      key: 'jobs',
      isBoardLink: true
    },
    {
      label: 'Starts',
      linkTo: !activeDepartment
        ? '/'
        : `/d/${activeDepartment}/header/boards/starts`,
      icon: <Starts />,
      key: 'starts',
      isBoardLink: true
    },
    {
      label: 'Activity',
      linkTo: !activeDepartment
        ? '/'
        : `/d/${activeDepartment}/header/boards/activity`,
      icon: <Activity />,
      key: 'activity',
      isBoardLink: true
    },
    {
      label: 'Where We At',
      linkTo: !activeDepartment
        ? '/'
        : `/d/${activeDepartment}/header/boards/dashboard`,
      icon: <WhereWeAt />,
      key: 'dashboard',
      isBoardLink: true
    },
    {
      label: 'Assigned Accounts',
      linkTo: !activeDepartment
        ? '/'
        : `/d/${activeDepartment}/header/boards/assigned-accounts`,
      icon: <AssignedAccounts />,
      key: 'assigned-accounts',
      isBoardLink: true
    },
    {
      label: 'User Guide',
      src: styleGuideUrl,
      icon: <StyleGuide height={17.6} width={22} />,
      key: 'style-guide',
      isBoardLink: false
    }
  ].filter(v => (!activeDepartment ? !v.isBoardLink : true))

export default menuConfig
