import React from 'react'
import { CardTitle, UpdatedDate, DealRankTable, FiscalDate } from './components'

const DealsDivisionBoardTable = ({ headerless }) => {
  return (
    <div className='c-dashboard'>
      {headerless && <UpdatedDate tight />}
      <CardTitle id='StackRank'>
        Deals This Month Division Stack Rank <FiscalDate />
      </CardTitle>
      <DealRankTable headerless={headerless} />
    </div>
  )
}

export default DealsDivisionBoardTable
