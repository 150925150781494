import React, { Component } from 'react'
import photo from '../../../theme/assets/noavatar.png'
import tinycolor from 'tinycolor2'

export default class UserCard extends Component {
  handleRequestClose = () => {
    this.props.setPopoverState(false, null, null)
  }

  render() {
    const {
      user,
      size,
      selectAction,
      isHeaderElement,
      showNameRight,
      showNameBottom,
      firstName,
      lastName,
      color,
      avatar,
      fontSize,
      className
    } = this.props

    const gradientContainerClassName = `department-card-gradient-container${
      isHeaderElement === false
        ? ' department-card-gradient-container--headerless'
        : ''
    }${className ? ` ${className}` : ''}`

    const departmentCardClassName = `department-card user${
      isHeaderElement === false ? ' department-card--headerless' : ''
    }${className ? ` ${className}` : ''}`

    return (
      <div
        className={`department-card-container ${(selectAction
          ? 'pointer-cursor'
          : '',
        showNameBottom ? 'bottom' : showNameRight ? 'right' : '')}`}
        onClick={event => {
          event.stopPropagation()
          user && selectAction({ ...user })
        }}
      >
        <div
          style={returnGradient({ color, isHeaderElement, size })}
          className={gradientContainerClassName}
        >
          <div
            className={departmentCardClassName}
            style={activeStyles({ isHeaderElement, avatar, size })}
          ></div>
        </div>
        {showNameBottom ? (
          <div
            style={{ fontSize: fontSize ? fontSize : size ? size * 0.3 : 24 }}
            className='header-username bottom'
          >
            {firstName} {lastName}
          </div>
        ) : null}
        {showNameRight ? (
          <div
            style={{ fontSize: fontSize ? fontSize : size ? size * 0.3 : 24 }}
            className='header-username right'
          >
            {firstName} {lastName}
          </div>
        ) : null}
      </div>
    )
  }
}

const activeStyles = ({ isHeaderElement, avatar, size }) => {
  const calculatedSize = size ? size * 0.8 : isHeaderElement ? '52px' : '110px'
  const sizeObj = {
    height: calculatedSize,
    width: calculatedSize,
    minHeight: calculatedSize,
    minWidth: calculatedSize
  }
  return {
    margin: 0,
    cursor: isHeaderElement ? 'pointer' : 'default',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${avatar ? avatar : photo})`,
    ...(size ? sizeObj : {})
  }
}

const returnGradient = ({ color, isHeaderElement, size }) => {
  const calculatedSize = size ? size : isHeaderElement ? '60px' : '120px'
  const fromColor = `#${color}`
  const toColor = tinycolor(fromColor)
    .lighten(25)
    .toString()
  const sizeObj = {
    height: calculatedSize,
    width: calculatedSize,
    minHeight: calculatedSize,
    minWidth: calculatedSize
  }
  return {
    cursor: isHeaderElement ? 'pointer' : 'default',
    background: `linear-gradient(45deg, ${fromColor}, ${toColor})`,
    ...(size ? sizeObj : {})
  }
}
