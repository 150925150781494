import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import BaseWebsocket from '../../network/websocket/BaseWebsocket'
import {
  handleStartshubSocket,
  loadStartsMngmtData,
  setSelectedPlacement
} from '../../redux/actions'
import {
  selectSelectedPlacement,
  selectStartsMngmtPlacementFilters,
  selectVisibleStartsMngmtPlacements
} from '../../redux/selectors/startsMngmt'
import ConsultantCard from '../boards/startsBoard/components/ConsultantCard'
import OnboardingPaperwork from './components/OnboardingPaperwork'
import CandidateApproveBtn from './components/CandidateApproveBtn'
import StartsMngmtCard from './components/StartsMngmtCard'
import { SOCKET_BOARDS } from 'constants'
import { Skeleton } from 'components/shared'
import { selectBoardLoadingObj } from 'redux/selectors'
import StartsMngmtSkeleton from './components/StartsMngmtSkeleton'

const StartsMngmt = () => {
  const dispatch = useDispatch()
  const [lastScrollHeight, setLastScrollHeight] = useState(0)
  const data = useSelector(selectVisibleStartsMngmtPlacements)
  const selected = useSelector(selectSelectedPlacement)
  const filters = useSelector(selectStartsMngmtPlacementFilters)
  const boardLoadingObj = useSelector(selectBoardLoadingObj)
  const handleStartshub = useCallback(
    msg => {
      dispatch(handleStartshubSocket(msg))
    },
    [dispatch]
  )
  const startsMngmtRef = useRef()
  const startsListRef = useRef()
  const setSelected = useCallback(
    value => {
      if (startsMngmtRef && startsMngmtRef.current) {
        if (value) {
          setLastScrollHeight(startsMngmtRef.current.scrollTop)
          startsMngmtRef.current.scrollTop = 0
        } else {
          startsListRef.current.style.minHeight = `${lastScrollHeight}px`
          startsMngmtRef.current.scrollTop = lastScrollHeight
          setTimeout(() => {
            startsListRef.current.style.minHeight = 0
          }, 5000)
        }
      }
      dispatch(setSelectedPlacement(value))
    },
    [dispatch, startsMngmtRef, lastScrollHeight, setLastScrollHeight]
  )
  useEffect(() => {
    dispatch(loadStartsMngmtData())
    BaseWebsocket.openSignalRSocket(SOCKET_BOARDS.STARTS_MNGMT, handleStartshub)
    return () => {
      BaseWebsocket.closeSignalRSocket(SOCKET_BOARDS.STARTS_MNGMT)
    }
  }, [dispatch, handleStartshub])
  useEffect(() => {
    if (startsMngmtRef && startsMngmtRef.current) {
      startsMngmtRef.current.scrollTop = 0
    }
  }, [filters])
  return (
    <Skeleton.Board
      loading={!boardLoadingObj['starts-management']}
      skeletonChildren={<StartsMngmtSkeleton />}
    >
      <div className='c-starts-mngmt board-container' ref={startsMngmtRef}>
        <div className='c-starts-mngmt__results-header'>
          {selected ? (
            <>
              <button
                className='c-btn c-btn--bare c-starts-mngmt__back-btn'
                onClick={() => setSelected(null)}
              >
                {'< Back to Search Results'}
              </button>
              <CandidateApproveBtn
                overrideDisable
                placement={selected}
                btnText={
                  selected.approved ? 'Revert Approval' : 'Approve Candidate'
                }
                className='c-approve-btn--mngmt'
              />
            </>
          ) : data && data.length ? (
            <span className='seeing-results'>
              Seeing {data.length} search results
            </span>
          ) : null}
        </div>
        <ul
          className={`c-starts-mngmt__items ${selected ? 'hidden' : ''}`}
          ref={startsListRef}
        >
          {data.map(pl => (
            <StartsMngmtCard
              showProgress
              hideApproveBtn
              type='mngmt'
              key={pl.bullhornId}
              placement={pl}
              onClick={() => setSelected(pl)}
            />
          ))}
        </ul>
        <div className={`c-starts-mngmt__selected ${selected ? '' : 'hidden'}`}>
          {selected && (
            <>
              <ConsultantCard
                placement={selected}
                showProgress
                className='c-starts-mngmt__card'
                allowClientPaperworkEdit
              />
              <div className='c-starts-mngmt__paperwork'>
                <OnboardingPaperwork placement={selected} />
              </div>
            </>
          )}
        </div>
      </div>
    </Skeleton.Board>
  )
}

export default StartsMngmt
