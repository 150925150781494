import { sendGraphQLQuery } from '../../network/apollo'
import {
  TOGGLE_ASSIGNED_ACCOUNTS_FILTER,
  GET_ASSIGNED_ACCOUNTS_BEGIN,
  GET_ASSIGNED_ACCOUNTS_SUCCESS,
  GET_ASSIGNED_ACCOUNTS_ERROR,
  SET_ASSIGNED_ACCOUNTS_COMPANY_FILTER,
  SET_ASSIGNED_ACCOUNTS_ACCOUNT_MANAGER_FILTER,
  SET_ASSIGNED_ACCOUNTS_DIVISION_FILTER,
  SET_ASSIGNED_ACCOUNTS_PROGRAM_FILTER,
  SET_ASSIGNED_ACCOUNTS_COMPANY_INPUT,
  SET_ASSIGNED_ACCOUNTS_AM_INPUT
} from '../actionTypes'
import {
  ASSIGNED_ACCOUNTS_QUERY,
  CORPORATION_ASSIGNED_ACCOUNTS_QUERY
} from './query'
import { mapValues, keyBy } from 'lodash'
import logger from '../../requests/logger'
import errorHandler from '../../helpers/errorHandler'
import { getDuration, getDate } from '../../helpers/dateTime'
import { setBoardLoadingObj } from '.'

export const getAssignedAccounts = () => async (dispatch, getState) => {
  dispatch(getAssignedAccountsBegin())
  try {
    const {
      assignedAccounts: { updatedAt }
    } = getState()
    const now = getDate()
    const duration = updatedAt && getDuration(now.diff(updatedAt))
    const minutes = duration && duration.minutes()
    const refreshDebounce = 3 // won't refresh in 3 minutes
    if (!updatedAt || minutes > refreshDebounce) {
      const resA = await sendGraphQLQuery(ASSIGNED_ACCOUNTS_QUERY)
      const resByCorp = await sendGraphQLQuery(
        CORPORATION_ASSIGNED_ACCOUNTS_QUERY
      )
      const assignedaccounts = resA && resA.data.managerassignedaccounts
      const assignedaccountsByCorp =
        resByCorp && resByCorp.data.corporateassignedaccounts

      if (!assignedaccounts || !assignedaccountsByCorp)
        throw new Error('Assigned accounts payload not found in response')

      // count activePlacements for each company, and patch them to each AM user
      const placementCounts = mapValues(
        keyBy(assignedaccountsByCorp, 'clientCorporation.bhId'),
        am => am.placementCount
      )

      assignedaccounts.forEach(aa => {
        const activePlacements = placementCounts[aa.clientCorporation.bhId]
        if (activePlacements) {
          aa.clientCorporation.activePlacements = activePlacements
        }
      })

      dispatch(getAssignedAccountsSuccess(assignedaccounts))
      dispatch(setBoardLoadingObj('assigned-accounts'))
    } else {
      dispatch(getAssignedAccountsError(false))
    }
  } catch (error) {
    errorHandler(error)
    logger({ error, stackError: error.message })
    dispatch(getAssignedAccountsError())
  }
}

const getAssignedAccountsBegin = () => {
  return {
    type: GET_ASSIGNED_ACCOUNTS_BEGIN
  }
}

const getAssignedAccountsSuccess = payload => {
  return {
    type: GET_ASSIGNED_ACCOUNTS_SUCCESS,
    payload
  }
}

const getAssignedAccountsError = error => {
  return {
    type: GET_ASSIGNED_ACCOUNTS_ERROR,
    payload: error
  }
}

export const toggleAssignedAccountsFilter = filterByCompany => {
  return {
    type: TOGGLE_ASSIGNED_ACCOUNTS_FILTER,
    payload: filterByCompany
  }
}

export const setAssignedAccountsCompanyFilter = payload => {
  return {
    type: SET_ASSIGNED_ACCOUNTS_COMPANY_FILTER,
    payload
  }
}

export const setAssignedAccountsAccountManagerFilter = payload => {
  return {
    type: SET_ASSIGNED_ACCOUNTS_ACCOUNT_MANAGER_FILTER,
    payload
  }
}

export const setAssignedAccountsDivisionFilter = payload => {
  return {
    type: SET_ASSIGNED_ACCOUNTS_DIVISION_FILTER,
    payload
  }
}

export const setAssignedAccountsProgramFilter = payload => {
  return {
    type: SET_ASSIGNED_ACCOUNTS_PROGRAM_FILTER,
    payload
  }
}

export const setAssignedAccountsCompanyInput = payload => {
  return {
    type: SET_ASSIGNED_ACCOUNTS_COMPANY_INPUT,
    payload
  }
}

export const setAssignedAccountsAMInput = payload => {
  return {
    type: SET_ASSIGNED_ACCOUNTS_AM_INPUT,
    payload
  }
}
