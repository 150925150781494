import {
  SET_USER,
  LOGOUT,
  SET_HEADERLESS_CREDENTIAL,
  UPDATE_HEADERLESS_TOKEN,
  REFRESH_TOKEN_BEGIN,
  CLEAR_HEADERLESS_CREDENTIAL,
  SET_IS_DISCONNECTED,
  UPDATE_SELECTED_DEPARTMENT_CATEGORY_ASSIGNMENT_NAME
} from '../actionTypes'

const DEFAULT_STATE = {
  activeUser: {},
  credential: {},
  isDisconnected: false
}

const auth = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        activeUser: action.user
      }
    case LOGOUT:
      return {
        ...state,
        activeUser: {}
      }
    case SET_HEADERLESS_CREDENTIAL:
      return {
        ...state,
        credential: action.payload
      }
    case REFRESH_TOKEN_BEGIN:
      return {
        ...state,
        credential: {
          ...state.credential,
          refreshOn: action.payload
        }
      }
    case UPDATE_HEADERLESS_TOKEN:
      return {
        ...state,
        credential: {
          ...state.credential,
          ...action.payload
        }
      }
    case CLEAR_HEADERLESS_CREDENTIAL:
      return {
        ...state,
        credential: {}
      }
    case SET_IS_DISCONNECTED:
      return {
        ...state,
        isDisconnected: action.payload
      }
    case UPDATE_SELECTED_DEPARTMENT_CATEGORY_ASSIGNMENT_NAME:
      return {
        ...state,
        selectedCategoryName: action.payload
      }
    default:
      return state
  }
}

export default auth
