export const selectSideBarIsCollapsed = state => state.UI.sideBarIsCollapsed
export const selectCandidateHistoryMenu = state => state.UI.candidateHistoryMenu
export const selectBasicMenuPopover = state => state.UI.basicMenuPopover
export const selectDatePickerOpen = state => state.UI.datePicker.open
export const selectTimePickerOpen = state => state.UI.timePicker.open
export const selectPopoverProps = state => state.UI.popover
export const selectBoardLoadingObj = state => state.UI.boardLoadingObj
export const selectMobileMenuOpen = state => state.UI.mobileMenuOpen
export const selectTouchscreenPaginationType = state =>
  state.UI.touchscreenPaginationType
export const selectFiscalMonthData = state => state.UI.fiscalMonthDropdown.data
