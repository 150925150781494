import React from 'react'

const ColumnSendouts = ({ list }) => {

  return (
    <div className='g-column-sendouts'>
      {list.map(({ value, label, color }, index) => (
        <div key={index}>
          <div className={`g-column-sendouts__value c-dashboard__color--${color}`}>
            {value}
          </div>
          <div className={'g-column-sendouts__label'}>
            {label}
          </div>
        </div>
      ))}
    </div>
  )
}

export default ColumnSendouts
