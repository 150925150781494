import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'antd'

import { setPiDbDetailsModal } from 'redux/actions/piDB'
import { selectPiDetailsModal } from 'redux/selectors/piDb'
import DetailsModalCloseIcon from './DetailsModalCloseIcon'
import DetailsModalForm from './DetailsModalForm'

const ManagePiDetailsModal = ({ allDepartments }) => {
  const dispatch = useDispatch()
  const { open, mode, data } = useSelector(selectPiDetailsModal)
  const closeModal = useCallback(() => {
    dispatch(setPiDbDetailsModal({ open: false, data: null }))
  }, [dispatch])
  return (
    <Modal
      className='c-pidb__details-modal'
      visible={open}
      footer={null}
      onCancel={closeModal}
      closeIcon={<DetailsModalCloseIcon />}
    >
      <div className='c-pidb__details-modal-header'>
        {mode === 'edit' ? 'Edit' : 'Add'} Device
      </div>
      <DetailsModalForm
        open={open}
        data={data}
        closeModal={closeModal}
        mode={mode}
        allDepartments={allDepartments}
      />
    </Modal>
  )
}

export default ManagePiDetailsModal
