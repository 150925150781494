import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export const AssignedAccountsIcon = ({ style }) => {
  return (
    <SvgIcon style={style}>
      <path d='M1,2 L1,21.5555556 L23,21.5555556 L23,2 L1,2 Z M18.1111111,18.1461988 L5.88888889,18.1461988 L5.88888889,15.7017544 L18.1111111,15.7017544 L18.1111111,18.1461988 Z M18.1111111,13.2573099 L5.88888889,13.2573099 L5.88888889,10.8128655 L18.1111111,10.8128655 L18.1111111,13.2573099 Z M18.1111111,8.36842105 L5.88888889,8.36842105 L5.88888889,5.92397661 L18.1111111,5.92397661 L18.1111111,8.36842105 Z'></path>
    </SvgIcon>
  )
}
