import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Menu } from 'components/shared'
import { setCandidateHistoryMenu } from 'redux/actions'
import { selectCandidateHistoryMenu } from 'redux/selectors/UI'
import { toHSL } from 'helpers/Util'
import { parseUtcDate } from 'helpers/dateTime'

const CandidateHistoryMenu = () => {
  const dispatch = useDispatch()
  const candidateHistoryProps = useSelector(selectCandidateHistoryMenu)
  const closeModal = useCallback(() => {
    dispatch(setCandidateHistoryMenu(null))
  }, [dispatch])
  if (!candidateHistoryProps || !candidateHistoryProps.data) return null
  const {
    data,
    name,
    jobSubmission,
    interviewSlots,
    anchorEl,
    position
  } = candidateHistoryProps
  let pdata = [
    {
      title: name,
      items: [
        {
          label: 'Submittals',
          value: data['Submitted']
        },
        {
          label: 'Client Submittals',
          value: data['Client Submission']
        },
        {
          label: 'Interviews',
          value: data['Interview Scheduled']
        },
        {
          label: 'Placements',
          value: data['Placed']
        },
        {
          label: 'Offer Extended',
          value: data['Offer Extended']
        }
      ]
    }
  ]

  const leftColor = toHSL(`#${jobSubmission.SendingUser.color}`, 0)
  const rightColor = toHSL(`#${jobSubmission.SendingUser.color}`, 25)
  const backgroundGradient = `linear-gradient(to right, ${leftColor} 0%, ${rightColor} 100%)`

  const interviewSlotSubmission = jobSubmission.InterviewSlotSubmission
  const { workingSpread, workingMargin } = interviewSlotSubmission || {}

  return (
    <Menu
      sx={menuStyle(backgroundGradient)}
      className='candidate-history'
      anchorEl={anchorEl}
      position={position}
      onClose={closeModal}
    >
      <div className='popover wide'>
        {pdata.map((item, index) => {
          return (
            <div className='popover-block' key={`popoverblock-${index}`}>
              <h1
                style={{
                  margin: '5px',
                  paddingLeft: '15px',
                  color: '#fff',
                  fontSize: '22px'
                }}
              >
                {item.title}
              </h1>
              <ul className='list-items'>
                {item.items.map((listItem, index2) => {
                  return (
                    <li
                      className={
                        listItem.children
                          ? 'list-item has-children'
                          : 'list-item'
                      }
                      key={`popover-item-${index}-${index2}`}
                    >
                      <div
                        className={
                          listItem.children
                            ? 'item-label has-children'
                            : 'item-label'
                        }
                      >
                        {listItem.label}
                      </div>
                      <div
                        className={
                          listItem.children
                            ? 'item-value has-children'
                            : 'item-value'
                        }
                      >
                        {listItem.value}
                      </div>
                      <div className='item-children'>
                        {listItem.children ? listItem.children : null}
                      </div>
                    </li>
                  )
                })}
              </ul>
              <div className='bottom-info'>
                <h2>
                  {`Candidate Submited By: ${jobSubmission.SendingUser.firstName} ${jobSubmission.SendingUser.lastName}`}
                </h2>
                <h2>
                  {`Candidate Owner: ${jobSubmission.Candidate.Owner.firstName} ${jobSubmission.Candidate.Owner.lastName}`}
                </h2>
                <h2>
                  {`${
                    jobSubmission.SendingUser.firstName
                  }'s Department: ${jobSubmission.SendingUser.PrimaryDepartment
                    .name || '???'}`}
                </h2>
                {interviewSlots && interviewSlots.length ? (
                  <h2 style={{ paddingBottom: '10px' }}>
                    First Interview:
                    {parseUtcDate(
                      interviewSlots[0].date,
                      'YYYY-MM-DD hh:mm A'
                    )}{' '}
                    {interviewSlots[0].timezone}
                  </h2>
                ) : null}
                {workingMargin || workingSpread ? (
                  <h2>
                    {`Spread/Margin: ${workingSpread || ''} / ${workingMargin ||
                      ''}%`}
                  </h2>
                ) : null}
              </div>
            </div>
          )
        })}
      </div>
    </Menu>
  )
}

const menuStyle = backgroundGradient => {
  return {
    '& .MuiMenu-paper': {
      borderRadius: '1rem',
      background: backgroundGradient
    },
    '& .MuiList-root': {
      paddingBottom: 0
    }
  }
}

export default CandidateHistoryMenu
