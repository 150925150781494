import React, { useState, useCallback } from 'react'
import { Select } from 'antd'
import SortIcon from '@mui/icons-material/Sort'

import SortIndicator from './SortIndicator'
import CaratDown from '../Icons/CaratDown'

const returnSelectOptions = dataSource => {
  return dataSource.map(option => {
    return (
      <Select.Option key={option.value} value={option.value}>
        {option.label}
      </Select.Option>
    )
  })
}

const PlaceHolder = () => {
  return (
    <div className='c-sort-dropdown__placeholder'>
      <SortIcon
        sx={{ fontSize: '20px', color: '#9B9B9B', marginRight: '10px' }}
      />
      Sort By...
    </div>
  )
}

const SortDirectionBtn = ({ value, onChange }) => {
  return (
    <div
      className={`c-sort-dropdown__dir-box ${!value ? 'disabled' : ''}`}
      onClick={onChange}
    >
      <SortIndicator value={value} />
    </div>
  )
}

const defaultSortObj = { value: undefined, direction: null }

const SortDropdown = ({ options = [], selected, onChange, sortDirection }) => {
  const [internalSortObj, setInternalSortObj] = useState(defaultSortObj)
  const onChangeFn = useCallback(
    value => {
      let sortObj = defaultSortObj
      if (value) sortObj = { value, direction: 'ASC' }
      if (onChange) onChange(sortObj)
      setInternalSortObj(sortObj)
    },
    [onChange, setInternalSortObj]
  )
  const onSortDirChange = useCallback(() => {
    let curDir = internalSortObj.direction
    let newDir = null
    if (curDir) {
      if (curDir === 'ASC') newDir = 'DESC'
      if (curDir === 'DESC') newDir = 'ASC'
    }
    const sortObj = { value: internalSortObj.value, direction: newDir }
    if (onChange) onChange(sortObj)
    setInternalSortObj(sortObj)
  }, [onChange, internalSortObj, setInternalSortObj])
  return (
    <div className='c-sort-dropdown'>
      <Select
        allowClear
        dropdownMatchSelectWidth={false}
        placeholder={<PlaceHolder />}
        value={selected || internalSortObj.value}
        mode='default'
        onChange={onChangeFn}
        suffixIcon={<CaratDown color='#9B9B9B' />}
      >
        {returnSelectOptions(options)}
      </Select>
      <SortDirectionBtn
        value={sortDirection || internalSortObj.direction}
        onChange={onSortDirChange}
      />
    </div>
  )
}

export default SortDropdown
