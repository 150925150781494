import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ACTIVITY_TABS_ENUM } from '../../../../constants'
import { setJobOrdersActivityOptionsTab } from '../../../../redux/actions'
import ToggleTabs from '../../../shared/ToggleTabs'

const selectActiveOptionTab = state => state.jobOrdersActivity.activeTab
const ActivityOptionTabs = () => {
  const dispatch = useDispatch()
  const selected = useSelector(selectActiveOptionTab)
  const setActiveTab = val => dispatch(setJobOrdersActivityOptionsTab(val))
  const options = [
    { label: 'All', value: ACTIVITY_TABS_ENUM.ALL },
    { label: 'Today', value: ACTIVITY_TABS_ENUM.TODAY },
    { label: 'Next Day', value: ACTIVITY_TABS_ENUM.NEXT_DAY }
  ]
  return (
    <>
      <div className='activity-board__filters'>
        <div className='filter-header-input-label'>Display Timeframe</div>
        <ToggleTabs
          options={options}
          onChange={setActiveTab}
          selected={selected}
        />
      </div>
    </>
  )
}

export default ActivityOptionTabs
