import React from 'react'

import {
  ConsultantCardProgressBar,
  ConsultantCardField
} from '../../../boards/startsBoard/components/ConsultantCard/subcomponents'
import { getCallStatusIndicatorProps } from 'components/boards/startsBoard/helpers/calls'
import { StatusIndicator } from 'components/shared/Icons'
import formatPlacement from '../../../boards/startsBoard/helpers/formatPlacement'

const renderAmRcNames = (amFullName, rcFullName) => {
  return (
    <>
      <div>
        <span className='c-consultant-card__field-header'>AM:</span>{' '}
        {amFullName}
      </div>
      <div>
        <span className='c-consultant-card__field-header'>R:</span> {rcFullName}
      </div>
    </>
  )
}
const StartsMngmtCard = ({
  placement,
  showProgress = false,
  onClick,
  className = ''
}) => {
  const {
    division,
    companyName,
    color,
    amFullName,
    rcFullName,
    employeeType,
    progressRemaining,
    submissionState,
    expectationCall,
    firstDayDetailsCall,
    candidateName,
    dateAdded,
    startDate
  } = formatPlacement(placement)
  const borderStyle = { borderColor: `#${color}` }
  return (
    <li
      className={`c-consultant-card c-consultant-card--mngmt ${
        showProgress ? 'c-consultant-card--w-progress' : ''
      } ${className}`}
      style={borderStyle}
      onClick={onClick}
    >
      <div className='c-consultant-card__main c-consultant-card__main--mngmt'>
        <div className='c-consultant-card__section c-consultant-card__info-block c-consultant-card__info-block--top'>
          <div className='c-consultant-card__candidate-block-wrapper'>
            <div
              className={`c-consultant-card__submission-status ${
                submissionState.toLowerCase() === 'approved' ? 'approved' : ''
              }`}
            >
              <span>{submissionState}</span>
            </div>
            <div className='c-consultant-card__candidate-block c-consultant-card__candidate-block--mngmt'>
              <button className='status-icon c-btn c-btn--bare c-btn--auto-cursor'>
                <StatusIndicator
                  className='status--default'
                  {...getCallStatusIndicatorProps(
                    expectationCall,
                    firstDayDetailsCall
                  )}
                />
              </button>
              <div>
                <ConsultantCardField
                  header='Consultant'
                  content={candidateName}
                />
                <ConsultantCardField header='Company' content={companyName} />
              </div>
              <div>
                <ConsultantCardField header='Division' content={division} />
                <ConsultantCardField
                  header='AM/R'
                  content={renderAmRcNames(amFullName, rcFullName)}
                />
              </div>
            </div>
          </div>
          <div>
            <div className='c-consultant-card__sub-date'>
              <div>
                <span className='c-consultant-card__field-header--sub-date'>
                  DA:{' '}
                </span>
                <span className='c-consultant-card__field-content--sub-date'>
                  {dateAdded}
                </span>
              </div>
              <div>
                <span className='c-consultant-card__field-header--sub-date'>
                  SD:{' '}
                </span>
                <span className='c-consultant-card__field-content--sub-date'>
                  {startDate}
                </span>
              </div>
              <ConsultantCardField
                header='Emp. Type'
                content={employeeType}
                modifier='sub-date'
              />
            </div>
          </div>
        </div>
      </div>
      {showProgress && (
        <ConsultantCardProgressBar progressRemaining={progressRemaining} />
      )}
    </li>
  )
}

export default StartsMngmtCard
