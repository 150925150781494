import { setStartsPlacements } from '..'
import { validateStartsDate } from '../../../helpers/dateTime'
import toast from '../../../helpers/toastSettings'
import { getStartsData } from '../../../requests'
import {
  setSelectedPlacement,
  setStartsMngmtPlacements,
  updateStartsMngmtPlacement
} from '../startsMngmt'
import {
  updateStartsPlacementV2,
  removeStartsPlacementV2
} from '../startsPlacements'
import { setBoardLoadingObj, setStartsFromFilter } from '../UI'
import transformStartsData from 'redux/actions/ws/helpers/transformStartsData'

const CHANGED = 'CHANGED'
const DELETED = 'DELETED'
const UNKNOWN = 'UNKNOWN'

export const loadStartsData = () => async (dispatch, getState) => {
  const {
    department: { selectedDepartment }
  } = getState()
  const encodedDep =
    selectedDepartment && encodeURIComponent(selectedDepartment)
  const res = await getStartsData({
    departmentRoute: encodedDep,
    approve: false
  })
  const data = (res && res.data && res.data.data) || []
  const transformedData = transformStartsData(data)
  dispatch(setStartsPlacements(transformedData))
  if (res && res.data) {
    dispatch(setStartsFromFilter(res.data.fromFilter))
  }
  dispatch(setBoardLoadingObj('starts'))
}

export const loadStartsMngmtData = (getApproved = false) => async dispatch => {
  const res = await getStartsData({ approve: getApproved })
  const data = (res && res.data && res.data.data) || []
  const transformedData = transformStartsData(data)
  dispatch(setStartsMngmtPlacements(transformedData))
  dispatch(setBoardLoadingObj('starts-management'))
  if (res && res.data) {
    dispatch(setStartsFromFilter(res.data.fromFilter))
  }
}

const findDivision = (divisions, selectedDepartment) => {
  return divisions.find(div => {
    return (
      div &&
      selectedDepartment &&
      div.replace('/', '').toLowerCase() === selectedDepartment.toLowerCase()
    )
  })
}

export const handleStartshubSocket = message => async (dispatch, getState) => {
  if (message) {
    const {
      department: { selectedDepartment },
      startsMngmt: { selectedPlacement }
    } = getState()
    const { id, eventType, divisions } = message
    if (id) {
      switch (eventType?.toUpperCase()) {
        case CHANGED:
          if (
            selectedDepartment &&
            divisions &&
            !!findDivision(divisions, selectedDepartment)
          ) {
            const placementRes = await getStartsData({ id })
            const placement = placementRes && placementRes.data
            if (placement && validateStartsDate(placement.dateBegin)) {
              const transformedPlacement = transformStartsData(placement)
              dispatch(updateStartsPlacementV2(transformedPlacement))
            }
          }
          if (!selectedDepartment) {
            const placementRes = await getStartsData({ id })
            const placement = placementRes && placementRes.data
            if (placement && validateStartsDate(placement.dateBegin)) {
              dispatch(updateStartsMngmtPlacement(placement))
              if (selectedPlacement && selectedPlacement.id === id) {
                const transformedPlacement = transformStartsData(placement)
                dispatch(setSelectedPlacement(transformedPlacement))
                // if (placement.approved) {
                //   toast.error(`${placement.candidateName} has been approved.`);
                // }
              }
            }
          }
          break
        case DELETED:
          dispatch(removeStartsPlacementV2(id))
          if (selectedPlacement && selectedPlacement.id === id) {
            toast.error('This placement has been deleted.')
          }
          break
        case UNKNOWN:
        default:
          console.log('Unhandled event type: ', eventType)
          break
      }
    }
  }
}
