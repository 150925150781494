import React from 'react'

const Calendar = ({ month }) => (
  <svg
    version='1.1'
    x='0px'
    y='0px'
    viewBox='0 0 512 512'
    width='59px'
    height='59px'
    fill={month ? '#F2589A' : '#CB4A7C'}
  >
    <path d='m456 48h-48v-8a24 24 0 0 0 -48 0v8h-80v-8a24 24 0 0 0 -48 0v8h-80v-8a24 24 0 0 0 -48 0v8h-48a40.045 40.045 0 0 0 -40 40v368a40.045 40.045 0 0 0 40 40h400a40.045 40.045 0 0 0 40-40v-368a40.045 40.045 0 0 0 -40-40zm-80-8a8 8 0 0 1 16 0v48a8 8 0 0 1 -16 0zm-128 0a8 8 0 0 1 16 0v48a8 8 0 0 1 -16 0zm-128 0a8 8 0 0 1 16 0v48a8 8 0 0 1 -16 0zm-64 24h48v24a24 24 0 0 0 48 0v-24h80v24a24 24 0 0 0 48 0v-24h80v24a24 24 0 0 0 48 0v-24h48a24.028 24.028 0 0 1 24 24v56h-448v-56a24.028 24.028 0 0 1 24-24zm400 416h-400a24.028 24.028 0 0 1 -24-24v-296h448v296a24.028 24.028 0 0 1 -24 24z' />
    <path d='m440 216h-32a8 8 0 0 0 -8 8v32a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-32a8 8 0 0 0 -8-8zm-8 32h-16v-16h16z' />
    <path d='m360 216h-32a8 8 0 0 0 -8 8v32a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-32a8 8 0 0 0 -8-8zm-8 32h-16v-16h16z' />
    <path d='m280 216h-32a8 8 0 0 0 -8 8v32a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-32a8 8 0 0 0 -8-8zm-8 32h-16v-16h16z' />
    <path d='m280 296h-32a8 8 0 0 0 -8 8v32a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-32a8 8 0 0 0 -8-8zm-8 32h-16v-16h16z' />
    <path d='m280 376h-32a8 8 0 0 0 -8 8v32a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-32a8 8 0 0 0 -8-8zm-8 32h-16v-16h16z' />
    <path d='m200 216h-32a8 8 0 0 0 -8 8v32a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-32a8 8 0 0 0 -8-8zm-8 32h-16v-16h16z' />
    <path d='m120 296h-32a8 8 0 0 0 -8 8v32a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-32a8 8 0 0 0 -8-8zm-8 32h-16v-16h16z' />
    <path d='m200 296h-32a8 8 0 0 0 -8 8v32a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-32a8 8 0 0 0 -8-8zm-8 32h-16v-16h16z' />
    <path d='m120 376h-32a8 8 0 0 0 -8 8v32a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-32a8 8 0 0 0 -8-8zm-8 32h-16v-16h16z' />
    <path d='m200 376h-32a8 8 0 0 0 -8 8v32a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-32a8 8 0 0 0 -8-8zm-8 32h-16v-16h16z' />
    <path d='m440 296h-32a8 8 0 0 0 -8 8v32a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-32a8 8 0 0 0 -8-8zm-8 32h-16v-16h16z' />
    <path d='m360 296h-32a8 8 0 0 0 -8 8v32a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-32a8 8 0 0 0 -8-8zm-8 32h-16v-16h16z' />
    <path d='m440 376h-32a8 8 0 0 0 -8 8v32a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-32a8 8 0 0 0 -8-8zm-8 32h-16v-16h16z' />
    <path d='m360 376h-32a8 8 0 0 0 -8 8v32a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-32a8 8 0 0 0 -8-8zm-8 32h-16v-16h16z' />
  </svg>
)

export default Calendar
