import { createSelector } from 'reselect'
import { isInRange } from '../../helpers/dateTime'

export const selectStartsMngmtPlacements = state => state.startsMngmt.data
export const selectStartsMngmtPlacementFilters = state => state.startsMngmt.meta.filters
export const selectSelectedPlacement = state => state.startsMngmt.selectedPlacement

export const selectVisibleStartsMngmtPlacements = createSelector(
  [selectStartsMngmtPlacements, selectStartsMngmtPlacementFilters],
  (data, filters) => {
    let placements = data.filter(pl =>
      filters.approvedView ? pl.approved : !pl.approved
    )
    if (filters.status) {
      placements = placements.filter(pl => pl.status === filters.status)
    }
    if (filters.brand) {
      placements = placements.filter(pl => pl.brand === filters.brand)
    }
    if (filters.division) {
      placements = placements.filter(pl => pl.division === filters.division)
    }
    if (filters.name) {
      placements = placements.filter(pl => {
        return pl.candidateName.toLowerCase().includes(filters.name)
      })
    }
    if (filters.empType) {
      placements = placements.filter(pl => pl.employeeType === filters.empType)
    }
    if (filters.startDateRange && filters.startDateRange.length) {
      placements = placements.filter((pl) =>
        isInRange(pl.dateBegin, filters.startDateRange)
      )
    }
    if (filters.owner.length) {
      placements = placements.filter(pl => filters.owner.includes(pl.accountManagerName))
    }
    if (filters.incompletePpw.length) {
      placements = placements.filter(pl => {
        const list = pl.isGovernment
          ? pl.paperworkJson
          : [...pl.clientPaperwork, ...pl.paperworkJson]
        return filters.incompletePpw.some(missing => {
          const found = list.find(ppw => ppw.title === missing)
          return found && !found.isNotApplicable && !found.isComplete
        })
      })
    }
    return placements
  }
)

export const selectStartsMngmtApprovedView = createSelector(
  [selectStartsMngmtPlacementFilters],
  filters => filters.approvedView
)
