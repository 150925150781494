import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectDepartment } from 'redux/actions'
import BrandDepartmentList from '../../../../DepartmentSelection/components/BrandDepartmentList'

const Divisions = ({ handleOpen }) => {
  const departments = useSelector(state => state.department.departmentList)
  const dispatch = useDispatch()
  const handleDepartmentChange = useCallback(
    department => {
      dispatch(selectDepartment(department))
      handleOpen()
    },
    [dispatch, handleOpen]
  )

  return (
    <BrandDepartmentList
      departments={departments}
      onDepartmentSelect={handleDepartmentChange}
    />
  )
}

export default Divisions
