import { getSlotColor } from '.'

const getStatusIndicatorProps = (available, clientConfirmed, prepped, slot) => {
  var color = slot ? getSlotColor(slot.faceToFace, slot.date) : '#FFF'
  if (!available && !clientConfirmed && !prepped) {
    // empty circle
    return { emptyCircle: true }
  }
  if (available && !clientConfirmed && !prepped) {
    // green circle
    return { circle: '#009F00' }
  }
  if (!available && clientConfirmed && !prepped) {
    // colored check
    return { check: color }
  }
  if (available && clientConfirmed && !prepped) {
    // green circle colored check
    return { circle: '#009F00', check: color }
  }
  if (available && !clientConfirmed && prepped) {
    // color circle
    return { circle: color }
  }
  if (available && clientConfirmed && prepped) {
    // color circle and color check
    return { circle: color, check: color }
  }

  return { emptyCircle: true }
}

const isOpenInterview = (interviewSlot = {}) => {
  const { available, clientConfirmed, prepped } = interviewSlot
  const status = getStatusIndicatorProps(available, clientConfirmed, prepped)
  const hasCheck = typeof status.check === 'string' || status.check === true
  return !hasCheck
}

export { getStatusIndicatorProps, isOpenInterview }
