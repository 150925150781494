
import React from 'react'
import TableHeader from './TableHeader'
import TableRow from './TableRow'

const CustomTable = ({ goalsHeader, children, className, headers, color }) => (
  <table className={className}>
    <TableHeader goalsHeader={goalsHeader} color={color} headers={headers} />
    <tbody>{children}</tbody>
  </table>
)

export {
  CustomTable,
  TableRow
}
