import errorHandler from '../../helpers/errorHandler'
import toast from '../../helpers/toastSettings'
import {
  updateClientPaperwork,
  updatePaperwork,
  approveCandidate,
  unApproveCandidate
} from '../../requests'
import {
  SET_STARTS_MNGMT_BRAND_FILTER,
  SET_STARTS_MNGMT_DIVISION_FILTER,
  SET_STARTS_MNGMT_EMP_TYPE_FILTER,
  SET_STARTS_MNGMT_DATE_RANGE_FILTER,
  SET_STARTS_MNGMT_PLACEMENTS,
  SET_STARTS_MNGMT_NAME_FILTER,
  SET_STARTS_MNGMT_STATUS_FILTER,
  SET_STARTS_MNGMT_OWNER_FILTER,
  SET_STARTS_MNGMT_INCOMPLETE_PPW_FILTER,
  UPDATE_ONBOARDING_PAPERWORK_SUCCESS,
  SET_SELECTED_PLACEMENT,
  SAVE_ONBOARDING_PAPERWORK_NOTES_SUCCESS,
  UPDATE_STARTS_MNGMT_PLACEMENT,
  APPROVE_STARTS_CANDIDATE,
  SET_STARTS_MNGMT_APPROVED_VIEW,
  REVERT_APPROVE_STARTS_CANDIDATE
} from '../actionTypes'
import transformStartsData from './ws/helpers/transformStartsData'

export const setStartsMngmtPlacements = value => {
  return {
    type: SET_STARTS_MNGMT_PLACEMENTS,
    payload: value
  }
}

export const setStartsMngmtBrandFilter = value => {
  return {
    type: SET_STARTS_MNGMT_BRAND_FILTER,
    payload: value
  }
}

export const setStartsMngmtDivisionFilter = value => {
  return {
    type: SET_STARTS_MNGMT_DIVISION_FILTER,
    payload: value
  }
}

export const setStartsMngmtEmpTypeFilter = value => {
  return {
    type: SET_STARTS_MNGMT_EMP_TYPE_FILTER,
    payload: value
  }
}

export const setStartsMngmtDateRangeFilter = value => {
  return {
    type: SET_STARTS_MNGMT_DATE_RANGE_FILTER,
    payload: value
  }
}

export const setStartsMngmntNameFilter = value => {
  return {
    type: SET_STARTS_MNGMT_NAME_FILTER,
    payload: value
  }
}

export const setStartsMngmntStatusFilter = value => {
  return {
    type: SET_STARTS_MNGMT_STATUS_FILTER,
    payload: value
  }
}

export const setStartsMngmntOwnerFilter = value => {
  return {
    type: SET_STARTS_MNGMT_OWNER_FILTER,
    payload: value
  }
}

export const setStartsMngmtIncompletePpwFilter = value => {
  return {
    type: SET_STARTS_MNGMT_INCOMPLETE_PPW_FILTER,
    payload: value
  }
}

export const setSelectedPlacement = value => {
  return {
    type: SET_SELECTED_PLACEMENT,
    payload: value
  }
}

export const updateStartsMngmtPlacement = value => {
  return {
    type: UPDATE_STARTS_MNGMT_PLACEMENT,
    payload: value
  }
}

export const updateOnboardingPaperworkSuccess = value => {
  return {
    type: UPDATE_ONBOARDING_PAPERWORK_SUCCESS,
    payload: value
  }
}

const updatePaperworkAndTransform = async (id, paperworkPayload, notes) => {
  try {
    const res = await updatePaperwork(id, paperworkPayload, notes)
    if (res && res.data) {
      const transformedData = transformStartsData(res.data)
      return transformedData
    }
  } catch (e) {
    console.warn(
      `There was an error updating paperwork for placement id: ${id}`,
      e
    )
    return false
  }
}

export const updateOnboardingPaperwork = (
  placement,
  paperwork,
  updateItem,
  updateValue
) => async dispatch => {
  try {
    if (!placement || !placement.id)
      throw new Error('Placement Id not provided')
    if (!paperwork) throw new Error('Paperwork not provided')
    const targetPaperwork = placement.paperworkJson.find(
      p => p.title === paperwork.title
    )
    if (targetPaperwork) {
      // Retain old functionality while allowing flexible values
      if (updateValue) {
        targetPaperwork[updateItem] = updateValue
      } else {
        const orItems = ['isNotApplicable', 'isComplete']
        if (orItems.includes(updateItem)) {
          const resetItem = orItems.find(item => item !== updateItem)
          targetPaperwork[updateItem] = !paperwork[updateItem]
          targetPaperwork[resetItem] = false
        }
      }
      const paperworkPayload = placement.paperworkJson.map(v => {
        if (v.title === targetPaperwork.title) {
          return targetPaperwork
        }
        return v
      })
      const transformedData = await updatePaperworkAndTransform(
        placement.id,
        paperworkPayload,
        placement.notes
      )
      if (transformedData) {
        dispatch(updateOnboardingPaperworkSuccess(transformedData))
      }
    }
  } catch (error) {
    errorHandler(error)
  }
}

export const saveOnboardingPaperworkNotesSuccess = value => {
  return {
    type: SAVE_ONBOARDING_PAPERWORK_NOTES_SUCCESS,
    payload: value
  }
}

export const saveOnboardingPaperworkNotes = (
  placement,
  notes
) => async dispatch => {
  try {
    if (!placement) throw new Error('Placement Id not provided')
    const transformedData = await updatePaperworkAndTransform(
      placement.id,
      placement.paperworkJson,
      notes
    )
    if (transformedData) {
      dispatch(updateOnboardingPaperworkSuccess(transformedData))
    }
    if (transformedData) {
      dispatch(saveOnboardingPaperworkNotesSuccess(transformedData))
      return true
    }
  } catch (error) {
    errorHandler(error)
  }
}

export const cycleClientPaperWork = (
  placement,
  paperwork
) => async dispatch => {
  try {
    if (!placement || !placement.id)
      throw new Error('Placement Id not provided')
    if (!paperwork) throw new Error('Paperwork not provided')
    const updatedPaperwork = {
      title: paperwork.title,
      isNotApplicable: false,
      isComplete: false
    }
    if (paperwork.isComplete) {
      updatedPaperwork.isNotApplicable = true
    } else if (!paperwork.isNotApplicable) {
      updatedPaperwork.isComplete = true
    }
    const paperworkPayload = placement.clientPaperwork.map(v => {
      if (v.title === updatedPaperwork.title) {
        return updatedPaperwork
      }
      return v
    })
    const res = await updateClientPaperwork(placement.id, paperworkPayload)
    if (res && res.data) {
      const transformedData = transformStartsData(res.data)
      dispatch(updateOnboardingPaperworkSuccess(transformedData))
    }
  } catch (error) {
    errorHandler(error)
  }
}

export const approveStartsCandidateSuccess = value => {
  return {
    type: APPROVE_STARTS_CANDIDATE,
    payload: value
  }
}

export const approveStartsCandidate = placementId => async dispatch => {
  try {
    const res = await approveCandidate(placementId)
    if (res) {
      dispatch(approveStartsCandidateSuccess(res.data))
      toast.success('Candidate approved.')
    } else {
      toast.success('Error approving candidate.')
    }
  } catch (error) {
    console.log('Error')
  }
}

export const setStartsMngmtApprovedView = value => {
  return {
    type: SET_STARTS_MNGMT_APPROVED_VIEW,
    payload: value
  }
}

export const revertApproveStartsCandidateSuccess = value => {
  return {
    type: REVERT_APPROVE_STARTS_CANDIDATE,
    payload: value
  }
}

export const revertApproveStartsCandidate = placementId => async dispatch => {
  try {
    const res = await unApproveCandidate(placementId)
    if (res) {
      dispatch(revertApproveStartsCandidateSuccess(res.data))
      toast.success('Approval reverted succesfully.')
    } else {
      toast.success('Error reverting approval.')
    }
  } catch (error) {
    console.log('Error')
  }
}
