import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import {
  loadPiDBData,
  setPiDbDetailsModal,
  setPiDToDelete
} from '../../../../redux/actions/piDB'
import columnConfig from './helpers/columnConfig'
import renderPaginationItems from './helpers/renderPaginationItems'
import filterData from './helpers/filterData'

const selectPiDBdata = state => state.piDb.data
const selectPiDBdataLoading = state => state.piDb.loading
const selectPiDBFilters = state => state.piDb.meta.filters

const PiDBTable = () => {
  const dispatch = useDispatch()
  const tableRef = useRef(null)
  const filters = useSelector(selectPiDBFilters)
  const data = useSelector(selectPiDBdata)
  const loading = useSelector(selectPiDBdataLoading)
  const openEditDeviceModal = useCallback(
    row => {
      dispatch(
        setPiDbDetailsModal({
          open: true,
          data: row,
          mode: 'edit'
        })
      )
    },
    [dispatch]
  )
  const openDeleteConfirmationDialog = useCallback(
    row => {
      dispatch(setPiDToDelete(row))
    },
    [dispatch]
  )
  const columns = columnConfig(
    openDeleteConfirmationDialog,
    openEditDeviceModal
  )
  const filteredData = useMemo(() => filterData(data, filters), [data, filters])
  const onPageChange = useCallback(() => {
    if (tableRef?.current) {
      tableRef.current.scrollIntoView()
    }
  }, [tableRef])
  useEffect(() => {
    dispatch(loadPiDBData())
  }, [dispatch])
  return (
    <Table
      ref={tableRef}
      tableLayout='fixed'
      className='c-pidb__table'
      columns={columns}
      dataSource={filteredData}
      rowKey={({ board, departmentBullhornId, macAddress }) =>
        `${board}-${departmentBullhornId}-${macAddress}`
      }
      scroll={{ x: '100%' }}
      pagination={{
        position: ['bottomLeft'],
        defaultPageSize: 15,
        showSizeChanger: false,
        itemRender: renderPaginationItems,
        onChange: onPageChange,
        className: 'c-pidb__table-pagination'
      }}
      loading={loading}
      prefixCls='c-pidb__table ant-table'
    />
  )
}

export default PiDBTable
