import React from 'react'
import { Apps, Close } from '@mui/icons-material'
import { ButtonUnstyled } from '@mui/material'

const DivisionSelector = ({ isOpen, handleOpen }) => {
  return (
    <div className='c-ts-menu__divisions'>
      <ButtonUnstyled className='c-ts-menu__divisions_btn' onClick={handleOpen}>
        {isOpen ? <Close /> : <Apps />}
        change division
      </ButtonUnstyled>
    </div>
  )
}

export default DivisionSelector
