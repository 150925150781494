import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSwipeable } from 'react-swipeable'

// Global Components
import Header from './components/Header'
import Menu from './components/Menu/Menu'
import FilterHeader from 'components/layout/header/subComponents/FilterHeader'

// boards
import Jobs from 'components/boards/jobsBoard'
import Deals from 'components/boards/dealsBoard'
import Starts from 'components/boards/startsBoard'
import WhereWeAt from './components/boards/WhereWeAt'
import Activity from 'components/boards/activityBoard'

// redux
import {
  setUserCssVars,
  fetchActiveDepartments,
  loadStartsData,
  setDepartmentSettings,
  setActiveUser,
  setModalState as setModalStateAction
} from 'redux/actions'
import { selectActiveUser } from 'redux/selectors'

// helpers
import { getAccessToken } from 'helpers/Util'
import useInitializeBoardSockets from './hooks/useInitializeBoardSockets'
import useUrlCredentials from './hooks/useUrlCredentials'
import TouchScreenSettingsDrawer from './components/SettingsDrawer'
import CandidateHistoryMenu from 'components/shared/CandidateHistoryMenu'
import BasicMenuPopover from 'components/shared/BasicMenuPopover'
import { store } from 'index'
import { useInterval } from 'components/hocs/hooks'
import Modal from 'components/shared/Modal'
import Popover from 'components/shared/popover'
import BaseWebsocket from 'network/websocket/BaseWebsocket'

// TODO: Is there supposed to be a specific active user? Components that use custom colors pull from the active user in store
const user = {
  color: 'e00005',
  bhId: 5090073
}

const TouchScreen = props => {
  const {
    match: {
      params: { department }
    },
    updateUserWSFxn
  } = props
  const departmentFromUrl = department
  const activeUser = useSelector(selectActiveUser)
  const [boardPage, setBoardPage] = useState(null) // setBoardPage can be used to manipulate the Board components current page
  const selectedDepartment = useSelector(
    state => state.department.selectedDepartment
  )
  useUrlCredentials()
  const dispatch = useDispatch()
  const {
    startSockets,
    closeSockets,
    reOpenSockets
  } = useInitializeBoardSockets(selectedDepartment)
  const [board, setBoard] = useState('deals')
  const boards = useMemo(
    () => ['deals', 'jobs', 'activity', 'starts', 'where-we-at'],
    []
  )
  const [isOpen, setIsOpen] = useState(false)
  const jobOrders = useSelector(state => state.jobOrders)
  const departments = useSelector(state => state.department)
  const UI = useSelector(state => state.UI)
  const [internalPagination, setInternalPagination] = useState({
    isPaginating: false,
    isLastPage: false,
    page: 0
  })
  const jobsFilters = useSelector(state => state.jobOrders.meta.filters)
  const jobsSort = useSelector(state => state.jobOrders.meta.sort)
  const startsFilters = useSelector(
    state => state.placementsStarts.meta.filters
  )
  const showFilters = !['deals', 'where-we-at'].includes(board)
  useEffect(() => {
    setIsOpen(true)
    setBoardPage(0)
  }, [jobsFilters, startsFilters, jobsSort])
  useEffect(() => {
    dispatch(setActiveUser(user))
  }, [dispatch])

  useEffect(() => {
    setIsOpen(false)
    setBoardPage(null)
    setInternalPagination({
      isPaginating: false,
      isLastPage: false,
      page: 0
    })
    setBoardPage(0)
    if (!selectedDepartment) {
      dispatch(setDepartmentSettings(departmentFromUrl))
    } else {
      dispatch(setDepartmentSettings(selectedDepartment))
    }
    startSockets()
    dispatch(setUserCssVars())
    dispatch(loadStartsData())

    return () => {
      closeSockets()
    }
  }, [
    closeSockets,
    departmentFromUrl,
    dispatch,
    selectedDepartment,
    startSockets
  ])

  useEffect(() => {
    if (!departments.length) {
      getAccessToken()
        .then(token => {
          const headerlessToken = store?.getState()?.auth?.credential
            ?.accessToken
          if (token || headerlessToken) {
            dispatch(fetchActiveDepartments())
          }
        })
        .catch(e => {
          console.warn('Access token not found.')
        })
    }
  }, [departments.length, dispatch])

  const handleNextBoard = useCallback(() => {
    if (!internalPagination.isPaginating) {
      const nextBoard =
        boards.findIndex(b => b === board) + 1 > 4
          ? 0
          : boards.findIndex(b => b === board) + 1
      setBoard(boards[nextBoard])
    } else {
      const nextPage =
        board !== 'where-we-at'
          ? internalPagination.page
          : internalPagination.page + 1
      setBoardPage(nextPage)
    }
  }, [board, boards, internalPagination.isPaginating, internalPagination.page])

  useEffect(() => {
    if (!isOpen && !internalPagination.isPaginating) {
      const timer = internalPagination.isLastPage ? 10 : 30
      const boardsInterval = setInterval(() => {
        handleNextBoard()
      }, timer * 1000)
      return () => clearInterval(boardsInterval)
    }

    if (isOpen) {
      const idleInterval = setInterval(() => {
        setIsOpen(false)
        handleNextBoard()
      }, 30 * 60 * 1000)
      return () => clearInterval(idleInterval)
    }
  }, [isOpen, board, internalPagination, handleNextBoard])

  const handleBoardChange = useCallback(selectedBoard => {
    setBoard(selectedBoard)
  }, [])

  useInterval(reOpenSockets, 60 * 1000)

  const handlePrevBoard = useCallback(() => {
    const firstPage = board !== 'where-we-at' ? 1 : 0
    if (
      (!internalPagination.isPaginating && !internalPagination.isLastPage) ||
      internalPagination.page === firstPage
    ) {
      const prevBoard =
        boards.findIndex(b => b === board) - 1 < 0
          ? 4
          : boards.findIndex(b => b === board) - 1
      setBoard(boards[prevBoard])
      setBoardPage('lastPage')
    } else {
      const prevPage =
        board !== 'where-we-at'
          ? internalPagination.page - 2
          : internalPagination.page - 1
      setBoardPage(prevPage)
    }
  }, [
    board,
    boards,
    internalPagination.isLastPage,
    internalPagination.isPaginating,
    internalPagination.page
  ])

  const handleDrag = direction => {
    if (direction === 'Right') {
      handlePrevBoard()
      setIsOpen(true)
    }
    if (direction === 'Left') {
      handleNextBoard()
      setIsOpen(true)
    }
  }

  const gestures = useSwipeable({
    onSwiped: e => handleDrag(e.dir)
  })

  useEffect(() => {
    setInternalPagination({
      isPaginating: false,
      isLastPage: false,
      page: 0
    })
  }, [board])

  const handleInternalPagination = useCallback(
    (paginating, lastPage, page, openMenu, changeBoard) => {
      if (paginating) {
        setInternalPagination(internalPagination => ({
          ...internalPagination,
          isPaginating: paginating
        }))
      }
      if (lastPage) {
        setInternalPagination({ isPaginating: false, isLastPage: lastPage })
      }
      if (typeof page === 'number') {
        setInternalPagination(internalPagination => ({
          ...internalPagination,
          page: page
        }))
      }
      if (openMenu) {
        setIsOpen(true)
      }
      if (typeof changeBoard === 'string') {
        if (changeBoard === 'next') {
          handleNextBoard()
        } else if (changeBoard === 'prev') {
          handlePrevBoard()
        }
      }
    },
    [handleNextBoard, handlePrevBoard]
  )

  const renderBoard = () => {
    switch (board) {
      case 'deals':
        return (
          <Deals
            useTouchscreenBoard
            selectedDepartment={selectedDepartment}
            isPaused={isOpen}
            handleInternalPagination={handleInternalPagination}
            boardPage={boardPage}
            setBoardPage={setBoardPage}
          />
        )
      case 'jobs':
        return (
          <Jobs
            useTouchscreenBoard
            selectedDepartment={selectedDepartment}
            isPaused={isOpen}
            jobOrders={jobOrders}
            handleInternalPagination={handleInternalPagination}
            boardPage={boardPage}
            setBoardPage={setBoardPage}
          />
        )
      case 'activity':
        return (
          <Activity
            useTouchscreenBoard
            selectedDepartment={selectedDepartment}
            isPaused={isOpen}
            handleInternalPagination={handleInternalPagination}
            boardPage={boardPage}
            setBoardPage={setBoardPage}
          />
        )
      case 'starts':
        return (
          <Starts
            useTouchscreenBoard
            selectedDepartment={selectedDepartment}
            isPaused={isOpen}
            handleInternalPagination={handleInternalPagination}
            boardPage={boardPage}
            setBoardPage={setBoardPage}
          />
        )
      case 'where-we-at':
        return (
          <WhereWeAt
            useTouchscreenBoard
            selectedDepartment={selectedDepartment}
            isPaused={isOpen}
            handleInternalPagination={handleInternalPagination}
            boardPage={boardPage}
            setBoardPage={setBoardPage}
          />
        )
      default:
        return (
          <Deals
            useTouchscreenBoard
            selectedDepartment={selectedDepartment}
            isPaused={isOpen}
            handleInternalPagination={handleInternalPagination}
            boardPage={boardPage}
            setBoardPage={setBoardPage}
          />
        )
    }
  }

  const updateUser = (userId, field, value) => {
    BaseWebsocket.send(this.userSocketType, {
      event: 'user_change',
      userId,
      field,
      value
    })
  }

  const setModalState = useCallback(() => {
    dispatch(setModalStateAction(false, null))
  }, [dispatch])

  return (
    <div className='c-ts-main' {...gestures}>
      <Header
        selectedDepartment={selectedDepartment}
        board={board.split('-').join(' ')}
      />
      <TouchScreenSettingsDrawer />
      {showFilters && (
        <div className='main-header-container'>
          <div className='main-header'>
            <FilterHeader
              board={board}
              categories={departments.categories}
              department={selectedDepartment}
              updateUserWSFxn={updateUserWSFxn}
              activeUser={activeUser}
            />
          </div>
        </div>
      )}
      {renderBoard()}
      <Menu
        boardSelector={{
          handleBoardChange: handleBoardChange,
          boards: boards,
          activeBoard: board
        }}
        isOpen={isOpen}
        handleOpen={() => setIsOpen(!isOpen)}
      />
      <Popover {...props} updateUserWSFxn={updateUser} />
      <CandidateHistoryMenu />
      <BasicMenuPopover />
      <Modal
        UI={UI}
        setModalState={setModalState}
        updateUserWSFxn={updateUser}
      />
    </div>
  )
}

export default TouchScreen
