import {
  SET_DEPARTMENT_SETTINGS,
  SET_DEPARTMENT_ERROR,
  SET_DEPARTMENT_FETCH_ERROR,
  SET_SELECTED_DEPARTMENT,
  SET_SELECTED_BRAND,
  CLEAR_DEPARTMENTS,
  SET_ACTIVE_DEPARTMENTS,
  SET_DEPARTMENT_CATEGORIES,
  UPDATE_DEPARTMENT_CATEGORY_GOAL,
  UPDATE_CATEGORIES_GOAL
} from '../actionTypes'

const DEFAULT_STATE = {
  departments: [],
  departmentColors: {},
  departmentList: [],
  selectedDepartment: null,
  selectedBrand: null,
  isGovernment: null,
  settings: {},
  categories: [],
  error: false,
  fetchError: false
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case CLEAR_DEPARTMENTS:
      return {
        ...state,
        settings: {},
        categories: [],
        error: false
      }
    case SET_DEPARTMENT_SETTINGS:
      return {
        ...state,
        settings: action.payload
      }
    case SET_DEPARTMENT_ERROR:
      return {
        ...state,
        error: action.payload
      }
    case SET_SELECTED_DEPARTMENT:
      return {
        ...state,
        selectedDepartment: action.payload
      }
    case SET_SELECTED_BRAND:
      return {
        ...state,
        isGovernment:
          typeof action.payload === 'string'
            ? action.payload.toLowerCase() === 'government'
            : null,
        selectedBrand: action.payload
      }
    case SET_ACTIVE_DEPARTMENTS: {
      const { displayNames, departmentColors, departmentList } = action.payload
      return {
        ...state,
        departments: displayNames,
        departmentColors,
        departmentList
      }
    }
    case SET_DEPARTMENT_FETCH_ERROR:
      return {
        ...state,
        fetchError: action.payload
      }
    case SET_DEPARTMENT_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      }
    case UPDATE_DEPARTMENT_CATEGORY_GOAL: {
      const { departmentCategoriesDealsAssignmentId, goal } = action.payload
      const categories = state.categories.map(category => {
        if (category.Id === departmentCategoriesDealsAssignmentId) {
          return {
            ...category,
            goal
          }
        }
        return category
      })
      return {
        ...state,
        categories
      }
    }
    case UPDATE_CATEGORIES_GOAL: {
      const dealsGoals = action.payload
      const updatedCats = state.categories.map(category => {
        const data = dealsGoals.find(
          dealsGoal => dealsGoal.divisionName === category.name
        )
        if (data) {
          category.goal = data.dealGoal
          category.count = data.dealCount
        }
        return category
      })
      return {
        ...state,
        categories: updatedCats
      }
    }
    default:
      return state
  }
}
