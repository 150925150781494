import React from 'react'

const colors = {
  black: '#1A1A1A',
  gray: '#D8D8D8'
}

const SortIndicator = ({ value }) => {
  const colorConfig = {
    up: value && value === 'DESC' ? colors.gray : colors.black,
    down: value && value === 'ASC' ? colors.gray : colors.black
  }
  return (
    <svg width='21' height='26' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path fill={colorConfig.up} d='M6.329 0L.049 6.264h4.71v11.007h3.14V6.264h4.71z' />
        <path fill={colorConfig.down} d='M16.189 19.736V8.729h-3.14v11.007h-4.71L14.619 26l6.28-6.264z' />
      </g>
    </svg>
  )
}

export default SortIndicator
