import React from 'react'

import StatusBtn from 'components/shared/StatusBtn'

const SubDoc = ({ title, status }) => {
  return (
    <div>
      {title}{' '}
      {status && (
        <StatusBtn
          btnClassname='c-starts-modal__document-btn'
          statusProps={status}
        />
      )}
    </div>
  )
}

const Document = ({ title, status, groupedPaperwork, subtitle }) => {
  return (
    <li
      className={`c-starts-modal__document ${
        !status ? 'c-starts-modal__document--inactive' : ''
      }`}
    >
      <div>
        <div>{title}</div>
        {status && !groupedPaperwork && <StatusBtn statusProps={status} />}
      </div>
      {subtitle ? (
        <div className='c-starts-modal__document--sub'>{subtitle}</div>
      ) : null}
      {groupedPaperwork && groupedPaperwork.length ? (
        <div className='c-starts-modal__document--sub'>
          {groupedPaperwork.map(p => (
            <SubDoc key={p.title} {...p} />
          ))}
        </div>
      ) : null}
    </li>
  )
}

export default Document
