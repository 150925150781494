import React, { useRef, useEffect, useState } from 'react'

const defaultWidth = 66

const NumberBlock = ({ value }) => {
  const node = useRef()
  const [width, setWidth] = useState(defaultWidth)
  useEffect(() => {
    const numWidth = node.current.scrollWidth
    if (numWidth > defaultWidth) {
      setWidth(numWidth)
    }
  }, [value])
  const scale = (defaultWidth / width).toFixed(2)
  const left = ((1 - scale) / 2 * 100).toFixed(2)
  const style = width > defaultWidth
    ? {
      transform: `scale(${scale})`,
      marginLeft: `-${left}%`
    } : {}
  return (
    <div className='g-numbercard__value'>
      <div ref={node} style={style}>{value}</div>
    </div>
  )
}

export default NumberBlock
