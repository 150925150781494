import React, { useState, useCallback, useEffect } from 'react'
import { Tooltip } from 'antd'

import OpenTimes from 'components/shared/Icons/OpenTimes'

/**
 * @param {function} openModal - function that opens OpenTimesModal on click
 * @param {Boolean} shouldRemainInvisible - handles keeping the tooltip invisible while retaining native visibility logic
 * @returns React.Fragment
 */
const OpenTimesIconTooltip = ({ openModal, shouldRemainInvisible }) => {
  const [open, setOpen] = useState(false)
  // this useEffect is needed for safari
  useEffect(() => {
    if (shouldRemainInvisible) {
      setOpen(false)
    }
  }, [shouldRemainInvisible])
  const onVisibleChange = useCallback(
    visible => {
      if (shouldRemainInvisible) {
        setOpen(false)
      } else {
        setOpen(visible)
      }
    },
    [shouldRemainInvisible, setOpen]
  )

  return (
    <Tooltip
      title='Click for open times'
      visible={open}
      onVisibleChange={onVisibleChange}
    >
      <span className='c-open-times-icon' onClick={openModal}>
        <OpenTimes />
      </span>
    </Tooltip>
  )
}

export default OpenTimesIconTooltip
