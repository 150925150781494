import { ROTATION_ENUM } from 'components/PiDB/helpers/constants'
import { sortAlphabetically } from 'helpers/sort'

const divisionOptions = allDepartments => {
  if (allDepartments?.length) {
    return allDepartments
      .map(({ displayName, bhId, name }) => ({
        label: displayName || name,
        value: bhId
      }))
      .sort((a, b) => sortAlphabetically(a.label, b.label))
  }

  return []
}

const boardOptions = [
  {
    value: 'activity',
    label: 'Activity'
  },
  {
    value: 'deals',
    label: 'Deals'
  },
  {
    value: 'jobs',
    label: 'Jobs'
  },
  {
    value: 'starts',
    label: 'Starts'
  }
]
const rotationOptions = [
  {
    label: 'Left',
    value: ROTATION_ENUM.LEFT
  },
  {
    label: 'Normal',
    value: ROTATION_ENUM.NORMAL
  },
  {
    label: 'Right',
    value: ROTATION_ENUM.RIGHT
  }
]

const urlFormatOptions = [
  {
    label: 'd/{0}/headerless/boards/{1}',
    value: 'd/{0}/headerless/boards/{1}'
  },
  {
    label: '/hotJobOrder',
    value: '/hotJobOrder'
  },
  {
    label: '/hotActivity',
    value: '/hotActivity'
  }
]

const formConfig = allDepartments => [
  {
    label: 'Mac Address',
    dataIndex: 'macAddress'
  },
  {
    label: 'Division Name',
    dataIndex: 'departmentBullhornId',
    placeHolder: 'Select Division',
    options: divisionOptions(allDepartments)
  },
  {
    label: 'Board',
    dataIndex: 'boardName',
    placeHolder: 'Select Board',
    options: boardOptions
  },
  {
    label: 'Display Rotation',
    dataIndex: 'displayRotation',
    placeHolder: 'Select Rotation',
    options: rotationOptions
  },
  {
    label: 'Url Format',
    dataIndex: 'urlFormat',
    placeHolder: 'Select Url Format',
    options: urlFormatOptions
  },
  {
    label: 'Startup URL',
    dataIndex: 'baseUrl',
    wide: true
  }
]

export {
  divisionOptions,
  boardOptions,
  rotationOptions,
  urlFormatOptions,
  formConfig
}
