import { newRequest } from '../helpers/axios'

export default function updateDivisionFiscalGoal(
  departmentCategoriesDealsAssignmentId,
  goal,
  divisionFiscalGoalId,
  fiscalMonth
) {
  const postBody = {
    goal: Number(goal),
    departmentCategoriesDealsAssignmentId
  }
  const patchBody = [{ op: 'replace', path: '/goal', value: Number(goal) }]
  if (divisionFiscalGoalId) {
    return newRequest(
      'PATCH',
      `/division-fiscal-months/${divisionFiscalGoalId}`,
      patchBody,
      null
    )
  } else {
    return newRequest(
      'POST',
      `/division-fiscal-months/${departmentCategoriesDealsAssignmentId}`,
      postBody,
      null
    )
  }
}
