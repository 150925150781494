import React from 'react'

const WhereWeAt = () => {
  return (
    <svg width='12' height='22' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.854 11.481c-.735-.46-1.758-.881-3.068-1.264-1.31-.382-2.251-.807-2.822-1.276-.572-.467-.858-1.096-.858-1.885 0-.85.271-1.514.813-1.989.541-.476 1.323-.713 2.345-.713.979 0 1.758.321 2.333.964.576.643.864 1.51.864 2.601L9.46 8h2.372l.001-.08c0-1.582-.401-2.829-1.205-3.74-.804-.91-1.927-1.455-3.371-1.632l-.007-.001V0h-2v2.551c-1.392.165-2.494.639-3.297 1.432C1.132 4.794.721 5.832.721 7.098c0 1.243.419 2.268 1.257 3.075.838.806 2.172 1.461 4.002 1.963 1.315.394 2.249.834 2.804 1.32.553.487.831 1.085.831 1.795 0 .842-.323 1.506-.967 1.992-.645.486-1.53.729-2.656.729-1.151 0-2.041-.289-2.668-.868-.627-.579-.94-1.405-.94-2.478H0c0 1.528.453 2.738 1.359 3.63.883.869 2.101 1.37 3.641 1.517V22h2v-2.21l.025-.001c1.572-.147 2.795-.617 3.667-1.413.871-.795 1.308-1.845 1.308-3.149 0-.818-.175-1.532-.522-2.143-.348-.609-.89-1.144-1.624-1.603z'
        fill='#4E4E4E'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default WhereWeAt
