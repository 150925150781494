import logger from '../requests/logger'

const ignoreErrors = [
  'ResizeObserver loop limit exceeded', // Safe to ignore(likely related to rc-resize-observer dependency): https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/50387233#50387233
  'ResizeObserver loop completed with undelivered notifications.' // Currently being thrown by antd when clicking on dropdowns, no solution as of 2023/07/27. Otherwise seems harmless: https://github.com/ant-design/ant-design/issues/26621
]

const handleWindowOnError = (message, url, lineNumber, column, error) => {
  if (
    process.env.NODE_ENV !== 'development' &&
    !ignoreErrors.includes(message)
  ) {
    if (error) {
      logger({
        error,
        stackError: error.message
      })
    }
    console.warn('Reloading the page in 30 seconds: ', {
      message
    })
    setTimeout(() => {
      logger({
        stackError: 'Reloaded the page due to catch-all error.'
      })
      window.location.reload()
    }, 30000)
  }
  return false
}

export default handleWindowOnError
