import React from 'react'
import { Clipboard, ClipboardPlus } from 'components/shared/Icons'

const InterviewprepDetailsButton = ({ hasInterviewPrepDetail, onClick }) => {
  const stopPropagation = e => {
    e.stopPropagation()
    onClick()
  }
  return hasInterviewPrepDetail ? (
    <button
      className='c-btn c-btn--bare c-btn--responsive'
      title='Add Interview Prep Details'
      onClick={stopPropagation}
    >
      <Clipboard />
    </button>
  ) : (
    <button
      className='c-btn c-btn--bare c-btn--responsive'
      title='View Interview Prep Details'
      onClick={stopPropagation}
    >
      <ClipboardPlus />
    </button>
  )
}

export default InterviewprepDetailsButton
