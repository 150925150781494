import {
  SET_STARTS_PLACEMENTS,
  ADD_STARTS_PLACEMENT,
  REMOVE_STARTS_PLACEMENT,
  CLEAR_PREVIOUS_DPEARTMENT_DATA,
  SET_STARTS_PLACEMENTS_COMPANY_FILTER,
  SET_STARTS_PLACEMENTS_OWNER_FILTER,
  SET_STARTS_PLACEMENTS_DATE_RANGE_FILTER,
  SET_STARTS_PLACEMENTS_VIEW_MODE,
  SET_STARTS_PLACEMENTS_PROGRAM_FILTER,
  SET_STARTS_PLACEMENTS_STATUS_FILTER,
  UPDATE_STARTS_PLACEMENT_IS_CLEARED_FLAG,
  UPDATE_STARTS_PLACEMENT,
  REMOVE_STARTS_PLACEMENT_V2,
  APPROVE_STARTS_CANDIDATE,
  REVERT_APPROVE_STARTS_CANDIDATE
} from '../actionTypes'

const DEFAULT_STATE = {
  data: [],
  meta: {
    filters: {
      company: '',
      owner: [],
      startDateRange: [],
      program: '',
      status: ''
    },
    viewMode: null
  }
}

const placementsStarts = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case CLEAR_PREVIOUS_DPEARTMENT_DATA:
      return DEFAULT_STATE
    case SET_STARTS_PLACEMENTS:
      return {
        ...state,
        data: action.placements
      }
    case ADD_STARTS_PLACEMENT:
      return {
        ...state,
        data: state.data.concat(action.placement)
      }
    case REMOVE_STARTS_PLACEMENT:
      return {
        ...state,
        data: state.data.filter(el => el.bhId === action.placement.bhId)
      }
    case SET_STARTS_PLACEMENTS_COMPANY_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters,
            company: action.payload
          }
        }
      }
    case SET_STARTS_PLACEMENTS_OWNER_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters,
            owner: action.payload
          }
        }
      }
    case SET_STARTS_PLACEMENTS_DATE_RANGE_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters,
            startDateRange: action.payload
          }
        }
      }
    case SET_STARTS_PLACEMENTS_PROGRAM_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters,
            program: action.payload
          }
        }
      }
    case SET_STARTS_PLACEMENTS_STATUS_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters,
            status: action.payload
          }
        }
      }
    case SET_STARTS_PLACEMENTS_VIEW_MODE:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters
          },
          viewMode: action.payload
        }
      }
    case UPDATE_STARTS_PLACEMENT:
    case UPDATE_STARTS_PLACEMENT_IS_CLEARED_FLAG: {
      const updatedPlacement = action.payload
      if (!state.data.find(p => p.id === updatedPlacement.id)) {
        return {
          ...state,
          data: [...state.data, updatedPlacement]
        }
      }
      return {
        ...state,
        data: state.data.map(p => {
          if (p.id === updatedPlacement.id) {
            return { ...p, ...updatedPlacement }
          }
          return p
        })
      }
    }
    case APPROVE_STARTS_CANDIDATE:
      return {
        ...state,
        data: state.data.filter(p => p.id !== action.payload.id)
      }
    case REVERT_APPROVE_STARTS_CANDIDATE:
      return {
        ...state,
        data: [...state.data, action.payload]
      }
    case REMOVE_STARTS_PLACEMENT_V2:
      return {
        ...state,
        data: state.data.filter(p => p.id !== action.payload)
      }
    default:
      return state
  }
}

export default placementsStarts
