import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { getAccessToken } from 'helpers/Util'
import logger from 'requests/logger'
import { refreshHeaderlessToken, setHeaderlessCredential } from 'redux/actions'

const REFRESH_INTERVAL = process.env.REACT_APP_REFRESH_INTERVAL || 90

export default function useUrlCredentials() {
  const dispatch = useDispatch()
  const { search } = useLocation()
  const [tokenExists, setTokenExists] = useState(false)
  const credential = useSelector(state => state.auth.credential)
  const isDisconnected = useSelector(state => state.auth.isDisconnected)
  const intRef = useRef()

  const refreshCredential = useCallback(async () => {
    if (!isDisconnected) {
      if (credential.macAddress && credential.clientId) {
        await dispatch(refreshHeaderlessToken())
      }
    }
  }, [dispatch, credential, isDisconnected])

  useEffect(() => {
    const timestamp = Math.floor(Date.now() / 1000)
    const expiresOn = timestamp + 3540
    const urlParams = new URLSearchParams(search)
    const accessToken = urlParams.get('access_token')
    const clientId = urlParams.get('client_id')
    const clientSecret = urlParams.get('client_secret')
    const macAddress = urlParams.get('mac_address')
    intRef.current = setInterval(refreshCredential, 60000 * REFRESH_INTERVAL)
    if (accessToken && clientId && clientSecret && !credential.expiresOn) {
      dispatch(
        setHeaderlessCredential({
          accessToken,
          clientId,
          clientSecret,
          macAddress,
          expiresOn
        })
      )
    } else if (credential.expiresOn && credential.expiresOn <= timestamp - 60) {
      dispatch(refreshHeaderlessToken())
    } else if (!credential.expiresOn) {
      getAccessToken().then(token => {
        setTokenExists(!!token)
      })
      logger(new Error('No credential info found'))
    }

    return () => {
      if (intRef.current) {
        clearInterval(intRef.current)
      }
    }
  }, [credential.expiresOn, dispatch, refreshCredential, search])

  useEffect(() => {
    if (!tokenExists && credential.accessToken) setTokenExists(true)
  }, [tokenExists, credential.accessToken])

  return { tokenExists }
}
