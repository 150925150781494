import React, { useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useInterval } from 'components/hocs/hooks'
import { selectTouchscreenPaginationType } from 'redux/selectors'
import TouchScreenPagination from '../Pagination'
import { SIMPLE } from 'constants'

const SECONDS_PER_SCREEN = 10 * 1000

const BoardContainer = ({
  headerless,
  maxPageNum,
  render,
  isPaused,
  handleInternalPagination,
  boardPage
}) => {
  const [paused, setPause] = useState(false)
  const [page, setPage] = useState(0)
  const paginationType = useSelector(selectTouchscreenPaginationType)

  useEffect(() => {
    if (page === 0) handleInternalPagination(true, null, 0)
  })

  useEffect(() => {
    setPause(isPaused)
  }, [isPaused])

  useEffect(() => {
    if (page !== 2) handleInternalPagination(true, null, page)
    if (page === 2) {
      handleInternalPagination(null, true, 2)
      setPause(true)
    }
  }, [handleInternalPagination, page])

  useEffect(() => {
    const nextPage =
      typeof boardPage === 'number'
        ? boardPage
        : boardPage === 'lastPage'
        ? 2
        : 0
    setPage(nextPage)
  }, [boardPage])

  const nextPage = useCallback(
    (next = true) => {
      let nextNum = next ? 0 : maxPageNum
      if (maxPageNum > 0) {
        if (next) {
          if (page < maxPageNum) {
            nextNum = page + 1
          } else {
            setPause(true)
            handleInternalPagination(null, null, null, false, 'next')
          }
        } else {
          if (page > 0) {
            nextNum = page - 1
          }
        }
      }
      setPage(nextNum)
    },
    [maxPageNum, page, handleInternalPagination]
  )

  useInterval(nextPage, SECONDS_PER_SCREEN, paused)

  const handlePaginationOnChange = nextPage => {
    setPage(curPage => {
      return typeof nextPage === 'number' ? nextPage - 1 : curPage
    })
    setPause(true)
    handleInternalPagination(false, null, null, true, nextPage)
  }
  const handlePrevBoard = () => {
    handlePaginationOnChange('prev')
  }
  const handleNextBoard = () => {
    handlePaginationOnChange('next')
  }
  return (
    <section>
      {render(page)}
      {headerless && maxPageNum > 0 && (
        <div className='c-dashboard__page'>
          {paginationType === SIMPLE ? (
            `${page + 1} / ${maxPageNum + 1}`
          ) : (
            <TouchScreenPagination
              total={maxPageNum + 1}
              current={page + 1}
              onChange={handlePaginationOnChange}
              handlePrevBoard={handlePrevBoard}
              handleNextBoard={handleNextBoard}
            />
          )}
        </div>
      )}
    </section>
  )
}

export default BoardContainer
