import React, { useState, useCallback } from 'react'

const ToggleTabs = ({ onChange, options = [], selected, className }) => {
  const [activeTab, setActiveTab] = useState(null)
  const selectedValue = selected !== undefined ? selected : activeTab
  const toggleTab = useCallback(value => {
      const newValue = selectedValue === value ? null : value
      setActiveTab(newValue)
      onChange && onChange(newValue)
    },
    [selectedValue, setActiveTab, onChange]
  )
  return (
    <div className={`c-toggle-tabs${className ? ` ${className}` : ''}`}>
      {options.map(({ label, value }, index) => (
        <button
          key={index}
          className={`c-btn c-btn--bare c-toggle-tabs__btn ${selectedValue === value ? 'selected' : ''}`}
          onClick={() => toggleTab(value)}
        >
          {label}
        </button>
      ))}
    </div>
  )
}

export default ToggleTabs
