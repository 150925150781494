import React from 'react'
import { SmartInput } from './SmartInput'
import { useSelector, useDispatch } from 'react-redux'
import { startsFilterConfig } from '../../../boards/startsBoard/helpers'
import {
  selectDepartmentSettings,
  selectSelectedDepartment
} from '../../../../redux/selectors/department'
import {
  selectPlacementsStarts,
  selectPlacementsStartsFilters
} from '../../../../redux/selectors/placementsStarts'
import {
  setStartsPlacementsCompanyFilter,
  setStartsPlacementsOwnersFilter,
  setStartsPlacementsDateRangeFilter,
  setStartsPlacementsProgramFilter,
  setStartsPlacementsStatusFilter
} from '../../../../redux/actions'

const StartsFilter = () => {
  const dispatch = useDispatch()
  const startsData = useSelector(selectPlacementsStarts)
  const filters = useSelector(selectPlacementsStartsFilters)
  const department = useSelector(selectSelectedDepartment)
  const depSettings = useSelector(selectDepartmentSettings)
  const hasPrograms = depSettings && depSettings['program_field']
  const startsFilter = startsFilterConfig(startsData)
  const handleStartsFilter = (filter, value) => {
    switch (filter) {
      case 'status':
        return dispatch(setStartsPlacementsStatusFilter(value))
      case 'company':
        return dispatch(setStartsPlacementsCompanyFilter(value))
      case 'owner':
        return dispatch(setStartsPlacementsOwnersFilter(value))
      case 'startDateRange':
        return dispatch(setStartsPlacementsDateRangeFilter(value))
      case 'program':
        return dispatch(setStartsPlacementsProgramFilter(value))
      default:
        console.warn('Unknown jobs board filter', filter)
    }
  }
  return startsFilter.map(filter => {
    if (
      (department &&
        filter.departments &&
        filter.departments.indexOf(department.toLowerCase()) === -1) ||
      (hasPrograms === undefined || (!hasPrograms && filter.programFilter))
    ) {
      return null
    }

    return (
      <SmartInput
        key={`${department}_starts_${filter.label}_filter`}
        label={filter.label}
        filterLabel={filter.filterLabel}
        value={filters[filter.filterLabel]}
        dataSource={filter.options}
        multiSelect={filter.multiSelect}
        onChange={value => handleStartsFilter(filter.filterLabel, value)}
        placeholder={filter.placeholder}
        dateRange={filter.dateRange}
      />
    )
  })
}

export default StartsFilter
