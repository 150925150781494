const shiftPickerDateToTZDate = (pickerDate, dateToAdd) => {
  const dateObject = pickerDate.getTime ? pickerDate : pickerDate.toDate()
  const dateToAddDateObject = dateToAdd.getTime ? dateToAdd : dateToAdd.toDate()
  let pickerOffset = dateObject.getTimezoneOffset()
  let utcDate = new Date()

  utcDate.setTime(dateObject.getTime() - pickerOffset * 60000)
  utcDate.setUTCMonth(
    dateToAddDateObject.getMonth(),
    dateToAddDateObject.getDate()
  )
  utcDate.setUTCDate(dateToAddDateObject.getDate())
  utcDate.setYear(dateToAddDateObject.getFullYear())

  return utcDate
}

export { shiftPickerDateToTZDate }
