// import toast from './toastSettings'
import { captureException, withScope } from '@sentry/react'

const errorHandler = (error, options = {}) => {
  if (!error) return
  const {
    origin,
    /* notifyUser = true, */ notifySentry = true /* message */
  } = options
  // let msg = error.message || error
  let req
  try {
    if (origin === 'axios') {
      const request = error.response && error.response.request
      if (
        request &&
        request.responseURL &&
        request.responseURL.includes('/board-logs')
      )
        return
      // msg = error.toString()
      req = request
      // ignore requests with no helpful data
      if (!req) return
    }
    if (notifySentry) {
      withScope(scope => {
        if (req) {
          const { status, statusText, responseURL } = req
          scope.setContext('request', {
            status,
            statusText,
            responseURL
          })
        }
        captureException(error)
      })
    }
    // if (notifyUser) toast.error(message || msg)
  } catch (error) {
    console.error(error)
  }
}

export default errorHandler
