import React from 'react'
import { Select } from 'antd'
import DatePicker from '../../../shared/DayjsDatePicker'
import CaratDown from 'components/shared/Icons/CaratDown'
const { RangePicker } = DatePicker

const returnAutoCompleteOptions = dataSource => {
  return dataSource.map(option => {
    return {
      label: option.label,
      value: option.value.toString()
    }
  })
}

const returnSelectOptions = dataSource => {
  return dataSource.map(option => {
    return (
      <Select.Option key={option.value} value={option.value}>
        {option.label}
      </Select.Option>
    )
  })
}

export const SmartInput = ({
  label,
  dataSource,
  placeholder,
  onChange,
  value,
  notFoundContent,
  showArrow,
  disabled,
  multiSelect,
  onSearch,
  dateRange,
  placement
}) => {
  if (dateRange) {
    return (
      <div className='filter-header-input'>
        <div className='filter-header-input-label'>{label}</div>
        <RangePicker
          allowClear
          disabled={disabled}
          onChange={onChange}
          value={value}
        />
      </div>
    )
  } else if (multiSelect) {
    const searchProps = onSearch ? { onSearch } : {}
    return (
      <div className='filter-header-input'>
        <div className='filter-header-input-label'>{label}</div>
        <Select
          allowClear
          suffixIcon={<CaratDown color='#9B9B9B' />}
          disabled={disabled}
          showArrow={showArrow}
          notFoundContent={notFoundContent}
          dropdownMatchSelectWidth={false}
          mode={multiSelect ? 'multiple' : 'default'}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          filterOption={(inputValue, option) => {
            return (
              option.props.children
                .toUpperCase()
                .indexOf(inputValue.toUpperCase()) !== -1
            )
          }}
          // TODO: rc-select is currently ignoring the width of the dropdown when calculating the position so it appears where
          // it should but if the content were to overflow outside of the viewport it just expands it which causes weird layout shifts,
          // in the future to avoid overflow issues either dropdownMatchSelectWidth should be reset to default or find another solution
          placement={placement}
          {...searchProps}
        >
          {returnSelectOptions(dataSource)}
        </Select>
      </div>
    )
  } else {
    return (
      <div className='filter-header-input'>
        <div className='filter-header-input-label'>{label}</div>
        <Select
          showSearch
          allowClear
          suffixIcon={<CaratDown color='#9B9B9B' />}
          className='filter-header-autocomplete'
          options={returnAutoCompleteOptions(dataSource)}
          placeholder={placeholder}
          onChange={onChange}
          dropdownMatchSelectWidth={false}
          value={value && value.length ? value : undefined}
          filterOption={(inputValue, option) =>
            option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          showArrow={showArrow}
        >
          {returnSelectOptions}
        </Select>
      </div>
    )
  }
}
