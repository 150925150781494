import React from 'react'

const SignOut = () => {
  return (
    <svg width='12' height='12' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 6L9 3v2.4H3.6v1.2H9V9l3-3zM7.8 0H1.2C.544 0 0 .544 0 1.2v9.6c0 .656.544 1.2 1.2 1.2h6.6v-1.2H1.2V1.2h6.6V0z'
        fill='#4E4E4E'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default SignOut
