import React, { Component } from 'react'

class RowsHeader extends Component {
  state = {}
  render() {
    let showProgram = this.props.department.settings.program_field
    let showDivision = this.props.department.settings.division_field
    return (
      <div className='element-container-fixed'>
        <div className='inner-container'>
          <div className='cell' style={{ flex: 0.05 }}>
            Added
          </div>
          <div className='cell' style={{ flex: showProgram ? 0.15 : 0.15 }}>
            Name
          </div>
          <div className='cell' style={{ flex: showProgram ? 0.18 : 0.28 }}>
            Company
          </div>
          <div className='cell' style={{ flex: showProgram ? 0.09 : 0.15 }}>
            Placement ID
          </div>
          {showProgram ? (
            <div className='cell' style={{ flex: 0.13 }}>
              Program
            </div>
          ) : null}
          {showDivision ? (
            <div className='cell' style={{ flex: 0.155 }}>
              Division
            </div>
          ) : null}
          <div className='cell' style={{ flex: showProgram ? 0.07 : 0.1 }}>
            Spread
          </div>
          <div className='cell' style={{ flex: showProgram ? 0.07 : 0.1 }}>
            Per.
          </div>
          <div className='cell' style={{ flex: showProgram ? 0.07 : 0.1 }}>
            AM/R
          </div>
          <div className='cell' style={{ flex: showProgram ? 0.0675 : 0.1 }}>
            Start
          </div>
        </div>
      </div>
    )
  }
}

export default RowsHeader
