import React from 'react'

const AssignedAccounts = () => {
  return (
    <svg width='18' height='20' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9 0a2.99 2.99 0 00-2.816 2H2a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V4a2 2 0 00-2-2h-4.184A2.99 2.99 0 009 0zm0 2a1 1 0 010 2h7v14H2V4h7a1 1 0 010-2zM4 6v2h10V6H4zm0 4v2h8v-2H4zm0 4v2h10v-2H4z'
        fill='#4E4E4E'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default AssignedAccounts
