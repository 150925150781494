import React from 'react'
import BoardCard from '../BoardCard'
import ColumnSendouts from '../ColumnSendouts'
import DealsIcon from '../DealsIcon'

// TODO: Progrmatically render sub-brand
const PurpleRedOpen = ({ division, brand, total, hasSubBrand, subBrand }) => {
  const list = [
    { label: 'Division', value: division, color: 'grape' },
    hasSubBrand
      ? { label: 'Sub-Brand', value: subBrand, color: 'grape' }
      : null,
    { label: 'Brand', value: brand, color: 'grape' },
    { label: 'ALKU', value: total, color: 'grape' }
  ].filter(v => v)
  return (
    <BoardCard className='c-sendout-mgt__open'>
      <div className='c-dashboard__title'>Purple/Red Sendouts Open</div>
      <div className='c-sendout-mgt__open__sendouts'>
        <div className='c-dealscard__icon c-dealscard__icon--purple c-sendout-mgt__icon'>
          <DealsIcon type='paper-plane' />
        </div>
        <div className='c-sendout-mgt__open__sendouts__list'>
          <ColumnSendouts list={list} />
        </div>
      </div>
    </BoardCard>
  )
}

export default PurpleRedOpen
