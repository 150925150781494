import { parseUtcDate } from '../../../../helpers/dateTime'
import calculateOnboardingPaperworkComplete from './calculateOnboardingPaperworkComplete'
import configPaperworkDocs, {
  completeStatus,
  inCompleteStatus,
  notApplicapleStatus
} from './paperworkDocsConfig'

const getInitials = nameArray => {
  if (!nameArray) return null
  return nameArray.reduce((initials, nextName) => {
    if (nextName && typeof nextName === 'string') {
      initials += `${nextName[0].toUpperCase()}.`
    }
    return initials
  }, '')
}

export default (deal, onClientPaperWorkClick, allowClientPaperworkEdit) => {
  const {
    division,
    dateAdded: dateAddedSource,
    dateBegin,
    status: submissionState,
    paperworkPercentageComplete = 0,
    recruiterName,
    accountManagerName,
    candidateName: candidateNameSource,
    companyName: companyNameSource,
    contactName: contactNameSource,
    color: colorSource,
    paperworkJson,
    formattedPaperworkJson,
    notes,
    clientPaperwork: clientPaperworkSource,
    margin: marginSource
  } = deal
  const paperworkJsonEmpty =
    (!paperworkJson || !paperworkJson.length) && (!notes || !notes.length)
  const companyName = companyNameSource || 'N/A'
  const am = accountManagerName
  const amInitials = am ? getInitials(am.split(' ')) : 'N/A'
  const amFullName = am || 'N/A'
  const rc = recruiterName
  const rcInitials = rc ? getInitials(rc.split(' ')) : 'N/A'
  const rcFullName = rc || 'N/A'
  const color = colorSource || 'FFFFFF'
  const progressRemaining = (1 - paperworkPercentageComplete) * 100

  const dateAdded = parseUtcDate(dateAddedSource, 'MM/DD')
  const startDate = parseUtcDate(dateBegin, 'MM/DD')
  const contactName = contactNameSource || 'N/A'
  const candidateName = candidateNameSource || 'N/A'
  const onboardingStatus = calculateOnboardingPaperworkComplete(paperworkJson)
  const onboardingDoc = {
    status: onboardingStatus ? completeStatus : inCompleteStatus,
    label: 'Onboarding Paperwork'
  }
  const clientPaperwork =
    clientPaperworkSource &&
    clientPaperworkSource.map(doc => {
      return {
        label: doc.title,
        status: doc.isNotApplicable
          ? notApplicapleStatus
          : doc.isComplete
          ? completeStatus
          : inCompleteStatus,
        tooltip: doc.isNotApplicable
          ? 'N/A'
          : doc.isComplete
          ? 'Complete'
          : 'Incomplete',
        onClick: allowClientPaperworkEdit
          ? () => onClientPaperWorkClick(doc)
          : undefined
      }
    })
  let margin = marginSource && Number(marginSource * 100)
  if (margin && margin % 1 !== 0) {
    margin = margin.toFixed(1)
  }
  const docObj = configPaperworkDocs(formattedPaperworkJson || paperworkJson)
  return {
    ...deal,
    division,
    companyName,
    amInitials,
    rcInitials,
    color,
    startDate,
    dateAdded,
    amFullName,
    rcFullName,
    progressRemaining,
    submissionState,
    contactName,
    candidateName,
    paperworkJsonEmpty,
    clientPaperwork,
    margin,
    onboardingDoc,
    ...docObj
  }
}
