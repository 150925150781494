import { store } from '../index'
import {
  refreshHeaderlessToken,
  clearHeaderlessCredential
} from '../redux/actions/auth'
import logger from '../requests/logger'
import queryString from 'query-string'
import { authProvider, getMsalAccesToken } from 'bootstrap/msal'

export const toHSL = (hex, lum = 0) => {
  if (hex === '#000' || hex === '000') {
    hex = '#000000'
  }

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  var r = parseInt(result[1], 16) / 255
  var g = parseInt(result[2], 16) / 255
  var b = parseInt(result[3], 16) / 255

  var max = Math.max(r, g, b),
    min = Math.min(r, g, b)
  var h,
    s,
    l = (max + min) / 2

  if (max === min) {
    h = s = 0 // achromatic
  } else {
    var d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
      default:
        break
    }
    h /= 6
  }

  s = s * 100
  s = Math.round(s)
  l = l * 100 + lum
  l = Math.round(l)
  h = Math.round(360 * h)

  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)'
}

// looks at the url and pulls comma separated ids from activityIds query.
export const getAMs = selectedAMs => {
  if (window.location.search.includes('activityIds')) {
    return window.location.search
      .split('activityIds=')[1]
      .split(',')
      .map(str => Number(str))
  } else {
    return selectedAMs
  }
}

export const checkIfHeaderless = () => {
  const pathname = window.location.pathname
  const isHeaderless =
    pathname &&
    (pathname.includes('headerless') ||
      pathname === '/hotJobOrder' ||
      pathname === '/hotActivity' ||
      pathname.startsWith('/ts/'))
  return isHeaderless
}

export const getHeaderlessToken = async credential => {
  const timestamp = Math.floor(Date.now() / 1000)
  const expiresOn = credential.expiresOn
  if (!expiresOn || expiresOn <= timestamp - 60) {
    await store.dispatch(refreshHeaderlessToken())
    const { accessToken } = store.getState().auth.credential
    return accessToken
  } else {
    return credential.accessToken
  }
}

export const checkConnection = () => {
  const { isDisconnected } = store.getState().auth
  return isDisconnected
}

export const getAccessToken = async () => {
  // only headerless will store credential
  const { credential, isDisconnected } = store.getState().auth
  if (credential.accessToken) {
    const accessToken = await getHeaderlessToken(credential)
    return accessToken
  } else if (!isDisconnected) {
    const token = await getMsalAccesToken()
    return token
  }
  return null
}

const checkErrorCode = error => {
  if (error) {
    if (error.status === 401 || error.status === 400) {
      return true
    } else if (
      error.message &&
      (error.message.includes(401) || error.message.includes(400))
    ) {
      return true
    }
  }
  return false
}

export const logoutAdal = error => {
  console.error('logoutAdal error: ', error)
  if (error) {
    logger(error)
  }
  const isHeaderless = checkIfHeaderless()
  if (!isHeaderless) {
    const isDisconnected = checkConnection()
    store.dispatch(clearHeaderlessCredential())
    if (!isDisconnected && checkErrorCode(error)) {
      authProvider.loginRedirect()
    }
  }
}

export const getSearchParamsFromLocations = location => {
  if (!location || !location.search) return {}
  return queryString.parse(location.search)
}
