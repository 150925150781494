import React, { Component } from 'react'
import UsersList from './components/UsersList'
import { connect } from 'react-redux'
import BaseWebsocket from '../../network/websocket/BaseWebsocket'
import {
  setActiveUser,
  clearUsers,
  handleUserHubSocket,
  loadUserSelection
} from '../../redux/actions'
import { push } from 'connected-react-router'
import { LogoHeader } from '../layout/header'
import { setUser as setSentryUser } from '@sentry/react'
import NotFound from '../shared/NotFound'
import { SOCKET_BOARDS } from 'constants'
import UserSelectionSkeleton from './UserSelectionSkeleton'

class UserSelection extends Component {
  componentDidMount() {
    const {
      match: {
        params: { department }
      },
      handleUserHubSocket,
      loadUserSelection
    } = this.props
    loadUserSelection(department)
    BaseWebsocket.openSignalRSocket(
      SOCKET_BOARDS.USER_HUB,
      handleUserHubSocket,
      department
    )
  }

  componentDidUpdate(preProps) {
    if (preProps.isDisconnected && !this.props.isDisconnected) {
      BaseWebsocket.restartSockets()
    }
  }

  changeDepartments = () => {
    const { clearUsers, push } = this.props
    clearUsers()
    push('/')
  }

  handleUserSelect = user => {
    const {
      setActiveUser,
      push,
      search,
      match: {
        params: { department }
      }
    } = this.props
    const urlParams = new URLSearchParams(search)
    const board = urlParams.get('board') || 'deals'
    setActiveUser(user)
    setSentryUser({
      id: user.bhId,
      username: `${user.firstName} ${user.lastName}`,
      email: user.email
    })
    push(`/d/${department}/header/boards/${board}`)
  }

  render() {
    const {
      match: {
        params: { department }
      },
      activeUser,
      users,
      usersLoading
    } = this.props
    return (
      <div className='login-container'>
        <LogoHeader />
        <div className='user-selection-main'>
          {users.length ? (
            <>
              <div
                className='change-dept-button'
                onClick={this.changeDepartments}
              >
                Change Department
              </div>
              <UsersList
                users={users}
                activeUser={activeUser}
                setActiveUser={this.handleUserSelect}
                activeDepartment={department}
              />
            </>
          ) : usersLoading ? (
            <UserSelectionSkeleton />
          ) : (
            <>
              <NotFound
                message={`No users have been added to Alku Everywhere for the ${department} department yet`}
              />
              <div
                className='change-dept-button'
                onClick={this.changeDepartments}
              >
                Change Department
              </div>
            </>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({
  users: { users, loading: usersLoading },
  auth: { activeUser, isDisconnected },
  router: {
    location: { search }
  }
}) => {
  return {
    users,
    activeUser,
    search,
    isDisconnected,
    usersLoading
  }
}

export default connect(
  mapStateToProps,
  {
    push,
    clearUsers,
    setActiveUser,
    handleUserHubSocket,
    loadUserSelection
  }
)(UserSelection)
