import React from 'react'

import { Menu } from 'components/shared'
import { returnGradient } from 'theme/returnGradient'

const AddJobMenu = ({
  jobs,
  addJOSubmissionFxn,
  activeUser,
  onClose,
  anchorEl,
  headerless,
  useTouchscreenBoard
}) => {
  const renderOpenJobs = () => {
    if (jobs.length > 0) {
      return jobs.map((job, index) => {
        return (
          <li
            style={{ cursor: 'pointer' }}
            onClick={() => {
              addJOSubmissionFxn(job.bhId) // job.candidate is the submission's candidate's bhId
              onClose()
            }}
            className={'list-item'}
            key={`popover-item-${index}`}
          >
            <div className={'item-label'}>
              {job.ClientCorporation.name} - {job.title} - ID{' '}
              {!headerless && !useTouchscreenBoard ? <a
                onClick={e => e.stopPropagation()}
                target='_blank'
                rel='noopener noreferrer'
                href={job.url}
              >
                {job.bhId}
              </a>
                :
                <b>{job.bhId}</b>}
            </div>
          </li>
        )
      })
    } else {
      return (
        <li className={'list-item'} key={'popover-item'}>
          <div className={'item-label'}>
            <em>No available jobs</em>
          </div>
        </li>
      )
    }
  }

  const menuStyle = {
    '& .MuiMenu-paper': {
      borderRadius: '1rem',
      ...returnGradient(`#${activeUser.color}`, 90)
    },
    '& .MuiList-root': {
      paddingBottom: 0,
      minWidth: 'calc(50vw - 60px)'
    }
  }

  return (
    <Menu
      sx={menuStyle}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
    >
      <div className='popover wide'>
        <div className='popover-block' key={'popoverblock'}>
          <h2>Add Job</h2>
          <ul className='list-items'>{renderOpenJobs()}</ul>
        </div>
      </div>
    </Menu>
  )
}

const anchorOrigin = {
  horizontal: 'left',
  vertical: 'bottom'
}

export default AddJobMenu
