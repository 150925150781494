import React from 'react'
import { connect } from 'react-redux'
import { OpenTimes, ScheduledSendouts, PurpleRedConfirmed, PurpleRedOpen, NumberCard } from '../subComponents'
import { defaultSendouts } from '../../helper'

const SendoutManagement = ({ sendouts }) => {
  const {
    openSendouts,
    confirmedScheduledSendouts,
    remainingScheduledSendouts,
    confirmedScheduledPurpleRedSendouts,
    openPurpleRedSendouts,
    candidatesPending,
    jobSubmissionsWithoutSendouts,
    openSendoutsUnconfirmed,
    openSlots
  } = sendouts
  return (
    <div className='c-sendout-mgt'>
      <div>
        <OpenTimes
          openSendouts={openSendouts || defaultSendouts}
          openSlots={openSlots || defaultSendouts}
          openSendoutsUnconfirmed={openSendoutsUnconfirmed || defaultSendouts}
        />
        <div className='c-sendout-mgt__scheduled'>
          <ScheduledSendouts
            type='confirmed'
            {...(confirmedScheduledSendouts || defaultSendouts)}
          />
          <ScheduledSendouts
            type='remaining'
            {...(remainingScheduledSendouts || defaultSendouts)}
          />
        </div>
      </div>
      <div>
        <PurpleRedConfirmed {...(confirmedScheduledPurpleRedSendouts || defaultSendouts)} />
        <PurpleRedOpen {...(openPurpleRedSendouts || defaultSendouts)} />
      </div>
      <div className='c-sendout-mgt__number-cards'>
        <NumberCard
          value={candidatesPending || 0}
          title={'Candidates Pending'}
          division
          color={'skyblue'} />
        <NumberCard
          value={jobSubmissionsWithoutSendouts || 0}
          title={'Jobs With Subs Without Sendouts'}
          division
          color={'darkblue'} />
      </div>
    </div>
  )
}

const mapStateToProps = ({ dashboard }) => ({
  sendouts: dashboard.sendouts
})

export default connect(mapStateToProps)(SendoutManagement)
