import React from 'react'
import { parseMultiValueString } from '../../../../helpers/handleMultiValueString'

const renderConferenceLine = value => {
  const parsedValue = parseMultiValueString(value)
  let displayValue = parsedValue

  if (Array.isArray(parsedValue)) {
    // eslint-disable-next-line no-unused-vars
    const [displayText, link] = parsedValue
    displayValue = displayText
  }
  return <span className='job-conf-line-text'>{displayValue}</span>
}

export default renderConferenceLine
