import React from 'react'
import tinycolor from 'tinycolor2'

import PatternStroke from '../PatternStroke'

export const ProgressBar = ({
  percent,
  count,
  goal,
  color = 'rgb(0 159 0)',
  includeBg = false,
  className,
  textColor
}) => {
  const goalReached = percent >= 100
  let patternStrokeColor
  if (goalReached) {
    patternStrokeColor = tinycolor(color)
      .darken(15)
      .toString()
  }
  return (
    <>
      <div
        className={`g-progressbar ${className ? className : ''}`}
        style={{ borderColor: color }}
      >
        <div
          style={{
            flex: `${percent}%`,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            zIndex: 1,
            background: color
          }}
        >
          <span className='g-progressbar__text' style={{ color: textColor }}>
            {!goalReached && count}
          </span>
        </div>
        <div
          key='background-bar'
          style={{ flex: 100 - percent }}
          className={`${includeBg ? 'g-progressbar--bg' : ''} 
          ${goal ? 'g-progressbar__align-text-end' : ''}`}
        >
          {goal && !goalReached ? (
            <span className='g-progressbar__text g-progressbar__goal g-progressbar__goal--w-spacing'>
              {goal}
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>
      {goalReached && patternStrokeColor && (
        <>
          <PatternStroke
            className='g-progressbar__pattern-overlay'
            color={patternStrokeColor}
          />
          <div
            className='g-progressbar__text g-progressbar__text--goal-reached'
            style={{ color: textColor }}
          >
            {`${count}${goal ? '/' : ''}`}
            {goal > 0 && (
              <span className='g-progressbar__goal'>{`${goal}`}</span>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default ProgressBar
