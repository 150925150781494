import { SET_PLACEMENTS, ADD_PLACEMENT, REMOVE_PLACEMENT } from '../actionTypes'
import logger from '../../requests/logger'
import { cloneDeep } from 'lodash'

export const PLACEMENT_COMMISSION_CREATED_EVENT = 'placement_commission_created'
export const PLACEMENT_COMMISSION_DELETED_EVENT = 'placement_commission_deleted'
export const PLACEMENT_COMMISSION_UPDATED_EVENT = 'placement_commission_updated'

export const setPlacements = placements => {
  return {
    type: SET_PLACEMENTS,
    placements
  }
}

export const addPlacement = placement => {
  return {
    type: ADD_PLACEMENT,
    placement
  }
}

export const removePlacement = placement => {
  return {
    type: REMOVE_PLACEMENT,
    placement
  }
}

export const updatePlacement = (placement, updatedFields, event) => (
  dispatch,
  getState
) => {
  const { placements } = getState()
  let newList = cloneDeep(placements)
  let placementUpdated = newList.find(plObj => plObj.bhId === placement.bhId)
  if (placementUpdated) {
    let payload = {}
    updatedFields.map(field => (payload[field] = placement[field]))

    let updatedPl = {
      ...placementUpdated,
      ...payload
    }

    let plIndex = newList.findIndex(plObj => plObj.bhId === placement.bhId)
    if (plIndex > -1) {
      newList.splice(plIndex, 1, updatedPl)
      dispatch(setPlacements(newList))
    } else {
      logger({
        stackError: `Placement not found for ${event} with placment bhId ${placement.bhId}`
      })
    }
  } else {
    logger({
      stackError: `Placement not found for ${event} with placment bhId ${placement.bhId}`
    })
  }
}

export const updatePlacementCommissions = (commission, event) => (
  dispatch,
  getState
) => {
  const { placements } = getState()
  const { placementId, User } = commission
  let newList = cloneDeep(placements)
  let placementUpdated = newList.find(plObj => plObj.bhId === placementId)
  if (placementUpdated && User) {
    switch (event) {
      case PLACEMENT_COMMISSION_CREATED_EVENT: {
        placementUpdated.commissions.push(commission)
        break
      }
      case PLACEMENT_COMMISSION_DELETED_EVENT: {
        const idx = placementUpdated.commissions.findIndex(
          com => com.User && com.User.bhId && com.User.bhId === User.bhId
        )
        if (idx > -1) {
          placementUpdated.commissions.splice(idx, 1)
        }
        break
      }
      case PLACEMENT_COMMISSION_UPDATED_EVENT: {
        placementUpdated.commissions.unshift(commission)
        break
      }
      default:
        break
    }
    dispatch(setPlacements(newList))
  } else {
    logger({
      stackError: `Placement not found for ${event} with placment bhId ${placementId}`
    })
  }
}

export const updateCandidate = (placementIds, candidate, event) => (
  dispatch,
  getState
) => {
  const { placements } = getState()
  let newList = cloneDeep(placements)

  placementIds.forEach(plId => {
    let plIndex = newList.findIndex(plObj => plObj.bhId === plId)
    if (plIndex > -1) {
      newList[plIndex].Candidate.firstName = candidate.firstName
      newList[plIndex].Candidate.lastName = candidate.lastName
      dispatch(setPlacements(newList))
    } else {
      logger({
        stackError: `Placement not found for ${event} with placment bhId ${plId}`
      })
    }
  })
}
