import React, { Component } from 'react'
import PauseIcon from '@mui/icons-material/Pause'
import _ from 'lodash'
import checkHeights from './masonryPagination/checkHeights'

const SECONDS_PER_SCREEN = 10
export default class Board extends Component {
  constructor(props) {
    super(props)
    this.state = {
      paused: false,
      page: 0,
      chunks: [],
      intervalId: null
    }
    this.checkingHeights = false
    this.calcOverflowNeeded = true
    if (this.props.headerless) {
      window.onkeyup = this.mapRemoteKeys.bind(this)
    }
    this.resetOverflowCalc = this.resetOverflowCalc.bind(this)
    this.checkHeights = checkHeights.bind(this)
  }

  shouldComponentUpdate(nextProps) {
    return !this.checkingHeights
  }

  componentDidMount() {
    if (this.props.headerless) {
      this.startTimer()
    }
  }

  componentDidUpdate() {
    if (this.props.data.length > 0 && !this.calculatingPagination) {
      this.resetOverflowCalc()
    }
  }

  mapRemoteKeys(e) {
    e.preventDefault()
    if (
      e.code === 'PageUp' ||
      e.code === 'ArrowUp' ||
      e.code === 'ArrowLeft' ||
      e.code === 'Minus'
    ) {
      if (this.state.chunks.length) {
        clearInterval(this.state.intervalId)
        this.setState({
          page: this.prePage(),
          paused: true,
          intervalId: null
        })
      }
    }

    if (
      e.code === 'PageDown' ||
      e.code === 'ArrowDown' ||
      e.code === 'ArrowRight' ||
      e.code === 'Equal'
    ) {
      if (this.state.chunks.length) {
        clearInterval(this.state.intervalId)
        this.setState({
          page: this.nextPage(),
          paused: true,
          intervalId: null
        })
      }
    }

    if (e.code === 'Enter') {
      if (this.state.chunks.length) {
        if (this.state.paused) {
          this.setState({ paused: false }, this.startTimer)
        } else {
          clearInterval(this.state.intervalId)
          this.setState({ paused: true, intervalId: null })
        }
        return false
      } else {
        clearInterval(this.state.intervalId)
        this.setState({ paused: false, intervalId: null })
      }
    }

    if (e.code === 'KeyP') {
      if (this.state.chunks.length) {
        this.timer()
        return false
      }
    }
  }

  handleOverflowPagination = async elements => {
    if (this.calcOverflowNeeded && !this.checkingHeights) {
      this.calcOverflowNeeded = false
      this.checkingHeights = true
      const chunks = this.checkHeights(elements)
      this.checkingHeights = false
      this.setState(
        {
          page: this.state.lastPage || 0,
          chunks
        },
        () => {
          this.calculatingPagination = false
          if (this.props.headerless) {
            if (
              this.state.chunks.length &&
              !this.state.intervalId &&
              !this.state.paused
            ) {
              this.startTimer()
            } else {
              if (!this.state.chunks.length) {
                clearInterval(this.state.intervalId)
                this.setState({ paused: false, intervalId: null })
              }
            }
          }
        }
      )
    }
  }

  // This is pretty important. By setting the below to true, on the next render the full list will render
  // and trigger a recalculation of the pagination. Since it's true, the recalculation will be done with every AM on the
  // screen again. Boards call this by getting a ref to this component.
  resetOverflowCalc() {
    this.calculatingPagination = true
    this.calcOverflowNeeded = true
    this.setState(
      {
        chunks: [],
        page: -1,
        lastPage: this.state.page
      },
      () => {
        this.handleOverflowPagination(
          Array.from(document.getElementsByClassName('element-container'))
        )
      }
    )
  }

  startTimer = () => {
    if (!this.state.intervalId && this.state.chunks.length > 1) {
      const intervalId = setInterval(this.timer, SECONDS_PER_SCREEN * 1000)
      this.setState({ intervalId })
    }
  }

  timer = () => {
    if (this.state.chunks.length) {
      this.setState({
        page: this.nextPage()
      })
    }
  }

  prePage = () => {
    const { page } = this.state
    if (this.state.chunks.length > 1) {
      if (page <= 0) {
        return this.state.chunks.length - 1
      } else {
        return page - 1
      }
    } else {
      return 0
    }
  }

  nextPage = () => {
    const { page } = this.state
    if (this.state.chunks.length > 1) {
      if (page >= this.state.chunks.length - 1) {
        return 0
      } else {
        return page + 1
      }
    } else {
      return 0
    }
  }

  renderElementsHeaderless = () => {
    if (this.checkingHeights) {
      return null
    }

    if (!this.state.chunks.length) {
      return this.props.data.map(d => {
        return this.props.renderElement(
          this.props.renderElementUsesData ? d : d.bhId
        )
      })
    } else {
      if (!this.state.chunks[this.state.page]) {
        return null
      }

      // pass the [[columns]] into the render function
      return this.props.renderElement(this.state.chunks[this.state.page])
    }
  }

  renderElementsHeader = () => {
    if (this.checkingHeights) {
      return null
    }

    return this.filterData(this.props.data).map(d => {
      return this.props.renderElement(
        this.props.renderElementUsesData ? d : d.bhId
      )
    })
  }

  renderPageNumber() {
    return (
      <div
        style={{
          position: 'fixed',
          fontSize: '25px',
          bottom: '8px',
          left: '48%'
        }}
      >
        {`${this.state.page + 1} / ${this.state.chunks.length}`}
      </div>
    )
  }

  filterData = data => {
    let filteredData = data
    _.forEach(this.state.filters, (filterState, fieldValue) => {
      filteredData = filteredData.filter(datum => {
        if (Array.isArray(filterState)) {
          return filterState.indexOf(_.get(datum, fieldValue)) !== -1
        } else {
          return _.get(datum, fieldValue) === filterState
        }
      })
    })
    return filteredData
  }

  render() {
    return (
      <React.Fragment>
        {this.props.renderHeader ? this.props.renderHeader() : null}
        {this.props.headerless
          ? this.renderElementsHeaderless()
          : this.renderElementsHeader()}
        {this.props.headerless && this.state.chunks.length > 1
          ? this.renderPageNumber()
          : null}
        {false && this.state.paused && (
          <PauseIcon
            sx={{
              position: 'fixed',
              bottom: '5%',
              left: '5%',
              textShadow: '0 0 70px rgba(0,0,0,0.2)',
              zIndex: 9999,
              fontSize: '10vw'
            }}
          />
        )}
      </React.Fragment>
    )
  }
}
