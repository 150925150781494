const focusableElements = [
  'button',
  '[href]',
  'input',
  'select',
  'textarea',
  '[tabindex]:not([tabindex=\'-1\']'
]

/**
 * Funciton used to handle tabbing within material-ui Popover component
 * @param {React.Ref} modalRef - parent element of tabbable elements
 * @returns {Function} - Function to pass into keydown event handler
 */
const createSimulateTabEvent = modalRef => {
  const focusables = Array.from(
    (modalRef &&
      modalRef.current &&
      modalRef.current.querySelectorAll &&
      modalRef.current.querySelectorAll(focusableElements.join(','))) ||
      []
  )
  if (!focusables.length) {
    console.warn('Accessibility Warning: No focusable elements on modal')
    return
  }
  const elements = {}
  elements.firstFocusableElement = focusables[0]
  elements.lastFocusableElement = focusables[focusables.length - 1]
  const handleKeydown = (e) => {
    if (e.key === 'Tab') {
      if (e.shiftKey) {
        // if shift key pressed for shift + tab combination
        if (document.activeElement === elements.firstFocusableElement) {
          elements.lastFocusableElement.focus() // add focus for the last focusable element
          e.preventDefault()
        }
      } else {
        // if tab key is pressed
        if (document.activeElement === elements.lastFocusableElement) {
          // if focused has reached to last focusable element then focus first focusable element after pressing tab
          elements.firstFocusableElement.focus() // add focus for the first focusable element
          e.preventDefault()
        } else {
          const currIndex = Array.prototype.indexOf.call(
            focusables,
            document.activeElement
          )
          if (currIndex >= 0) {
            const nextEl = focusables[currIndex + 1]
            nextEl.focus()
            e.preventDefault()
          }
        }
      }
    }
  }

  return handleKeydown
}

export default createSimulateTabEvent
