import React from 'react'
import AlkuTooltip from '../AlkuTooltip'
import { StatusIndicator } from '../Icons'

const StatusBtn = ({ onClick, statusProps, btnClassname = '', tooltip }) =>
  onClick ? (
    <button
      className={`status-icon c-btn c-btn--bare ${btnClassname}`}
      onClick={onClick}
    >
      <AlkuTooltip title={tooltip} placement='top'>
        <StatusIndicator className={'status--default'} {...statusProps} />
      </AlkuTooltip>
    </button>
  ) : (
    <div className={btnClassname} onClick={onClick}>
      <StatusIndicator className={'status--default'} {...statusProps} />
    </div>
  )

export default StatusBtn
