import React, { useCallback, useRef } from 'react'
import BaseWebsocket from '../../../../network/websocket/BaseWebsocket'
import OpenTimesIconTooltip from './OpenTimesIconTooltip'

import { setBasicMenuPopover } from 'redux/actions'
import { useDispatch } from 'react-redux'
import { StatusIndicator } from 'components/shared/Icons'

const JobCardHeader = ({
  job,
  job: {
    submissionsNeeded,
    title,
    Openings,
    program,
    Owner,
    clearance,
    securityClearance,
    ClientCorporation: { name, url: companyUrl },
    hasOpenTime
  },
  boardType,
  isHot,
  showClearance,
  bhId,
  jobColor,
  department: {
    settings: { program_field }
  },
  setOpenTimesModalContent,
  openTimesModalVisible,
  headerless
}) => {
  const dispatch = useDispatch()
  const companyRef = useRef()
  const clearanceInfo =
    clearance && (showClearance || (isHot && securityClearance))
      ? ` - ${clearance}`
      : null
  const openPopover = useCallback(() => {
    if (headerless) return
    dispatch(
      setBasicMenuPopover({
        anchorEl: companyRef.current,
        items: [{ url: companyUrl, primaryText: 'View on Bullhorn' }]
      })
    )
  }, [headerless, dispatch, companyUrl])

  const openOpenTimesModal = useCallback(() => {
    setOpenTimesModalContent(job)
  }, [setOpenTimesModalContent, job])

  return (
    <React.Fragment>
      <h2
        className='client-corporation-name'
        style={{
          color: `${jobColor()}`,
          textAlign: 'left',
          minHeight: '35px',
          marginTop: '5px',
          fontWeight: 'bold'
        }}
      >
        <div
          ref={companyRef}
          className={'c-menu__popover-pointer'}
          onClick={openPopover}
        >
          {name}
        </div>
      </h2>
      <h2
        className='job-info'
        style={{
          color: `${jobColor()}`,
          textAlign: 'left',
          cursor: 'pointer',
          fontWeight: 'bold'
        }}
      >
        <button
          className='c-btn--responsive c-btn--pointer-cursor c-btn--bare'
          onClick={() =>
            BaseWebsocket.send(boardType, {
              event: 'job_order_change',
              jobOrderId: bhId,
              field: 'submissionsNeeded',
              value: !submissionsNeeded
            })
          }
        >
          <StatusIndicator
            style={{
              top: '5px',
              position: 'relative',
              margin: '0 15px',
              cursor: 'pointer'
            }}
            check={!submissionsNeeded}
          />
        </button>
        {title}
        {Openings > 1 ? ' x' + Openings : ''}
        {program_field && program ? ` - ${program}` : null}
        {isHot ? ` - ${Owner.PrimaryDepartment.name}` : null}
        {clearanceInfo}
        {hasOpenTime && (
          <OpenTimesIconTooltip
            openModal={openOpenTimesModal}
            shouldRemainInvisible={openTimesModalVisible}
          />
        )}
      </h2>
    </React.Fragment>
  )
}

export default JobCardHeader
