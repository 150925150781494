const isSlotReady = slot => {
  const { jobSubmissions } = slot
  if (!jobSubmissions || jobSubmissions.length !== 1) {
    return false
  }
  const submission =
    jobSubmissions[0] && jobSubmissions[0].InterviewSlotSubmission
  const { available, clientConfirmed, prepped, workingMargin, workingSpread } =
    submission || {}
  if (!available || !clientConfirmed || !prepped || !workingMargin || !workingSpread) {
    return false
  }
  return true
}

export default isSlotReady
