import React from 'react'
import { Tooltip } from 'antd'

const Title = ({ otherDivisions }) => {
  return (
    <div>
      <span className='c-md-tooltip__text'>Also working with client:</span>
      <ul>
        {otherDivisions.map((contributor, index) =>
          contributor ? (
            <li
              key={`${contributor?.department}-${contributor?.employee}-${index}`}
            >
              <div className='c-md-tooltip__department'>
                {contributor.department}
              </div>
              <div className='c-md-tooltip__employee'>
                {contributor.employee}
              </div>
            </li>
          ) : null
        )}
      </ul>
    </div>
  )
}

const MultiDivisionTooltip = props => {
  const { children, otherDivisions, ...restProps } = props
  if (!otherDivisions?.length) return children

  return (
    <Tooltip
      overlayClassName='c-tooltip--toptab c-tooltip--multi-division'
      placement='top'
      title={<Title otherDivisions={otherDivisions} />}
      {...restProps}
    >
      {children}
    </Tooltip>
  )
}

export default MultiDivisionTooltip
