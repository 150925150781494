const DIRECTIONS = {
  BOTTOM: 'bottom',
  TOP: 'top'
}

const calculateOverflowDirection = () => {
  const overflow = []
  const scrollYMax = document.body.scrollHeight - document.body.offsetHeight
  const currentScrollY = window.scrollY || window.pageYOffset
  if (currentScrollY < scrollYMax) {
    overflow.push(DIRECTIONS.BOTTOM)
  }

  return overflow
}

export default calculateOverflowDirection
