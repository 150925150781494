import React from 'react'

const Jobs = () => {
  return (
    <svg width='22' height='21' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7 0v1H2C.897 1 0 1.897 0 3v12c0 1.103.897 2 2 2h8.182a2.997 2.997 0 010-2H2v-2h9c0-.772.3-1.468.78-2H2V3h16v6.182c.409.145.794.37 1.121.697l.121.121H20V3c0-1.103-.897-2-2-2h-5V0H7zm3 7a1 1 0 100 2 1 1 0 000-2zm6.984 4a1 1 0 00-.691.293l-.707.707H14a1 1 0 00-1 1v1.586l-.707.707a1 1 0 000 1.414l.707.707V19a1 1 0 001 1h1.586l.707.707a1 1 0 001.414 0l.707-.707H20a1 1 0 001-1v-1.586l.707-.707a1 1 0 000-1.414L21 14.586V13a1 1 0 00-1-1h-1.586l-.707-.707a1 1 0 00-.723-.293zM17 13.414l.293.293A1 1 0 0018 14h1v1a1 1 0 00.293.707l.293.293-.293.293A1 1 0 0019 17v1h-1a1 1 0 00-.707.293l-.293.293-.293-.293A1 1 0 0016 18h-1v-1a1 1 0 00-.293-.707L14.414 16l.293-.293A1 1 0 0015 15v-1h1a1 1 0 00.707-.293l.293-.293z'
        fill='#4E4E4E'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default Jobs
