import React from 'react'
import BoardCard from '../BoardCard'
import CardTitle from '../CardTitle'
import NumberBlock from '../NumberBlock'

const LeadsManagement = ({ hot, yellow, marketplaceInfo, reference }) => {
  const types = [
    { value: hot || 0, title: 'Hot', color: 'orange' },
    { value: yellow || 0, title: 'Yellow', color: 'gold' },
    {
      value: marketplaceInfo || 0,
      title: 'Marketplace Info',
      color: 'lightblue'
    },
    { value: reference || 0, title: 'Reference', color: 'royal-blue' }
  ]
  return (
    <div className='c-leadsmgt'>
      <CardTitle id='LeadsManagement'>Leads Management</CardTitle>
      <BoardCard>
        <div className='c-dashboard__title c-submittal__mgt__title'>
          Leads Type
        </div>
        <div className='c-leadsmgt__types'>
          {types.map(({ value, title, color }, index) => (
            <div
              key={index}
              className={`g-numbercard g-numbercard--xs g-numbercard--${color}`}
            >
              <NumberBlock value={value} />
              <div className='g-numbercard__labels'>
                <div className='g-numbercard__title'>{title}</div>
              </div>
            </div>
          ))}
        </div>
      </BoardCard>
    </div>
  )
}

export default React.memo(LeadsManagement)
