import React from 'react'

const Edit = ({ className, onClick, width, height }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      viewBox='0 0 20 20'
      width={width || '20'}
      height={height || '20'}
      xmlns='http://www.w3.org/2000/svg'
      preserveAspectRatio='xMinYMin'
    >
      <path
        d='M15.603 1a.945.945 0 00-.67.278l-1.895 1.894-1.34 1.34L1 15.21V19h3.79L18.721 5.067c.37-.37.37-.97 0-1.34l-2.45-2.45a.945.945 0 00-.67-.277zm0 2.287l1.11 1.11-1.225 1.225-1.11-1.11 1.225-1.225zm-2.565 2.564l1.11 1.11L4.005 17.106h-1.11v-1.11L13.038 5.85z'
        fill='#8E8E8E'
        fillRule='evenodd'
      />
    </svg>
  )
}

export default Edit
