import { newRequest } from '../helpers/axios'

export default function updateUserSelectedDepartmentCategoryAssignmentId(
  depCatId,
  id
) {
  const postBody = {
    type: 'update-user-selected-department-categories-deals-assignment',
    DepartmentCategoriesDealsAssignmentId: depCatId === 'null' ? null : depCatId
  }
  return newRequest('POST', `/corporate-users/${id}/commands/`, postBody, null)
}
