import breakTooTallCard from './breakToTallCards'

export default function checkHeights(elements) {
  let paginatedConfig = [[[]]]
  let headerChunk = [[]]
  let placed = []
  let appViewColumn = 0
  let accumulatedHeight = 0
  let accumulatedWidth = 0
  let { splitElements, headerless, multiFlexColumn } = this.props
  const top = document
    .getElementsByClassName('board-container')[0]
    .getBoundingClientRect().top
  const bottom = document.getElementsByClassName('c-ts-menu').length ? document.getElementsByClassName('c-ts-menu')[0].getBoundingClientRect().height + 130 : 0
  const containerHeight = window.innerHeight - top - bottom
  const containerWidth = window.innerWidth
  const boardOffset = this.props.customOffset || 0
  const elementMargin = this.props.elementMargins || 0
  if (!headerless) {
    // for app view
    // loop over the elements and place them into 1 column. On activity, css grid handles the columns in header mode.
    elements.forEach((element, i) => {
      let obj = {
        // * in the id means render whole array of data
        id: `${splitElements ? element.id + '-*' : element.id}`
      }
      headerChunk[appViewColumn].push(obj)
      placed.push(element.id)
      appViewColumn === headerChunk.length - 1
        ? (appViewColumn = 0)
        : appViewColumn++
    })
    return headerChunk
  } else {
    const pages = [[[]]]
    const currentChunkInfo = {
      height: 0,
      index: 0
    }
    const jobsPlaced = {}
    elements.forEach(e => (jobsPlaced[e.id] = { placed: 0 }))
    let currentPage = 0
    let emptyJobHeaders = []

    if (splitElements) {
      emptyJobHeaders = elements.filter(
        ele => !ele.getElementsByClassName('job-slot-container').length
      ).map(ele => ({
        height: 86,
        id: 0,
        isEmpty: true,
        accountManagerId: ele.id
      }))
      elements = elements.filter(
        ele => ele.getElementsByClassName('job-slot-container').length
      )
    }
    const cardsByJobData = [emptyJobHeaders]
    elements.forEach(element => {
      if (splitElements) {
        // the memory ref to dom elements should end here, map over them and from there on use the height
        // and am only for calulations.ß
        cardsByJobData.push(
          breakTooTallCard(element, containerHeight, 0)
        )
      } else {
        const elementRect = element.getBoundingClientRect()
        const elementHeight = elementRect.height + elementMargin
        const elementWidth = elementRect.width + elementMargin
        const willNotExceedContainerWidth = accumulatedWidth + elementWidth <= containerWidth
        const willNotExceedContainerHeight = elementHeight + accumulatedHeight + boardOffset <= containerHeight
        if (
          !multiFlexColumn &&
          (willNotExceedContainerWidth || willNotExceedContainerHeight)
        ) {
          paginatedConfig[currentPage][0].push({ id: Number(element.id) })
          accumulatedWidth += elementWidth
          if (accumulatedWidth > containerWidth) {
            accumulatedHeight += elementHeight
          }
        } else if (
          multiFlexColumn &&
          (willNotExceedContainerWidth || willNotExceedContainerHeight)
        ) {
          paginatedConfig[currentPage][0].push({ id: Number(element.id) })
          if (willNotExceedContainerWidth) {
            accumulatedWidth += elementWidth
            if (accumulatedHeight === 0) {
              accumulatedHeight += elementHeight
            }
          } else {
            accumulatedHeight += elementHeight
            accumulatedWidth = elementWidth
          }
        } else {
          accumulatedHeight = 0
          paginatedConfig.push([[]])
          currentPage++
          paginatedConfig[currentPage][0].push({ id: Number(element.id) })
          accumulatedHeight += elementHeight
          accumulatedWidth = elementWidth
        }
      }
    })
    if (splitElements) {
      for (let j = 0; j < cardsByJobData.length; j++) {
        let cardsByAM = cardsByJobData[j]
        let i = 0
        while (i < cardsByAM.length) {
          const jobData = cardsByAM[i]
          const remainingColumnSpace = containerHeight - currentChunkInfo.height
          const currentColumn = pages[currentPage][currentChunkInfo.index]
          const currentColumnJobsCount = currentColumn.length
          const currentColumnLastJob = currentColumnJobsCount && currentColumn[currentColumnJobsCount - 1]
          const sameAMJobs = currentColumnLastJob && currentColumnLastJob.accountManagerId === jobData.accountManagerId
          const nextHeight = sameAMJobs ? jobData.height - 50 : jobData.height

          if (currentChunkInfo.height === 0 && nextHeight > remainingColumnSpace) {
            i++
          } else if (nextHeight <= remainingColumnSpace) {
            currentChunkInfo.height += jobData.height
            if (sameAMJobs) {
              currentColumnLastJob.toIndex = jobData.index + 1
            } else {
              currentColumn.push(jobData)
            }
            i++ // go to next job only have enough space in current page and current column
          } else if (currentChunkInfo.index < this.props.numberOfColumns - 1) {
            pages[currentPage].push([])
            currentChunkInfo.index++
            currentChunkInfo.height = 0
          } else {
            currentPage++
            pages.push([[]])
            currentChunkInfo.index = 0
            currentChunkInfo.height = 0
          }
        }
      }
      paginatedConfig = pages.map(page => {
        return page.map(columnConfig => {
          return columnConfig.map(amCard => {
            // +1 at the end below because .splice does not return the ending index passed and we want it
            const toIndex = amCard.toIndex || amCard.index + 1
            const idPost = amCard.isEmpty ? '*' : `${amCard.index}/${toIndex}`
            return {
              id: `${amCard.accountManagerId}-${idPost}`
            }
          })
        })
      })
    }
    return paginatedConfig
  }
}
