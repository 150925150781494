import React, { useMemo } from 'react'
import { Pagination } from 'antd'
import renderPaginationItems from 'components/PiDB/components/PiDBTable/helpers/renderPaginationItems'

const TouchScreenPagination = ({
  total,
  current,
  onChange,
  handlePrevBoard,
  handleNextBoard
}) => {
  const config = useMemo(() => {
    return {
      onPrevClick: () => {
        if (typeof handlePrevBoard === 'function' && current === 1) {
          handlePrevBoard()
        }
      },
      onNextClick: () => {
        if (typeof handleNextBoard === 'function' && current === total) {
          handleNextBoard()
        }
      }
    }
  }, [handlePrevBoard, handleNextBoard, total, current])
  return (
    <Pagination
      showLessItems
      className='c-pidb__table-pagination c-pidb__table ant-table-pagination'
      itemRender={(...args) =>
        renderPaginationItems(...args, current, total, config)
      }
      total={total}
      current={current}
      showSizeChanger={false}
      onChange={onChange}
      pageSize={1}
    />
  )
}

export default TouchScreenPagination
