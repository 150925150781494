import React from 'react'
import BoardCard from '../BoardCard'

const OpenTimes = ({ openSendouts, openSlots, openSendoutsUnconfirmed }) => {
  const configList = configObj => {
    if (configObj) {
      return [
        { label: 'Division', name: 'division', color: 'navyblue' },
        configObj.hasSubBrand
          ? { label: 'Sub-Brand', name: 'brand', color: 'celtic-blue' }
          : null,
        { label: 'Brand', name: 'brand', color: 'airblue' },
        { label: 'ALKU', name: 'total', color: 'turblue' }
      ]
        .filter(v => v)
        .map(item => ({ ...item, value: configObj[item.name] }))
    } else {
      return []
    }
  }
  const list = configList(openSendouts)
  return (
    <BoardCard className='c-sendout-mgt__opentimes'>
      <div className='c-dashboard__title'>Open Times</div>
      <div className='c-sendout-mgt__opentimes__sendouts'>
        <div className='g-sendouts__data'>
          {list.map(({ label, value, color }) => (
            <div key={label}>
              <div
                className={`g-sendouts__number  c-dashboard__color--${color}`}
              >
                {value}
              </div>
              <div className='g-sendouts__label'>{label}</div>
            </div>
          ))}
        </div>
      </div>
      <div className='c-sendout-mgt__opentimes-data'>
        <div className='c-sendout-mgt__opentimes-data-cols'>
          <div className='c-dashboard__title c-dashboard__title--sm'>
            Open Slots
          </div>
          <div className='c-dashboard__title c-dashboard__title--sm'>
            Unconfirmed Sendouts
          </div>
        </div>
        <div className='c-sendout-mgt__opentimes-data-cols'>
          <ul>
            {configList(openSlots).map(({ label, value }) => (
              <li key={label}>
                <span
                  className={'g-sendouts__number  c-dashboard__color--hopbush'}
                >
                  {value}
                </span>
                <span className='g-sendouts__label'>{label}</span>
              </li>
            ))}
          </ul>
          <ul>
            {configList(openSendoutsUnconfirmed).map(({ label, value }) => (
              <li key={label}>
                <span
                  className={'g-sendouts__number  c-dashboard__color--green'}
                >
                  {value}
                </span>
                <span className='g-sendouts__label'>{label}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </BoardCard>
  )
}

export default OpenTimes
