import { newRequest } from '../helpers/axios'

export default function getFiscalMonthData() {
  return newRequest(
    'GET',
    '/users/department/currentfiscalmonths',
    null,
    null,
    'nodepartment'
  )
}
