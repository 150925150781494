import React, { Component } from 'react'
import CandidateComponent from './Candidate'

class JobCardCanidates extends Component {
  constructor(props) {
    super(props)
    this.numSubmissionsPerRow = props.isHot ? 4 : 5
  }

  renderSubmissions = job => {
    let submissions = this.props.job.JobSubmissions.filter(
      submission => !submission.hidden
    )

    return (
      <div
        key='submissions-row'
        className={`submissions-row ${this.props.isHot ? 'hot' : ''}`}
      >
        {submissions.map(submission => (
          <CandidateComponent
            {...this.props}
            isHot={this.props.isHot}
            key={`${job.bhId}_${submission.bhId}`}
            submission={submission}
            job={job}
          />
        ))}
      </div>
    )
  }

  render() {
    return (
      <div className='submissions'>
        {this.renderSubmissions(this.props.job)}
      </div>
    )
  }
}

export default JobCardCanidates
