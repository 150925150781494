import {
  GET_DASHBOARD_SENDOUTS_BEGIN,
  GET_DASHBOARD_SENDOUTS_SUCCESS,
  GET_DASHBOARD_SENDOUTS_ERROR,
  CLEAR_PREVIOUS_DPEARTMENT_DATA,
  SET_CATEGORY_GOAL,
  SET_RECENT_DEALS,
  SET_DASHBOARD_CUSTOM_FISCAL_DATE,
  SET_DASHBOARD_GO_LIVE
} from '../actionTypes'
import { getDate } from '../../helpers/dateTime'

const DEFAULT_STATE = {
  meta: {
    loading: false,
    sendoutsFetchError: false
  },
  sendouts: { divisionDealsGoals: [] },
  recentDeals: [],
  updatedAt: null,
  customFiscalDate: null,
  goLive: true
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case CLEAR_PREVIOUS_DPEARTMENT_DATA: {
      return DEFAULT_STATE
    }
    case GET_DASHBOARD_SENDOUTS_BEGIN: {
      const updatedAt = state.updatedAt
      return {
        ...state,
        meta: {
          ...state.meta,
          loading: !updatedAt,
          sendoutsFetchError: false
        }
      }
    }
    case GET_DASHBOARD_SENDOUTS_SUCCESS: {
      return {
        ...state,
        meta: {
          ...state.meta,
          loading: false,
          sendoutsFetchError: false
        },
        sendouts: action.payload,
        updatedAt: getDate()
      }
    }
    case GET_DASHBOARD_SENDOUTS_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          loading: false,
          sendoutsFetchError: action.payload
        }
      }
    case SET_CATEGORY_GOAL: {
      const { name, dealsGoal } = action.payload
      const updatedGoals = state.sendouts.divisionDealsGoals.map(dg => {
        if (dg.divisionName === name) {
          dg.dealGoal = Number(dealsGoal)
        }
        return dg
      })
      const sendoutsCopy = state.sendouts
      sendoutsCopy.divisionDealsGoals = updatedGoals

      return {
        ...state,
        sendouts: sendoutsCopy
      }
    }
    case SET_RECENT_DEALS:
      return {
        ...state,
        recentDeals: action.payload
      }
    case SET_DASHBOARD_CUSTOM_FISCAL_DATE:
      return {
        ...state,
        customFiscalDate: action.payload
      }
    case SET_DASHBOARD_GO_LIVE:
      return {
        ...state,
        goLive: action.payload
      }
    default:
      return state
  }
}
