import { put } from '../../helpers/axios'

export default async function updateClientPaperwork(placementId, paperwork) {
  const url = `/starts/${placementId}/clientpaperwork`
  const payload = {
    paperworkJson: paperwork
  }
  const res = await put(url, payload)
  return res
}
