import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { getStatusIndicatorProps, handleCandidateTap } from '../../helpers'
import logger from '../../../../../requests/logger'
import { setBasicMenuPopover, setCandidateHistoryMenu } from 'redux/actions'
import { AMRTooltip } from 'components/shared'
import { Edit, StatusIndicator } from 'components/shared/Icons'

const JobSlotSubmission = ({
  jobSubmission,
  slot,
  slotColor,
  isHot,
  headerless,
  changeSubbmissionStateFxn,
  updateSlot,
  boardType,
  setInterviewSlotMarginSpreadData
}) => {
  const dispatch = useDispatch()
  const setBasicMenu = useCallback(
    v => {
      dispatch(setBasicMenuPopover(v))
    },
    [dispatch]
  )
  const setCandidateHistory = useCallback(
    v => {
      dispatch(setCandidateHistoryMenu(v))
    },
    [dispatch]
  )
  const renderSpreadMargin = useCallback(
    interviewSlot => {
      const { workingMargin, workingSpread, billRate, payRate } = interviewSlot
      const renderConditonalRateField = numberValue =>
        typeof numberValue === 'number' && numberValue !== 0
          ? `$${numberValue} / `
          : null
      let title = ''
      let extraFieldCount = 0
      const displayBillrate = !!renderConditonalRateField(billRate)
      const displayPayRate = !!renderConditonalRateField(payRate)
      if (displayBillrate) {
        title += 'Bill Rate / '
        extraFieldCount++
      }
      if (displayPayRate) {
        title += 'Pay Rate / '
        extraFieldCount++
      }
      title += 'Spread Amount / Spread Percentage'
      return (
        <span className='margin-spread' title={title}>
          {extraFieldCount === 1 ? (
            <span className='single-rate-field'>
              {displayBillrate && 'B'}
              {displayPayRate && 'P'}R:{' '}
            </span>
          ) : null}
          {renderConditonalRateField(billRate)}
          {renderConditonalRateField(payRate)}
          {`$${workingSpread || ''} / ${workingMargin || ''}%`}{' '}
          {headerless ? null : (
            <Edit width={20} height={20} className='pencil' />
          )}
        </span>
      )
    },
    [headerless]
  )

  const renderRecruiterInitials = recruiter => (
    <>
      {recruiter ? (
        <span
          className='recruiter-initials'
          style={{ color: `#${recruiter.color}` }}
        >
          <AMRTooltip topText={`${recruiter.firstName} ${recruiter.lastName}`}>
            {`(${recruiter.firstName[0]}${recruiter.lastName[0]})`}
          </AMRTooltip>
        </span>
      ) : null}
    </>
  )

  if (!jobSubmission.InterviewSlotSubmission) {
    logger({
      stackerror: `JobOrder with bhid "${jobSubmission.bhId}" has a null InterviewSlot`
    })
    return null
  }
  let prescreen = jobSubmission.InterviewSlotSubmission.securityPrescreen
  let {
    available,
    clientConfirmed,
    prepped
  } = jobSubmission.InterviewSlotSubmission
  return (
    <div
      key={`slot-${jobSubmission.bhId}-${slot.id}`}
      className='job-slot-line'
    >
      <div className='slotsub-leftbox'>
        <button
          className='c-btn--responsive c-btn--pointer-cursor c-btn--bare'
          onClick={() =>
            changeSubbmissionStateFxn(
              jobSubmission.bhId,
              slot.id,
              available,
              clientConfirmed,
              prepped
            )
          }
        >
          <StatusIndicator
            className='status'
            {...getStatusIndicatorProps(
              available,
              clientConfirmed,
              prepped,
              slot
            )}
          />
        </button>
        <span
          style={{
            flex: 1,
            display: 'inline-flex',
            justifyContent: 'space-between'
          }}
        >
          <span
            id={`submission-${jobSubmission.bhId}-${slot.id}`}
            className={'candidate-name'}
            onClick={e => {
              handleCandidateTap(
                `#submission-${jobSubmission.bhId}-${slot.id}`,
                slot,
                jobSubmission,
                boardType,
                updateSlot,
                setBasicMenu,
                setCandidateHistory
              )
            }}
            style={{
              color: `${slotColor}`,
              borderBottom: prescreen ? '2px solid blue' : 'none'
            }}
          >
            {trunc(
              `${jobSubmission.Candidate.firstName} ${jobSubmission.Candidate.lastName}`,
              headerless ? 20 : 70
            )}
            <br />
            {isHot ? (
              <span className='hotInfo'>
                {`(S: ${jobSubmission.SendingUser.firstName[0]}. ${jobSubmission.SendingUser.lastName
                  } O: ${jobSubmission.Candidate.Owner.firstName[0]}. ${jobSubmission.Candidate.Owner.lastName
                  })`}
              </span>
            ) : null}
          </span>
        </span>
        {renderRecruiterInitials(jobSubmission.SendingUser)}
      </div>
      <div className='slotsub-rightbox'>
        <div
          onClick={e => {
            if (headerless) return
            e.stopPropagation()
            setInterviewSlotMarginSpreadData(jobSubmission, slot.id)
          }}
          className='activity-sm'
        >
          {renderSpreadMargin(jobSubmission.InterviewSlotSubmission)}
        </div>
      </div>
    </div>
  )
}

export default JobSlotSubmission

const trunc = (string, max) => {
  if (string.length <= max) {
    return string
  } else {
    return string.substring(0, max - 3) + '...'
  }
}
