import {
  CheckCircleOutline,
  Bolt,
  Timer,
  Work,
  AttachMoney
} from '@mui/icons-material'
import { ButtonUnstyled } from '@mui/material'
import React from 'react'

const BoardSelector = ({ handleBoardChange, boards, activeBoard, isOpen }) => {
  const handleClick = board => handleBoardChange(board)

  const renderIcon = board => {
    const name = board.split('-').join(' ')
    switch (board) {
      case 'deals':
        return (
          <>
            <CheckCircleOutline />
            {name}
          </>
        )
      case 'jobs':
        return (
          <>
            <Bolt />
            {name}
          </>
        )
      case 'activities':
        return (
          <>
            <Timer />
            {name}
          </>
        )
      case 'starts':
        return (
          <>
            <Work />
            {name}
          </>
        )
      case 'where-we-at':
        return (
          <>
            <AttachMoney />
            {name}
          </>
        )
      default:
        return (
          <>
            <CheckCircleOutline />
            {name}
          </>
        )
    }
  }

  return (
    <ul className='c-ts-menu__board-selector'>
      {boards.map(board => (
        <li key={board}>
          <ButtonUnstyled
            onClick={() => handleClick(board)}
            className={`c-ts-menu__board-selector__item ${activeBoard ===
              board && 'is-active'} ${isOpen && 'is-open'}`}
            aria-label={`Go to ${board}`}
          >
            {isOpen && renderIcon(board)}
          </ButtonUnstyled>
        </li>
      ))}
    </ul>
  )
}

export default BoardSelector
