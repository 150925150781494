import React from 'react'

const JobCardInfo = props => {
  const {
    job,
    job: {
      startDate,
      clientBillRate,
      ClientCorporation: { city: clCity, state: clState },
      city,
      state,
      requiredSkill,
      fullPartTime,
      duration,
      workSiteType
    },
    bhId,
    department,
    headerless
  } = props

  return (
    <div className='topright-info'>
      <h2 className='title'>
        ID{' '}
        {!headerless ?
          <a
            rel='noopener noreferrer'
            onClick={e => e.stopPropagation()}
            href={job.url}
            target='_blank'
          >
            {bhId}
          </a>
          :
          <b>{bhId}</b>
        }
        {headerless && <span> | </span>}
      </h2>
      <h2>
        {startDate.split('-')[1]}/{startDate.split('-')[2]}
      </h2>
      {clientBillRate ? (
        <h2>
          <span> | </span>
          {`$${clientBillRate}`}
        </h2>
      ) : null}
      {duration && (
        <h2>
          <span> | </span>
          {`${duration}`}
        </h2>
      )}

      {!headerless && <br />}

      {fullPartTime && (
        <h2>
          {headerless && <span> | </span>}
          {`${fullPartTime}`}
        </h2>
      )}

      {workSiteType && (
        <h2>
          <span> | </span>
          {`${workSiteType}`}
        </h2>
      )}
      <h2 className='city'>
        {headerless && <span> | </span>}
        {city === ''
          ? clCity && clState
            ? `${clCity}, ${clState}`
            : 'N/A'
          : typeof city === 'string' && typeof state === 'string'
            ? `${city}${city.trim().length && state.trim().length ? ', ' : ''
            }${state}`
            : 'N/A'}
      </h2>
      {department.settings.required_skill_sort && requiredSkill ? (
        <h2>
          <span> | </span>
          {`${requiredSkill.replace('[', '').replace(']', '')}`}
        </h2>
      ) : null}
    </div>
  )
}

export default JobCardInfo
