import React, { Component } from 'react'
import photo from '../../../../../theme/assets/noavatar.png'
import { toHSL } from '../../../../../helpers/Util'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import AddJobMenu from './AddJobMenu'

class ActivityPaneHeader extends Component {
  state = {
    targetEl: null
  }
  render() {
    const leftColor = toHSL(this.props.user.color, 0)
    const rightColor = toHSL(this.props.user.color, 25)

    const emptyJobs = this.props.jobs.filter(job => {
      return job.InterviewSlots.length === 0
    })
    const {
      auth: { activeUser },
      addJOSubmissionFxn,
      isHot,
    } = this.props

    return (
      <div
        key={`activityPane-${this.props.user.bhId}`}
        className='card-container'
      >
        <div
          className='card-header'
          style={{
            backgroundImage: `linear-gradient(to right, ${leftColor} , ${rightColor})`,
            backgroundColor: rightColor
          }}
        >
          <div className='leftbox'>
            <img
              alt='user avatar'
              src={this.props.user.avatar ? this.props.user.avatar : photo}
            />
            {`${this.props.user.firstName} ${this.props.user.lastName} - ${isHot ? this.props.user.PrimaryDepartment.name : ''
              }`}
            {this.props.headerless && !this.props.useTouchscreenBoard ? null : (
              <span className='conf-line'>
                {this.props.user.conferenceLine ? '#' : ''}
                {this.props.user.conferenceLine}
              </span>
            )}
          </div>
          <div
            className={`rightbox ${this.props.headerless ? 'headerless' : ''}`}
            onClick={async e => {
              var target = e.currentTarget
              this.setState({
                targetEl: target
              })
            }}
          >
            {this.props.headerless && !this.props.useTouchscreenBoard ? (
              this.props.user.conferenceLine ? (
                `#${this.props.user.conferenceLine}`
              ) : null
            ) : (
              <div className='add-job'>
                <span className='text'>Add Job</span> <AddCircleOutlineIcon />
              </div>
            )}
          </div>
        </div>
        <div
          className='card-header-border-bottom'
          style={{
            backgroundImage: `linear-gradient(to right, ${rightColor} , ${leftColor})`,
            backgroundColor: leftColor
          }}
        />
        <AddJobMenu
          jobs={emptyJobs}
          addJOSubmissionFxn={addJOSubmissionFxn}
          activeUser={activeUser}
          anchorEl={this.state.targetEl}
          onClose={() => this.setState({ targetEl: null })}
          headerless={this.props.headerless}
          useTouchscreenBoard={this.props.useTouchscreenBoard}
        />
      </div>
    )
  }
}

export default ActivityPaneHeader
