import React from 'react'

const ConsultantCardField = ({
  header,
  content,
  modifier,
  borderStyle,
  onClick
}) => {
  return (
    <div
      className={`c-consultant-card__field
      ${onClick ? 'c-consultant-card__field--pointer' : ''} 
      ${modifier ? `c-consultant-card__field--${modifier}` : ''}
    `}
      style={borderStyle}
      onClick={onClick ? onClick : undefined}
    >
      <div
        className={`c-consultant-card__field-header ${modifier ? `c-consultant-card__field-header--${modifier}` : ''
          }`}
      >
        {header}
      </div>
      <div
        className={`c-consultant-card__field-content ${modifier ? `c-consultant-card__field-content--${modifier}` : ''
          }`}
      >
        {content}
      </div>
    </div>
  )
}

export default ConsultantCardField
