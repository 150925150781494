import React from 'react'
import UserCard from '../../shared/userCard'

const UsersList = React.memo(
  ({ users, activeUser, setActiveUser, activeDepartment }) => (
    <div className='user-selection-inner'>
      {users.map((user, index) => (
        <UserCard
          key={`user-${user.bhId}`}
          showNameBottom
          index={index}
          active={activeDepartment}
          user={user}
          fontSize={16}
          firstName={user.firstName}
          lastName={user.lastName}
          color={user.color}
          avatar={user.avatar}
          selectAction={setActiveUser}
        />
      ))}
    </div>
  )
)

UsersList.displayName = 'UsersList'

export default UsersList
