import { newRequest } from 'helpers/axios'

const getPiDBdata = () => {
  return newRequest('GET', '/admin/raspberrypiregistration', null, null)
}

const deletePi = async record => {
  try {
    const response = await newRequest(
      'DELETE',
      `/admin/raspberrypiregistration/${record.id}`,
      null,
      null
    )
    if (response.status >= 200 && response.status < 300) {
      return true
    } else {
      return false
    }
  } catch (e) {
    console.error(e)
  }
}

const addPi = async record => {
  try {
    const response = await newRequest(
      'POST',
      '/admin/raspberrypiregistration',
      record,
      null
    )

    if (response?.status === 200) {
      return response.data
    } else {
      return false
    }
  } catch (e) {
    console.error(e)
  }
}

const editPi = async record => {
  try {
    const response = await newRequest(
      'PATCH',
      `/admin/raspberrypiregistration/${record.id}`,
      record,
      null
    )
    if (response?.status === 200) {
      return response.data
    } else {
      return false
    }
  } catch (e) {
    console.error(e)
  }
}

export { getPiDBdata, deletePi, addPi, editPi }
