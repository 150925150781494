import React from 'react'
import { connect } from 'react-redux'

const UpdatedDate = React.memo(({ updatedAt, tight = false }) => {
  const dateDisplay =
    updatedAt && updatedAt.format('MMMM DD[th], YYYY, @ hh:mm A [EST]')
  return (
    <div
      className={`c-dashboard__updatedate ${
        tight ? 'c-dashboard__updatedate--tight' : ''
      }`}
    >
      Last updated on {dateDisplay} <div />
    </div>
  )
})

UpdatedDate.displayName = 'UpdateDate'

const mapStateToProps = ({ dashboard }) => ({
  updatedAt: dashboard.updatedAt
})

export default connect(mapStateToProps)(UpdatedDate)
