import 'abortcontroller-polyfill'
import './helpers/sentryConfig'
import React from 'react'
import ReactDOM from 'react-dom'

import { Provider } from 'react-redux'
import configureStore, { history } from './store'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import { ConnectedRouter } from 'connected-react-router'
import { ErrorBoundary } from '@sentry/react'

import App from './App'

import muiTheme from './theme/muiTheme'
import { ThemeProvider } from '@mui/material/styles'
import AdapterDayjs from '@mui/lab/AdapterDayjs'
import LocalizationProvider from '@mui/lab/LocalizationProvider'

import 'antd/dist/antd.min.css'
import './theme/reset.css'
import './theme/scss/main.scss'

import client from './network/apollo/index'
import handleWindowOnError from './helpers/handleWindowOnError'

window.__APOLLO_CLIENT__ = client

window.onerror = handleWindowOnError

export const store = configureStore()
export const persistor = persistStore(store)
const rootNode = 'root'

ReactDOM.render(
  <ErrorBoundary fallback={'An error has occurred'}>
    <ThemeProvider theme={muiTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ConnectedRouter history={history}>
              <App />
            </ConnectedRouter>
          </PersistGate>
        </Provider>
      </LocalizationProvider>
    </ThemeProvider>
  </ErrorBoundary>,
  document.getElementById(rootNode)
)
