import React from 'react'

const TableBody = ({ className, data, config }) => {
  if (!data || !data.length) return null
  return (
    <tbody className={className}>
      {data.map(({ color, rank, ...rest }, index) => (
        <tr className='c-deal-division-table__row' key={index}>
          <td className='c-deal-division-table__cell'>
            <div className='g-numbercard g-numbercard--xs'>
              <div
                className='g-numbercard__value g-numbercard__value--xs'
                style={{ borderColor: `#${color}` }}
              >
                <div>{rank}</div>
              </div>
            </div>
          </td>
          {config.map(({ value, defaultValue, fixLevel }) => (
            <td
              key={value}
              className={`c-deal-division-table__cell c-deal-division-table__cell--${value}`}
            >
              <div>
                {(rest[value] && (rest[value].toFixed && fixLevel ? rest[value].toFixed(fixLevel) : rest[value])) ||
                defaultValue}
              </div>
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  )
}

export default TableBody
