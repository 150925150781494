export const CLIENT_SUBMISSION_STATE = 'Client Submission'
export const INTERVIEW_SCHEDULED_STATE = 'Interview Scheduled'
export const PLACED_STATE = 'Placed'
export const OFFER_EXT_STATE = 'Offer Extended'
export const OFFER_REJ_STATE = 'Offer Rejected'
export const CLIENT_REJECTED_STATE = 'Client Rejected'

// paperworkJson constants
export const ANTI_HARASSMENT_TRAINING = 'Anti-Harassment Training'
export const BACKGROUND = 'Background'
export const BENEFITS_COMPLETED = 'Benefits Completed'
export const CHAIRTY_DEDUCTION = 'Charity Deduction'
export const CLEARANCE = 'Clearance'
export const CLIENT_SPECIFIC_PAPERWORK = 'Client Specific Paperwork'
export const COI = 'COI'
export const CONTRACT = 'Contract'
export const CROSSOVER_APPROVED = 'Crossover Approved'
export const CUSTOMER_ISSUED_EQUIPMENT = 'Customer Issued Equipment'
export const DIRECT_DEPOSIT = 'Direct Deposit'
export const DRUG = 'Drug'
export const EMERGENCY_CONTACT = 'Emergency Contact'
export const EMPLOYEE_HANDBOOK_ACKNOWLEDGMENT =
  'Employee Handbook Acknowledgement'
export const EXPENSE_POLICY = 'Expense Policy'
export const FEDERAL_W4 = 'Federal W4'
export const FORM_8233 = 'Form 8233'
export const HIPAA_ATTESTATION = 'HIPAA Attestation'
export const HOURS_DISCLOSURE = 'Hours Disclosure'
export const I9 = 'I9'
export const I9_W2_ONLY = 'I9 - W2 Only'
export const IMMUNIZATIONS = 'Immunizations'
export const SECURITY_OB = 'Security OB'
export const SS4 = 'SS4'
export const STATE_W4 = 'State W4'
export const W8_BEN_E = 'W8 BEN E'
export const W9 = 'W9'

// clientPaperwork constants
export const MSA = 'MSA'
export const SOW = 'SOW'
export const BILL_TO = 'Bill To'
export const PO_RECEIVED = 'PO Received'

// TODO: Build api endpoint to fetch up to date list of paperwork
export const PAPERWORK_LIST = [
  ANTI_HARASSMENT_TRAINING,
  BACKGROUND,
  BENEFITS_COMPLETED,
  CHAIRTY_DEDUCTION,
  CLEARANCE,
  CLIENT_SPECIFIC_PAPERWORK,
  COI,
  CONTRACT,
  CROSSOVER_APPROVED,
  CUSTOMER_ISSUED_EQUIPMENT,
  DIRECT_DEPOSIT,
  DRUG,
  EMERGENCY_CONTACT,
  EMPLOYEE_HANDBOOK_ACKNOWLEDGMENT,
  EXPENSE_POLICY,
  FEDERAL_W4,
  FORM_8233,
  HIPAA_ATTESTATION,
  HOURS_DISCLOSURE,
  I9,
  I9_W2_ONLY,
  IMMUNIZATIONS,
  SECURITY_OB,
  SS4,
  STATE_W4,
  W8_BEN_E,
  W9,
  MSA,
  SOW,
  BILL_TO,
  PO_RECEIVED
]

export const ACTIVITY_TABS_ENUM = {
  ALL: 0,
  TODAY: 1,
  NEXT_DAY: 2
}

export const SOCKET_BOARDS = {
  ASSIGNED_ACCOUNTS: 'assigned-accounts',
  HOT_USER: 'hot-user',
  STARTS_V2: 'STARTS_V2',
  STARTS_MNGMT: 'starts-mngmt',
  ASSIGNED_ACCOUNTS_HUB: 'ASSIGNED_ACCOUNTS_HUB',
  USER_HUB: 'USER_HUB',
  JOBS_HUB: 'JOBS_HUB',
  DEALS_HUB: 'DEALS_HUB',
  ACTIVITY_HUB: 'ACTIVITY_HUB',
  HOT_ACTIVITY_HUB: 'HOT_ACTIVITY_HUB',
  HOT_JOBS_HUB: 'HOT_JOBS_HUB',
  FISCAL_MONTH_HUB: 'FISCAL_MONTH_HUB'
}

// Touchscreen
export const SIMPLE = 'simple'
export const ADVANCED = 'advanced'
