export const sortAlphabetically = (a, b) => {
  // sort null values to the bottom
  if (!a || !b) {
    if (!b) return -1

    return 1
  }

  const valueA = (a || '').toUpperCase() // ignore upper and lowercase
  const valueB = (b || '').toUpperCase() // ignore upper and lowercase
  if (!b || valueA < valueB) {
    return -1
  }
  if (valueA > valueB) {
    return 1
  }

  // values must be equal
  return 0
}
