import { SIMPLE } from 'constants'
import {
  SET_POPOVER_STATE,
  SET_MODAL_STATE,
  SET_SELECTED_AMS,
  SET_BACKGROUND_STRING,
  SET_STARTS_FROM_FILTER,
  SET_SIDEBAR_IS_COLLAPSED,
  SET_CANDIDATE_HISTORY_MENU,
  SET_BASIC_MENU_POPOVER,
  OPEN_DATE_PICKER,
  OPEN_TIME_PICKER,
  CLOSE_DATE_PICKER,
  CLOSE_TIME_PICKER,
  SET_BOARD_LOADING_OBJ,
  SET_TOUCHSCREEN_PAGINATION_TYPE,
  LOAD_FISCAL_MONTH_DATA_BEGIN,
  LOAD_FISCAL_MONTH_DATA_SUCCESS,
  LOAD_FISCAL_MONTH_DATA_FAIL
} from '../actionTypes'

const DEFAULT_STATE = {
  popover: {
    open: false,
    content: null,
    targetEl: null,
    position: null,
    width: null,
    className: null
  },
  candidateHistoryMenu: null,
  basicMenuPopover: null,
  datePicker: {
    open: false
  },
  timePicker: {
    open: false
  },
  modal: {
    open: false,
    content: null
  },
  activeModal: null,
  selectedAMs: [5091474],
  startsFromFilter: null,
  sideBarIsCollapsed: true,
  touchscreenPaginationType: SIMPLE,
  boardLoadingObj: {},
  mobileMenuOpen: false,
  fiscalMonthDropdown: {
    fetching: false,
    data: null
  }
}
// ?activityIds=5091474,5105844

const UI = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_POPOVER_STATE:
      return {
        ...state,
        popover: {
          ...state.popover,
          ...action.payload
        }
      }
    case SET_MODAL_STATE:
      return {
        ...state,
        modal: {
          content: action.content || null,
          open: action.openBool
        }
      }
    case SET_SELECTED_AMS:
      return {
        ...state,
        selectedAMs: action.accountManagerIdsArr
      }
    case SET_BACKGROUND_STRING:
      return {
        ...state,
        background: action.backgroundString,
        color: action.color
      }
    case SET_STARTS_FROM_FILTER:
      return {
        ...state,
        startsFromFilter: action.payload
      }
    case SET_SIDEBAR_IS_COLLAPSED:
      return {
        ...state,
        sideBarIsCollapsed: action.payload
      }
    case SET_CANDIDATE_HISTORY_MENU:
      return {
        ...state,
        candidateHistoryMenu: action.payload
      }
    case SET_BASIC_MENU_POPOVER:
      return {
        ...state,
        basicMenuPopover: action.payload
      }
    case OPEN_DATE_PICKER: {
      return {
        ...state,
        datePicker: {
          open: true
        }
      }
    }
    case OPEN_TIME_PICKER: {
      return {
        ...state,
        timePicker: {
          open: true
        }
      }
    }
    case CLOSE_DATE_PICKER: {
      return {
        ...state,
        datePicker: DEFAULT_STATE.datePicker
      }
    }
    case CLOSE_TIME_PICKER: {
      return {
        ...state,
        timePicker: DEFAULT_STATE.timePicker
      }
    }
    case SET_BOARD_LOADING_OBJ: {
      if (action.payload === 'reset') {
        return {
          ...state,
          boardLoadingObj: {}
        }
      }
      return {
        ...state,
        boardLoadingObj: {
          ...state.boardLoadingObj,
          [action.payload]: true
        }
      }
    }
    case SET_TOUCHSCREEN_PAGINATION_TYPE: {
      return {
        ...state,
        touchscreenPaginationType: action.payload
      }
    }
    case LOAD_FISCAL_MONTH_DATA_BEGIN: {
      return {
        ...state,
        fiscalMonthDropdown: {
          ...state.fiscalMonthDropdown,
          fetching: true
        }
      }
    }
    case LOAD_FISCAL_MONTH_DATA_SUCCESS: {
      return {
        ...state,
        fiscalMonthDropdown: {
          fetching: false,
          data: action.payload
        }
      }
    }
    case LOAD_FISCAL_MONTH_DATA_FAIL: {
      return {
        ...state,
        fiscalMonthDropdown: {
          ...state.fiscalMonthDropdown,
          fetching: false
        }
      }
    }
    default:
      return state
  }
}

export default UI
