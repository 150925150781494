import React, { useCallback } from 'react'
import MuiPopover from '@mui/material/Popover'
import { useDispatch, useSelector } from 'react-redux'
import { selectPopoverProps } from 'redux/selectors'
import { setPopoverState } from 'redux/actions'

const Popover = ({ updateUserWSFxn }) => {
  const dispatch = useDispatch()
  const onClose = useCallback(() => {
    dispatch(setPopoverState({ open: false }))
  }, [dispatch])
  const popoverProps = useSelector(selectPopoverProps)
  const {
    open,
    targetEl,
    content,
    sx,
    anchorOrigin,
    transformOrigin
  } = popoverProps
  if (open) {
    let popoverSx = {
      '& .MuiBackdrop-root': {
        background: 'rgba(0, 0, 0, 0.5)'
      },
      '& .MuiPopover-paper': {
        borderRadius: '1rem'
      }
    }
    if (sx)
      popoverSx = {
        ...popoverSx,
        ...sx
      }
    return (
      <MuiPopover
        sx={popoverSx}
        open={open}
        onClose={onClose}
        anchorEl={targetEl}
        anchorOrigin={anchorOrigin || defaultAnchorOrigin}
        transformOrigin={transformOrigin}
      >
        {content ? React.cloneElement(content, { updateUserWSFxn }) : null}
      </MuiPopover>
    )
  }

  return null
}

const defaultAnchorOrigin = {
  vertical: 'bottom',
  horizontal: 'left'
}

export default Popover
