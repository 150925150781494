import React, { useEffect, useState } from 'react'
import BoardSelector from './subComponents/BoardSelector'
import { IconButton } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { HighlightOff } from '@mui/icons-material'
import DivisionSelector from './subComponents/DivisionSelector'
import Divisions from './subComponents/Divisions'

const Menu = ({ boardSelector, isOpen, handleOpen }) => {
  const [divisionOpen, setDivisionOpen] = useState(false)
  const handleDivisionChange = () => {
    if (!isOpen && !divisionOpen) handleOpen()
    setDivisionOpen(!divisionOpen)
  }

  useEffect(() => {
    !isOpen && setDivisionOpen(false)
  }, [isOpen])

  return (
    <div className={`c-ts-menu ${isOpen || divisionOpen ? 'is-open' : ''}`}>
      {divisionOpen ?
        <Divisions handleOpen={handleOpen} />
        :
        <>
          <BoardSelector {...boardSelector} isOpen={isOpen} />
          <IconButton
            className={`c-ts-menu__button ${isOpen && 'is-open'}`}
            onClick={handleOpen}
          >
            {isOpen ? <HighlightOff /> : <MenuIcon />}
          </IconButton>
        </>
      }
      <DivisionSelector isOpen={divisionOpen} handleOpen={handleDivisionChange} />
    </div>
  )
}

export default Menu
