import {
  SET_JOB_ORDERS,
  SET_JOB_ORDERS_IS_PRESORTED,
  SET_JOB_ORDERS_COMPANY_FILTER,
  SET_JOB_ORDERS_OWNERS_FILTER,
  SET_JOB_ORDERS_PROGRAM_FILTER,
  SET_JOB_ORDERS_REQUIRED_SKILL_FILTER,
  SET_JOB_ORDERS_TAG_FILTER,
  SET_JOB_ORDERS_SORT,
  SET_JOB_ORDERS_OPEN_TIMES_TOGGLE,
  SET_MULTI_DIVISIONS_TOGGLE
} from '../actionTypes'
import logger from '../../requests/logger'
import { cloneDeep } from 'lodash'

export const setJobOrders = jobOrders => {
  return {
    type: SET_JOB_ORDERS,
    jobOrders: jobOrders
  }
}

export const setJobOrdersIsPresorted = (jobOrders, isPresorted) => {
  return {
    type: SET_JOB_ORDERS_IS_PRESORTED,
    payload: { jobOrders, isPresorted }
  }
}

export const setJobOrdersCompanyFilter = company => {
  return {
    type: SET_JOB_ORDERS_COMPANY_FILTER,
    payload: company
  }
}

export const setJobOrdersOwnersFilter = owner => {
  return {
    type: SET_JOB_ORDERS_OWNERS_FILTER,
    payload: owner
  }
}

export const setJobOrdersProgramFilter = company => {
  return {
    type: SET_JOB_ORDERS_PROGRAM_FILTER,
    payload: company
  }
}

export const setJobOrdersRequiredSkillFilter = owner => {
  return {
    type: SET_JOB_ORDERS_REQUIRED_SKILL_FILTER,
    payload: owner
  }
}

export const setJobOrdersTagFilter = tag => {
  return {
    type: SET_JOB_ORDERS_TAG_FILTER,
    payload: tag
  }
}

export const setJobOrdersSort = sortObj => {
  return {
    type: SET_JOB_ORDERS_SORT,
    payload: sortObj
  }
}

export const setJobOrdersOpenTimesToggle = payload => {
  return {
    type: SET_JOB_ORDERS_OPEN_TIMES_TOGGLE,
    payload: payload
  }
}

export const setMultiDivisionsToggle = payload => {
  return {
    type: SET_MULTI_DIVISIONS_TOGGLE,
    payload: payload
  }
}

export const updateJOSubmission = (jobId, submissionId, payload, event) => {
  return function(dispatch, getState) {
    let {
      jobOrders: { data }
    } = getState()
    let newOrders = cloneDeep(data)
    let JO = newOrders.find(JO => JO.bhId === jobId)

    if (!JO) {
      logger({
        stackError: `JobOrder not found for ${event} event with the job id ${jobId}`
      })
      return
    }

    let submission = JO.JobSubmissions.find(
      submission => submission.bhId === submissionId
    )

    if (!submission) {
      logger({
        stackError: `Submission not found in JobOrder for a ${event} event with the submission id ${submissionId} and job id ${jobId}`
      })
      return
    }

    let submissionIdx = JO.JobSubmissions.findIndex(
      submission => submission.bhId === submissionId
    )
    let newSubmission = {
      ...submission,
      ...payload
    }

    JO.JobSubmissions.splice(submissionIdx, 1, newSubmission)
    dispatch(setJobOrders(newOrders))
  }
}

export const addJOSubmission = (jobSubmission, event) => {
  return function(dispatch, getState) {
    const jobOrderId = jobSubmission.jobOrder
    let {
      jobOrders: { data }
    } = getState()
    let newOrders = cloneDeep(data)
    let JOIndex = data.findIndex(JO => JO.bhId === jobOrderId)
    if (JOIndex > -1) {
      newOrders[JOIndex].JobSubmissions = data[JOIndex].JobSubmissions.concat(
        jobSubmission
      )
      dispatch(setJobOrders(newOrders))
    } else {
      logger({
        stackError: `Job order not found for ${event} event with the jobOrder id ${jobSubmission.jobOrder} and submission id ${jobSubmission.bhId}`
      })
      return
    }
  }
}

export const jobOrderChange = (jobOrderId, payload, event) => {
  return function(dispatch, getState) {
    let {
      jobOrders: { data }
    } = getState()
    let newOrders = cloneDeep(data)
    let JO = newOrders.find(JO => JO.bhId === jobOrderId)
    if (JO) {
      Object.assign(JO, payload)
      dispatch(setJobOrders(newOrders))
    } else {
      logger({
        stackError: `Job order not found for ${event} event with the jobOrder id ${jobOrderId}`
      })
      return
    }
  }
}

export const jobOrderSubmissionChange = (
  jobOrderId,
  jobSubmissionId,
  field,
  value,
  event
) => {
  return function(dispatch, getState) {
    let {
      jobOrders: { data }
    } = getState()
    let newOrders = cloneDeep(data)
    let JO = newOrders.find(JO => JO.bhId === jobOrderId)
    if (JO) {
      let submission = JO.JobSubmissions.find(
        jos => jos.bhId === jobSubmissionId
      )
      if (submission) {
        submission[field] = value
        dispatch(setJobOrders(newOrders))
      } else {
        logger({
          stackError: `Submission not found for ${event} event with the jobOrder id ${jobOrderId} and submissionid ${jobSubmissionId}`
        })
        return
      }
    } else {
      logger({
        stackError: `Job Order not found for ${event} event with the jobOrder id ${jobOrderId} and submissionid ${jobSubmissionId}`
      })
      return
    }
  }
}

export const updateCandidateInJO = (instances, candidate, event) => (
  dispatch,
  getState
) => {
  let {
    jobOrders: { data }
  } = getState()
  let newOrders = cloneDeep(data)

  instances.forEach(instance => {
    let JOIndex = newOrders.findIndex(
      orderObj => orderObj.bhId === instance.jobOrder
    )
    if (JOIndex > -1) {
      let JobSubIndex = newOrders[JOIndex].JobSubmissions.findIndex(
        jobSubObj => jobSubObj.bhId === instance.jobSubmission
      )
      if (JobSubIndex > -1) {
        newOrders[JOIndex].JobSubmissions[JobSubIndex].Candidate = {
          ...newOrders[JOIndex].JobSubmissions[JobSubIndex].Candidate,
          ...candidate
        }
        dispatch(setJobOrders(newOrders))
      } else {
        logger({
          stackError: `Job Submission not found for one of the instances in ${event} event with the jobOrder id ${instance.jobOrder} and submissionid ${instance.jobSubmission}`
        })
        return
      }
    } else {
      logger({
        stackError: `Job Order not found for one of the instances in ${event} event with the jobOrder id ${instance.jobOrder} and submissionid ${instance.jobSubmission}`
      })
      return
    }
  })
}

export const updateJobOrderOwner = (bhId, payload) => (dispatch, getState) => {
  const {
    jobOrders: { data }
  } = getState()
  const newOrders = cloneDeep(data)
  const jo = newOrders.find(Jo => Jo.Owner && Jo.Owner.bhId === bhId)
  if (jo) {
    newOrders.forEach(jobOrder => {
      if (jobOrder.Owner && jobOrder.Owner.bhId === bhId) {
        jobOrder.Owner = { ...jobOrder.Owner, ...payload }
      }
    })
    dispatch(setJobOrders(newOrders))
  }
  return
}
