import React, { useCallback, useState, useMemo } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'

import Button from '@mui/material/Button'
import { useDispatch } from 'react-redux'

import debounce from '../../../../helpers/debounce'
import {
  approveStartsCandidate,
  revertApproveStartsCandidate
} from '../../../../redux/actions'
import getApprovedButtonDisabledState from '../../helpers/getApprovedButtonDisabledState'

const ApproveDialog = ({
  candidateName = 'Candidate',
  closeFn,
  approveFn,
  revertApprovalFn,
  isApproved
}) => (
  <Dialog
    open={true}
    onClose={closeFn}
    aria-labelledby='approve-dialog-title'
    aria-describedby='approve-dialog-description'
  >
    <DialogTitle id='approve-dialog-title' sx={{ color: 'red' }}>
      WARNING!
    </DialogTitle>
    <DialogContent>
      <DialogContentText id='approve-dialog-description'>
        {isApproved
          ? `Are you sure you'd like to undo the approval for ${candidateName}?`
          : `Marking ${candidateName} as Approved will remove them from your Starts Board. If you need to undo this action please contact your Personnel Coordinator.`}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button variant='text' disabled={false} onClick={closeFn}>
        Cancel
      </Button>
      <Button
        variant='text'
        style={{ color: 'red' }}
        onClick={isApproved ? revertApprovalFn : approveFn}
      >
        {isApproved ? 'Confirm' : 'Remove'}
      </Button>
    </DialogActions>
  </Dialog>
)

const CandidateApproveBtn = ({
  placement,
  overrideDisable = false,
  btnText,
  className
}) => {
  const dispatch = useDispatch()
  const [dialogOpen, setDialogOpen] = useState(false)
  const debouncedApprove = useMemo(
    () =>
      debounce(pl => {
        dispatch(approveStartsCandidate(pl.id))
        setDialogOpen(false)
      }, 500),
    [dispatch, setDialogOpen]
  )
  const approveFn = useCallback(() => {
    if (placement) {
      debouncedApprove(placement)
    }
  }, [debouncedApprove, placement])
  const debouncedRevert = useMemo(
    () =>
      debounce(pl => {
        dispatch(revertApproveStartsCandidate(pl.id))
        setDialogOpen(false)
      }, 500),
    [dispatch, setDialogOpen]
  )
  const revertApprovalFn = useCallback(() => {
    if (placement) {
      debouncedRevert(placement)
    }
  }, [debouncedRevert, placement])
  const disabled = overrideDisable
    ? false
    : getApprovedButtonDisabledState(placement)
  const openDialog = useCallback(() => {
    setDialogOpen(true)
  }, [setDialogOpen])

  return (
    <>
      <button
        className={`c-btn c-btn--bare c-approve-btn${
          disabled ? ' is-disabled' : ''
        } ${className ? className : ''}`}
        onClick={openDialog}
        disabled={disabled}
      >
        {btnText || (placement.approved ? 'Revert' : 'Approve')}
      </button>
      {dialogOpen && (
        <ApproveDialog
          candidateName={placement.candidateName}
          closeFn={() => setDialogOpen(false)}
          approveFn={approveFn}
          isApproved={placement.approved}
          revertApprovalFn={revertApprovalFn}
        />
      )}
    </>
  )
}

export default CandidateApproveBtn
