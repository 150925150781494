import React, { useState, useEffect } from 'react'
import RecentDealsCard from './components/RecentDealsCard'
import { useSelector } from 'react-redux'

const selectDeals = state => state.dashboard.recentDeals || []

const RecentDeals = ({ headerless }) => {
  const [active, setActive] = useState(0)
  let deals = useSelector(selectDeals)
  if (deals.length > 5) deals = deals.slice(0, 6)
  const nextActive = active + 1 >= deals.length ? 0 : active + 1
  useEffect(() => {
    if (deals.length > 1) {
      const cardTimeout = setTimeout(() => {
        setActive(nextActive)
        // seconds until the next card is shown
      }, 5 * 1000)
      return () => {
        if (cardTimeout) clearTimeout(cardTimeout)
      }
    }
  }, [active, nextActive, deals])
  const cards =
    deals.length &&
    deals.map((deal, index) => (
      <RecentDealsCard
        key={index}
        index={index}
        deal={deal}
        totalCount={deals.length}
        headerless={headerless}
      />
    ))
  return (
    <>
      <div
        className={`c-recent-deals c-recent-deals--single${headerless ? ' c-recent-deals--headerless' : ' c-recent-deals--header'
          }`}
      >
        {deals && deals.length ? cards[active] : <RecentDealsCard />}
      </div>
      <div
        className={`c-recent-deals c-recent-deals--list${headerless ? ' c-recent-deals--headerless' : ' c-recent-deals--header'
          }`}
      >
        {deals && deals.length ? cards : <RecentDealsCard />}
      </div>
    </>
  )
}

export default RecentDeals
