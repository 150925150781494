import React from 'react'
import {
  sortActivePlacements,
  sortContacts,
  sortProgram,
  sortDivision,
  sortAMName,
  sortAssignedDate,
  sortCompanyName
} from '../helpers/tableColumnSortFunctions'
import { formatAssignedDate } from '../helpers/formatAssignedDate'
import UserCard from '../../../shared/userCard'

const ASSIGNED_DATE_COLUMN_WIDTH = '210px'

export const companyColumnConfig = (
  shouldShowProgramFilter,
  datasetLargerThanOne,
  hasProgramValues,
  verticalConfig
) => [
  {
    title: 'ACCOUNT MANAGER',
    dataIndex: 'user',
    key: 'user',
    className: 'assigned-accounts-card-table-header',
    width: shouldShowProgramFilter ? '30%' : '40%',
    render: user => (
      <UserCard
        showNameRight
        size={verticalConfig ? 52 : 38}
        fontSize={14}
        bhId={user.bhId}
        color={user.color}
        avatar={user.avatar}
        firstName={user.firstName}
        lastName={user.lastName}
      />
    ),
    defaultSortOrder: datasetLargerThanOne && 'ascend',
    sortDirections: ['ascend', 'descend'],
    sorter: datasetLargerThanOne && sortAMName
  },
  {
    title: 'CONTACTS',
    dataIndex: 'contactCount',
    key: 'contacts',
    className: 'assigned-accounts-card-table-header',
    sortDirections: ['ascend', 'descend'],
    sorter: datasetLargerThanOne && sortContacts
  },
  {
    title: 'DIVISION',
    dataIndex: ['category', 'name'],
    key: 'category',
    className: 'assigned-accounts-card-table-header',
    sortDirections: ['ascend', 'descend'],
    sorter: datasetLargerThanOne && sortDivision
  },
  {
    title: 'PROGRAM',
    dataIndex: 'program',
    key: 'program',
    hidden: !shouldShowProgramFilter,
    className: 'assigned-accounts-card-table-header',
    sortDirections: ['ascend', 'descend'],
    sorter: datasetLargerThanOne && hasProgramValues && sortProgram
  },
  {
    title: 'ASSIGNED DATE',
    key: 'assignedDate',
    dataIndex: 'assignedDate',
    width: ASSIGNED_DATE_COLUMN_WIDTH,
    render: formatAssignedDate,
    className: 'assigned-accounts-card-table-header',
    sortDirections: ['ascend', 'descend'],
    sorter: datasetLargerThanOne && sortAssignedDate
  }
]

export const AMColumnConfig = (
  datasetLargerThanOne,
  hasProgramValues,
  renderCompanyPopover,
  shouldShowProgramFilter
) => {
  return [
    {
      title: 'COMPANY',
      dataIndex: ['clientCorporation', 'name'],
      key: 'clientCorporation',
      width: '25%',
      className: 'assigned-accounts-card-table-header',
      render: (companyName, dataSet) => (
        <div className='assigned-accounts-card-table-cell-text'>
          <span
            id={`am-comp-${dataSet.id}`}
            className='c-menu__popover-pointer'
            onClick={() =>
              renderCompanyPopover(
                { id: `am-comp-${dataSet.id}` },
                dataSet.clientCorporationUrl
              )
            }
          >
            {companyName}
          </span>
        </div>
      ),
      defaultSortOrder: datasetLargerThanOne && 'ascend',
      sortDirections: ['ascend', 'descend'],
      sorter: datasetLargerThanOne && sortCompanyName
    },
    {
      title: 'ACTIVE PLACEMENTS',
      dataIndex: 'placementCount',
      key: 'allActivePlacements',
      width: '27%',
      className: 'assigned-accounts-card-table-header',
      onHeaderCell: () => {
        return {
          showtooltip: true
        }
      },
      sortDirections: ['ascend', 'descend'],
      sorter: datasetLargerThanOne && sortActivePlacements
    },
    {
      title: 'CONTACTS',
      dataIndex: 'contactCount',
      key: 'contacts',
      className: 'assigned-accounts-card-table-header',
      sortDirections: ['ascend', 'descend'],
      sorter: datasetLargerThanOne && sortContacts
    },
    {
      title: 'DIVISION',
      dataIndex: ['category', 'name'],
      key: 'category',
      className: 'assigned-accounts-card-table-header',
      sortDirections: ['ascend', 'descend'],
      sorter: datasetLargerThanOne && sortDivision
    },
    {
      title: 'PROGRAM',
      dataIndex: 'program',
      key: 'program',
      hidden: !shouldShowProgramFilter,
      className: 'assigned-accounts-card-table-header',
      sortDirections: ['ascend', 'descend'],
      sorter: datasetLargerThanOne && hasProgramValues && sortProgram
    },
    {
      title: 'ASSIGNED DATE',
      key: 'assignedDate',
      dataIndex: 'assignedDate',
      width: ASSIGNED_DATE_COLUMN_WIDTH,
      render: formatAssignedDate,
      className: 'assigned-accounts-card-table-header',
      sortDirections: ['ascend', 'descend'],
      sorter: datasetLargerThanOne && sortAssignedDate
    }
  ]
}
