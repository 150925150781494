import React from 'react'

const ClipboardPlus = () => {
  return (
    <svg width='16' height='18' xmlns='http://www.w3.org/2000/svg'>
      <g fillRule='nonzero' fill='none'>
        <path
          d='M8 0C6.84 0 5.864.744 5.497 1.778h-3.72C.797 1.778 0 2.573 0 3.556V16c0 .982.796 1.778 1.778 1.778h12.444c.982 0 1.778-.796 1.778-1.778V3.556c0-.983-.796-1.778-1.778-1.778h-3.719A2.658 2.658 0 008 0zm0 1.778a.89.89 0 010 1.778h6.222V16H1.778V3.556H8a.89.89 0 010-1.778z'
          fill='#666'
        />
        <path
          fill='#888'
          d='M7.111 6.444v2.667H4.444v1.778h2.667v2.667h1.778v-2.667h2.667V9.111H8.889V6.444z'
        />
      </g>
    </svg>
  )
}

export default ClipboardPlus
