import React from 'react'
import { Tooltip } from 'antd'

const Title = ({ topText, bottomText }) => {
  return (
    <>
      {topText}
      {bottomText && <br />}
      {bottomText}
    </>
  )
}

const AMRTooltip = props => {
  const { children, topText, bottomText } = props
  if (!topText && !bottomText) return children
  return (
    <Tooltip
      overlayClassName='c-tooltip--amr'
      placement='top'
      title={<Title topText={topText} bottomText={bottomText} />}
      {...props}
    >
      {children}
    </Tooltip>
  )
}

export default AMRTooltip
