import { newRequest } from 'helpers/axios'

/**
 *
 * @param {number} jobOrderId
 * @returns
 */
export default async function postInterviewPrepDetails(jobOrderId) {
  try {
    return await newRequest(
      'POST',
      `/job-orders/interviewprepdetails/${jobOrderId}`,
      null,
      null
    )
  } catch (e) {
    console.error(e)
  }
}
