import {
  SAVE_FISCAL_GOAL,
  SET_RECENT_DEALS,
  SET_DEALS_CUSTOM_FISCAL_DATE,
  SET_DEALS_GO_LIVE
} from '../actionTypes'
import { sendGraphQLQuery } from '../../network/apollo'
import { getRecentDealsQuery } from './query'
import errorHandler from '../../helpers/errorHandler'

export const saveFiscalGoal = goal => {
  return {
    type: SAVE_FISCAL_GOAL,
    payload: goal
  }
}

export const getRecentDeals = department => async (dispatch, getState) => {
  try {
    const {
      department: { selectedDepartment }
    } = getState()
    let err
    const dep = department || selectedDepartment

    if (dep) {
      const encodedDep = encodeURIComponent(dep)
      const queryStr = getRecentDealsQuery(encodedDep)
      const res = await sendGraphQLQuery(queryStr)
      const recentDeals =
        res &&
        res.data &&
        res.data.divisionDashboardQuery &&
        res.data.divisionDashboardQuery.recentDeals
      if (recentDeals) {
        dispatch({
          type: SET_RECENT_DEALS,
          payload: recentDeals
        })
      } else {
        err = `Could not retrieve deals for ${dep}`
      }
    } else {
      err =
        'No department provided or found in state while fetching dealsGoal and dealsCount'
    }

    if (err) throw new Error(err)
  } catch (e) {
    errorHandler(e)
  }
}

export const setDealsCustomFiscalDate = date => ({
  type: SET_DEALS_CUSTOM_FISCAL_DATE,
  payload: date
})

export const setDealsGoLive = date => ({
  type: SET_DEALS_GO_LIVE,
  payload: date
})
