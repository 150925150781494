import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { handleStartshubSocket, loadStartsData } from 'redux/actions'
import { selectSelectedDepartment } from 'redux/selectors/department'
import {
  selectStartsCardViewMode,
  selectVisiblePlacementsStarts
} from 'redux/selectors/placementsStarts'
import {
  ConsultantCard,
  OnboardingPaperwork
} from 'components/boards/startsBoard/components'
import BoardManager from 'components/hocs/BoardManager'
import { SOCKET_BOARDS } from 'constants'
import Skeleton from 'components/shared/Skeleton'
import { selectBoardLoadingObj } from 'redux/selectors'
import StartsBoardSkeleton from 'components/boards/startsBoard/StartsBoardSkeleton'
import { useInterval } from 'components/hocs/hooks'

//ws
import BaseWebsocket from 'network/websocket/BaseWebsocket'
import verifyConnectionState from 'network/websocket/helpers/verifyConnectionState'

const Starts = ({
  isPaused,
  useTouchscreenBoard,
  handleInternalPagination,
  boardPage,
  setBoardPage
}) => {
  const dispatch = useDispatch()
  const { header } = useParams()
  const [modalPlacement, setModalPlacement] = useState(null)
  const department = useSelector(selectSelectedDepartment)
  const data = useSelector(selectVisiblePlacementsStarts)
  const viewMode = useSelector(selectStartsCardViewMode)
  const boardLoadingObj = useSelector(selectBoardLoadingObj)
  const isHeaderless = useMemo(() => {
    return useTouchscreenBoard ? true : !header || header === 'headerless'
  }, [useTouchscreenBoard, header])
  const startsBoardRef = useRef()
  const handleStartshub = useCallback(
    msg => {
      dispatch(handleStartshubSocket(msg))
    },
    [dispatch]
  )
  const renderStartsCard = useCallback(
    start => {
      if (start.bullhornId) {
        return (
          <ConsultantCard
            key={start.bullhornId}
            showProgress
            placement={start}
            setModalPlacement={() => setModalPlacement(start)}
            viewMode={viewMode}
            className='element-container'
          />
        )
      }
    },
    [setModalPlacement, viewMode]
  )
  const renderSoloOrArray = useCallback(
    datum => {
      if (!Array.isArray(datum)) {
        return renderStartsCard(datum)
      } else {
        return datum.map((arr2, idx) => (
          <ul className='c-starts-board__items' key={`masonry-col-${idx}`}>
            {arr2.map(o => {
              const start = data.find(d => d.bullhornId === o.id)
              return start ? renderStartsCard(start) : null
            })}
          </ul>
        ))
      }
    },
    [renderStartsCard, data]
  )

  const openSockets = useCallback(() => {
    if (!verifyConnectionState(BaseWebsocket.sockets.STARTS_V2)) {
      BaseWebsocket.openSignalRSocket(SOCKET_BOARDS.STARTS_V2, handleStartshub)
      dispatch(loadStartsData())
    }
  }, [dispatch, handleStartshub])

  useEffect(() => {
    if (!useTouchscreenBoard && department) {
      openSockets()
    }
    return () => {
      if (!useTouchscreenBoard && department) {
        BaseWebsocket.closeSignalRSocket(SOCKET_BOARDS.STARTS_V2)
      }
    }
  }, [department, dispatch, handleStartshub, openSockets, useTouchscreenBoard])

  useInterval(openSockets, 60 * 1000)

  return (
    <React.Fragment>
      <div className='c-starts-board board-container'>
        {isHeaderless ? (
          <BoardManager
            useTouchscreenBoard={useTouchscreenBoard}
            department={department}
            elementMargins={30}
            ref={startsBoardRef}
            key='startsboard'
            data={data}
            headerless={isHeaderless}
            renderElement={renderSoloOrArray}
            renderElementUsesData
            multiFlexColumn
            handleInternalPagination={handleInternalPagination}
            isPaused={isPaused}
            boardPage={boardPage}
            setBoardPage={setBoardPage}
          />
        ) : (
          <Skeleton.Board
            loading={!boardLoadingObj.starts}
            skeletonChildren={<StartsBoardSkeleton />}
          >
            <ul className='c-starts-board__items'>
              {data.map(renderStartsCard)}
            </ul>
          </Skeleton.Board>
        )}
      </div>
      <OnboardingPaperwork
        placement={modalPlacement}
        closeModal={() => setModalPlacement(null)}
      />
    </React.Fragment>
  )
}

export default Starts
