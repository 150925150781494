const getCallStatusIndicatorProps = (expectationCall, firstDayDetailsCall) => {
  let props = { emptyCircle: true }

  if (expectationCall && !firstDayDetailsCall) {
    // blue circle
    props = { circle: '#1100FF' }
  } else if (!expectationCall && firstDayDetailsCall) {
    // green check
    props = { check: true }
  } else if (expectationCall && firstDayDetailsCall) {
    // green check blue circle
    props = { circle: true, check: true }
  }

  return props
}

const callStatusCycle = (
  e,
  bhId,
  expectationCall,
  firstDayDetailsCall,
  handleFieldChange
) => {
  if (
    (expectationCall && firstDayDetailsCall) ||
    (!expectationCall && !firstDayDetailsCall)
  ) {
    firstDayDetailsCall = false
  } else {
    firstDayDetailsCall = true
  }

  handleFieldChange(e, bhId, 'expectationCall', !expectationCall)
  handleFieldChange(e, bhId, 'firstDayDetailsCall', firstDayDetailsCall)
}

export { getCallStatusIndicatorProps, callStatusCycle }
