import React from 'react'

import Skeleton from 'components/shared/Skeleton'

const SkeletonHeaderFilter = ({ topHidden = false }) => {
  return (
    <div
      style={{
        display: 'inline-flex',
        marginRight: 20,
        flexDirection: 'column'
      }}
    >
      <div
        style={{
          marginBottom: 6,
          visibility: topHidden ? 'hidden' : undefined
        }}
      >
        <Skeleton.Input style={{ width: 200, height: 20 }} active />
      </div>
      <Skeleton.Input style={{ width: 200, height: 40 }} active />
    </div>
  )
}

export default SkeletonHeaderFilter
