import { useEffect } from 'react'

export const useWindowEvent = (event, callback) => {
  useEffect(() => {
    window.addEventListener(event, callback)
    return () => window.removeEventListener(event, callback)
  }, [event, callback])
}

export const useMouseDown = (callback) => {
  return useWindowEvent('mousedown', callback)
}

export const useKeyUpEvent = (callback) => {
  return useWindowEvent('keyup', callback)
}
