import React from 'react'

import { Menu } from 'components/shared'
import { returnGradient } from 'theme/returnGradient'
import debounce from 'helpers/debounce'

const AddCandidateMenu = ({
  jobSubmissions = [],
  addSubmissionToSlotFxn,
  slotId,
  activeUser,
  onClose,
  anchorEl
}) => {
  const renderCandidates = () => {
    if (jobSubmissions.length > 0) {
      return jobSubmissions.map((item, index) => {
        const onClick = debounce(() => {
          addSubmissionToSlotFxn(item.bhId, slotId) // item.candidate is the submission's candidate's bhId
          onClose()
        }, 250)
        return (
          <li
            style={{ cursor: 'pointer' }}
            onClick={onClick}
            className={'list-item'}
            key={`popover-item-${index}`}
          >
            <div className={'item-label'}>
              {item.Candidate.firstName} {item.Candidate.lastName}
            </div>
          </li>
        )
      })
    } else {
      return (
        <li className={'list-item'} key={'popover-item'}>
          <div className={'item-label'}>
            <em>No available candidates</em>
          </div>
        </li>
      )
    }
  }

  const menuStyle = {
    '& .MuiMenu-paper': {
      borderRadius: '1rem',
      ...returnGradient(`#${activeUser.color}`, 90)
    },
    '& .MuiList-root': {
      paddingBottom: 0
    }
  }

  return (
    <Menu sx={menuStyle} onClose={onClose} anchorEl={anchorEl}>
      <div className='popover wide'>
        <div className='popover-block' key={'popoverblock'}>
          <h2>Add Candidate</h2>
          <ul className='list-items'>{renderCandidates()}</ul>
        </div>
      </div>
    </Menu>
  )
}

export default AddCandidateMenu
