import {
  setJobOrdersActivity,
  slotSubmissionAdded,
  slotSubmissionUpdated,
  interviewSlotAdded,
  jobOrderActivityChange,
  slotSubmissionRemoved,
  interviewSlotRemoved,
  updateCandidateSlot,
  interviewSlotUpdated
} from '..'
import { processUpdateFields } from './handleJobsData'
import { setBoardLoadingObj } from '..'

export const handleActivityBoardData = result => dispatch => {
  switch (result.event) {
    case 'list':
      dispatch(setJobOrdersActivity(result.jobOrders))
      dispatch(setBoardLoadingObj('activity'))
      break
    case 'slot_submission':
      dispatch(
        slotSubmissionAdded(
          result.jobOrderId,
          result.slotId,
          result.JobSubmission,
          result.event // for error logging
        )
      )
      break
    case 'slot_submission_change': {
      const payload = {}
      result.updatedFields.forEach(field => {
        payload[field] = result.interviewSlotSubmission[field]
      })
      dispatch(
        slotSubmissionUpdated(
          result.jobOrderId,
          result.interviewSlotSubmission,
          payload,
          result.event
        )
      )
      break
    }
    case 'add_interview_slot':
      dispatch(
        interviewSlotAdded(
          result.jobOrderId,
          result.date,
          result.faceToFace,
          result.id,
          result.timezone,
          result.event
        )
      )
      break
    case 'job_order_change': {
      const payload = {}
      const updatedFields = processUpdateFields(
        result.updatedFields,
        result.jobOrder
      )
      updatedFields.forEach(field => {
        payload[field] = result.jobOrder[field]
      })
      dispatch(
        jobOrderActivityChange(result.jobOrder.bhId, payload, result.event)
      )
      break
    }
    case 'slot_submission_removed':
      dispatch(
        slotSubmissionRemoved(
          result.jobOrderId,
          result.interviewSlotId,
          result.jobSubmissionId,
          result.event
        )
      )
      break
    case 'slot_removed':
      dispatch(
        interviewSlotRemoved(result.slot.jobOrder, result.slot.id, result.event)
      )
      break
    case 'candidate_change':
      return dispatch(
        updateCandidateSlot(result.instances, result.Candidate, result.event)
      )
    case 'slot_change': {
      const payload = {}
      result.updatedFields.forEach(field => {
        payload[field] = result.slot[field]
      })
      dispatch(
        interviewSlotUpdated(
          result.slot.jobOrder,
          result.slot.id,
          payload,
          result.event
        )
      )
      break
    }
    default:
      return false
  }
}
