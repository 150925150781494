import { createSelector } from 'reselect'
import { isInRange } from '../../helpers/dateTime'
import filterProgram from '../../helpers/filterProgram'
import { selectUserCategoryName } from './auth'
import { selectCategories } from './department'

export const selectPlacementsStarts = state => state.placementsStarts.data
export const selectPlacementsStartsFilters = state =>
  state.placementsStarts.meta.filters
export const selectStartsCardViewMode = state =>
  state.placementsStarts.meta.viewMode

export const selectVisiblePlacementsStarts = createSelector(
  [
    selectPlacementsStarts,
    selectPlacementsStartsFilters,
    selectCategories,
    selectUserCategoryName
  ],
  (data, filters, categories, selectedCategory) => {
    let placements = data
    placements = placements.filter(pl => !pl.approved)
    if (categories.length > 1 && selectedCategory) {
      placements = filterProgram(placements, selectedCategory)
    }
    if (filters.status) {
      placements = placements.filter(pl => pl.status === filters.status)
    }
    if (filters.company) {
      placements = placements.filter(pl => pl.companyId === filters.company)
    }
    if (filters.owner.length) {
      placements = placements.filter(
        pl =>
          filters.owner.includes(pl.ownerId) ||
          filters.owner.includes(pl.recruiterName)
      )
    }
    if (filters.startDateRange && filters.startDateRange.length) {
      placements = placements.filter(pl =>
        isInRange(pl.dateBegin, filters.startDateRange)
      )
    }
    if (filters.program) {
      placements = placements.filter(pl => pl.program === filters.program)
    }
    return placements
  }
)
