import React, { Component } from 'react'
import { connect } from 'react-redux'
import Icon from '@mui/material/Icon'

import * as actions from '../../../redux/actions'
import FilterHeader from './subComponents/FilterHeader'
import { push } from 'connected-react-router'

import logoLarge from '../../../theme/assets/alku-everywhere-logo_2021.png'
import Logo from '../../../components/shared/Logo'
import PlainHeader from './subComponents/PlainHeader'
import MobileHeader from './subComponents/MobileHeader'
import { noHeaderBoards } from '../helpers/routesConfig'

class Header extends Component {
  componentDidUpdate = prevProps => {
    const {
      users,
      board,
      activeDepartment,
      headerless,
      auth: { activeUser },
      push
    } = this.props
    if (
      !headerless &&
      board &&
      board !== 'starts-management' &&
      users.length !== prevProps.users.length
    ) {
      const userId = activeUser.bhId
      if (!userId || !users.find(({ bhId }) => bhId === userId)) {
        push(`/d/${activeDepartment}/header?board=${board}`)
      }
    }
  }

  handleActivityChange = value => {
    const { toggleAMSelectedState } = this.props
    toggleAMSelectedState(value)
  }

  shouldRenderFilters = () => {
    const {
      board,
      activeDepartment,
      department: { categories }
    } = this.props

    if (categories.length > 1) return true
    if (!board) return false
    if (board === 'starts-management' && activeDepartment) return false
    const boardMatch = /jobs|assigned-accounts|starts|starts-management|activity/
    if (board && board.match(boardMatch)) return true
    return false
  }

  returnHeaderVariant = () => {
    const { board } = this.props
    const boardMatch = /starts-management/
    if (board && board.match(boardMatch)) return PlainHeader.VARIANTS.ICON
    return PlainHeader.VARIANTS.SIMPLE
  }

  shouldRenderPlainHeader = () => {
    const { board } = this.props
    const boardMatch = /starts-management/
    if (board && board.match(boardMatch)) return true
    return false
  }

  shouldReturnNoHeader = () => {
    const { path, boardLoadingObj, board } = this.props
    if (noHeaderBoards.includes(path) || !boardLoadingObj[board]) {
      return true
    }
    return false
  }

  render() {
    const {
      activeDepartment,
      board,
      headerless,
      auth: { activeUser },
      department: { categories },
      updateUserWSFxn
    } = this.props

    const shouldReturnNoHeader = this.shouldReturnNoHeader()
    if (shouldReturnNoHeader) {
      return null
    }
    const shouldRenderFilters = this.shouldRenderFilters()
    const shouldRenderPlainHeader = this.shouldRenderPlainHeader()
    const headerVariant = this.returnHeaderVariant()
    if (headerless || !board) return <HeaderlessHeader board={board} />
    if (!board) return <LogoHeader />
    return (
      <>
        <MobileHeader board={board} />
        <div className='main-header-container'>
          <div className='main-header'>
            {shouldRenderPlainHeader && (
              <PlainHeader board={board} variant={headerVariant} />
            )}
            {shouldRenderFilters ? (
              <FilterHeader
                board={board}
                categories={categories}
                department={activeDepartment}
                updateUserWSFxn={updateUserWSFxn}
                activeUser={activeUser}
              />
            ) : null}
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = ({
  auth,
  department,
  users: { users },
  UI: { boardLoadingObj }
}) => {
  return {
    auth,
    department,
    users,
    boardLoadingObj
  }
}

export default connect(
  mapStateToProps,
  { ...actions, push }
)(Header)

export const HeaderlessHeader = ({ board }) => {
  function renderTitle(name) {
    switch (name) {
      case 'deals':
        return (
          <div>
            <Icon baseClassName='fas' className='fa-check-circle' /> Deals
          </div>
        )
      case 'jobs':
        return (
          <div>
            <Icon baseClassName='fas' className='fa-briefcase' /> Jobs
          </div>
        )
      case 'starts':
        return (
          <div>
            <Icon baseClassName='fas' className='fa-clock' /> Starts
          </div>
        )
      case 'activity':
        return (
          <div>
            <Icon baseClassName='fas' className='fa-bolt' /> Activity
          </div>
        )
      case 'dashboard':
        return (
          <div>
            <Icon baseClassName='fas' className='fa-dollar-sign' /> Where We At
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div className='headerless-header'>
      <img src={logoLarge} alt='alku logo' />
      {renderTitle(board)}
    </div>
  )
}

export const LogoHeader = () => (
  <div className='logo-container'>
    <Logo src={logoLarge} className='logo-image' />
  </div>
)
