import React from 'react'
import { Tooltip } from 'antd'

import MultiDivisionTooltip from './MultiDivisionToolTip'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const JobCardTopTabs = props => {
  const {
    job: { exclusive, isRTC, otherDivisions, activePlacementCount },
    jobColor,
    department
  } = props
  return (
    <div className='tabs-container'>
      {exclusive ? (
        <div className='toptab' style={{ background: jobColor() }}>
          <p>Exclusive</p>
        </div>
      ) : null}
      {isRTC ? (
        <div className='toptab rtc'>
          <svg
            width='19px'
            height='18px'
            viewBox='0 0 19 18'
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
          >
            <g
              id='Page-1'
              stroke='none'
              strokeWidth='1'
              fill='none'
              fillRule='evenodd'
            >
              <g
                id='Jobs-Job-ID-|-Exclusive'
                transform='translate(-258.000000, -389.000000)'
                fill='#FFFFFF'
                fillRule='nonzero'
              >
                <g
                  id='Exclusive-Tag'
                  transform='translate(229.000000, 381.000000)'
                >
                  <g transform='translate(29.000000, 7.000000)' id='Star'>
                    <path d='M18.8731527,7.93532882 C18.8731527,7.65553381 18.661489,7.48165013 18.2378436,7.41351887 L12.544335,6.5856943 L9.9924122,1.42496015 C9.84883998,1.11493314 9.66343556,0.959999084 9.43663594,0.959999084 C9.20987605,0.959999084 9.02459082,1.11493314 8.88089941,1.42496015 L6.32885746,6.5856943 L0.635070713,7.41351887 C0.211743207,7.48165013 0,7.65553381 0,7.93532882 C0,8.09419581 0.0945494994,8.27566729 0.283608772,8.4798227 L4.41216431,12.4949188 L3.43675512,18.1658627 C3.42161926,18.2717741 3.41411092,18.3476123 3.41411092,18.3927815 C3.41411092,18.5515691 3.45375814,18.6857261 3.53313205,18.7954512 C3.61246623,18.9053352 3.73148737,18.9599991 3.89035436,18.9599991 C4.02653742,18.9599991 4.17769744,18.9148298 4.34403305,18.8240147 L9.43651676,16.1469926 L14.5292388,18.8237366 C14.6881456,18.9145518 14.8393453,18.9599991 14.9827586,18.9599991 C15.2931034,18.9599991 15.448117,18.7712974 15.448117,18.3930596 C15.448117,18.294895 15.4443032,18.2192951 15.4367154,18.1659819 L14.4613857,12.4952367 L18.5784205,8.48010078 C18.7749881,8.28321536 18.8731527,8.10166443 18.8731527,7.93532882 Z'></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <p>TC</p>
        </div>
      ) : null}
      {!department?.isGovernment && otherDivisions?.length ? (
        <div className='toptab multi-division'>
          <p>Multi-Division</p>
          <MultiDivisionTooltip otherDivisions={otherDivisions}>
            <InfoOutlinedIcon fontSize='16px' />
          </MultiDivisionTooltip>
        </div>
      ) : null}
      {typeof activePlacementCount === 'number' && activePlacementCount > 0 ? (
        <div className='toptab active-placement'>
          <p>Active Placements</p>
          <Tooltip
            overlayClassName='c-tooltip--toptab'
            title={`${activePlacementCount} Active Placement${
              activePlacementCount > 1 ? 's' : ''
            }`}
          >
            <InfoOutlinedIcon fontSize='16px' />
          </Tooltip>
        </div>
      ) : null}
    </div>
  )
}

export default JobCardTopTabs
