import React, { useCallback } from 'react'
import formatPlacement from '../../helpers/formatPlacement'
import { consultantCardConfig } from '../../../startsBoard/helpers'
import {
  DocumentField,
  ConsultantCardProgressBar,
  ConsultantCardField
} from './subcomponents'
import { useDispatch } from 'react-redux'
import {
  cycleClientPaperWork,
  cycleConsultantStatus,
  updateStartsPlacementIsClearedFlag
} from '../../../../../redux/actions'
import CandidateApproveBtn from '../../../../StartsMngmt/components/CandidateApproveBtn'
import toast from '../../../../../helpers/toastSettings'
import { AMRTooltip } from 'components/shared'
import { StatusIndicator } from 'components/shared/Icons'
import { getCallStatusIndicatorProps } from '../../helpers/calls'

const ConsultantCard = ({
  placement,
  setModalPlacement,
  type = 'starts',
  showProgress = false,
  onClick,
  className = '',
  viewMode,
  allowClientPaperworkEdit,
  hideApproveBtn = false
}) => {
  const dispatch = useDispatch()
  const onClientPaperWorkClick = useCallback(
    paperwork => {
      if (allowClientPaperworkEdit && !placement.approved) {
        dispatch(cycleClientPaperWork(placement, paperwork))
      } else if (!placement.approved) {
        toast.error(
          `${placement.candidateName} has been approved and can no longer be updated.`
        )
      }
      return null
    },
    [allowClientPaperworkEdit, dispatch, placement]
  )
  const onConsultantStatusClick = useCallback(
    pl => {
      dispatch(cycleConsultantStatus(pl))
    },
    [dispatch]
  )
  const {
    id,
    companyName,
    spread,
    margin,
    amInitials,
    rcInitials,
    color,
    startDate,
    employeeType,
    progressRemaining,
    submissionState,
    program,
    bullhornId,
    expectationCall,
    firstDayDetailsCall,
    candidateName,
    isCleared,
    contactName,
    isGovernment,
    bgDoc,
    drugDoc,
    i9Doc,
    onboardingDoc,
    contractDoc,
    paperworkJsonEmpty,
    dateAdded,
    clientPaperwork,
    coiDoc,
    securityObDoc,
    bcDoc,
    recruiterName,
    accountManagerName
  } = formatPlacement(
    placement,
    onClientPaperWorkClick,
    allowClientPaperworkEdit
  )
  const toggleIsCleared = useCallback(
    value => {
      dispatch(updateStartsPlacementIsClearedFlag(id, value))
    },
    [dispatch, id]
  )
  if (!paperworkJsonEmpty) onboardingDoc.onClick = setModalPlacement
  const borderStyle = { borderColor: `#${color}` }
  return (
    <li
      id={bullhornId}
      className={`c-consultant-card c-consultant-card--${type} ${
        showProgress ? 'c-consultant-card--w-progress' : ''
      } ${className}`}
      style={borderStyle}
      onClick={onClick}
    >
      <div
        className={`c-consultant-card__main c-consultant-card__main--${type} ${
          !isGovernment ? 'c-consultant-card__main--top' : ''
        }`}
      >
        <div className='c-consultant-card__section c-consultant-card__info-block c-consultant-card__info-block--top'>
          <div className='c-consultant-card__candidate-block-wrapper'>
            <div
              className={`c-consultant-card__submission-status ${
                submissionState.toLowerCase() === 'approved' ? 'approved' : ''
              }`}
            >
              <span>{submissionState}</span>
            </div>
            <div className='c-consultant-card__candidate-block'>
              <button
                className='status-icon c-btn c-btn--bare'
                onClick={() => onConsultantStatusClick(placement)}
              >
                <StatusIndicator
                  className='status--default'
                  {...getCallStatusIndicatorProps(
                    expectationCall,
                    firstDayDetailsCall
                  )}
                />
              </button>
              <div>
                <ConsultantCardField
                  header='Consultant'
                  content={candidateName}
                />
                {type === 'starts' && viewMode !== 'consultant' && (
                  <ConsultantCardField header='Contact' content={contactName} />
                )}
                <ConsultantCardField header='Company' content={companyName} />
                {isGovernment && (
                  <ConsultantCardField header='Program' content={program} />
                )}
              </div>
            </div>
          </div>
          <div>
            <div className='c-consultant-card__sub-date'>
              {dateAdded}
              {viewMode !== 'client' && (
                <ConsultantCardField
                  header='Emp. Type'
                  content={employeeType}
                  modifier='sub-date'
                />
              )}
            </div>
            {!hideApproveBtn && (
              <CandidateApproveBtn
                placement={placement}
                className='c-approve-btn--card'
              />
            )}
          </div>
        </div>
        {type === 'starts' && viewMode !== 'client' && (
          <ul className='c-consultant-card__section' style={borderStyle}>
            {!isGovernment ? (
              viewMode === 'consultant' ? (
                <>
                  {/* Consultant view documents */}
                  <DocumentField data={bgDoc} />
                  <DocumentField data={drugDoc} />
                  <DocumentField
                    data={employeeType === 'W2' ? i9Doc : coiDoc}
                  />
                  <DocumentField data={onboardingDoc} />
                </>
              ) : (
                <>
                  {/* Default non-gov documents */}
                  <DocumentField
                    data={[bgDoc, employeeType === 'W2' ? i9Doc : coiDoc]}
                  />
                  <DocumentField data={[drugDoc, onboardingDoc]} />
                </>
              )
            ) : (
              <>
                {/* Default "Government" card documents */}
                <DocumentField data={i9Doc} />
                <DocumentField data={bcDoc} />
                <DocumentField data={securityObDoc} />
                <DocumentField data={contractDoc} />
                <DocumentField data={onboardingDoc} />
              </>
            )}
          </ul>
        )}
        {viewMode !== 'client' && (
          <>
            <div
              className='c-consultant-card__section c-consultant-card__info-block c-consultant-card__section--w-divisor-before'
              style={borderStyle}
            >
              <div className='c-consultant-card__info-col'>
                <ConsultantCardField
                  header='Placement ID'
                  content={bullhornId}
                />
                <ConsultantCardField
                  header='Spread'
                  content={spread ? `$${spread.toFixed(2)}` : 'N/A'}
                />
              </div>
              <div className='c-consultant-card__info-col'>
                <ConsultantCardField
                  header='Margin'
                  content={margin ? `${margin}%` : 'N/A'}
                />
                <ConsultantCardField
                  header='AM/R'
                  content={
                    <AMRTooltip
                      topText={accountManagerName}
                      bottomText={recruiterName}
                    >
                      {amInitials}
                      {amInitials === 'N/A' || rcInitials === 'N/A' ? '|' : '/'}
                      {rcInitials}
                    </AMRTooltip>
                  }
                />
              </div>
            </div>
            <div className='c-consultant-card__section'>
              <ConsultantCardField
                header='Start'
                content={startDate}
                modifier='date'
                borderStyle={
                  !isCleared
                    ? consultantCardConfig.approvedStyle
                    : { borderColor: '#000' }
                }
                onClick={() => toggleIsCleared(!isCleared)}
              />
            </div>
          </>
        )}
      </div>
      {type === 'starts' && (!isGovernment && viewMode !== 'consultant') && (
        <div
          className={`c-consultant-card__main ${
            viewMode !== 'client' ? 'c-consultant-card__main--bottom' : ''
          }`}
        >
          {viewMode !== 'client' && (
            <div className='c-consultant-card__section-header'>
              CLIENT PAPERWORK
            </div>
          )}
          <ul className='c-consultant-card__section' style={borderStyle}>
            {clientPaperwork.map(doc => (
              <DocumentField key={doc.label} data={doc} />
            ))}
          </ul>
        </div>
      )}
      {showProgress && (
        <ConsultantCardProgressBar progressRemaining={progressRemaining} />
      )}
    </li>
  )
}

export default ConsultantCard
