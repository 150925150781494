import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setJobOrdersSort } from '../../../../redux/actions'
import { selectJobsOrderSortObj } from '../../../../redux/selectors/jobOrders'
import { SortDropdown } from 'components/shared'
import JobsBoardToggles from '../JobsBoardToggles'

export const START_DATE = 'START_DATE'
export const COMPANY = 'COMAPNY'
export const OWNER = 'OWNER'
export const CHECKED_UNCHECKED = 'CHECKED_UNCHECKED'
export const ACTIVE_SUBS = 'ACTIVE_SUBS'
export const PENDING = 'PENDING'
const options = [
  { value: START_DATE, label: 'Start Date' },
  { value: COMPANY, label: 'Company' },
  { value: OWNER, label: 'Owner' },
  { value: CHECKED_UNCHECKED, label: 'Checked/Unchecked' },
  { value: ACTIVE_SUBS, label: 'Active Subs' },
  { value: PENDING, label: 'Pending' }
]

const JobsBoardSort = () => {
  const dispatch = useDispatch()
  const sortObj = useSelector(selectJobsOrderSortObj)
  const onChange = useCallback(
    value => {
      dispatch(setJobOrdersSort(value))
    },
    [dispatch]
  )
  return (
    <>
      <JobsBoardToggles />
      <div className='filter-header-input sort-dropdown'>
        <SortDropdown
          options={options}
          onChange={onChange}
          selected={sortObj.value}
          sortDirection={sortObj.direction}
        />
      </div>
    </>
  )
}

export default JobsBoardSort
