import React from 'react'

import SkeletonHeaderFilter from '../shared/SkeletonHeaderFilter'
import SkeletonFullWidthCard from '../shared/SkeletonFullWidthCard'

const AssignedAccountSkeleton = () => {
  return (
    <React.Fragment>
      <div
        style={{
          marginBottom: 32,
          whiteSpace: 'nowrap'
        }}
      >
        <div>
          <SkeletonHeaderFilter />
          <SkeletonHeaderFilter />
          <SkeletonHeaderFilter />
          <SkeletonHeaderFilter topHidden />
        </div>
      </div>
      <SkeletonFullWidthCard height={169} marginBottom={12} />
      <SkeletonFullWidthCard height={169} marginBottom={12} />
      <SkeletonFullWidthCard height={169} marginBottom={12} />
      <SkeletonFullWidthCard height={169} marginBottom={12} />
    </React.Fragment>
  )
}

export default AssignedAccountSkeleton
