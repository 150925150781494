import {
  SET_DEPARTMENT_SETTINGS,
  SET_DEPARTMENT_ERROR,
  SET_SELECTED_DEPARTMENT,
  SET_SELECTED_BRAND,
  CLEAR_DEPARTMENTS,
  CLEAR_PREVIOUS_DPEARTMENT_DATA,
  SET_ACTIVE_DEPARTMENTS,
  SET_DEPARTMENT_CATEGORIES,
  UPDATE_CATEGORIES_GOAL,
  UPDATE_DEPARTMENT_CATEGORY_GOAL,
  SET_DEPARTMENT_FETCH_ERROR
} from '../actionTypes'
import {
  getDepartmentSettings,
  getDepartments,
  getDepartmentCategoriesAssignments,
  getDepartmentCategoryAssignmentDealGoals,
  updateDivisionFiscalGoal
} from '../../requests'
import formatDepartments from '../../helpers/formatDepartments.js'
import { getDepartmentDealsGoalsQuery } from './query'
import { sendGraphQLQuery } from '../../network/apollo'
import logger from '../../requests/logger'
import { updateSelectedCategoryAssignmentName } from './auth'
import dayjs from 'dayjs'

const setDepartmentBrand = department => async (dispatch, getState) => {
  const {
    department: { departmentList }
  } = getState()
  if (typeof department === 'string') {
    let brand
    if (!departmentList?.length) {
      const response = await getDepartments()
      if (Array.isArray(response?.data)) {
        const departmentData = response.data.find(
          dep => dep.displayName.toLowerCase() === department.toLowerCase()
        )
        brand = departmentData?.brand
      } else {
        console.warn(
          'Could not set brand. Departments payload not found in response'
        )
      }
    } else {
      const departmentData = departmentList.find(
        dep => dep.displayName.toLowerCase() === department.toLowerCase()
      )
      brand = departmentData?.brand
    }
    if (typeof brand === 'string') {
      dispatch({
        type: SET_SELECTED_BRAND,
        payload: brand
      })
    } else {
      console.warn(`Could not find ${department}'s brand.`)
    }
  } else {
    if (department) {
      console.warn(`${department} is not the right type to set the brand.`)
    }
  }
}

export const setDepartmentSettings = department => async dispatch => {
  dispatch({ type: SET_SELECTED_DEPARTMENT, payload: department })
  dispatch(setDepartmentBrand(department))
  try {
    const settings = await getDepartmentSettings(department)
    dispatch({
      type: SET_DEPARTMENT_SETTINGS,
      payload: settings.data
    })
    const encodedDep = encodeURIComponent(department)
    // fetches category assignments related to department
    const categoriesAssignmentsResponse = await getDepartmentCategoriesAssignments(
      encodedDep
    )
    const graphQlQuery = getDepartmentDealsGoalsQuery(encodedDep)
    // graphQl query returns goals and deals
    const dealGoalsResponse = await sendGraphQLQuery(graphQlQuery)
    const dealGoals =
      dealGoalsResponse?.data?.divisionDashboardQuery?.divisionDealsGoals || []
    const catAssignIds =
      categoriesAssignmentsResponse &&
      categoriesAssignmentsResponse.data.map(cat => {
        return cat.Id
      })
    // fiscal month response includes goals and ids to determine if a goal must be posted or patched
    const now = dayjs()
    const isoString = now.toISOString()
    const fiscalMonthsResponse = await getDepartmentCategoryAssignmentDealGoals(
      catAssignIds,
      isoString
    )
    if (
      categoriesAssignmentsResponse.data &&
      categoriesAssignmentsResponse.data.length
    ) {
      let categories = categoriesAssignmentsResponse.data.map(category => {
        const dg = dealGoals.find(
          dg => dg.divisionName.toLowerCase() === category.name.toLowerCase()
        )
        const divisionGoal = fiscalMonthsResponse.data.find(
          div => div.departmentCategoriesDealsAssignmentId === category.Id
        )
        const { dealCount, dealGoal } = dg || {}

        return {
          ...category,
          count: dealCount || 0,
          goal: dealGoal || 0,
          divisionGoalId: divisionGoal && divisionGoal.id,
          fiscalMonth: divisionGoal && divisionGoal.fiscalMonth
        }
      })
      dispatch({
        type: SET_DEPARTMENT_CATEGORIES,
        payload: categories
      })
      dispatch(updateSelectedCategoryAssignmentName())
    }
  } catch (error) {
    logger(error)
  }
}

export const setDepartmentError = error => {
  return {
    type: SET_DEPARTMENT_ERROR,
    payload: error,
    error: true
  }
}

export const clearDepartments = () => {
  return { type: CLEAR_DEPARTMENTS }
}

export const selectDepartment = department => (dispatch, getState) => {
  const { selectedDepartment } = getState().department
  if (selectedDepartment !== department) {
    dispatch({ type: SET_SELECTED_DEPARTMENT, payload: department })
    dispatch({ type: CLEAR_PREVIOUS_DPEARTMENT_DATA })
  }
}

export const fetchActiveDepartments = () => dispatch => {
  dispatch({
    type: SET_DEPARTMENT_FETCH_ERROR,
    payload: false
  })
  getDepartments()
    .then(({ data }) => {
      const departments = data && data.length && formatDepartments(data)
      if (data) {
        dispatch({
          type: SET_ACTIVE_DEPARTMENTS,
          payload: departments
        })
      } else {
        console.warn('Departments payload not found in response')
      }
    })
    .catch(error => {
      logger(error)
      if (error) {
        dispatch({
          type: SET_DEPARTMENT_FETCH_ERROR,
          payload: true
        })
      }
    })
}

// called when ws receives goal or deals change event
export const getCategoryDealsGoals = () => async (dispatch, getState) => {
  try {
    const {
      department: { selectedDepartment },
      deals: { customFiscalDate, goLive }
    } = getState()
    let err
    if (selectedDepartment) {
      const queryStr = getDepartmentDealsGoalsQuery(
        selectedDepartment,
        customFiscalDate,
        !goLive
      )
      const res = await sendGraphQLQuery(queryStr)
      const sendouts = res.data.divisionDashboardQuery
      if (sendouts) {
        const dealsGoals = sendouts.divisionDealsGoals
        dispatch({
          type: UPDATE_CATEGORIES_GOAL,
          payload: dealsGoals
        })
      } else {
        err = `Could not retrieve deals for ${selectedDepartment}`
      }
    } else {
      err =
        'No department provided or found in state while fetching dealsGoal and dealsCount'
    }
    if (err) throw new Error(err)
  } catch (e) {
    logger(e)
  }
}

export const updateDepartmentCategoryGoal = (
  departmentCategoryId,
  goal,
  divisionGoalId,
  fiscalMonth
) => async dispatch => {
  try {
    const results = await updateDivisionFiscalGoal(
      departmentCategoryId,
      goal,
      divisionGoalId,
      fiscalMonth
    )
    if (results && results.data) {
      dispatch({
        type: UPDATE_DEPARTMENT_CATEGORY_GOAL,
        payload: results.data
      })
    }
  } catch (error) {
    logger(error)
  }
}
