import React, { useState, useEffect } from 'react'

import { Layout } from 'antd'
import calculateOverflowDirection from 'helpers/calculateOverflowDirection'
import { useSelector } from 'react-redux'
import { selectBoardLoadingObj } from 'redux/selectors'

const ContentLayout = props => {
  const [overflow, setOverflow] = useState([])
  const boardLoading = !useSelector(selectBoardLoadingObj)[props.board]

  useEffect(() => {
    const onScroll = () => {
      const _overflow = calculateOverflowDirection()
      setOverflow(oldOverflow => {
        if (oldOverflow.toString() !== _overflow.toString()) {
          return _overflow
        }
        return oldOverflow
      })
    }
    setOverflow(calculateOverflowDirection())
    window.addEventListener('scroll', onScroll)
    return () => {
      if (onScroll) {
        window.removeEventListener('scroll', onScroll)
      }
    }
  }, [props.board])
  return (
    <Layout
      id='content-layout'
      className={`c-content-layout ${boardLoading ? '' : overflow.join(' ')}`}
    >
      {props.children}
    </Layout>
  )
}

export default ContentLayout
