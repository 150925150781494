import candidateIsPending from 'components/boards/jobsBoard/JobCard/Candidates/candidateIsPending'
import { isOpenInterview } from '../../../../components/boards/activityBoard/helpers/statuses'
import { isValidActivityDate, sortDate } from 'helpers/dateTime'

const jobHasOpenTime = job => {
  if (job?.openInterviewSlots?.length) return true
  let hasOpenTime = false

  if (job?.JobSubmissions) {
    let slotSubmissionExists = false
    const hasOpenTimeJob = job.JobSubmissions.find(js => {
      if (js?.interviewSlots?.length) {
        return js.interviewSlots.find(slot => {
          if (!isValidActivityDate(slot.date)) {
            return false
          }
          slotSubmissionExists = true
          return (
            slot &&
            slot.InterviewSlotSubmission &&
            isOpenInterview(slot.InterviewSlotSubmission)
          )
        })
      }
      return false
    })

    if (slotSubmissionExists && hasOpenTimeJob) hasOpenTime = true
  }

  return hasOpenTime
}

const jobHasPendingCandidates = job => {
  const submissions = job.JobSubmissions.filter(
    submission => !submission.hidden
  )
  const submissionWithPendingCadidate = submissions.find(
    ({ interviewSlots }) => {
      return candidateIsPending(interviewSlots)
    }
  )
  return !!submissionWithPendingCadidate
}

const getLastSubmittalDate = jobSubmissions => {
  if (Array.isArray(jobSubmissions) && jobSubmissions.length) {
    if (jobSubmissions.length > 1) {
      const sorted = jobSubmissions.slice().sort((a, b) => {
        return sortDate(b.createdAt, a.createdAt)
      })
      return sorted[0].createdAt
    }
    return jobSubmissions[0].createdAt
  }

  return null
}

/**
 * adds extra state
 * @param {Array|Object} data
 * @returns {Object[]}
 */
const transformJobsData = data => {
  if (!data) return null
  let copy
  if (Array.isArray(data)) {
    copy = data.slice()
  } else {
    copy = [data]
  }
  return copy.map(datum => {
    const hasOpenTime = jobHasOpenTime(datum)
    const hasPendingCandidates = jobHasPendingCandidates(datum)
    const lastSubmittalDate = getLastSubmittalDate(datum?.JobSubmissions)

    return {
      ...datum,
      hasOpenTime,
      hasPendingCandidates,
      lastSubmittalDate
    }
  })
}

export default transformJobsData
