import { get } from '../../helpers/axios'
import queryString from 'query-string'

/**
 * 
 * @param {Object} options
 * @param {String} options.id
 * @param {String} options.departmentRoute
 * @param {String} options.approve
 * @returns {import("axios").AxiosResponse}
 */
export default async function getStartsData(options = {}) {
  const { id, ...filters } = options
  const qs = queryString.stringify(filters)
  const url = `/starts/${id || ''}${qs ? `?${qs}` : ''}`
  const res = await get(url)
  return res
}
