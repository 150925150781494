import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input } from 'antd'

import { formConfig } from './helpers/options'
import { SmartInput } from 'components/layout/header/subComponents/SmartInput'
import Warning from 'components/shared/Icons/Warning'
import { addPi, editPi } from 'requests'
import {
  addPi as addPiAction,
  editPi as editPiAction
} from 'redux/actions/piDB'
import toastSettings from 'helpers/toastSettings'
import { selectPiDetailsModal } from 'redux/selectors/piDb'

const ErrorBanner = ({ visible = false }) => {
  if (!visible) return null

  return (
    <div className='c-pidb__error-banner'>
      <Warning />
      An error occured while trying to save your changes. Please review the
      fields with errors below.
    </div>
  )
}

const ErrorInput = ({ visible = false }) => {
  if (!visible) return null

  return (
    <span className='c-pidb__input-error'>
      <Warning width='18' height='16' /> Cannot be blank
    </span>
  )
}

const dataFields = formConfig().map(v => v.dataIndex)
const defaultFormData = {
  lastReportedIp: null,
  additionalUrlArguments: null,
  isRegistered: false
}

const DetailsModalForm = ({ data, closeModal, mode, allDepartments }) => {
  const dispatch = useDispatch()
  const { open } = useSelector(selectPiDetailsModal)
  const [formData, setFormData] = useState(defaultFormData)
  const [isProcessing, setIsProcessing] = useState(false)
  const [attemptCount, setAttemptCount] = useState(0)
  const [errors, setErrors] = useState(false)
  const resetForm = useCallback(() => {
    setFormData(defaultFormData)
    setErrors(false)
    setAttemptCount(0)
  }, [setFormData, setErrors, setAttemptCount])
  const onInputChange = useCallback(
    (value, field) => {
      setFormData(v => ({
        ...v,
        [field]: value
      }))
    },
    [setFormData]
  )

  const onSubmit = useCallback(async () => {
    const requestMethod = mode === 'edit' ? editPi : addPi
    const dispatchMethod = mode === 'edit' ? editPiAction : addPiAction
    let error = false
    dataFields.forEach(field => {
      if (!formData[field]) {
        error = true
      }
    })
    if (!error) {
      setIsProcessing(true)
      // convert types for form payload
      const _formData = Object.assign({}, formData)
      _formData.departmentBullhornId = Number(formData.departmentBullhornId)
      _formData.displayRotation = Number(formData.displayRotation)
      if (mode === 'edit') {
        _formData.id = data.id
        _formData.isRegistered = data.isRegistered
      }
      const response = await requestMethod(_formData)
      if (response) {
        dispatch(dispatchMethod(response))
        closeModal()
        toastSettings.success('Device saved.')
      } else {
        toastSettings.error('Error saving your device.')
      }
      setIsProcessing(false)
      resetForm()
    } else {
      setErrors(error)
      setAttemptCount(v => v + 1)
    }
  }, [closeModal, dispatch, formData, mode, data, resetForm])

  useEffect(() => {
    // open boolean to reset form when modal is closed
    if (data && open) {
      // convert types for form
      const _data = dataFields.reduce((acc, nextField) => {
        if (typeof data[nextField] === 'number') {
          acc[nextField] = data[nextField].toString()
        } else {
          acc[nextField] = data[nextField]
        }
        return acc
      }, {})
      setFormData(_data)
    }
    return () => {
      if (mode === 'edit') {
        resetForm()
      }
    }
  }, [data, resetForm, open, mode])

  const processingText = mode === 'edit' ? 'Saving..' : 'Adding...'
  const defaultText = mode === 'edit' ? 'Save Device' : 'Add Device'
  const saveText = isProcessing ? processingText : defaultText

  return (
    <div className='c-pidb__details-modal-body'>
      <ErrorBanner visible={errors} />
      {formConfig(allDepartments).map(config => {
        if (config.options) {
          return (
            <React.Fragment key={`pidb_modal_${config.dataIndex}`}>
              <SmartInput
                {...config}
                key={`pidb_modal_${config.dataIndex}`}
                value={formData[config.dataIndex]}
                label={config.label}
                filterLabel={config.dataIndex}
                dataSource={config.options}
                onChange={value => onInputChange(value, config.dataIndex)}
                placeholder={config.placeHolder}
              />
              <ErrorInput
                visible={attemptCount > 0 && !formData[config.dataIndex]}
              />
            </React.Fragment>
          )
        } else {
          return (
            <React.Fragment key={`pidb_modal_${config.dataIndex}`}>
              <div
                className={`filter-header-input${config.wide ? ' wide' : ''}`}
              >
                <div className='filter-header-input-label'>{config.label}</div>
                <Input
                  allowClear
                  onChange={event =>
                    onInputChange(event.currentTarget.value, config.dataIndex)
                  }
                  value={formData[config.dataIndex]}
                />
              </div>
              <ErrorInput
                visible={attemptCount > 0 && !formData[config.dataIndex]}
              />
            </React.Fragment>
          )
        }
      })}
      <div className='c-pidb__details-modal-footer'>
        <button
          className='c-btn--cta-1 c-btn c-btn--bare'
          disabled={isProcessing}
          onClick={onSubmit}
        >
          {saveText}
        </button>
        <button
          onClick={() => {
            closeModal()
            resetForm()
          }}
          className='c-btn c-btn--bare c-btn--cancel'
          disabled={isProcessing}
        >
          Cancel
        </button>
      </div>
    </div>
  )
}

export default DetailsModalForm
