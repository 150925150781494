import React from 'react'
import { connect } from 'react-redux'
import { DealsCardsGrid } from '../subComponents'
import { defaultSendouts } from '../../helper'

const WeekDeals = ({ sendouts }) => {
  const {
    weeklyDealsAdded,
    weeklyDealMarginAdded,
    weeklyDealSpreadAdded,
    weeklyDealSpreadAddedAverage,
    weeklyDealsAddedAverageDuration,
    weeklyDealBreakersAdded
  } = sendouts
  const deals = [
    [
      { title: 'Deals Added', type: 'circle-check', ...(weeklyDealsAdded || defaultSendouts) },
      { title: 'Average Margin', decimal: true, type: 'circle-percent', ...(weeklyDealMarginAdded || defaultSendouts) },
    ],
    [
      { title: 'Spread Added', decimal: true, type: 'dollar-bag', bag: 'add', ...(weeklyDealSpreadAdded || defaultSendouts) },
      { title: 'Average Spread', decimal: true, type: 'dollar-bag', bag: 'avg', ...(weeklyDealSpreadAddedAverage || defaultSendouts) },
    ],
    [
      { title: 'Average Duration (Weeks)', type: 'calendar', ...(weeklyDealsAddedAverageDuration || defaultSendouts) },
      { title: 'Account Breakers', type: 'fire-wall', ...(weeklyDealBreakersAdded || defaultSendouts) },
    ]
  ]
  return (
    <DealsCardsGrid deals={deals} />
  )
}

const mapStateToProps = ({ dashboard }) => ({
  sendouts: dashboard.sendouts
})

export default connect(mapStateToProps)(WeekDeals)
