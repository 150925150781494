export const ASSIGNED_ACCOUNTS_QUERY = `
  {
    managerassignedaccounts {
      id
      assignedDate
      category {
        bhId
        name
      }
      placementCount
      contactCount
      program
      user {
        lastName
        bhId
        color
        firstName
        avatar
      }
      clientCorporationUrl
      clientCorporation {
        bhId
        name
      }
    }
  }
`

export const CORPORATION_ASSIGNED_ACCOUNTS_QUERY = `
  {
    corporateassignedaccounts {
      clientCorporation {
        bhId
        name
      }
      contactCount
      placementCount
      assignedAccounts {
        id
        category {
          bhId
          name
        }
        assignedDate
        program
        user {
          lastName
          bhId
          firstName
          avatar
        }
      }
    }
  }
`

const DIV_OBJ = `{
  division
  brand
  total
  subBrand
  hasSubBrand 
}`

const WEEK_OBJ = `{
  weekDay
  count
}`

/**
 *  @param {string} departmentName
 * @param {string} userId
 * @param {{ startDate: string, endDate: string }} fiscalMonth
 * @param {boolean} excludeGoLiveJobs
 * @returns
 */
export const getDivisionDashboardQuery = (
  departmentName,
  userId,
  fiscalMonth,
  excludeGoLiveJobs
) => {
  const fiscalMonthString = fiscalMonth
    ? `, startDate: "${fiscalMonth.startDate}", endDate: "${fiscalMonth.endDate}" `
    : ''
  const goLiveString = `excludeGoLiveJobs: ${excludeGoLiveJobs ? true : false}`
  const altArgs = fiscalMonth
    ? `(${fiscalMonthString}, ${goLiveString})`
    : `(${goLiveString})`
  const addInfo = `(departmentName: "${departmentName}", userId: ${userId}${fiscalMonthString}, ${goLiveString})`
  return `
    {
      divisionDashboardQuery {
        confirmedScheduledPurpleRedSendouts ${addInfo} ${DIV_OBJ}
        openPurpleRedSendouts ${addInfo} ${DIV_OBJ}
        openSendouts ${addInfo} ${DIV_OBJ}
        openSlots ${addInfo} ${DIV_OBJ}
        openSendoutsUnconfirmed ${addInfo} ${DIV_OBJ}
        confirmedScheduledSendouts ${addInfo} ${DIV_OBJ}
        remainingScheduledSendouts ${addInfo} ${DIV_OBJ}
        candidatesPending ${addInfo}
        jobSubmissionsWithoutSendouts ${addInfo}
        jobsWithoutSubmissions ${addInfo}
        unscreenedCandidates ${addInfo}
        candidatesWithVoicemail ${addInfo}
        candidatesScreenedNotSent ${addInfo}
        leadsManagement (departmentName: "${departmentName}", userId: ${userId}${fiscalMonthString}) {
          hot
          yellow
          marketplaceInfo
          reference
        }
        weeklyJobsAdded ${addInfo} {
          division ${WEEK_OBJ}
          brand ${WEEK_OBJ}
          subBrand ${WEEK_OBJ}
          hasSubBrand
          total ${WEEK_OBJ}
        }
        weeklyDealsAdded ${addInfo} ${DIV_OBJ}
        weeklyDealMarginAdded ${addInfo} ${DIV_OBJ}
        weeklyDealSpreadAdded ${addInfo} ${DIV_OBJ}
        weeklyDealSpreadAddedAverage ${addInfo} ${DIV_OBJ}
        weeklyDealsAddedAverageDuration ${addInfo} ${DIV_OBJ}
        weeklyDealBreakersAdded ${addInfo} ${DIV_OBJ}
        monthlyDealsAdded ${addInfo} ${DIV_OBJ}
        monthlyDealMarginAdded ${addInfo} ${DIV_OBJ}
        monthlyDealSpreadAdded ${addInfo} ${DIV_OBJ}
        monthlyDealSpreadAddedAverage ${addInfo} ${DIV_OBJ}
        monthlyDealsAddedAverageDuration ${addInfo} ${DIV_OBJ}
        monthlyDealBreakersAdded ${addInfo} ${DIV_OBJ}
        divisionDealsGoals ${altArgs} {
          dealGoal
          dealCount
          divisionShortName
          divisionName
          color
          brand
        }
        divisionFiscalMonthRank ${altArgs} {
          divisionName
          divisionShortName
          spreadAdded
          dealsAdded
          avarageMargin
          avarageSpread
          avarageDuration
          accountBreakers
          color
        }
      }
    }
  `
}

export const getRecentDealsQuery = department => `
  {
    divisionDashboardQuery(departmentName: "${department}") {
      recentDeals(maxResults: 5) {
        recruiter {
            firstName
            lastName
            color
          }
        accountManager {
            firstName
            lastName
            color
          }
        spread
        margin
        companyName
        companyUrl
        division
        dateAdded
      }
    }
  }
`

/**
 *
 * @param {string} department
 * @param {{ startDate: string, endDate: string }} customFiscalMonth
 * @param {boolean} excludeGoLiveJobs
 * @returns
 */
export const getDepartmentDealsGoalsQuery = (
  department,
  customFiscalMonth,
  excludeGoLiveJobs
) => {
  const fiscalMonthString = customFiscalMonth
    ? `\nstartDate: "${customFiscalMonth.startDate}"\nendDate: "${customFiscalMonth.endDate}"`
    : ''
  return `
  {
    divisionDashboardQuery(departmentName: "${department}"${fiscalMonthString}) {
      divisionDealsGoals(departmentScope: "${department}", excludeGoLiveJobs: ${
    excludeGoLiveJobs ? true : false
  }) {
        dealGoal
        dealCount
        divisionName
        color
      }
    }
  }
`
}
