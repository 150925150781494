const filterProgram = (things, selectedCategoryName) => {
  return things.filter(t => {
    if (!selectedCategoryName) {
      return true
    } else {
      let categories = t.Categories
      if (!categories && t.JobOrder && t.JobOrder.Categories) {
        categories = t.JobOrder.Categories
      }
      if (categories && categories.length) {
        const found = categories.find(cat => cat.name === selectedCategoryName)
        return !!found
      } else {
        return true
      }
    }
  })
}

// function getParameterByName(name, url) {
//   if (!url) url = window.location.href;
//   name = name.replace(/[[\]]/g, "\\$&");
//   var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
//     results = regex.exec(url);
//   if (!results) return null;
//   if (!results[2]) return "";
//   return decodeURIComponent(results[2].replace(/\+/g, " "));
// }

export default filterProgram
