import React, { useState, useCallback } from 'react'
import PauseIcon from '@mui/icons-material/Pause'
import { useKeyUpEvent, useInterval } from './hooks'

const SECONDS_PER_SCREEN = 10 * 1000
const pauseStyle = {
  position: 'fixed',
  fontSize: '10vw'
}

export const checkPreKey = e => {
  return (
    e.code === 'PageUp' ||
    e.code === 'ArrowUp' ||
    e.code === 'ArrowRight' ||
    e.code === 'Equal'
  )
}

export const checkNextKey = e => {
  return (
    e.code === 'PageDown' ||
    e.code === 'ArrowDown' ||
    e.code === 'ArrowLeft' ||
    e.code === 'Minus'
  )
}

const BoardContainer = ({ headerless, maxPageNum, render }) => {
  const [paused, setPause] = useState(false)
  const [page, setPage] = useState(0)

  const nextPage = useCallback(
    (next = true) => {
      let nextNum = next ? 0 : maxPageNum
      if (maxPageNum > 0) {
        if (next) {
          if (page < maxPageNum) {
            nextNum = page + 1
          }
        } else {
          if (page > 0) {
            nextNum = page - 1
          }
        }
      }
      setPage(nextNum)
    },
    [maxPageNum, page]
  )

  const handleRemoteKeys = useCallback(
    e => {
      e.preventDefault()
      const preKey = checkPreKey(e)
      const nextKey = checkNextKey(e)
      if (preKey || nextKey) {
        setPause(true)
        nextPage(nextKey)
      } else if (e.code === 'Enter') {
        setPause(maxPageNum > 0 && !paused)
      } else if (e.code === 'KeyP') {
        nextPage()
      }
    },
    [maxPageNum, paused, nextPage]
  )

  useKeyUpEvent(handleRemoteKeys)
  useInterval(nextPage, SECONDS_PER_SCREEN, paused)

  return (
    <section>
      {render(page)}
      {headerless && maxPageNum > 0 && (
        <div className='c-dashboard__page'>
          {`${page + 1} / ${maxPageNum + 1}`}
        </div>
      )}
      {paused && (
        <PauseIcon
          className='material-icons c-dashboard__pause'
          sx={pauseStyle}
        />
      )}
    </section>
  )
}

export default BoardContainer
