import React, { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import BaseWebsocket from '../../network/websocket/BaseWebsocket'
import { selectDepartment, fetchActiveDepartments } from '../../redux/actions'
import { LogoHeader } from '../layout/header'
import BrandDepartmentList from './components/BrandDepartmentList'
import BrandDepartmentListSkeleton from './components/BrandDepartmentListSkeleton'
import NotFound from '../shared/NotFound'
import { getAccessToken } from 'helpers/Util'

const selectSearch = state => state.router.location.search
const selectDepartments = state => state.department.departmentList
const selectFetchError = state => state.department.fetchError

const DepartmentSelection = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const onDepartmentSelect = useCallback(
    dep => {
      dispatch(selectDepartment(dep))
      history.push(`d/${dep}/header`)
    },
    [dispatch, history]
  )
  const fetchError = useSelector(selectFetchError)
  const search = useSelector(selectSearch)
  const departments = useSelector(selectDepartments)

  useEffect(() => {
    if (BaseWebsocket.getSocketInstance('user')) {
      BaseWebsocket.closeSocket('user')
    }
    const urlParams = new URLSearchParams(search)
    const department = urlParams.get('d') || urlParams.get('department')
    if (department && departments.includes(department)) {
      history.push(`/d/${department}/header`)
    } else {
      history.push('/')
    }
    if (!departments.length) {
      getAccessToken()
        .then(token => {
          if (token) {
            dispatch(fetchActiveDepartments())
          }
        })
        .catch(e => {
          console.warn('Access token not found.')
        })
    }
  }, [departments, dispatch, search, history])
  return (
    <div className='c-department-selection'>
      <LogoHeader />
      {!fetchError ? (
        <>
          {departments.length ? (
            <BrandDepartmentList
              departments={departments}
              onDepartmentSelect={onDepartmentSelect}
            />
          ) : (
            <BrandDepartmentListSkeleton />
          )}
        </>
      ) : (
        <NotFound message={'Error fetching departments'} />
      )}
    </div>
  )
}

export default DepartmentSelection
