import React from 'react'
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  LabelList,
  ResponsiveContainer,
  YAxis,
  CartesianGrid
} from 'recharts'

const defBgColor = '#EBEBEB'
const textColor = '#645363'

const calculateXShift = (dealCount, dealGoal) => {
  const numString = `${dealCount} / ${dealGoal}`

  return numString.length * 10
}

const CustomizedYLabel = React.memo(props => {
  const { x, y, name, color, data, dataKey } = props
  if (dataKey === 'name') {
    return (
      <g>
        <text
          x={x + 16}
          y={y - 5}
          className='c-dealprogress--vertical__div-text'
        >
          {name}
        </text>
      </g>
    )
  }
  const deal = data.find(div => div.name === name)
  if (!deal) {
    return null
  }
  const { dealCount, dealGoal, overage } = deal
  if (dataKey === 'target' && overage) return null
  if (dataKey === 'zero' && !overage) return null
  const xShift = calculateXShift(dealCount, dealGoal)
  const textAttr = { x: x - xShift > 0 ? x - xShift : 16, y: y + 36 }
  return (
    <g>
      <text {...textAttr} dx={dealCount < 10 ? 5 : 0}>
        <tspan fill={color}>{dealCount || 0} </tspan>
        <tspan fill={textColor}>/ {dealGoal || 0}</tspan>
      </text>
    </g>
  )
})

CustomizedYLabel.displayName = 'CustomizedYLabel'

const DealProgressChartVertical = ({ data, ypoints }) => {
  const top = (ypoints.length ? parseInt(ypoints[0]) : 5) * 1.15
  const formattedData = data.map(d => ({ ...d, zero: 0 }))

  return (
    <div className='c-dealprogress--vertical'>
      {data?.length ? (
        <ResponsiveContainer width='100%' height={data.length * 69}>
          <BarChart data={formattedData} layout='vertical'>
            <XAxis
              type='number'
              domain={[5, 'dataMax']}
              tickCount={7}
              tick={{
                color: '#3B3B3B',
                fontFamily: 'Lato',
                fontSize: '16px',
                fontWeight: 'bold'
              }}
              allowDecimals={false}
              padding={{ left: -5, right: 10 }}
              orientation='top'
            />
            <YAxis type='category' dataKey='name' yAxisId={0} hide />
            <Bar dataKey='value' barSize={20} yAxisId={0} stackId='a'>
              <LabelList
                content={
                  <CustomizedYLabel data={formattedData} dataKey='name' />
                }
              />
              {data.map((entry, index) => (
                <Cell
                  key={index}
                  fill={entry.value === top ? '#ffffff00' : entry.color}
                />
              ))}
            </Bar>
            <Bar
              yAxisId={0}
              stackId='a'
              dataKey='target'
              barSize={20}
              radius={[0, 10, 10, 0]}
            >
              <LabelList
                content={
                  <CustomizedYLabel data={formattedData} dataKey='target' />
                }
              />
              {data.map((entry, index) => (
                <Cell
                  key={index}
                  fill={
                    entry.overage
                      ? `${entry.color}40`
                      : entry.equal
                      ? entry.color
                      : defBgColor
                  }
                />
              ))}
            </Bar>
            <Bar yAxisId={0} stackId='a' dataKey='zero' barSize={0}>
              <LabelList
                content={
                  <CustomizedYLabel data={formattedData} dataKey='zero' />
                }
              />
            </Bar>
            <CartesianGrid strokeDasharray='4 6' horizontal={false} />
          </BarChart>
        </ResponsiveContainer>
      ) : null}
    </div>
  )
}

export default React.memo(DealProgressChartVertical)
