import { axiosRequest } from '../helpers/axios'

export default function getDepartmentSettings(department) {
  return axiosRequest(
    'GET',
    '/users/department/settings',
    null,
    null,
    department
  )
}
