import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setDealsCustomFiscalDate, setDealsGoLive } from 'redux/actions'
import { selectDealsGoLive } from 'redux/selectors/deals'
import { FiscalMonthDropdown, ToggleBtn } from 'components/shared'

const DealsFilters = () => {
  const dispatch = useDispatch()
  const goLive = useSelector(selectDealsGoLive)
  const handleCustomFiscalDateChange = useCallback(
    data => {
      if (data.isCurrent) {
        dispatch(setDealsCustomFiscalDate(null))
      } else {
        dispatch(
          setDealsCustomFiscalDate({
            startDate: data.startDate,
            endDate: data.endDate
          })
        )
      }
    },
    [dispatch]
  )
  const handleGoLiveToggle = useCallback(
    val => {
      dispatch(setDealsGoLive(val))
    },
    [dispatch]
  )
  return (
    <div className='c-filters'>
      <div className='c-filters__item'>
        <FiscalMonthDropdown onChange={handleCustomFiscalDateChange} />
      </div>
      <div className='c-go-live c-filters__item'>
        <ToggleBtn
          activeColor={'#e00005'}
          onChange={handleGoLiveToggle}
          checked={goLive}
        />
        Include Go Live Activity
      </div>
    </div>
  )
}

export default DealsFilters
