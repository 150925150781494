import React from 'react'
import { Tooltip } from 'antd'

const AlkuTooltip = props => {
  const { children } = props
  return (
    <Tooltip overlayClassName='c-tooltip' {...props}>
      {children}
    </Tooltip>
  )
}

export default AlkuTooltip
