import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../../redux/actions'
import {
  handleAssignedAccountsData,
  getAssignedAccounts
} from '../../../redux/actions'
import { Cards } from './subComponents'
import {
  selectVisibleData,
  selectSearchStatus,
  selectLoading,
  selectFilterBy
} from './selectors'
import BaseWebsocket from '../../../network/websocket/BaseWebsocket'
import EmptyListElement from '../../shared/EmptyListElement'
import { SOCKET_BOARDS } from 'constants'
import { Skeleton } from 'components/shared'
import AssignedAccountSkeleton from './AssignedAccountSkeleton'

const PAGINATION_PAGE_SIZE = 10
const PAGINATION_BASE_PAGE_NUMBER = 0
const PAGINATION_END_REACHED_PERCENTAGE = 0.7

class AssignedAccounts extends Component {
  state = {
    pageNumber: PAGINATION_BASE_PAGE_NUMBER
  }

  componentDidMount() {
    const {
      match: {
        params: { department }
      },
      getAssignedAccounts,
      handleAssignedAccountsData
    } = this.props
    BaseWebsocket.openSignalRSocket(
      SOCKET_BOARDS.ASSIGNED_ACCOUNTS_HUB,
      handleAssignedAccountsData,
      department
    )
    window.addEventListener('scroll', this.handleTableScroll, true)
    window.addEventListener('beforeunload', this.boardCleanUp)
    this.setScrollableDivHeight()
    getAssignedAccounts()
  }

  componentWillUnmount() {
    this.boardCleanUp()
  }

  componentDidUpdate() {
    this.setScrollableDivHeight()
  }

  boardCleanUp = event => {
    window.removeEventListener('scroll', this.handleTableScroll)
    BaseWebsocket.closeSocket('assigned-accounts')
    if (event) {
      window.removeEventListener('beforeunload', this.boardCleanUp)
    }
  }

  setScrollableDivHeight = () => {
    if (this.scrollableDiv) {
      this.scrollableDivHeight =
        this.scrollableDiv.scrollHeight - this.scrollableDiv.clientHeight
    } else {
      this.scrollableDivHeight = 0
    }
  }

  handleTableScroll = () => {
    const { data } = this.props
    const { pageNumber } = this.state
    if (this.scrollableDiv) {
      const scrollTop = this.scrollableDiv.scrollTop
      const scrollPercentage = scrollTop / this.scrollableDivHeight
      const endReached =
        Object.keys(data).length <= pageNumber * PAGINATION_PAGE_SIZE
      if (
        scrollPercentage >= PAGINATION_END_REACHED_PERCENTAGE &&
        !endReached
      ) {
        this.handlePaginationEndReached()
      }
    }
  }

  handlePaginationEndReached = () => {
    const { data } = this.props
    const { pageNumber } = this.state
    const dataLength = Object.keys(data).length
    const endReached = dataLength <= pageNumber * PAGINATION_PAGE_SIZE
    this.setState({
      pageNumber: endReached ? pageNumber : pageNumber + 1
    })
  }

  paginateData = (object, pageNumber, pageSize) => {
    const keys = Object.keys(object).slice(
      PAGINATION_BASE_PAGE_NUMBER,
      (pageNumber + 1) * pageSize
    )
    let paginatedData = {}
    for (let i = 0; i < keys.length; i++) {
      paginatedData[keys[i]] = object[keys[i]]
    }
    return paginatedData
  }

  renderCompanyPopover = (ref, companyUrl) => {
    const el = ref
      ? ref.current || (ref.id && document.getElementById(ref.id))
      : null
    this.props.setBasicMenuPopover({
      anchorEl: el,
      items: [{ url: companyUrl, primaryText: 'View on Bullhorn' }]
    })
  }

  render() {
    const {
      data,
      filterByCompany,
      loading,
      emptySearchResults,
      match: {
        params: { header, department }
      },
      boardLoadingObj
    } = this.props
    const { pageNumber } = this.state
    const dataLength = Object.keys(data).length
    const endReached = dataLength <= pageNumber * PAGINATION_PAGE_SIZE
    const isHeaderless = !header || header === 'headerless'
    const isEmpty = !Object.keys(data).length
    if (emptySearchResults) {
      return <EmptyListElement title='No search results!' />
    } else if (isEmpty && !loading) {
      return <EmptyListElement title='No assigned accounts yet!' />
    }

    return (
      <Skeleton.Board
        loading={!boardLoadingObj['assigned-accounts']}
        skeletonChildren={<AssignedAccountSkeleton />}
      >
        <div
          id='assigned-accounts'
          ref={ref => (this.scrollableDiv = ref)}
          className={`board-container ${
            isHeaderless ? 'headerless' : 'board-container--header'
          }`}
        >
          <Cards
            data={data}
            filterByCompany={filterByCompany}
            department={department}
            pageNumber={pageNumber}
            paginateData={this.paginateData}
            renderCompanyPopover={this.renderCompanyPopover}
          />
          {endReached ? <EmptyListElement title='. . .' /> : null}
        </div>
      </Skeleton.Board>
    )
  }
}

const mapStateToProps = state => {
  return {
    loading: selectLoading(state),
    data: selectVisibleData(state),
    emptySearchResults: selectSearchStatus(state),
    filterByCompany: selectFilterBy(state),
    boardLoadingObj: state.UI.boardLoadingObj
  }
}

export default connect(
  mapStateToProps,
  {
    ...actions,
    getAssignedAccounts,
    handleAssignedAccountsData
  }
)(AssignedAccounts)
