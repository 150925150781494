import React from 'react'
import { connect } from 'react-redux'
import { DealsCardsGrid } from '../subComponents'
import { defaultSendouts } from '../../helper'

const MonthDeals = ({ sendouts }) => {
  const {
    monthlyDealsAdded,
    monthlyDealMarginAdded,
    monthlyDealSpreadAdded,
    monthlyDealSpreadAddedAverage,
    monthlyDealsAddedAverageDuration,
    monthlyDealBreakersAdded
  } = sendouts
  const deals = [
    [
      { title: 'Deals Added', type: 'circle-check', ...(monthlyDealsAdded || defaultSendouts) },
      { title: 'Average Margin', decimal: true, type: 'circle-percent', ...(monthlyDealMarginAdded || defaultSendouts) },
    ],
    [
      { title: 'Spread Added', decimal: true, type: 'dollar-bag', bag: 'add', ...(monthlyDealSpreadAdded || defaultSendouts) },
      { title: 'Average Spread', decimal: true, type: 'dollar-bag', bag: 'avg', ...(monthlyDealSpreadAddedAverage || defaultSendouts) },
    ],
    [
      { title: 'Average Duration (Weeks)', type: 'calendar', ...(monthlyDealsAddedAverageDuration || defaultSendouts) },
      { title: 'Account Breakers', type: 'fire-wall', ...(monthlyDealBreakersAdded || defaultSendouts) },
    ]
  ]
  return (
    <DealsCardsGrid deals={deals} month />
  )
}

const mapStateToProps = ({ dashboard }) => ({
  sendouts: dashboard.sendouts
})

export default connect(mapStateToProps)(MonthDeals)
