import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FileBase64 from 'react-file-base64'
import { ChromePicker } from 'react-color'

import photo from '../../../../theme/assets/noavatar.png'
import { Close } from '../../../shared/Icons'
import { setAvatar } from '../../../../requests'
import { returnGradient } from '../../../../theme/returnGradient'
import {
  updateDepartmentCategoryGoal,
  setModalState as setModalStateAction,
  setPopoverState as setPopoverStateAction
} from '../../../../redux/actions'
import {
  selectActiveUser,
  selectCategories,
  selectSelectedDepartment
} from '../../../../redux/selectors'

const DEFAULT_STATE = {
  colorSelected: '#FFFF',
  files: [],
  avatar: photo,
  goal: 0
}

const UserSettings = ({ updateUserWSFxn }) => {
  const dispatch = useDispatch()
  const [state, setState] = useState(DEFAULT_STATE)
  const categories = useSelector(selectCategories)
  const department = useSelector(selectSelectedDepartment)
  const user = useSelector(selectActiveUser)

  const setPopoverState = useCallback(
    (...args) => {
      dispatch(setPopoverStateAction(...args))
    },
    [dispatch]
  )

  const setModalState = useCallback(
    (...args) => {
      dispatch(setModalStateAction(...args))
    },
    [dispatch]
  )

  const handleRequestClose = useCallback(() => {
    setPopoverState(false, null, null)
  }, [setPopoverState])

  useEffect(() => {
    if (user) {
      const updateObject = {}
      if (user.avatar) updateObject.avatar = user.avatar
      if (user.color) updateObject.colorSelected = `#${user.color}`
      setState(v => ({ ...v, ...updateObject }))
    }
  }, [user, setState])

  const handleConferenceChange = useCallback(
    e => {
      updateUserWSFxn(user.bhId, 'conferenceLine', e.target.value)
    },
    [updateUserWSFxn, user.bhId]
  )

  const handleInputClick = () => {
    const inputs = document.getElementsByTagName('input')
    for (var i = 0; i < inputs.length; i++) {
      if (inputs[i].type.toLowerCase() === 'file') {
        inputs[i].click()
      }
    }
  }

  const onFileDone = useCallback(
    async file => {
      await setAvatar(user.bhId, {
        event: 'user_change',
        feild: 'avatar',
        userId: user.bhId,
        value: file.base64
      })
      setState(v => ({
        ...v,
        avatar: file.base64
      }))
    },
    [setState, user]
  )

  const handleCategoryGoalSet = useCallback(
    (event, Id, divisionGoalId, fiscalMonth) => {
      dispatch(
        updateDepartmentCategoryGoal(
          Id,
          event.target.value,
          divisionGoalId,
          fiscalMonth
        )
      )
    },
    [dispatch]
  )

  const renderGoalBlocks = list => {
    return (
      <>
        {list.map(({ name, goal, divisionGoalId, Id, fiscalMonth }, index) => {
          const input = Id ? (
            <input
              type='number'
              className='number'
              onBlur={e =>
                handleCategoryGoalSet(e, Id, divisionGoalId, fiscalMonth)
              }
              defaultValue={goal}
            />
          ) : null
          return (
            <div className='goal-block' key={index}>
              <div className='goal-block__label'>{name}</div>
              <div className='goal-select'>
                <div className='decrease' style={{ background: 'green' }}>
                  #
                </div>
                {input}
              </div>
            </div>
          )
        })}
      </>
    )
  }

  const openColorPicker = useCallback(
    e => {
      const onChangeComplete = ({ hex }) => {
        setState(v => ({
          ...v,
          colorSelected: hex
        }))
        updateUserWSFxn(user.bhId, 'color', hex.split('#')[1])
      }
      const component = (
        <div>
          <ChromePicker
            color={state.colorSelected}
            onChangeComplete={onChangeComplete}
            disableAlpha
          />
        </div>
      )
      const anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'left'
      }
      const transformOrigin = {
        vertical: 'top',
        horizontal: 'left'
      }
      setPopoverState({
        open: true,
        content: component,
        targetEl: e.target,
        sx: { '& .MuiBackdrop-root': 'none' },
        anchorOrigin,
        transformOrigin
      })
    },
    [setPopoverState, setState, user, state.colorSelected, updateUserWSFxn]
  )

  return (
    <div
      className='settings-wrapper'
      style={returnGradient(state.colorSelected, 90)}
    >
      <div className='settings-header'>
        <h2>Settings</h2>
        <button
          onClick={() => {
            setModalState(false)
            handleRequestClose()
          }}
          aria-label='Click to close the user settings modal'
        >
          <Close />
        </button>
      </div>
      <div className='bottom-content'>
        <div className='profile-picture block'>
          <h3>Profile Picture</h3>
          <div className='avatar-upload'>
            <img src={state.avatar} alt='user profile' />
            <FileBase64
              className='hello'
              multiple={false}
              onDone={onFileDone}
            />
            <input
              type='button'
              defaultValue='Choose File'
              onClick={handleInputClick}
            />
          </div>
        </div>
        <div className='profile-color block'>
          <h3>Profile Color</h3>
          <div
            onClick={openColorPicker}
            className={'profile-color'}
            style={{ backgroundColor: state.colorSelected }}
          />
        </div>
        {department ? (
          <div className='profile-goal profile-goal--monthly block'>
            <h3>Monthly Deal Goal</h3>
            {categories && categories.length
              ? renderGoalBlocks(categories)
              : null}
          </div>
        ) : null}
        <div className='profile-goal block'>
          <h3>Conference Line</h3>
          <div className='goal-select' style={{ marginRight: '0.625rem' }}>
            <div className='decrease' style={{ background: '#BD1212' }}>
              #
            </div>
            <input
              type={'number'}
              className='number'
              onBlur={handleConferenceChange}
              defaultValue={user.conferenceLine}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserSettings
