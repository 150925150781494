import React from 'react'

const TableCell = React.memo(({ children, onClick }) => {
  return (
    <td onClick={onClick} className='assigned-accounts-card-table-cell'>
      {children}
    </td>
  )
})

TableCell.displayName = 'TableCell'

export default TableCell
