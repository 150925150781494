import React from 'react'

import Skeleton from 'components/shared/Skeleton'
import SkeletonHeaderFilter from '../shared/SkeletonHeaderFilter'
import SkeletonFullWidthCard from '../shared/SkeletonFullWidthCard'

const JobsBoardSkeleton = () => {
  return (
    <React.Fragment>
      <div
        style={{
          marginBottom: 32,
          display: 'flex',
          justifyContent: 'space-between',
          whiteSpace: 'nowrap'
        }}
      >
        <div>
          <SkeletonHeaderFilter />
          <SkeletonHeaderFilter />
          <SkeletonHeaderFilter />
          <SkeletonHeaderFilter topHidden />
        </div>
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Skeleton.Input
            style={{ width: 190, height: 40, marginRight: 8 }}
            active
          />
          <Skeleton.Input style={{ width: 40, height: 40 }} active />
        </div>
      </div>
      <SkeletonFullWidthCard height={160} />
      <SkeletonFullWidthCard height={160} />
      <SkeletonFullWidthCard height={160} />
    </React.Fragment>
  )
}

export default JobsBoardSkeleton
