import tinycolor from 'tinycolor2'

import {
  SET_USER,
  LOGOUT,
  SET_HEADERLESS_CREDENTIAL,
  UPDATE_HEADERLESS_TOKEN,
  REFRESH_TOKEN_BEGIN,
  CLEAR_HEADERLESS_CREDENTIAL,
  SET_IS_DISCONNECTED,
  UPDATE_SELECTED_DEPARTMENT_CATEGORY_ASSIGNMENT_ID,
  UPDATE_SELECTED_DEPARTMENT_CATEGORY_ASSIGNMENT_NAME
} from '../actionTypes'
import {
  clearDepartments,
  toggleAMSelectedState,
  setBoardLoadingObj
} from '../actions'
import { push } from 'connected-react-router'
import { clearUsers } from './users'
import axios from 'axios'
import { getDashboardSendouts } from './dashboard'
import logger from '../../requests/logger'
import { updateUserSelectedDepartmentCategoryAssignmentId } from '../../requests'

export const setUserCssVars = user => async (dispatch, getState) => {
  const {
    auth: { activeUser }
  } = getState()
  const color = user?.color || activeUser?.color
  if (color && document) {
    const fromColor = `#${color}`
    const toColor = tinycolor(fromColor)
      .lighten(15)
      .toString()
    const toColorDark = tinycolor(fromColor)
      .darken(15)
      .toString()
    document.documentElement.style.setProperty('--usercolor', `#${color}`)
    document.documentElement.style.setProperty(
      '--usergradient',
      `linear-gradient(270deg, ${fromColor}, ${toColor})`
    )
    document.documentElement.style.setProperty(
      '--usergradientdark',
      `linear-gradient(270deg, ${fromColor}, ${toColorDark})`
    )
  }
}

export const setActiveUser = user => dispatch => {
  dispatch({ type: SET_USER, user })
  dispatch(setUserCssVars(user))
  dispatch(updateSelectedCategoryAssignmentName())
  if (user.isAccountManager) dispatch(toggleAMSelectedState(user.bhId))
}

export const clearActiveUser = () => {
  return {
    type: LOGOUT
  }
}

export const logout = history => dispatch => {
  dispatch(push('/'))
  dispatch(clearUsers())
  dispatch(clearDepartments())
  dispatch(clearActiveUser())
  dispatch(setBoardLoadingObj('reset'))
}

export const setHeaderlessCredential = credential => ({
  type: SET_HEADERLESS_CREDENTIAL,
  payload: credential
})

export const beginRefreshToken = currentTime => ({
  type: REFRESH_TOKEN_BEGIN,
  payload: currentTime
})

export const updateHeaderlessToken = token => ({
  type: UPDATE_HEADERLESS_TOKEN,
  payload: token
})

export const clearHeaderlessCredential = () => ({
  type: CLEAR_HEADERLESS_CREDENTIAL
})

export const refreshHeaderlessToken = () => async (dispatch, getState) => {
  try {
    const { credential } = getState().auth
    /*
     * Todo: try to find a way block additional requests, until one complete
     * not a big issue:
     * it will hit following url more than one time when click wide-board and token expired;
     * (depends on parallel requests number caused by wide-board click).
     *
     * // Example: using redux-saga take
     * const currentTime = Math.floor(Date.now() / 1000);
     * if (!credential.refreshOn || credential.refreshOn < currentTime) {
     *   dispatch(beginRefreshToken()); // first request
     * } else {
     *   // following requests will wait action complete
     *   yield take('UPDATE_HEADERLESS_TOKEN');
     * }
     */
    const url = `/api/pis/${credential.macAddress}/token`
    const headers = {
      'Content-Type': 'application/json'
    }
    const data = {
      clientId: credential.clientId,
      clientSecret: credential.clientSecret
    }
    const timestamp = Math.floor(Date.now() / 1000)
    const response = await axios(url, {
      method: 'POST',
      headers,
      data
    })
    const { accessToken } = response.data
    const expiresOn = timestamp + 3540
    dispatch(
      updateHeaderlessToken({
        accessToken,
        expiresOn
      })
    )
  } catch (e) {
    logger(e)
  }
}

export const setIsDisconnected = isDisconnected => ({
  type: SET_IS_DISCONNECTED,
  payload: isDisconnected
})

export const updateSelectedDepartmentCategoryAssignmentId = selectedId => async (
  dispatch,
  getState
) => {
  try {
    const {
      auth: {
        activeUser: { bhId }
      }
    } = getState()
    await updateUserSelectedDepartmentCategoryAssignmentId(selectedId, bhId)
    dispatch({
      type: UPDATE_SELECTED_DEPARTMENT_CATEGORY_ASSIGNMENT_ID
    })
    dispatch(getDashboardSendouts())
  } catch (e) {
    logger(e)
  }
}

export const updateSelectedCategoryAssignmentName = () => (
  dispatch,
  getState
) => {
  const {
    auth: {
      activeUser: { departmentCategoriesDealsAssignmentId }
    },
    department: { categories }
  } = getState()
  const selectedCategory =
    categories &&
    categories.find(category => {
      return category.Id === departmentCategoriesDealsAssignmentId
    })
  const selectedCategoryName = selectedCategory ? selectedCategory.name : null
  dispatch({
    type: UPDATE_SELECTED_DEPARTMENT_CATEGORY_ASSIGNMENT_NAME,
    payload: selectedCategoryName
  })
}
