import { setDepartmentError } from '../department'
import { getDashboardSendouts } from '../dashboard'
import { setUsers, patchUser } from '..'

export const handleUserHubSocket = data => (dispatch, getState) => {
  switch (data.event) {
    case 'error':
      return dispatch(setDepartmentError(new Error('department error!')))
    case 'list':
      return dispatch(setUsers(data.users))
    case 'user_change': {
      const {
        auth: { activeUser },
        department: { selectedDepartment }
      } = getState()
      const { bhId } = activeUser
      const payload = {}
      data.updatedFields.forEach(field => {
        payload[field] = data.user[field]
      })
      if (data.updatedFields.length) {
        dispatch(patchUser(data.user.bhId, payload, data.event))
        if (data.user.bhId === bhId && selectedDepartment) {
          dispatch(getDashboardSendouts(selectedDepartment))
        }
      }
      return false
    }
    default:
      return false
  }
}
