import React, { useState, useCallback, useEffect } from 'react'
import { Button, Tabs, Modal, Input } from 'antd'

import { returnGradient } from '../../../theme/returnGradient'
import LinkForm from './components/LinkForm'
import {
  createMultiValueString,
  parseMultiValueString,
} from '../../../helpers/handleMultiValueString'

const { TabPane } = Tabs

const TABS = {
  LINE: '1',
  LINK: '2'
}
const MAX_AMOUNT = 254

const CLModal = ({
  closeModal,
  visible,
  conferenceLine,
  auth,
  updateJobOrderFxn,
  bhId
}) => {
  const color = auth && auth.activeUser && auth.activeUser.color
  const [linkProps, setLinkProps] = useState({
    link: '',
    displayText: ''
  })
  const [conferenceLineInput, setConferenceLineInput] = useState('')
  const [activeTab, setActiveTab] = useState(TABS.LINE)
  const handleConferenceChange = useCallback(() => {
    let newValue
    let error
    if (activeTab === TABS.LINK) {
      newValue = createMultiValueString([
        linkProps.displayText,
        linkProps.link
      ])
      const currCharLength = linkProps.displayText.length + linkProps.link.length
      const emptyField = !linkProps.displayText.length || !linkProps.link.length
      if (emptyField) {
        error = 'Display Text and Link fields are requried!'
      } else if (MAX_AMOUNT - currCharLength < 0) {
        error = 'Maximum character limit reached.'
      }
    } else {
      newValue = conferenceLineInput
    }
    if (error) {
      window.alert(error)
    } else if (newValue !== undefined) {
      updateJobOrderFxn(bhId, 'conferenceLine', newValue)
      closeModal()
    }
  }, [
    activeTab,
    closeModal,
    linkProps.displayText,
    linkProps.link,
    conferenceLineInput,
    updateJobOrderFxn,
    bhId
  ])
  useEffect(() => {
    if (bhId) {
      const authConferenceLine =
        auth && auth.activeUser && auth.activeUser.conferenceLine
      let conferenceLineDefault = conferenceLine || '',
        displayTextValue = '',
        tabDefault = TABS.LINE,
        linkDefault = ''
      const parsedValue = parseMultiValueString(conferenceLine)
      if (Array.isArray(parsedValue)) {
        conferenceLineDefault = authConferenceLine || ''
        displayTextValue = parsedValue[0]
        linkDefault = parsedValue[1]
        tabDefault = TABS.LINK
      }
      setLinkProps({ link: linkDefault, displayText: displayTextValue })
      setConferenceLineInput(conferenceLineDefault)
      setActiveTab(tabDefault)
    }
  }, [auth, bhId, conferenceLine])

  return (
    <Modal
      className='c-starts-modal c-conference-line-modal'
      visible={visible}
      footer={null}
      onCancel={closeModal}
      maskClosable={false}
    >
      <div className='c-starts-modal'>
        <div
          className='c-starts-modal__header'
          style={{
            ...returnGradient(`#${color}`, 90, 15),
            color: !color ? 'black' : undefined,
          }}
        >
          <div>Conference Line</div>
        </div>
        <div className='c-starts-modal__body'>
          <Tabs activeKey={activeTab} onChange={v => setActiveTab(v)}>
            <TabPane tab='Line #' key={TABS.LINE}>
              <div className='link-form'>
                <div className='link-form-item'>
                  <div className='link-form__input link-form__input--prefix'>
                    <div className='prefix-icon' style={{ background: 'red' }}>
                      #
                    </div>
                    <Input
                      type={'text'}
                      onChange={e =>
                        setConferenceLineInput(e.currentTarget.value)
                      }
                      placeholder='1234'
                      value={conferenceLineInput}
                    />
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab='Link' key={TABS.LINK}>
              <LinkForm
                setForm={setLinkProps}
                form={linkProps}
                onSubmit={handleConferenceChange}
                maxTotalChars={MAX_AMOUNT}
              />
            </TabPane>
          </Tabs>
          <Button
            type='primary'
            htmlType='submit'
            style={{ marginRight: '6px' }}
            onClick={handleConferenceChange}
          >
            Save
          </Button>
          <Button htmlType='button' onClick={closeModal}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default CLModal
