import React from 'react'

const PatternStroke = ({ color = 'rgb(255,0,0)', className }) => {
  const lineStyle = {
    stroke: color,
    strokeWidth: 1
  }
  return (
    <svg
      className={className}
      width='100%'
      height='19'
      xmlns='http://www.w3.org/2000/svg'
    >
      <defs>
        <pattern
          id='Pattern'
          x='0'
          y='0'
          width='14'
          height='32'
          patternUnits='userSpaceOnUse'
        >
          <line x1='-7' y1='19' x2='7' y2='0' style={lineStyle} />
          <line x1='0' y1='19' x2='14' y2='0' style={lineStyle} />
          <line x1='7' y1='19' x2='21' y2='0' style={lineStyle} />
        </pattern>
      </defs>
      <rect fill='url(#Pattern)' width='100%' height='18' />
    </svg>
  )
}

export default PatternStroke
