const sortOnboardingPaperworkModal = (a, b) => {
  const {
    title: aTitle,
    // isComplete: aIsComplete,
    isNotApplicable: aIsNotApplicable
  } = a
  const {
    title: bTitle,
    // isComplete: bIsComplete,
    isNotApplicable: bIsNotApplicable
  } = b
  if (aIsNotApplicable && !bIsNotApplicable) return 1
  if (!aIsNotApplicable && bIsNotApplicable) return -1
  return (aTitle || '').localeCompare(bTitle || '')
}

export default sortOnboardingPaperworkModal
