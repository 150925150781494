const handleCustomTag = (tag, jo) => {
  switch (tag) {
    case 'checked':
      return jo.submissionsNeeded === false
    case 'fullTime':
      return jo.fullPartTime === 'FT'
    case 'partTime':
      return jo.fullPartTime === 'PT'
    default:
      break
  }
}

export default (jobOrders, tags) => {
  return jobOrders.filter(jo => {
    let isIncluded = false
    for (let i = 0; i < tags.length; i++) {
      if (isIncluded) break
      let tag = tags[i]
      if (tag[0] === '_') {
        tag = tag.slice(1)
        isIncluded = handleCustomTag(tag, jo)
      } else {
        isIncluded = jo[tag]
      }
    }
    return isIncluded
  })
}
