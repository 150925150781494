import { getCategoryDealsGoals } from '..'

export const handleFiscalData = result => dispatch => {
  if (result) {
    switch (result.event) {
      case 'goal_change':
        dispatch(getCategoryDealsGoals())
        break
      default:
        break
    }
  }
}
