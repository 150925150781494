import _ from 'lodash'
import { sortDate, sortDateReverse } from '../../../helpers/dateTime'
import {
  ACTIVE_SUBS,
  CHECKED_UNCHECKED,
  COMPANY,
  OWNER,
  PENDING,
  START_DATE
} from './JobsBoardSort'

export const sortHotJobs = jobs => {
  return jobs.sort((a, b) => new Date(a.created_at) - b.created_at)
}

const sortByJobOwner = (aJob, bJob) => {
  const aBhid = aJob?.Owner?.bhId
  const bBhid = bJob?.Owner?.bhId
  if (typeof aBhid === 'number' && typeof bBhid === 'number') {
    return bBhid - aBhid
  }

  return 0
}

export const sortJobs = (jobs, required_skill_sort) => {
  if (!jobs.length) {
    return []
  }

  let sorted

  if (required_skill_sort) {
    return _.sortBy(
      _.flatten(
        _.values(
          _.groupBy(jobs, currentObj => {
            return currentObj.requiredSkill
          })
        )
      ),
      p => p.requiredSkill
    ).sort(sortByJobOwner)
  } else {
    sorted = _.flatten(
      _.values(
        _.groupBy(jobs, currentObj => {
          return currentObj.ClientCorporation.bhId
        })
      )
    )

    // sort clients by name - tertiary sort
    sorted.sort((a, b) => {
      return a.ClientCorporation.name.localeCompare(b.ClientCorporation.name)
    })

    // sort by division - secondary sort

    sorted.sort((a, b) => {
      if (a.division && b.division) {
        return a.division.localeCompare(b.division)
      } else {
        return false
      }
    })

    // sort by Job Owner - primary sort
    return sorted.sort(sortByJobOwner)
  }
}

const sortByStartDate = (a, b) => {
  if (a.startDate && b.startDate) {
    return sortDate(a.startDate, b.startDate, 'YYYY-MM-DD')
  } else {
    return 0
  }
}

const sortByStartDateReverse = (a, b) => {
  if (a.startDate && b.startDate) {
    return sortDateReverse(a.startDate, b.startDate, 'YYYY-MM-DD')
  } else {
    return 0
  }
}

const sortByCompany = (a, b) => {
  const aValue = a.ClientCorporation && a.ClientCorporation.name
  const bValue = b.ClientCorporation && b.ClientCorporation.name
  if (aValue === bValue) return 0
  if (aValue && bValue) {
    return aValue.localeCompare(bValue)
  }
}

const sortByCompanyReverse = (a, b) => {
  const aValue = a.ClientCorporation && a.ClientCorporation.name
  const bValue = b.ClientCorporation && b.ClientCorporation.name
  if (aValue === bValue) return 0
  if (aValue && bValue) {
    return bValue.localeCompare(aValue)
  }
}

const sortByOwner = (a, b) => {
  const aValue = a.Owner && `${a.Owner.firstName} ${a.Owner.lastName}`
  const bValue = b.Owner && `${b.Owner.firstName} ${b.Owner.lastName}`
  if (aValue === bValue) return 0
  if (aValue && bValue) {
    return aValue.localeCompare(bValue)
  }
}

const sortByOwnerReverse = (a, b) => {
  const aValue = a.Owner && `${a.Owner.firstName} ${a.Owner.lastName}`
  const bValue = b.Owner && `${b.Owner.firstName} ${b.Owner.lastName}`
  if (aValue === bValue) return 0
  if (aValue && bValue) {
    return bValue.localeCompare(aValue)
  }
}

const sortByChecked = (a, b) => {
  const aValue = a.submissionsNeeded
  const bValue = b.submissionsNeeded
  if (aValue === bValue) return 0
  if (bValue) {
    return -1
  }
  if (aValue) {
    return 1
  }
  return 0
}

const sortByUnchecked = (a, b) => {
  const aValue = a.submissionsNeeded
  const bValue = b.submissionsNeeded
  if (aValue === bValue) return 0
  if (aValue) {
    return -1
  }
  if (bValue) {
    return 1
  }
  return 0
}

// const reduceJobSubmissionToInterviewSlotCount = (total, nextJS) => {
//   if (nextJS && nextJS.interviewSlots) total += nextJS.interviewSlots.length
//   return total
// }

const reduceJobSubmissionToActiveSubsCount = (total, nextJS) => {
  if (nextJS && nextJS.hidden === false) total += 1
  return total
}

const sortByActiveSubs = (a, b) => {
  const aValue =
    a.JobSubmissions &&
    a.JobSubmissions.reduce(reduceJobSubmissionToActiveSubsCount, 0)
  const bValue =
    b.JobSubmissions &&
    b.JobSubmissions.reduce(reduceJobSubmissionToActiveSubsCount, 0)
  if (aValue === bValue) return 0
  if (aValue < bValue) {
    return -1
  }
  if (aValue > bValue) {
    return 1
  }
  return 0
}

const sortByActiveSubsReverse = (a, b) => {
  const aValue =
    a.JobSubmissions &&
    a.JobSubmissions.reduce(reduceJobSubmissionToActiveSubsCount, 0)
  const bValue =
    b.JobSubmissions &&
    b.JobSubmissions.reduce(reduceJobSubmissionToActiveSubsCount, 0)
  if (aValue === bValue) return 0
  if (aValue < bValue) {
    return 1
  }
  if (aValue > bValue) {
    return -1
  }
  return 0
}

const sortByPendingCandidates = (a, b, direction) => {
  if (a.hasPendingCandidates === b.hasPendingCandidates) return 0
  if (direction === 'DESC') {
    if (a.hasPendingCandidates) return 1
    return -1
  } else {
    if (b.hasPendingCandidates) return 1
    return -1
  }
}

export const selectJobSortFn = (value, direction) => {
  switch (value) {
    case START_DATE:
      if (direction === 'DESC') return sortByStartDateReverse
      return sortByStartDate
    case COMPANY:
      if (direction === 'DESC') return sortByCompanyReverse
      return sortByCompany
    case OWNER:
      if (direction === 'DESC') return sortByOwnerReverse
      return sortByOwner
    case CHECKED_UNCHECKED:
      if (direction === 'DESC') return sortByUnchecked
      return sortByChecked
    case ACTIVE_SUBS:
      if (direction === 'DESC') return sortByActiveSubsReverse
      return sortByActiveSubs
    case PENDING:
      return (a, b) => sortByPendingCandidates(a, b, direction)
    default:
      return undefined
  }
}
