import React from 'react'

import { parseDate, sortDate } from 'helpers/dateTime'
import { sortAlphabetically } from 'helpers/sort'
import { DeleteIcon, Edit } from 'components/shared/Icons'
import { formatDisplayRotationValueToLabel } from 'components/PiDB/helpers/format'

const dateFormat = 'YYYY-MM-DD h:mm a'

const capitalizeFirst = v => {
  if (typeof v === 'string') {
    return `${v.charAt(0).toUpperCase() + v.toLowerCase().slice(1)}`
  }
  return v
}

const genColConfig = (title, dataIndex, width, sortFn, renderFn) => {
  return {
    title,
    dataIndex,
    width: width ? width + 32 : undefined,
    sorter: sortFn ? (a, b) => sortFn(a[dataIndex], b[dataIndex]) : undefined,
    render: renderFn
  }
}

const columnConfig = (openDeleteConfirmationDialog, openEditDeviceModal) => [
  {
    title: '',
    dataIndex: 'actions',
    width: 100,
    render: (_, row) => {
      return (
        <div className='c-pidb__action-cell'>
          <button
            className='c-btn c-btn--bare'
            onClick={() => openEditDeviceModal(row)}
          >
            <Edit />
          </button>
          <button
            className='c-btn c-btn--bare'
            onClick={() => openDeleteConfirmationDialog(row)}
          >
            <DeleteIcon />
          </button>
        </div>
      )
    }
  },
  genColConfig('Mac Address', 'macAddress', 130, sortAlphabetically),
  genColConfig('Division Name', 'departmentName', 140, sortAlphabetically),
  genColConfig('Board', 'boardName', 140, sortAlphabetically, capitalizeFirst),
  genColConfig(
    'Display Rotation',
    'displayRotation',
    140,
    (a, b) =>
      sortAlphabetically(
        formatDisplayRotationValueToLabel(a),
        formatDisplayRotationValueToLabel(b)
      ),
    formatDisplayRotationValueToLabel
  ),
  genColConfig('URL Format', 'urlFormat', 200, sortAlphabetically),
  genColConfig('Startup URL', 'baseUrl', 550, sortAlphabetically),
  genColConfig('Created', 'createdAt', 160, sortDate, v =>
    parseDate(v, dateFormat)
  ),
  genColConfig('Last Updated', 'updatedAt', 160, sortDate, v =>
    parseDate(v, dateFormat)
  )
]

export default columnConfig
