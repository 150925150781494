import React, { useState, useEffect, useCallback } from 'react'
import MuiMenu from '@mui/material/Menu'

const Menu = ({ children, onClose, ...restProps }) => {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    setOpen(Boolean(restProps.anchorEl))
  }, [setOpen, restProps.anchorEl])
  const handleClose = useCallback(() => {
    setOpen(false)
    onClose && onClose()
  }, [onClose])
  if (!restProps.anchorEl) return null
  return (
    <MuiMenu
      open={open}
      anchorEl={restProps.anchorEl}
      onClose={handleClose}
      BackdropProps={{ invisible: false }}
      {...restProps}
    >
      {children}
    </MuiMenu>
  )
}

export default Menu
