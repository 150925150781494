import React from 'react'

const ReferenceCheck = ({ className = '' }) => {
  return (
    <svg
      width='14'
      height='16'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M12.444 1.6H9.19C8.87.67 8.014 0 7 0S5.131.67 4.81 1.6H1.556C.696 1.6 0 2.316 0 3.2v11.2c0 .884.696 1.6 1.556 1.6h10.888c.86 0 1.556-.716 1.556-1.6V3.2c0-.884-.696-1.6-1.556-1.6zM6.222 12h-3.11v-1.6h3.11V12zm0-4h-3.11V6.4h3.11V8zM7 3.2a.79.79 0 01-.778-.8c0-.442.349-.8.778-.8a.79.79 0 01.778.8.79.79 0 01-.778.8zM9.655 12H7.778v-1.6H9.01l1.556-1.6 1.1 1.131L9.655 12zm0-4H7.778V6.4H9.01l1.556-1.6 1.1 1.131L9.655 8zm2.79 6.4H1.554V3.2h10.89v11.2z'
        fill='#666'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default ReferenceCheck
