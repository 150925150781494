import { newRequest } from '../helpers/axios'

export default function getDepartmentCategories(path) {
  return newRequest(
    'GET',
    `/department-categories-assignment?filter[category_departments.department.route.path]=eq:${path}`,
    null,
    null
  )
}
