import React from 'react'

export default function CandidateHistoryIcon({ title }) {
  return (
    <>
      <p>
        <svg width='16px' height='16px' viewBox='0 0 16 16' version='1.1'>
          <g id='Jobs' stroke='none' fill='none'>
            <g
              id='Jobs---Meta-data---AF-3'
              transform='translate(-848.000000, -369.000000)'
              fill='#222222'
            >
              <g id='Job-Card' transform='translate(145.000000, 169.000000)'>
                <g id='Bar' transform='translate(0.000000, 192.000000)'>
                  <g
                    id='icons8-time_machine'
                    transform='translate(703.000000, 8.000000)'
                  >
                    <path
                      d='M8,0 C5.59069792,0 3.44297624,1.08453456 1.97656248,2.77656248 L0,0.8 L0,5.6 L4.8,5.6 L3.10156248,3.90156248 C4.27603856,2.49963832 6.03168792,1.6 8,1.6 C11.5288,1.6 14.4,4.4712 14.4,8 C14.4,11.5288 11.5288,14.4 8,14.4 C4.4712,14.4 1.6,11.5288 1.6,8 L0,8 C0,12.4112 3.5888,16 8,16 C12.4112,16 16,12.4112 16,8 C16,3.5888 12.4112,0 8,0 Z M7.2,3.2 L7.2,8.3312496 L9.8343752,10.9656248 L10.9656248,9.8343752 L8.8,7.6687504 L8.8,3.2 L7.2,3.2 Z'
                      id='Shape'
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
        {` ${title}`}
      </p>
    </>
  )
}
