import React from 'react'
import { useSelector } from 'react-redux'

import TouchScreenBoard from 'components/TouchScreen/components/boards/Board'
import Board from '../withMasonryPagination'

import { selectTouchscreenPaginationType } from 'redux/selectors'

const BoardManager = ({ useTouchscreenBoard = false, ...props }, ref) => {
  const paginationType = useSelector(selectTouchscreenPaginationType)
  if (useTouchscreenBoard) {
    return <TouchScreenBoard paginationType={paginationType} {...props} />
  }

  return <Board {...props} />
}

export default React.forwardRef(BoardManager)
