import { uniqBy } from 'lodash'
import { sortAlphabetically } from '../../../../helpers/sort'

export default placements => [
  {
    filterLabel: 'status',
    type: 'search-select',
    match: 'exact',
    label: 'Status',
    placeholder: 'Select Status',
    options: uniqBy(
      placements
        .map(placement => {
          return {
            value: placement.status,
            label: placement.status
          }
        })
        .filter(placement => placement.value || placement.name),
      option => option.value
    ),
    filterByLabel: true
  },
  {
    filterLabel: 'company',
    type: 'search-select',
    match: 'exact',
    label: 'Company',
    placeholder: 'Select Company',
    options: uniqBy(
      placements
        .map(placement => {
          if (placement) {
            return {
              value: placement.companyId,
              label: placement.companyName
            }
          } else {
            return {
              value: null
            }
          }
        })
        .filter(placement => placement.value || placement.name),
      option => option.value
    ),
    filterByLabel: true
  },
  {
    filterLabel: 'owner',
    multiSelect: true,
    type: 'search-select',
    match: 'exact',
    label: 'Owners',
    placeholder: 'Select Owners',
    options: uniqBy(
      placements
        .reduce((options, placement) => {
          if (placement) {
            options = [
              ...options,
              {
                value: placement.recruiterName,
                label: placement.recruiterName
              },
              {
                value: placement.ownerId,
                label: placement.ownerName
              }
            ]
          }
          return options
        }, [])
        .sort((a, b) => sortAlphabetically(a.label, b.label)),
      option => option.value
    ),
    filterByLabel: true
  },
  {
    filterLabel: 'startDateRange',
    dateRange: true,
    match: 'exact',
    label: 'Start Date Range'
  },
  {
    filterLabel: 'program',
    type: 'search-select',
    match: 'exact',
    label: 'Program',
    placeholder: 'Select Program',
    programFilter: true,
    options: uniqBy(
      placements
        .map(placement => {
          return {
            value: placement.program,
            label: placement.program
          }
        })
        .filter(placement => placement.value || placement.name),
      option => option.value
    ),
    filterByLabel: true
  }
]
