import dayjs from 'dayjs'

const getSlotColor = (faceToFace, date) => {
  let thisWeek = dayjs()
  let dateWeek = dayjs(date)

  if (thisWeek.isSame(dateWeek, 'week')) {
    if (faceToFace) {
      return '#000' // black
    } else {
      return '#00f' // blue
    }
  } else {
    if (faceToFace) {
      return 'rgb(255, 0, 0)' // FTP next week = red
    } else {
      return 'rgb(158, 0, 255)' // Phone next week = purple
    }
  }
}

export {
  getSlotColor
}