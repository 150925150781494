import React from 'react'
import { useSelector } from 'react-redux'
import { Card, Table, Collapse } from 'antd'
import { map } from 'lodash'

import { TableRow, TableCell, CardHeader, TableHeaderCell } from './index'
import { filterColumnsPerDepartment } from '../helpers/sortAssignedAccounts'
import { companyColumnConfig, AMColumnConfig } from '../config'
import { selectDepartmentSettings } from '../../../../redux/selectors/department'
import VerticalTable from './VerticalTable'

const PAGINATION_PAGE_SIZE = 10

const Cards = React.memo(
  ({
    data,
    filterByCompany,
    department,
    pageNumber,
    paginateData,
    renderCompanyPopover
  }) => {
    const depSettings = useSelector(selectDepartmentSettings)
    const shouldShowProgramFilter = depSettings && depSettings['program_field']

    // filter data based on current pagination state
    const paginatedData = paginateData(data, pageNumber, PAGINATION_PAGE_SIZE)

    return map(paginatedData, data => {
      // every grouped assigned account array data set will have the same user or corporation,
      // so it doesn't matter which index we pull it from in the next two constant declarations.
      // we pull from data[0] to protect from out of bounds exceptions
      const key = `assigned_accounts_card_${
        filterByCompany
          ? `company_${data[0].clientCorporation.bhId}`
          : `account_manager_${data[0].user.bhId}`
      }`

      const cardTitleData = filterByCompany
        ? data[0].clientCorporation
        : { ...data[0].user, totalAccounts: data.length }

      const datasetLargerThanOne = data.length > 1
      const hasProgramValues = !!data.filter(x => x.program).length
      return (
        <Card key={key} className='assigned-accounts-card'>
          <Collapse
            expandIconPosition='right'
            bordered={false}
            defaultActiveKey={[key]}
          >
            <Collapse.Panel
              showArrow={false}
              header={
                <CardHeader
                  cardTitleData={cardTitleData}
                  filterByCompany={filterByCompany}
                  renderCompanyPopover={ref =>
                    renderCompanyPopover(ref, data[0].clientCorporationUrl)
                  }
                />
              }
              key={key}
            >
              <VerticalTable
                className='assigned-accounts-card-table--vertical'
                rowKey={record => `${key}_${record.id}`}
                columns={filterColumnsPerDepartment(
                  filterByCompany
                    ? companyColumnConfig(
                        shouldShowProgramFilter,
                        datasetLargerThanOne,
                        hasProgramValues,
                        true
                      )
                    : AMColumnConfig(
                        datasetLargerThanOne,
                        hasProgramValues,
                        renderCompanyPopover,
                        shouldShowProgramFilter
                      ),
                  department
                )}
                dataSource={data}
              />
              <Table
                className='assigned-accounts-card-table'
                rowKey={record => `${key}_${record.id}`}
                columns={filterColumnsPerDepartment(
                  filterByCompany
                    ? companyColumnConfig(
                        shouldShowProgramFilter,
                        datasetLargerThanOne,
                        hasProgramValues,
                        false
                      )
                    : AMColumnConfig(
                        datasetLargerThanOne,
                        hasProgramValues,
                        renderCompanyPopover,
                        shouldShowProgramFilter
                      ),
                  department
                )}
                dataSource={data}
                pagination={false}
                components={{
                  header: {
                    cell: ({ children, className, onClick, showtooltip }) => (
                      <TableHeaderCell
                        onClick={onClick}
                        className={className}
                        showtooltip={showtooltip}
                      >
                        {children}
                      </TableHeaderCell>
                    )
                  },
                  body: {
                    row: ({ children }) => <TableRow>{children}</TableRow>,
                    cell: ({ children }) => <TableCell>{children}</TableCell>
                  }
                }}
              />
            </Collapse.Panel>
          </Collapse>
        </Card>
      )
    })
  }
)

Cards.displayName = 'Cards'

export default Cards
