import React from 'react'
import { AssignedAccountsIcon } from './AssignedAccountsIcon'

const VARIANTS = {
  SIMPLE: 'simple',
  ICON: 'icon'
}

const SimpleText = ({ board }) => {
  return (
    <span className='main-header__text main-header__text--simple'>
      {board.replace('-', ' ')}
    </span>
  )
}

const IconText = ({ board }) => {
  return (
    <>
      <AssignedAccountsIcon
        style={{
          color: '#E01112',
          fontSize: 11,
          marginRight: '8px'
        }}
        className={'fa fa-bolt no-transition'}
      />
      <span className='main-header__text main-header__text--icon'>
        {board.replace('-', ' ')}
      </span>
    </>
  )
}

const PlainHeader = ({ board, variant, className, style }) => {
  let headerText

  switch (variant) {
    case VARIANTS.ICON:
      headerText = <IconText board={board} />
      break
    case VARIANTS.SIMPLE:
    default:
      headerText = <SimpleText board={board} />
      break
  }

  return (
    <div
      className={`list c-plain-header${className ? ` ${className}` : ''}`}
      style={style}
    >
      <div className='list-element'>{headerText}</div>
    </div>
  )
}

PlainHeader.VARIANTS = VARIANTS

export default PlainHeader
