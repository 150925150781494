import React from 'react'

const Settings = () => {
  return (
    <svg width='12' height='12' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.496 0l-.294 1.514a4.77 4.77 0 00-1.346.775l-1.454-.502L0 4.213l1.165 1.012c-.045.275-.068.53-.068.775s.024.5.068.775L0 7.789l1.402 2.425 1.453-.5c.398.326.853.586 1.347.773L4.496 12h2.801l.294-1.514a4.765 4.765 0 001.347-.775l1.454.502 1.4-2.425-1.163-1.013c.045-.275.068-.53.068-.775s-.023-.499-.068-.773v-.002l1.165-1.013-1.402-2.425-1.453.5a4.771 4.771 0 00-1.348-.773L7.297 0h-2.8zm.99 1.2h.822l.233 1.2.626.237c.377.142.716.337 1.01.579l.518.424 1.153-.396.412.711-.921.802.105.66c.037.226.053.414.053.583 0 .169-.016.357-.053.582l-.107.66.922.802-.412.712-1.152-.397-.519.425a3.558 3.558 0 01-1.009.58h-.001L6.54 9.6l-.233 1.2h-.821l-.234-1.2-.625-.237a3.565 3.565 0 01-1.01-.579l-.519-.424-1.153.396-.411-.711.922-.803-.106-.657v-.001A3.695 3.695 0 012.297 6c0-.169.016-.357.053-.582l.106-.66-.922-.802.411-.712 1.153.397.518-.425a3.565 3.565 0 011.01-.58l.626-.236.234-1.2zm.41 2.4a2.41 2.41 0 00-2.4 2.4 2.41 2.41 0 002.4 2.4 2.41 2.41 0 002.4-2.4 2.41 2.41 0 00-2.4-2.4zm0 1.2c.668 0 1.2.533 1.2 1.2 0 .667-.532 1.2-1.2 1.2-.666 0-1.2-.533-1.2-1.2 0-.667.534-1.2 1.2-1.2z'
        fill='#4E4E4E'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default Settings
