import logger from '../../requests/logger'
import Sockette from 'sockette'
import config from '../../config'
import { getAccessToken, checkConnection } from '../../helpers/Util'
import { HubConnectionBuilder } from '@microsoft/signalr'
import { SOCKET_BOARDS } from 'constants'

/*
  Websocket singleton to manage all websocket connections
*/

const DEFAULT_TIME = 1000
const DEFAULT_TIMEOUT = 10000
const MAX_ATTEMPTS = -1
const LOG_TO_CONSOLE = false
const SIGNALR_SOCKET_TYPE = 'SIGNALR_SOCKET_TYPE'
const BASE_WEB_SOCKET_TYPE = 'BASE_WEB_SOCKET_TYPE'

class BaseWebsocket {
  constructor() {
    this.cd = false
    this.loading = false
    this.sockets = []
    this.openlogs = {} // socket open log parameters, using to reopen sockets with new token
    this.updateReduxWebsocketStatus = () => {}
    this.removeReduxWebsocket = () => {}
    this.interval = setInterval(this.checkToken, 60 * 1000)
    this.socketInterval = setInterval(this.checkSockets, 5000)
  }

  initialize = ({ updateReduxWebsocketStatus, removeReduxWebsocket }) => {
    this.updateReduxWebsocketStatus = updateReduxWebsocketStatus
    this.removeReduxWebsocket = removeReduxWebsocket
  }

  checkToken = async () => {
    /*
     * clear interval for iframe created new websocket
     * pathname is /
     */
    const isDisconnected = checkConnection()
    if (!isDisconnected) {
      await getAccessToken()
    }
  }

  checkSockets = () => {
    const boardLogs = Object.keys(this.openlogs)
    const boards = boardLogs.filter(board => !this.sockets[board])
    if (!this.loading && boards.length && !this.cd) {
      this.cd = true
      setTimeout(() => (this.cd = false), 60 * 1000)
      this.openSockets(boards)
    }
  }

  clearCheckToken = () => {
    if (this.interval) {
      clearInterval(this.interval)
    }
    if (this.socketInterval) {
      clearInterval(this.socketInterval)
    }
  }

  makeSocketConnection(board, url, handleData) {
    this.log(`⌛️ Connecting to ${board} socket`)
    const ws = new Sockette(url, {
      timeout: DEFAULT_TIMEOUT,
      maxAttempts: MAX_ATTEMPTS,
      onmessage: ({ data }) => {
        try {
          handleData(JSON.parse(data))
        } catch (error) {
          logger({
            error,
            stackError: `socket parse error: ${data}`,
            type: 'socket parse error'
          })
        }
      },
      onopen: event => {
        this.updateReduxWebsocketStatus(board, 'open')
        this.log(`✅ Connected to ${board} socket`)
      },
      onreconnect: event => {
        this.updateReduxWebsocketStatus(board, 'reconnecting')
        this.log(`⌛️ Reconnecting to ${board} socket`)
      },
      onmaximum: event => {
        this.updateReduxWebsocketStatus(board, 'maximum')
        this.log(`⚠️ ${board} socket maximum reconnect attempts reached`)
      },
      onclose: event => {
        this.removeReduxWebsocket(board)
        if (this.sockets[board]) {
          this.sockets[board].close(DEFAULT_TIME)
          delete this.sockets[board]
          this.handleError(
            new Error(
              `${board} socket closed unexpectedly (${
                event.wasClean ? 'clean' : 'not clean'
              })`
            )
          )
        } else {
          this.log(
            `🛑 Disconnected from ${board} socket (${
              event.wasClean ? 'clean' : 'not clean'
            })`
          )
        }
      },
      onerror: error => {
        if (this.sockets[board]) {
          this.sockets[board].close(DEFAULT_TIME)
          delete this.sockets[board]
        }
        this.handleError(error)
      }
    })
    this.sockets[board] = ws
    this.sockets[board].socketType = BASE_WEB_SOCKET_TYPE
  }

  closeSocket(board, time = DEFAULT_TIME) {
    if (!this.sockets[board]) {
      delete this.openlogs[board]
      return
    }
    if (this.sockets[board].loading) {
      this.log('found one that is loading')
    }
    if (typeof this.sockets[board].close === 'function') {
      this.sockets[board].close(time)
    }
    delete this.openlogs[board]
    delete this.sockets[board]
  }

  restartSockets = () => {
    this.loading = true
    this.closeSockets()
    const boards = Object.keys(this.openlogs)
    setTimeout(() => {
      this.openSockets(boards)
      this.loading = false
    }, 1000)
  }

  closeSockets = () => {
    const boards = Object.keys(this.sockets)
    for (let board of boards) {
      if (this.sockets[board] && !this.sockets[board].loading) {
        if (
          this.sockets[board].socketType === BASE_WEB_SOCKET_TYPE &&
          typeof this.sockets[board].close === 'function'
        ) {
          this.sockets[board].close(DEFAULT_TIME)
        } else if (this.sockets[board].socketType === SIGNALR_SOCKET_TYPE) {
          this.sockets[board].stop()
        }
        delete this.sockets[board]
      }
    }
  }

  openSockets = boards => {
    for (let board of boards) {
      const openlog = this.openlogs[board]
      if (openlog) {
        const { department, onmessage, signalRSocket } = openlog
        if (signalRSocket) {
          this.openSignalRSocket(board, onmessage, department)
        } else {
          this.openSocket(board, department, onmessage)
        }
      }
    }
  }

  returnUrl = (board, department, params) => {
    const paramString = params
      ? Object.entries(params).reduce((resultString, [key, value]) => {
          if (key && value) {
            return resultString + `&${key}=${value}`
          }
          return resultString
        }, '')
      : ''
    switch (board) {
      case SOCKET_BOARDS.STARTS_V2:
      case SOCKET_BOARDS.STARTS_MNGMT:
        return '/starts/api/startsHub'
      case SOCKET_BOARDS.HOT_USER:
        return `${config.APIBase}/hubs/users`
      case SOCKET_BOARDS.USER_HUB:
        return `${config.APIBase}/hubs/users?department=${department}`
      case SOCKET_BOARDS.ASSIGNED_ACCOUNTS_HUB:
        return `${config.APIBase}/hubs/assignedaccounts?department=${department}`
      case SOCKET_BOARDS.JOBS_HUB:
        return `${config.APIBase}/hubs/joborders?department=${department}`
      case SOCKET_BOARDS.DEALS_HUB:
        return `${config.APIBase}/hubs/placements?department=${department}&board=deals${paramString}`
      case SOCKET_BOARDS.ACTIVITY_HUB:
        return `${config.APIBase}/hubs/activities?department=${department}`
      case SOCKET_BOARDS.HOT_ACTIVITY_HUB:
        return `${config.APIBase}/hubs/hotactivities?board=hotActivity`
      case SOCKET_BOARDS.HOT_JOBS_HUB:
        return `${config.APIBase}/hubs/hotjoborders?board=hotJobOrder`
      case SOCKET_BOARDS.FISCAL_MONTH_HUB:
        return `${config.APIBase}/hubs/fiscalmonths?department=${department}`
      default:
        this.log(`Unknown board ${board}`, true)
        return false
    }
  }

  returnTargetMethod = board => {
    switch (board) {
      case SOCKET_BOARDS.STARTS_V2:
      case SOCKET_BOARDS.STARTS_MNGMT:
        return 'ReceiveMessage'
      case SOCKET_BOARDS.ASSIGNED_ACCOUNTS_HUB:
      case SOCKET_BOARDS.DEALS_HUB:
      case SOCKET_BOARDS.JOBS_HUB:
      case SOCKET_BOARDS.USER_HUB:
      case SOCKET_BOARDS.HOT_USER:
      case SOCKET_BOARDS.ACTIVITY_HUB:
      case SOCKET_BOARDS.HOT_ACTIVITY_HUB:
      case SOCKET_BOARDS.FISCAL_MONTH_HUB:
      case SOCKET_BOARDS.HOT_JOBS_HUB:
        return 'Notify'
      default:
        this.log(`Unknown board ${board}`, true)
        return false
    }
  }

  log = (message, warn = false) => {
    if (!LOG_TO_CONSOLE) return
    if (warn) console.warn(message)
    else console.log(message)
  }

  openSocket = async (board, department, onmessage) => {
    const url = this.returnUrl(board, department)
    if (!url) return this.handleError(new Error(`Unknown board: ${board}`))
    if (!this.sockets[board]) {
      this.openlogs[board] = { board, department, onmessage }
      this.sockets[board] = { loading: true } // act as a debounce
      this.updateReduxWebsocketStatus(board, 'connecting')
      const token = await getAccessToken()
      if (token) {
        const wsUrl = new URL(url)
        wsUrl.searchParams.set('access_token', token)
        this.makeSocketConnection(board, wsUrl.toString(), onmessage)
      } else {
        delete this.sockets[board]
      }
    } else {
      if (!this.sockets[board].loading) {
        this.handleError(new Error(`${board} socket already connected`))
      } else {
        this.handleError(new Error(`${board} socket connection in progress...`))
      }
    }
  }

  openSignalRSocket = async (board, onMessage, department, params) => {
    const encodedDep = encodeURIComponent(department)
    const url = this.returnUrl(board, encodedDep, params)
    if (!url) return this.handleError(new Error(`Unknown board: ${board}`))
    const connection = new HubConnectionBuilder()
      .withUrl(url, { accessTokenFactory: getAccessToken })
      .withAutomaticReconnect()
      .build()
    if (connection) {
      connection
        .start()
        .then(result => {
          this.openlogs[board] = {
            board,
            onmessage: onMessage,
            department,
            signalRSocket: true
          }
          this.sockets[board] = connection
          this.sockets[board].socketType = SIGNALR_SOCKET_TYPE
          const targetMethod = this.returnTargetMethod(board)
          if (!targetMethod)
            console.warn(
              `Signal R socket board: "${board}" does not have a target method and will not be able to properly handle messages.`
            )
          this.sockets[board].targetMethod = targetMethod
          if (targetMethod && typeof onMessage === 'function') {
            const onMessageHandler = this.handleSignalROnMessage(onMessage)
            connection.on(targetMethod, onMessageHandler)
          }
        })
        .catch(e => this.handleError(e))
      connection.onclose(event => {
        if (this.sockets[board]) {
          this.sockets[board].close(DEFAULT_TIME)
          delete this.sockets[board]
        }
      })
    }
  }

  handleSignalROnMessage = onMessage => {
    return message => {
      let data
      if (message?.event || message?.eventType) {
        data = message
      } else if (typeof message === 'string') {
        data = JSON.parse(message)
      }
      if (data) {
        onMessage(data)
      } else {
        console.warn('Unknown SignalR event type', { message })
        onMessage({ event: 'error' })
      }
    }
  }
  closeSignalRSocket = async board => {
    const socket = this.sockets[board]
    if (socket) {
      socket.stop()
      delete this.sockets[board]
      delete this.openlogs[board]
    }
  }

  getSocketInstance = board => this.sockets[board]

  handleError = error => {
    this.log(error, true)
    logger({
      error,
      stackError: error.code
        ? `socket error code: ${error.code}`
        : 'unknown socket error'
    })
  }

  send = (board, data) => {
    const socket = this.sockets[board]
    if (!socket) {
      return this.handleError(new Error(`Socket for ${board} is not open!`))
    }
    if (socket.socketType === SIGNALR_SOCKET_TYPE) {
      socket.send(socket.targetMethod, JSON.stringify(data)).catch(e => {
        console.error(e)
      })
    } else {
      socket.json(data)
    }
  }
}

export default new BaseWebsocket()
