import React from 'react'

const OpenTimes = () => {
  return (
    <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg'>
      <g stroke='#1A1A1A' strokeWidth='2' fill='none' fillRule='evenodd'>
        <path d='M10 1a8.996 8.996 0 019 9 8.972 8.972 0 01-2.636 6.364A8.972 8.972 0 0110 19a8.972 8.972 0 01-6.364-2.636A8.972 8.972 0 011 10a8.972 8.972 0 012.636-6.364A8.972 8.972 0 0110 1z' />
        <circle fill='#D8D8D8' cx='10' cy='10' r='5' />
      </g>
    </svg>
  )
}

export default OpenTimes
