import tinycolor from 'tinycolor2'

export const returnGradient = (color, deg = 45, lighten = 25) => {
  const toColor = tinycolor(color)
    .lighten(lighten)
    .toString()
  return {
    background: `linear-gradient(${deg}deg, ${color}, ${toColor})`
  }
}
