import { patch } from '../../helpers/axios'

export default async function patchStarts(placementId, config = []) {
  const url = `/starts/${placementId}`
  const payload = config.map(({ attribute, value }) => {
    return {
      op: 'replace',
      path: `/${attribute}`,
      value
    }
  })
  const res = await patch(url, payload)
  return res
}
