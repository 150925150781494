import React from 'react'

const Hamburger = ({ className, onClick, width, height }) => {
  return (
    <svg
      width='28'
      height='20'
      viewBox='0 0 28 20'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 0v2.8h28V0H0zm0 8.4v2.8h28V8.4H0zm0 8.4v2.8h28v-2.8H0z'
        fill='#4E4E4E'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default Hamburger
