import { getDate } from '../../helpers/dateTime'
import {
  TOGGLE_ASSIGNED_ACCOUNTS_FILTER,
  GET_ASSIGNED_ACCOUNTS_BEGIN,
  GET_ASSIGNED_ACCOUNTS_SUCCESS,
  GET_ASSIGNED_ACCOUNTS_ERROR,
  SET_ASSIGNED_ACCOUNTS_COMPANY_FILTER,
  SET_ASSIGNED_ACCOUNTS_ACCOUNT_MANAGER_FILTER,
  SET_ASSIGNED_ACCOUNTS_DIVISION_FILTER,
  SET_ASSIGNED_ACCOUNTS_PROGRAM_FILTER,
  SET_ASSIGNED_ACCOUNTS_COMPANY_INPUT,
  SET_ASSIGNED_ACCOUNTS_AM_INPUT
} from '../actionTypes'

const DEFAULT_STATE = {
  meta: {
    loading: false,
    filterByCompany: true,
    filters: {
      company: [],
      accountManager: [],
      division: [],
      program: [],
      companyInput: '',
      amInput: ''
    }
  },
  updatedAt: null,
  data: []
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case GET_ASSIGNED_ACCOUNTS_BEGIN:
      return {
        ...state,
        meta: {
          ...state.meta,
          loading: true,
          error: false,
          filters: {
            ...state.meta.filters,
            companyInput: '',
            amInput: ''
          }
        }
      }
    case GET_ASSIGNED_ACCOUNTS_SUCCESS:
      return {
        ...state,
        meta: {
          ...state.meta,
          loading: false,
          error: false,
        },
        updatedAt: getDate(),
        data: action.payload
      }
    case GET_ASSIGNED_ACCOUNTS_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          loading: false,
          error: action.payload
        }
      }
    case TOGGLE_ASSIGNED_ACCOUNTS_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filterByCompany: action.payload
        }
      }
    case SET_ASSIGNED_ACCOUNTS_COMPANY_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters,
            company: action.payload,
            companyInput: ''
          }
        }
      }
    case SET_ASSIGNED_ACCOUNTS_ACCOUNT_MANAGER_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters,
            accountManager: action.payload,
            amInput: ''
          }
        }
      }
    case SET_ASSIGNED_ACCOUNTS_DIVISION_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters,
            division: action.payload
          }
        }
      }
    case SET_ASSIGNED_ACCOUNTS_PROGRAM_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters,
            program: action.payload
          }
        }
      }
    case SET_ASSIGNED_ACCOUNTS_COMPANY_INPUT:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters,
            companyInput: action.payload
          }
        }
      }
    case SET_ASSIGNED_ACCOUNTS_AM_INPUT:
      return {
        ...state,
        meta: {
          ...state.meta,
          filters: {
            ...state.meta.filters,
            amInput: action.payload
          }
        }
      }
    default:
      return state
  }
}
