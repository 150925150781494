import React from 'react'

const TableHeader = ({ goalsHeader, headers, color }) => {
  const renderGoalsHeader = () => {
    if (goalsHeader) {
      return (
        <tr>
          <td>{goalsHeader}</td>
        </tr>
      )
    }

    return null
  }
  return (
    <thead>
      {renderGoalsHeader()}
      <tr>
        {headers.map((column, key) => (
          <th
            style={{ color: color }}
            width={column.width}
            key={`headers-${key}`}
            className='row-header'
          >
            {column.value}
          </th>
        ))}
      </tr>
    </thead>
  )
}

export default TableHeader
