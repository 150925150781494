import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setJobOrdersOpenTimesToggle,
  setMultiDivisionsToggle
} from '../../../../redux/actions'
import { selectJobOrdersFilters } from '../../../../redux/selectors/jobOrders'
import { ToggleBtn } from 'components/shared'
import { selectActiveUser } from 'redux/selectors'

const JobsBoardToggles = ({ includeLabelSpacer = false }) => {
  const dispatch = useDispatch()
  const jobOrdersFilters = useSelector(selectJobOrdersFilters)
  const user = useSelector(selectActiveUser)
  const onToggle = useCallback(
    value => dispatch(setJobOrdersOpenTimesToggle(value)),
    [dispatch]
  )
  const onMultiDivisionToggle = useCallback(
    value => dispatch(setMultiDivisionsToggle(value)),
    [dispatch]
  )
  return (
    <>
      <div className='filter-header-input open-times'>
        {includeLabelSpacer ? (
          <div className='filter-header-input-label'>&nbsp;</div>
        ) : null}
        <div className='filter-header-input-custom'>
          <ToggleBtn
            activeColor={`#${user.color}`}
            onChange={onToggle}
            checked={jobOrdersFilters.openTimes}
          />
          <span>&nbsp;Open times only</span>
        </div>
      </div>
      <div className='filter-header-input open-times'>
        {includeLabelSpacer ? (
          <div className='filter-header-input-label'>&nbsp;</div>
        ) : null}
        <div className='filter-header-input-custom'>
          <ToggleBtn
            activeColor={`#${user.color}`}
            onChange={onMultiDivisionToggle}
            checked={jobOrdersFilters.multiDivisions}
          />
          <span>&nbsp;Multi-Division</span>
        </div>
      </div>
    </>
  )
}

export default JobsBoardToggles
