import React from 'react'

import Skeleton from 'components/shared/Skeleton'
import SkeletonFullWidthCard from '../shared/SkeletonFullWidthCard'

const DashboardSkeleton = () => {
  return (
    <div>
      <SkeletonFullWidthCard height={115} />
      <div style={{ display: 'flex' }}>
        {/* Left Column start */}
        <div style={{ marginRight: 37, marginBottom: 43 }}>
          {/* Left Section 1 start  */}
          <Skeleton.Input
            active
            style={{ width: 293, height: 20, marginBottom: 20 }}
          />
          <div style={{ marginBottom: 35, display: 'flex' }}>
            {/* Left Section 1 - Left half-column start */}
            <div
              style={{
                marginRight: 20,
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Skeleton.Input
                active
                style={{ width: 295, height: 282, marginBottom: 20 }}
              />
              <Skeleton.Input
                active
                style={{ width: 295, height: 213, marginBottom: 20 }}
              />
              <Skeleton.Input active style={{ width: 295, height: 108 }} />
            </div>
            {/* Left Section 1 - Left half-column end */}

            {/* Left Section 1 - Right half-column start */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Skeleton.Input
                active
                style={{ width: 279, height: 130, marginBottom: 20 }}
              />
              <Skeleton.Input
                active
                style={{ width: 279, height: 132, marginBottom: 20 }}
              />
              <Skeleton.Input
                active
                style={{ width: 279, height: 213, marginBottom: 20 }}
              />
              <Skeleton.Input active style={{ width: 279, height: 108 }} />
            </div>
            {/* Left Section 1 - Right half-column end */}
          </div>
          {/* Left Section 1 end  */}

          {/* Left Section 2 start  */}
          <Skeleton.Input
            active
            style={{ width: 293, height: 20, marginBottom: 20 }}
          />
          <div style={{ display: 'flex' }}>
            {/* Left Section 2 - Left half-column start */}
            <div
              style={{
                marginRight: 20,
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Skeleton.Input
                active
                style={{ width: 284, height: 140, marginBottom: 20 }}
              />
              <Skeleton.Input
                active
                style={{ width: 284, height: 140, marginBottom: 20 }}
              />
              <Skeleton.Input active style={{ width: 284, height: 140 }} />
            </div>
            {/* Left Section 2 - Left half-column end */}

            {/* Left Section 2 - Right half-column start */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Skeleton.Input
                active
                style={{ width: 284, height: 140, marginBottom: 20 }}
              />
              <Skeleton.Input
                active
                style={{ width: 284, height: 140, marginBottom: 20 }}
              />
              <Skeleton.Input active style={{ width: 284, height: 140 }} />
            </div>
            {/* Left Section 2 - Right half-column end */}
          </div>
          {/* Left Section 2 end  */}
        </div>
        {/* Left Column end */}

        {/* Right Column start */}
        <div style={{ marginRight: 37, marginBottom: 43 }}>
          {/* Right Section 1 start */}
          <Skeleton.Input
            style={{ width: 293, height: 20, marginBottom: 20 }}
            active
          />
          <div style={{ marginBottom: 35, display: 'flex' }}>
            <div
              style={{
                marginRight: 20,
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Skeleton.Input
                active
                style={{ width: 280, height: 110, marginBottom: 20 }}
              />
              <Skeleton.Input active style={{ width: 280, height: 110 }} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Skeleton.Input
                active
                style={{ width: 280, height: 110, marginBottom: 20 }}
              />
              <Skeleton.Input active style={{ width: 280, height: 110 }} />
            </div>
          </div>
          {/* Right Section 1 End */}

          {/* Right Section 2 Start */}
          <div style={{ marginBottom: 35, display: 'flex' }}>
            <div
              style={{
                marginRight: 20,
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Skeleton.Input
                style={{ width: 311, height: 20, marginBottom: 20 }}
                active
              />
              <Skeleton.Input active style={{ width: 321, height: 331 }} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Skeleton.Input
                style={{ width: 232, height: 20, marginBottom: 20 }}
                active
              />
              <Skeleton.Input active style={{ width: 232, height: 331 }} />
            </div>
          </div>
          {/* Right Section 2 End */}

          {/* Right Section 3 Start */}
          <Skeleton.Input
            style={{ width: 293, height: 20, marginBottom: 20 }}
            active
          />
          <div style={{ display: 'flex' }}>
            <div
              style={{
                marginRight: 20,
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Skeleton.Input
                style={{ width: 284, height: 140, marginBottom: 20 }}
                active
              />
              <Skeleton.Input
                style={{ width: 284, height: 140, marginBottom: 20 }}
                active
              />
              <Skeleton.Input style={{ width: 284, height: 140 }} active />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Skeleton.Input
                style={{ width: 284, height: 140, marginBottom: 20 }}
                active
              />
              <Skeleton.Input
                style={{ width: 284, height: 140, marginBottom: 20 }}
                active
              />
              <Skeleton.Input style={{ width: 284, height: 140 }} active />
            </div>
          </div>
          {/* Right Section 3 End */}
        </div>
        {/* Right Column end */}
      </div>

      {/* Stack Rank Skeleton */}
      <div style={{ marginBottom: 20 }}>
        <Skeleton.Input
          style={{ width: 381, height: 20, marginBottom: 20 }}
          active
        />
        <Skeleton.Input style={{ width: 1211, height: 319 }} active />
      </div>
    </div>
  )
}

export default DashboardSkeleton
