import React from 'react'
import { useSelector } from 'react-redux'
import { formatFiscalDisplayDate } from '../../helper'
import { selectDashboardCustomFiscalDate } from 'redux/selectors/dashboard'

const selectCurrentFiscalMonthStart = state =>
  state?.department?.settings?.['current_fiscal_month_start']
const selectCurrentFiscalMonthEnd = state =>
  state?.department?.settings?.['current_fiscal_month_end']

const FiscalDate = React.memo(() => {
  const startDate = useSelector(selectCurrentFiscalMonthStart)
  const endDate = useSelector(selectCurrentFiscalMonthEnd)
  const customFiscalDate = useSelector(selectDashboardCustomFiscalDate)
  const customFiscalDateDisplay = customFiscalDate
    ? formatFiscalDisplayDate(
        customFiscalDate.startDate,
        customFiscalDate.endDate
      )
    : null
  const dateDisplay =
    startDate && endDate && formatFiscalDisplayDate(startDate, endDate)
  return (
    <span className='g-cardtitle--regular'>
      {customFiscalDateDisplay || dateDisplay || ''}
    </span>
  )
})

FiscalDate.displayName = 'FiscalDate'

export default FiscalDate
