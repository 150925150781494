import React, { Component } from 'react'
import _ from 'lodash'
import checkHeights from 'components/hocs/masonryPagination/checkHeights'
import TouchScreenPagination from '../Pagination'
import { ADVANCED, SIMPLE } from 'constants'

const SECONDS_PER_SCREEN = 10
export default class Board extends Component {
  constructor(props) {
    super(props)
    this.state = {
      paused: this.props.isPaused,
      page: 0,
      chunks: [],
      intervalId: null
    }
    this.checkingHeights = false
    this.calcOverflowNeeded = true
    this.resetOverflowCalc = this.resetOverflowCalc.bind(this)
    this.checkHeights = checkHeights.bind(this)
    this.handlePaginationOnChange = this.handlePaginationOnChange.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !this.checkingHeights
  }

  componentDidMount() {
    if (this.props.data.length > 0 && !this.calculatingPagination) {
      this.resetOverflowCalc()
    }
    if (this.props.headerless && !this.state.paused) {
      this.startTimer()
    }
  }

  componentWillUnmount() {
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const currTotalPageNumber = this.state.chunks.length
    const currPageNumber = this.state.page + 1
    const isLastPage = currTotalPageNumber === currPageNumber
    if (
      isLastPage &&
      prevState.chunks.length !== 0 &&
      prevState.page !== this.state.page
    ) {
      this.props.handleInternalPagination &&
        this.props.handleInternalPagination(null, true)
      clearInterval(this.state.intervalId)
    }

    if (prevState.chunks.length !== currTotalPageNumber) {
      const hasPagination = currTotalPageNumber > 1 ? true : false
      this.props.handleInternalPagination &&
        this.props.handleInternalPagination(hasPagination, null)
    }

    if (prevState.page !== this.state.page) {
      this.props.handleInternalPagination &&
        this.props.handleInternalPagination(null, null, currPageNumber)
    }

    if (prevProps.isPaused !== this.props.isPaused) {
      if (this.props.isPaused) {
        clearInterval(this.state.intervalId)
        this.setState({
          page: this.state.page,
          paused: true,
          intervalId: null
        })
      } else {
        this.setState({ paused: false }, this.startTimer)
      }
    }

    // setting boardPage to null will cause another update to happen
    // so to make sure we use the actual value and it's not overwritten by
    // the previous logic, we use the prevProps boardPage value
    if (prevProps.boardPage !== null && !this.checkingHeights) {
      if (prevProps.boardPage === 'lastPage') {
        const lastPage = this.state.chunks.length
          ? this.state.chunks.length - 1
          : 0
        this.setState({ page: lastPage, lastPage: lastPage })
      } else if (typeof prevProps.boardPage === 'number') {
        this.setState({
          page: prevProps.boardPage,
          lastPage: prevProps.boardPage
        })
      }
      if (typeof this.props.setBoardPage === 'function') {
        this.props.setBoardPage(null)
      }
    }
    if (
      prevProps.boardPage !== this.props.boardPage &&
      this.props.boardPage !== null
    ) {
      this.setState({
        page: this.props.boardPage,
        intervalId: null
      })
    }
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      this.resetOverflowCalc()
    }
  }

  handleOverflowPagination = async elements => {
    if (this.calcOverflowNeeded && !this.checkingHeights) {
      this.calcOverflowNeeded = false
      this.checkingHeights = true
      const chunks = this.checkHeights(elements)
      this.checkingHeights = false
      this.setState(
        {
          page: this.state.lastPage || 0,
          chunks
        },
        () => {
          this.calculatingPagination = false
          if (this.props.headerless) {
            if (
              this.state.chunks.length &&
              !this.state.intervalId &&
              !this.state.paused
            ) {
              this.startTimer()
            } else {
              if (!this.state.chunks.length) {
                clearInterval(this.state.intervalId)
                this.setState({ paused: false, intervalId: null })
              }
            }
          }
        }
      )
    }
  }

  // This is pretty important. By setting the below to true, on the next render the full list will render
  // and trigger a recalculation of the pagination. Since it's true, the recalculation will be done with every AM on the
  // screen again. Boards call this by getting a ref to this component.
  resetOverflowCalc() {
    this.calculatingPagination = true
    this.calcOverflowNeeded = true
    this.setState(
      {
        chunks: [],
        page: -1,
        lastPage: this.state.page
      },
      () => {
        this.handleOverflowPagination(
          Array.from(document.getElementsByClassName('element-container'))
        )
      }
    )
  }

  startTimer = () => {
    if (!this.state.intervalId && this.state.chunks.length > 1) {
      const intervalId = setInterval(this.timer, SECONDS_PER_SCREEN * 1000)
      this.setState({ intervalId })
    }
  }

  timer = () => {
    if (this.state.chunks.length) {
      this.setState({
        page: this.nextPage()
      })
    }
  }

  prePage = () => {
    const { page } = this.state
    if (this.state.chunks.length > 1) {
      if (page <= 0) {
        return this.state.chunks.length - 1
      } else {
        return page - 1
      }
    } else {
      return 0
    }
  }

  nextPage = () => {
    const { page } = this.state
    if (this.state.chunks.length > 1) {
      if (page >= this.state.chunks.length - 1) {
        return 0
      } else {
        return page + 1
      }
    } else {
      return 0
    }
  }

  renderElementsHeaderless = () => {
    if (this.checkingHeights) {
      return null
    }

    if (!this.state.chunks.length) {
      return this.props.data.map(d => {
        return this.props.renderElement(
          this.props.renderElementUsesData ? d : d.bhId
        )
      })
    } else {
      if (!this.state.chunks[this.state.page]) {
        return null
      }

      // pass the [[columns]] into the render function
      return this.props.renderElement(this.state.chunks[this.state.page])
    }
  }

  renderElementsHeader = () => {
    if (this.checkingHeights) {
      return null
    }

    return this.filterData(this.props.data).map(d => {
      return this.props.renderElement(
        this.props.renderElementUsesData ? d : d.bhId
      )
    })
  }

  handlePaginationOnChange = nextPage => {
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId)
    }
    if (typeof this.props.handleInternalPagination === 'function') {
      this.props.handleInternalPagination(null, null, null, true, nextPage)
    }
    this.setState({
      page: typeof nextPage === 'number' ? nextPage - 1 : this.state.page,
      paused: true
    })
  }

  renderPageNumber() {
    const { paginationType } = this.props
    const handlePrevBoard = () => {
      this.handlePaginationOnChange('prev')
    }
    const handleNextBoard = () => {
      this.handlePaginationOnChange('next')
    }
    return (
      <div
        style={{
          position: 'fixed',
          fontSize: '25px',
          bottom: `${paginationType === ADVANCED ? 175 : 200}px`,
          left: '50%',
          transform: 'translateX(-50%)'
        }}
      >
        {paginationType === SIMPLE ? (
          `${this.state.page + 1} / ${this.state.chunks.length}`
        ) : (
          <TouchScreenPagination
            total={this.state.chunks.length || 1}
            current={this.state.page + 1}
            onChange={this.handlePaginationOnChange}
            handlePrevBoard={handlePrevBoard}
            handleNextBoard={handleNextBoard}
          />
        )}
      </div>
    )
  }

  filterData = data => {
    let filteredData = data
    _.forEach(this.state.filters, (filterState, fieldValue) => {
      filteredData = filteredData.filter(datum => {
        if (Array.isArray(filterState)) {
          return filterState.indexOf(_.get(datum, fieldValue)) !== -1
        } else {
          return _.get(datum, fieldValue) === filterState
        }
      })
    })
    return filteredData
  }
  render() {
    return (
      <React.Fragment>
        {this.props.renderHeader ? this.props.renderHeader() : null}
        {this.props.headerless
          ? this.renderElementsHeaderless()
          : this.renderElementsHeader()}
        {this.props.headerless &&
        (this.state.chunks.length > 1 || this.props.paginationType === ADVANCED)
          ? this.renderPageNumber()
          : null}
      </React.Fragment>
    )
  }
}
