import React from 'react'

const ConsultantCardProgressBar = ({ progressRemaining }) => {
  return (
    <div className='c-consultant-card__progress'>
      <div className='c-consultant-card__progress-bar' />
      <div
        className='c-consultant-card__progress-bar c-consultant-card__progress-bar--remaining'
        style={{ width: `${progressRemaining}%` }}
      />
    </div>
  )
}

export default ConsultantCardProgressBar
