import React from 'react'
import TaskAltIcon from '@mui/icons-material/TaskAlt'

const Header = ({ selectedDepartment, board }) => {
  return (
    <div className='c-ts-header'>
      <TaskAltIcon style={{ color: '#ff0000' }} fontSize='large' />
      <span>{selectedDepartment}</span>
      <i className='c-ts-header__separator'></i>
      <span>{board}</span>
    </div>
  )
}

export default Header
