import React, { Component } from 'react'
import AddCircle from '@mui/icons-material/AddCircleOutline'
import { getSubmissions } from '../../../../../requests'
import BaseWebsocket from '../../../../../network/websocket/BaseWebsocket'
import NotReadyIndicator from '../NotReadyIndicator'
import { parseUtcDate } from '../../../../../helpers/dateTime'
import AddCandidateMenu from './AddCandidateMenu'
import { DeleteIcon, Edit } from 'components/shared/Icons'

class JobSlotHeader extends Component {
  state = {
    targetEl: null,
    available: []
  }

  filterAvailableSubmissions(submissions) {
    let { slot } = this.props
    if (!slot.jobSubmissions.length) {
      return submissions
    } else {
      return submissions.filter(submission => {
        return !slot.jobSubmissions.find(js => js.bhId === submission.bhId)
      })
    }
  }

  render() {
    let { slotColor, job, slot } = this.props
    return (
      <div className='job-slot-header'>
        <div className='job-slot-header-leftbox'>
          <div style={{ color: slotColor }}>
            {parseUtcDate(slot.date, 'MM/DD')} |{' '}
            {parseUtcDate(slot.date).format('hh:mm A')} {slot.timezone}
          </div>
          {slot.isNotReady && <NotReadyIndicator />}
        </div>
        <div
          className={`job-slot-header-rightbox ${this.props.headerless && !this.props.useTouchscreenBoard ? 'headerless' : ''
            }`}
        >
          <button
            className='c-btn--responsive c-btn--pointer-cursor c-btn--bare'
            onClick={() => {
              this.props.setSelectedJO(job.bhId, slot.id)
            }}
          >
            <Edit
              onClick={() => {
                this.props.setSelectedJO(job.bhId, slot.id)
              }}
            />
          </button>
          <button
            className='c-btn--responsive c-btn--pointer-cursor c-btn--bare'
            onClick={() => {
              if (slot.jobSubmissions.length) {
                window.alert(
                  'You can not remove an interview slot with submissions'
                )
              } else {
                let confirm = window.confirm(
                  'Are you sure you want to remove this slot?'
                )
                if (confirm) {
                  BaseWebsocket.send(this.props.boardType, {
                    event: 'slot_remove',
                    slotId: slot.id
                  })
                }
              }
            }}
          >
            <DeleteIcon />
          </button>
          <button
            className='c-btn--responsive c-btn--pointer-cursor c-btn--bare'
            onClick={async e => {
              const request = await getSubmissions(job.bhId)
              const available = request
                ? this.filterAvailableSubmissions(request.data)
                : []
              const targetEl = document.querySelector(`#add-button-${slot.id}`)
              this.setState({
                targetEl,
                available
              })
            }}
          >
            <AddCircle
              sx={{ fontSize: '22px', color: '#8E8E8E' }}
              id={`add-button-${slot.id}`}
            />
          </button>
        </div>
        <AddCandidateMenu
          jobSubmissions={this.state.available}
          user={this.props.user}
          slotId={slot.id}
          activeUser={this.props.auth.activeUser}
          addSubmissionToSlotFxn={this.props.addSubmissionToSlotFxn}
          anchorEl={this.state.targetEl}
          onClose={() => this.setState({ targetEl: null })}
        />
      </div>
    )
  }
}

export default JobSlotHeader
