import React from 'react'

const Starts = () => {
  return (
    <svg width='18' height='20' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8 0v2.059c-4.49.5-8 4.32-8 8.941 0 4.959 4.041 9 9 9s9-4.041 9-9c0-4.62-3.51-8.441-8-8.941V0H8zm1 4c3.878 0 7 3.122 7 7s-3.122 7-7 7-7-3.122-7-7 3.122-7 7-7zM8 6v5.414l3.293 3.293 1.414-1.414L10 10.586V6H8z'
        fill='#4E4E4E'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default Starts
