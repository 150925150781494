import React from 'react'
import { Radio } from 'antd'

import StatusBtn from '../../../../shared/StatusBtn'

const returnStatusProps = status =>
  status ? { check: true } : { emptyCircle: true }

const RightCol = ({
  empty,
  radioItems,
  radioValue,
  onToggle,
  isComplete,
  isNotApplicable
}) => {
  const rightColClassname = [
    'c-onboarding-paperwork__col',
    'c-onboarding-paperwork__col--right',
    empty && 'c-onboarding-paperwork__col-header'
  ]
    .filter(v => v)
    .join(' ')
  if (empty) return <div className={rightColClassname} />
  if (radioItems?.length) {
    return (
      <div className={rightColClassname}>
        <Radio.Group
          className='c-onboarding-paperwork__radio-group'
          onChange={e => onToggle(e?.target?.value)}
          value={radioValue}
          disabled={isNotApplicable}
        >
          {radioItems.map(item => (
            <Radio key={`${item.value || item}`} value={item.value || item}>
              {item.label || item.value || item}
            </Radio>
          ))}
        </Radio.Group>
      </div>
    )
  }
  return (
    <div className={rightColClassname}>
      <span>
        <StatusBtn
          onClick={() => onToggle('isComplete')}
          statusProps={returnStatusProps(isComplete)}
        />
        Complete
      </span>
      <span>
        <StatusBtn
          onClick={() => onToggle('isNotApplicable')}
          statusProps={returnStatusProps(isNotApplicable)}
        />
        N/A
      </span>
    </div>
  )
}

const PaperworkItem = ({
  title,
  isComplete,
  isNotApplicable,
  onToggle,
  sectionHeader,
  subItem,
  radioItems,
  radioValue
}) => {
  const leftColClassname = [
    'c-onboarding-paperwork__col',
    'c-onboarding-paperwork__col--left',
    subItem && 'c-onboarding-paperwork__col--left-sub',
    sectionHeader && 'c-onboarding-paperwork__col-header'
  ]
    .filter(v => v)
    .join(' ')
  return (
    <div className='c-onboarding-paperwork__item'>
      <div className={leftColClassname}>{title}</div>
      <RightCol
        empty={sectionHeader}
        radioItems={radioItems}
        radioValue={radioValue}
        onToggle={onToggle}
        isComplete={isComplete}
        isNotApplicable={isNotApplicable}
      />
    </div>
  )
}

export default PaperworkItem
