import React from 'react'
import { Tooltip } from 'antd'

export const TableHeader = React.memo(({ children, onClick }) => (
  <tbody onClick={onClick} className='assigned-accounts-card-table-header'>
    {children}
  </tbody>
))

TableHeader.displayName = 'TableHeader'

export const TableHeaderCell = React.memo(
  ({ children, onClick, className, showtooltip }) => {
    if (!showtooltip) {
      return (
        <th onClick={onClick} className={className}>
          {children}
        </th>
      )
    }
    return (
      <Tooltip
        arrowPointAtCenter
        placement='left'
        title={
          <div className='antd-tooltip-text-style'>
            Total ALKU Consultants Currently On Billing
          </div>
        }
      >
        <th onClick={onClick} className={className}>
          {children}
        </th>
      </Tooltip>
    )
  }
)

TableHeaderCell.displayName = 'TableHeaderCell'
