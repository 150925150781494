export const formatDepartmentColors = departments => {
  return departments.reduce((depObj, nextDep) => {
    const { displayName, color } = nextDep
    depObj[displayName] = `#${color}`
    return depObj
  }, {})
}

export const formatDisplayNames = departments => {
  return departments.map(({ displayName }) => displayName).sort()
}

export default departments => {
  const displayNames = formatDisplayNames(departments)
  const departmentColors = formatDepartmentColors(departments)
  return {
    displayNames,
    departmentColors,
    departmentList: departments
  }
}
