import { getCandidateHistory } from '../../../../../requests'

const renderCandidateMenuItems = (
  submission,
  department,
  job,
  interviewSlots,
  bhId,
  securityClearance,
  referenceCheck,
  candidate,
  candidateBhId,
  targetEl,
  setCandidateHistoryMenu,
  changeSubmissionState,
  headerless = false
) => {
  const items = []
  const viewHistory = async () => {
    const request = await getCandidateHistory(candidateBhId)
    setCandidateHistoryMenu({
      data: request.data,
      name: `History: ${candidate.firstName} ${candidate.lastName}`,
      jobSubmission: job.JobSubmissions.find(jos => {
        return jos.bhId === bhId
      }),
      anchorEl: targetEl,
      interviewSlots
    })
  }
  if (submission.Candidate.url && !headerless) {
    items.push({
      url: submission.Candidate.url,
      primaryText: 'View on Bullhorn'
    })
  }
  items.push({
    primaryText: 'View History',
    onClick: viewHistory
  })
  items.push({
    primaryText: 'Toggle Reference Check',
    onClick: () => {
      changeSubmissionState(bhId, 'referenceCheck', !referenceCheck)
    }
  })
  if (department.settings.security_clearance) {
    items.push({
      primaryText: 'Toggle Security Clearance',
      onClick: () => {
        changeSubmissionState(bhId, 'securityClearance', !securityClearance)
      }
    })
  }
  return items
}

export default renderCandidateMenuItems
