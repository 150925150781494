const groupPaperwork = paperwork => {
  const groupedPaperwork = []
  const groupIndex = {}
  paperwork.forEach(p => {
    if (p) {
      if (p.groupName) {
        const index = groupIndex[p.groupName]
        if (index) {
          groupedPaperwork[index].groupedPaperwork.push(p)
        } else {
          const nextIndex = groupedPaperwork.length
          const groupDoc = {
            title: p.groupName,
            groupName: p.groupName,
            isNotApplicable: false,
            isComplete: false,
            answerTypeId: 'group-doc',
            answerTypeName: null,
            answers: [],
            groupedPaperwork: [p]
          }
          groupedPaperwork.push(groupDoc)
          groupIndex[p.groupName] = nextIndex
        }
      } else {
        groupedPaperwork.push(p)
      }
    }
  })
  // determine if the paperwork with subitems is not applicable using subitems
  Object.values(groupIndex).forEach(index => {
    let isNotApplicableCount = 0,
      isCompleteCount = 0
    const paperwork = groupedPaperwork[index]
    paperwork.groupedPaperwork.forEach(p => {
      if (p.isNotApplicable) isNotApplicableCount++
      if (p.isComplete) isCompleteCount++
    })
    const isNotApplicable =
      isNotApplicableCount === paperwork.groupedPaperwork.length
    const isComplete = isCompleteCount === paperwork.groupedPaperwork.length
    groupedPaperwork[index].isNotApplicable = isNotApplicable
    groupedPaperwork[index].isComplete = isComplete
  })
  return groupedPaperwork
}

const processStart = start => {
  const startCopy = Object.assign({}, start)
  const groupedPaperwork = groupPaperwork(start.paperworkJson)
  startCopy.formattedPaperworkJson = groupedPaperwork
  return startCopy
}

const transformStartsData = startsData => {
  if (Array.isArray(startsData)) {
    return startsData.map(start => {
      if (start?.paperworkJson?.find(p => p.groupName)) {
        return processStart(start)
      }
      return start
    })
  }
  return processStart(startsData)
}

export default transformStartsData
