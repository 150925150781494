import React from 'react'

const CirclePercent = ({ month }) => (
  <div className={`g-circle-percent ${month ? 'g-circle-percent--month' : ''}`}>
    <div className='g-circle-percent__line'>|</div>
    <div className='g-circle-percent__dot g-circle-percent__dot--front' />
    <div className='g-circle-percent__dot g-circle-percent__dot--back' />
  </div>
)

export default CirclePercent
