import React from 'react'
import { toast } from 'react-toastify'
import CheckIcon from '@mui/icons-material/Check'

const renderSuccessMsg = msg => {
  return (
    <div className='c-notice__msg'>
      <CheckIcon sx={{ fontSize: 20, color: '#009F00' }} />
      {msg}
    </div>
  )
}

export default {
  success: (msg, options = {}) => {
    return toast.success(renderSuccessMsg(msg), {
      className: 'c-notice c-notice__success',
      progressClassName:
        'c-notice__progress-bar c-notice__progress-bar--success',
      ...options
    })
  },
  error: (msg, options = {}) => {
    return toast.error(msg, {
      hideProgressBar: true,
      className: 'c-notice c-notice__error',
      ...options
    })
  },
  options: {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    newestOnTop: false,
    closeOnClick: true,
    pauseOnHover: true,
    closeButton: false,
    draggable: false
  }
}
