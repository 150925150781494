import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { SmartInput } from 'components/layout/header/subComponents/SmartInput'
import filterConfig from './helpers/filterConfig'
import {
  setPiDBBoardFilter,
  setPiDbDetailsModal,
  setPiDBDivisionFilter,
  setPiDBSearchFilter
} from 'redux/actions/piDB'

const selectPiDBFilters = state => state.piDb.meta.filters
const selectPiDBdata = state => state.piDb.data

const ActionBar = () => {
  const dispatch = useDispatch()
  const piDBFilters = useSelector(selectPiDBFilters)
  const data = useSelector(selectPiDBdata)
  const openAddDeviceModal = useCallback(() => {
    dispatch(
      setPiDbDetailsModal({
        open: true,
        data: null,
        mode: 'add'
      })
    )
  }, [dispatch])
  const handleFilterChange = useCallback(
    (filter, value) => {
      switch (filter) {
        case 'search':
          return dispatch(setPiDBSearchFilter(value))
        case 'board':
          return dispatch(setPiDBBoardFilter(value))
        case 'division':
          return dispatch(setPiDBDivisionFilter(value))
        default:
          console.warn('Unknown pidb filter', filter)
      }
    },
    [dispatch]
  )
  const renderFilters = useCallback(() => {
    const filters = filterConfig(data)

    return filters.map(filter => {
      if (filter.hidden) {
        return null
      }
      return (
        <SmartInput
          key={`pidb_${filter.filterLabel}_filter`}
          label={filter.label}
          filterLabel={filter.filterLabel}
          value={piDBFilters[filter.filterLabel]}
          dataSource={filter.options}
          multiSelect={filter.multiSelect}
          onChange={value => handleFilterChange(filter.filterLabel, value)}
          placeholder={filter.placeholder}
          showArrow={filter.showArrow}
        />
      )
    })
  }, [piDBFilters, handleFilterChange, data])

  return (
    <div className='c-pidb__action-bar'>
      {renderFilters()}
      <button
        className='c-btn--cta-1 c-btn c-btn--bare'
        onClick={openAddDeviceModal}
      >
        Add Device
      </button>
    </div>
  )
}

export default ActionBar
