import React from 'react'
import { CardTitle, WeekDeals, MonthDeals, FiscalDate } from './components'

const DealsThisWeekMonth = ({ idPrefix = '' }) => {
  return (
    <div className='c-dashboard__content c-dashboard__content--deals'>
      <div>
        <CardTitle id={`${idPrefix}DealWeek`}>Deals This Week</CardTitle>
        <div className='c-dashboard__content__left'>
          <WeekDeals />
        </div>
      </div>
      <div>
        <CardTitle
          id={`${idPrefix}DealsMonth`}
          className='c-dashboard__content__right'
        >
          Deals This Month <FiscalDate />
        </CardTitle>
        <div className='c-dashboard__content__right'>
          <MonthDeals />
        </div>
      </div>
    </div>
  )
}

export default DealsThisWeekMonth
