import React from 'react'
import BoardCard from '../BoardCard'
import DealsIcon from '../DealsIcon'
import numeral from 'numeral'

const DealsCard = ({
  title,
  type,
  decimal,
  bag,
  division,
  brand,
  total,
  month,
  hasSubBrand,
  subBrand
}) => {
  const list = [
    { label: 'Division', value: division },
    hasSubBrand ? { label: 'Sub-Brand', value: subBrand } : null,
    { label: 'Brand', value: brand },
    { label: 'ALKU', value: total }
  ].filter(v => v)

  const numbers = [division, brand, total].map(n => n.toString().length)
  const maxLen = Math.max(...numbers)
  let size

  if (maxLen <= 3) {
    size = 'sm'
  } else if (maxLen <= 6) {
    size = 'md'
  } else {
    size = 'lg'
  }

  return (
    <BoardCard className='c-dealscard'>
      <div className='c-dealscard__title'>{title}</div>
      <div className='c-dealscard__content'>
        <div className='c-dealscard__icon'>
          <DealsIcon type={type} month={month} bag={bag} />
        </div>
        <div
          className={`c-dealscard__data${hasSubBrand ? ' has-sub-brand' : ''}`}
        >
          {list.map(({ value, label }, index) => (
            <div key={index}>
              <div
                className={`c-dealscard__data__value c-dealscard__data__value--${size}`}
              >
                {numeral(value).format(decimal ? '0,0.00' : '0,0.[00]')}
              </div>
              <div
                className={`c-dealscard__data__label c-dealscard__data__label--${size}${
                  hasSubBrand ? ' has-sub-brand' : ''
                }`}
              >
                {label}
              </div>
            </div>
          ))}
        </div>
      </div>
    </BoardCard>
  )
}

export default DealsCard
