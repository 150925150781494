import React, { useEffect, useState, useCallback } from 'react'
import { CalendarTodayOutlined } from '@mui/icons-material'
import { Select } from 'antd'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'

import {
  selectDepartmentSettings,
  selectFiscalMonthData
} from 'redux/selectors'
import Skeleton from '../Skeleton'
import { formatFiscalDisplayDate } from 'components/boards/dashBoard/helper'
import { loadFiscalMonthData } from 'redux/actions'

const INPUT_WIDTH = 120

const baseOptions = [
  { value: 0, label: 'January' },
  { value: 1, label: 'February' },
  { value: 2, label: 'March' },
  { value: 3, label: 'April' },
  { value: 4, label: 'May' },
  { value: 5, label: 'June' },
  { value: 6, label: 'July' },
  { value: 7, label: 'August' },
  { value: 8, label: 'September' },
  { value: 9, label: 'October' },
  { value: 10, label: 'November' },
  { value: 11, label: 'December' }
]

const renderOptions = fiscalMonthData => {
  const curYear = dayjs().year()
  return baseOptions.map(({ value, label }) => {
    const fiscalMonth = fiscalMonthData.find(
      v =>
        dayjs(v.endDate).month() === value &&
        dayjs(v.endDate).year() === curYear
    )
    const startDate = fiscalMonth?.startDate
    const endDate = fiscalMonth?.endDate
    return {
      value,
      selectLabel: label,
      label: label + ' ' + formatFiscalDisplayDate(startDate, endDate),
      startDate,
      endDate,
      isCurrent: fiscalMonth?.isCurrent
    }
  })
}

const renderMonths = (currentFiscalMonthEnd, fiscalMonthData) => {
  const currentMonth = dayjs(currentFiscalMonthEnd).month()
  const optionsList = renderOptions(fiscalMonthData)
  return optionsList.slice(0, currentMonth + 1)
}

const FiscalMonthDropdown = ({ onChange }) => {
  const dispatch = useDispatch()
  const [defaultValue, setDefaultValue] = useState(null)
  const [options, setOptions] = useState(null)
  const departmentSettings = useSelector(selectDepartmentSettings)
  const fiscalMonthData = useSelector(selectFiscalMonthData)
  const handleChange = useCallback(
    value => {
      if (typeof onChange === 'function') {
        const option = options.find(o => o.value === value)
        onChange(option)
      }
    },
    [onChange, options]
  )
  useEffect(() => {
    if (!fiscalMonthData) {
      dispatch(loadFiscalMonthData())
    } else {
      const optionsList = renderMonths(
        departmentSettings?.['current_fiscal_month_end'],
        fiscalMonthData
      )
      setDefaultValue(optionsList[optionsList.length - 1].value)
      setOptions(optionsList)
    }
  }, [departmentSettings, dispatch, fiscalMonthData])
  // return null until defaultValue is determined so ant design renders with the correct default value
  if (defaultValue === null) {
    return <Skeleton.Input style={{ width: INPUT_WIDTH, height: 32 }} active />
  }
  return (
    <Select
      className='c-select'
      dropdownClassName='c-select__dropdown'
      optionLabelProp='label'
      dropdownMatchSelectWidth={false}
      defaultValue={defaultValue}
      style={{ width: INPUT_WIDTH }}
      placement='bottomRight'
      onChange={handleChange}
      suffixIcon={<CalendarTodayOutlined fontSize='inherit' />}
    >
      {options?.map(({ value, label, selectLabel }) => (
        <Select.Option key={value} value={value} label={selectLabel}>
          <div>{label}</div>
        </Select.Option>
      ))}
    </Select>
  )
}
export default FiscalMonthDropdown
