import React from 'react'

const CharWarning = ({ maxAmount, currAmount, showWarningAt = 10 }) => {
  const difference = maxAmount - currAmount
  const warningType = difference >= 0 ? 'green' : 'red'

  return (
    <span
      className={`c-char-warning ${
        difference <= showWarningAt ? warningType : 'hide'
      }`}
    >
      {difference}
    </span>
  )
}

export default CharWarning
