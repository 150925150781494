import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Menu, Dropdown } from 'antd'

import { KebabMenu, Settings, SignOut } from '../../../shared/Icons'
import { logout, setModalState } from '../../../../redux/actions'
import UserSettings from '../../header/subComponents/UserSettings'

const configMenu = (openUserSettings, logoutFn, userExists) => (
  <Menu>
    {userExists ? (
      <Menu.Item key='settings' title={null}>
        <div
          className='c-sidebar__user-kebab-menu-item'
          onClick={openUserSettings}
        >
          <Settings />
          Settings
        </div>
      </Menu.Item>
    ) : null}
    <Menu.Item key='sign-out' title={null}>
      <div
        className='c-sidebar__user-kebab-menu-item'
        onClick={logoutFn}
        title={null}
      >
        <SignOut />
        {userExists ? 'Sign Out' : 'Home'}
      </div>
    </Menu.Item>
  </Menu>
)

const UserMenuDropdown = ({ updateUserWSFxn, user }) => {
  const dispatch = useDispatch()
  const [menuVisible, setMenuVisible] = useState(false)
  const userExists = user && !!Object.entries(user).length
  const logoutUser = useCallback(() => {
    dispatch(logout())
  }, [dispatch])
  const openUserSettings = useCallback(() => {
    setMenuVisible(false)
    dispatch(
      setModalState(true, <UserSettings updateUserWSFxn={updateUserWSFxn} />)
    )
  }, [dispatch, setMenuVisible, updateUserWSFxn])

  const menu = configMenu(openUserSettings, logoutUser, userExists)
  return (
    <Dropdown
      overlay={menu}
      placement='bottomRight'
      trigger={['click']}
      visible={menuVisible}
      onVisibleChange={setMenuVisible}
    >
      <button className='c-sidebar__user-kebab-btn'>
        <KebabMenu />
      </button>
    </Dropdown>
  )
}

export default UserMenuDropdown
