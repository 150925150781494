import { patch } from '../../helpers/axios'

export default async function updateIsClearedFlag(placementId, value) {
  const url = `/starts/${placementId}`
  const payload = [
    {
      op: 'replace',
      path: '/isCleared',
      value
    }
  ]
  const res = await patch(url, payload)
  return res
}
