import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setStartsPlacementsViewMode } from '../../../../../redux/actions'
import { selectStartsCardViewMode } from '../../../../../redux/selectors/placementsStarts'
import ToggleTabs from '../../../../shared/ToggleTabs'

const StartsToggle = () => {
  const dispatch = useDispatch()
  const selectedValue = useSelector(selectStartsCardViewMode)
  const setViewMode = value => dispatch(setStartsPlacementsViewMode(value))
  const options = [
    { label: 'Consultant', value: 'consultant' },
    { label: 'Client', value: 'client' }
  ]
  return (
    <div className='c-starts-toggle'>
      <div className='c-starts-toggle__input'>
        <ToggleTabs
          options={options}
          onChange={setViewMode}
          selected={selectedValue}
        />
      </div>
    </div>
  )
}

export default StartsToggle
