import React from 'react'
import { Route, Switch } from 'react-router'
import { AzureAD } from 'react-aad-msal'

import { authProvider } from 'bootstrap/msal'

import UserSelection from 'components/UserSelection'
import DepartmentSelection from 'components/DepartmentSelection'
import BaseLayout from 'components/layout/BaseLayout'
import TouchScreen from 'components/TouchScreen'

const Routes = () => {
  return (
    <Switch>
      <Route exact path='/ts/:department' component={TouchScreen} />
      <Route
        exact
        path={[
          '/d/:department/:header(headerless)/boards/:board',
          '/hotJobOrder',
          '/hotActivity'
        ]}
        component={BaseLayout}
      />
      <AzureAD provider={authProvider} forceLogin>
        <Switch>
          <Route exact path='/' component={DepartmentSelection} />
          <Route
            exact
            path='/d/:department/:header'
            component={UserSelection}
          />
          <Route
            exact
            path={[
              '/pidb',
              '/d/:department/:header/boards/:board',
              '/p/:header/:board'
            ]}
            component={BaseLayout}
          />
          {/* Catch all and redirect home */}
          <Route component={DepartmentSelection} />
        </Switch>
      </AzureAD>
    </Switch>
  )
}

export default Routes
