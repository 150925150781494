import React from 'react'
import { Modal } from 'antd'

import getOpenTimes from './helpers/getOpenTimes'
import { parseUtcDate, sortDate } from 'helpers/dateTime'

/**
 * @param {{ date: string, timezone: string }} dateTimezone
 * @param {number | string} key
 * @returns {React.ReactNode}
 */
const renderTime = ({ date, timezone }, key) => (
  <li className='c-modal__list-item--open-times' key={key}>
    {parseUtcDate(date, 'MM/DD  |  hh:mm A ') + timezone}
  </li>
)

/**
 * @param {*} job - entity from jobOrders websocket
 * @param {function} closeModal
 * @returns
 */
const OpenTimesModal = ({ job, closeModal }) => {
  const times = getOpenTimes(job)
    ?.filter((currValue, currIndex, originalArr) => {
      return originalArr.findIndex(v => v.date === currValue.date) === currIndex
    })
    .sort((a, b) => {
      return sortDate(a.date, b.date)
    })
  return (
    <Modal
      className='c-modal'
      visible={!!job}
      footer={null}
      onCancel={closeModal}
    >
      <div className='c-modal'>
        <div className='c-modal__header'>
          <div>Open Times</div>
        </div>
        <div className='c-modal__body'>
          <ul className='c-modal__list'>{times.map(renderTime)}</ul>
        </div>
      </div>
    </Modal>
  )
}

export default OpenTimesModal
