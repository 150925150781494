import React from 'react'
import BoardCard from '../BoardCard'
import ColumnSendouts from '../ColumnSendouts'
import DealsIcon from '../DealsIcon'

const PurpleRedConfirmed = ({
  division,
  brand,
  total,
  subBrand,
  hasSubBrand
}) => {
  const list = [
    { label: 'Division', value: division, color: 'grapeA' },
    hasSubBrand
      ? { label: 'Sub-Brand', value: subBrand, color: 'grapeB' }
      : null,
    { label: 'Brand', value: brand, color: 'grapeB' },
    { label: 'ALKU', value: total, color: 'grapeC' }
  ].filter(v => v)
  // const goal = total * 1.4

  return (
    <BoardCard className='c-sendout-mgt__confirmed'>
      <div className='c-dashboard__title'>Purple/Red Sendouts Confirmed</div>
      <div className='c-sendout-mgt__confirmed__sendouts'>
        <div className='c-dealscard__icon c-dealscard__icon--purple c-sendout-mgt__icon'>
          <DealsIcon type='paper-plane' />
        </div>
        <ColumnSendouts list={list} />
      </div>
    </BoardCard>
  )
}

export default PurpleRedConfirmed
