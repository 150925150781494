const OTHERS = 'OTHERS'

const sortBrandGroups = (a, b) => {
  if (a.name.toUpperCase() === OTHERS) {
    return 1
  } else if (b.name.toUpperCase() === OTHERS) {
    return -1
  }

  return a.name.localeCompare(b.name)
}

const sortDepartments = (a, b) => {
  return (a.name || a.divisionName || '').localeCompare(
    b.name || b.divisionName || ''
  )
}

const groupDepartments = departments => {
  const unknownBrandDepartments = []
  const groupedDepartments = departments
    .reduce((acc, nextDepartment) => {
      if (nextDepartment.brand) {
        const indexOfBrand = acc.findIndex(
          brandGroup => brandGroup.name === nextDepartment.brand
        )
        if (indexOfBrand >= 0) {
          acc[indexOfBrand].departments.push(nextDepartment)
        } else {
          acc.push({
            name: nextDepartment.brand,
            departments: [nextDepartment]
          })
        }
      } else {
        unknownBrandDepartments.push(nextDepartment)
      }

      return acc
    }, [])
    .sort(sortBrandGroups)
  groupedDepartments.forEach(group => {
    group.departments.sort(sortDepartments)
  })
  return unknownBrandDepartments.length
    ? [
        ...groupedDepartments,
        { name: 'Unknown', departments: unknownBrandDepartments }
      ]
    : groupedDepartments
}

export default groupDepartments
