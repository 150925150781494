import React, { useCallback, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'

import SendoutsDashboard from './SendoutsDashboard'
import DealsDivisionBoard from './DealsDivisionBoard'
import DealsDivisionRankTable from './DealsDivisionRankTable'
import BoardContainer from '../../hocs/BoardContainer'
import { getDashboardSendouts, getRecentDeals } from '../../../redux/actions'
import { getSearchParamsFromLocations } from '../../../helpers/Util'
import { Skeleton } from 'components/shared'
import DashboardSkeleton from './DashboardSkeleton'
import { getMsalAccesToken } from 'bootstrap/msal'
import {
  selectDashboardCustomFiscalDate,
  selectDashboardGoLive
} from 'redux/selectors/dashboard'

const DivisionDashboard = ({
  getDashboardSendouts,
  refreshSeconds,
  match,
  meta,
  getRecentDeals,
  location
}) => {
  const {
    params: { department, header }
  } = match
  const { loading } = meta
  const isHeaderless = header === 'headerless'
  const { view } = getSearchParamsFromLocations(location)
  const fiscalMonth = useSelector(selectDashboardCustomFiscalDate)
  const goLive = useSelector(selectDashboardGoLive)
  const loadDashboard = useCallback(() => {
    getMsalAccesToken()
      .then(token => {
        if (token) {
          getDashboardSendouts(department, fiscalMonth, goLive)
          getRecentDeals(department)
        }
      })
      .catch(e => {
        console.error(e)
      })
  }, [department, getDashboardSendouts, getRecentDeals, fiscalMonth, goLive])
  useEffect(() => {
    loadDashboard()
  }, [loadDashboard])

  useEffect(() => {
    let interval
    if (refreshSeconds) {
      interval = setInterval(() => {
        loadDashboard()
      }, refreshSeconds * 1000)
    }
    return () => {
      if (interval) clearInterval(interval)
    }
  }, [refreshSeconds, loadDashboard])

  if (!isHeaderless) {
    return (
      <Skeleton.Board
        loading={loading}
        skeletonChildren={<DashboardSkeleton />}
      >
        <section>
          <SendoutsDashboard headerless={isHeaderless} />
          <DealsDivisionBoard />
          <DealsDivisionRankTable />
        </section>
      </Skeleton.Board>
    )
  }

  if (view === 'stack-rank') {
    return (
      <BoardContainer
        headerless
        render={page => <DealsDivisionRankTable headerless />}
      />
    )
  }

  return (
    <BoardContainer
      headerless
      maxPageNum={2}
      render={page =>
        page === 1 ? (
          <DealsDivisionBoard headerless />
        ) : page === 2 ? (
          <DealsDivisionRankTable headerless />
        ) : (
          <SendoutsDashboard headerless />
        )
      }
    />
  )
}

const mapStateToProps = ({ dashboard, department }) => ({
  meta: dashboard.meta,
  refreshSeconds: department.settings.dashboard_refresh_seconds
})

const mapDispatchToProps = {
  getDashboardSendouts,
  getRecentDeals
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DivisionDashboard)
