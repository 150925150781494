import React from 'react'

const FireWall = ({ month }) => (
  <svg
    id='firewall'
    width='80px'
    height='80px'
    viewBox='0 0 300 300'
    fill={month ? '#59C1AD' : '#F1AE54'}
  >
    <g>
      <path d='m260 106c2.209 0 4-1.789 4-4v-28c0-2.211-1.791-4-4-4h-72c-1.16 0-2.262.504-3.021 1.379s-1.102 2.039-.938 3.188l4 28c.281 1.968 1.969 3.433 3.959 3.433h28v24h-28c-2.018 0-3.719 1.504-3.969 3.504l-3.562 28.496h-8.469c-1.154 0-2.25.496-3.01 1.367l-20.99 23.988v-9.355c0-1.969-1.432-3.645-3.377-3.949l-33.725-5.325c.673-.716 1.102-1.665 1.102-2.726v-28h24c2.018 0 3.719-1.504 3.969-3.504l4-32c.143-1.137-.211-2.281-.971-3.145-.758-.859-1.852-1.351-2.998-1.351h-28v-24c0-2.211-1.791-4-4-4h-72c-2.209 0-4 1.789-4 4v28c0 2.211 1.791 4 4 4h28v24h-28c-2.209 0-4 1.789-4 4v32c0 2.211 1.791 4 4 4h28v24h-28c-2.209 0-4 1.789-4 4v32c0 2.211 1.791 4 4 4h28v20h-28c-2.209 0-4 1.789-4 4s1.791 4 4 4h220c2.209 0 4-1.789 4-4s-1.791-4-4-4h-32v-20h32c2.209 0 4-1.789 4-4v-32c0-2.211-1.791-4-4-4h-32v-24h32c2.209 0 4-1.789 4-4v-32c0-2.211-1.791-4-4-4h-32v-24zm-144 120v-24h68v24zm-72-148h64v20h-64zm32 28h63.469l-3 24h-60.469zm-32 32h64v24h-64zm32 32.68 68 10.738v12.582h-68zm-32 31.32h64v24h-64zm32 32h68v20h-68zm144 20h-68v-20h68zm36-28h-64v-24h64zm-36-32h-63.187l21.002-24h10.185 32zm36-32h-63.469l3-24h60.469zm-63.389-84h63.389v20h-60.531z' />
      <path d='m152 66c2.209 0 4-1.789 4-4v-20c0-2.211-1.791-4-4-4s-4 1.789-4 4v20c0 2.211 1.791 4 4 4z' />
      <path d='m164 70c1.023 0 2.047-.391 2.828-1.172l12-12c1.562-1.562 1.562-4.094 0-5.656s-4.094-1.562-5.656 0l-12 12c-1.562 1.562-1.562 4.094 0 5.656.781.781 1.805 1.172 2.828 1.172z' />
      <path d='m137.172 68.828c.781.781 1.805 1.172 2.828 1.172s2.047-.391 2.828-1.172c1.562-1.562 1.562-4.094 0-5.656l-12-12c-1.562-1.562-4.094-1.562-5.656 0s-1.562 4.094 0 5.656z' />
    </g>
  </svg>
)

export default FireWall
