import React, { useState, useCallback } from 'react'
import { Drawer } from 'antd'
import SettingsIcon from '@mui/icons-material/Settings'
import { useDispatch, useSelector } from 'react-redux'

import { setTouchscreenPaginationType } from 'redux/actions'
import { selectTouchscreenPaginationType } from 'redux/selectors'
import { ADVANCED, SIMPLE } from 'constants'
import { ToggleBtn } from 'components/shared'

const TouchScreenSettingsDrawer = () => {
  const dispatch = useDispatch()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const paginationType = useSelector(selectTouchscreenPaginationType)
  const togglePagination = useCallback(() => {
    const nextType = paginationType === ADVANCED ? SIMPLE : ADVANCED
    dispatch(setTouchscreenPaginationType(nextType))
  }, [dispatch, paginationType])
  const showDrawer = () => {
    setDrawerOpen(true)
  }

  const onClose = () => {
    setDrawerOpen(false)
  }
  return (
    <>
      <button
        className='c-btn c-btn--bare'
        style={{ position: 'absolute', top: '20px', right: '15px' }}
        onClick={showDrawer}
      >
        <SettingsIcon style={{ color: '#ff0000' }} fontSize='large' />
      </button>
      <Drawer
        placement='right'
        title='Settings'
        onClose={onClose}
        visible={drawerOpen}
        className='c-drawer'
      >
        <div className='c-drawer__line-item'>
          <div className='c-drawer__line-item-left'>Advanced Pagination</div>
          <div className='c-drawer__line-item-right'>
            <ToggleBtn
              activeColor={'#e00005'}
              onChange={togglePagination}
              checked={paginationType === ADVANCED}
            />
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default TouchScreenSettingsDrawer
