import React, { useState } from 'react'
import { Menu, Tooltip } from 'antd'
import { Link } from 'react-router-dom'

const LinkWrapper = ({ src, linkTo, children, onClick }) => {
  const wrapperClassName = 'c-sidebar__link-menu-item'
  if (linkTo) {
    return (
      <Link className={wrapperClassName} to={linkTo} onClick={onClick}>
        {children}
      </Link>
    )
  } else {
    return (
      <a
        className={wrapperClassName}
        target='_blank'
        rel='noopener noreferrer'
        href={src}
      >
        {children}
      </a>
    )
  }
}

const LinkMenu = ({ config, board, isCollapsed, onRouteChange }) => {
  const [visibleKey, setVisibleKey] = useState(null)

  return (
    <Menu
      theme='light'
      mode='inline'
      className='c-sidebar__link-menu'
      selectedKeys={[board]}
    >
      {config.map(({ label, key, linkTo, icon, src }) => (
        <Menu.Item
          key={key}
          title={null}
          onMouseEnter={() => setVisibleKey(key)}
          onMouseLeave={() => setVisibleKey(null)}
        >
          <LinkWrapper
            linkTo={linkTo}
            src={src}
            onClick={
              typeof onRouteChange === 'function' && linkTo
                ? onRouteChange
                : undefined
            }
          >
            <span className='c-sidebar__link-menu-item-icon'>
              <Tooltip
                placement='bottom'
                title={label}
                overlayClassName={'c-sidebar__link-menu-item-tooltip'}
                visible={isCollapsed && key === visibleKey}
              >
                {icon}
              </Tooltip>
            </span>
            <span className='c-sidebar__link-menu-item-label c-sidebar__hide-collapsed'>
              {label}
            </span>
          </LinkWrapper>
        </Menu.Item>
      ))}
    </Menu>
  )
}

export default LinkMenu
