import React from 'react'

import Skeleton from 'components/shared/Skeleton'
import SkeletonHeaderFilter from '../shared/SkeletonHeaderFilter'

const StartsBoardSkeleton = () => {
  return (
    <React.Fragment>
      <div
        style={{
          marginBottom: 32,
          whiteSpace: 'nowrap'
        }}
      >
        <div>
          <SkeletonHeaderFilter />
          <SkeletonHeaderFilter />
          <SkeletonHeaderFilter />
          <SkeletonHeaderFilter />
          <SkeletonHeaderFilter topHidden />
          <SkeletonHeaderFilter topHidden />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton.Input
          style={{ width: 370, height: 870, marginRight: 20, marginBottom: 20 }}
          active
        />
        <Skeleton.Input
          style={{ width: 370, height: 870, marginRight: 20, marginBottom: 20 }}
          active
        />
        <Skeleton.Input
          style={{ width: 370, height: 870, marginBottom: 20 }}
          active
        />
      </div>
    </React.Fragment>
  )
}

export default StartsBoardSkeleton
