import { sendGraphQLQuery } from '../../network/apollo'
import { getDivisionDashboardQuery } from './query'
import logger from '../../requests/logger'
import {
  GET_DASHBOARD_SENDOUTS_BEGIN,
  GET_DASHBOARD_SENDOUTS_SUCCESS,
  GET_DASHBOARD_SENDOUTS_ERROR,
  SET_DASHBOARD_CUSTOM_FISCAL_DATE,
  SET_DASHBOARD_GO_LIVE
} from '../actionTypes'
import errorHandler from '../../helpers/errorHandler'
import { setBoardLoadingObj } from '.'

const getDashboardSendoutsBegin = () => {
  return {
    type: GET_DASHBOARD_SENDOUTS_BEGIN
  }
}

const getDashboardSendoutsSuccess = payload => {
  return {
    type: GET_DASHBOARD_SENDOUTS_SUCCESS,
    payload
  }
}

const getDashboardSendoutsError = error => {
  return {
    type: GET_DASHBOARD_SENDOUTS_ERROR,
    error
  }
}

export const getDashboardSendouts = (
  departmentName,
  fiscalDate,
  goLive
) => async (dispatch, getState) => {
  dispatch(getDashboardSendoutsBegin())
  try {
    const {
      auth: { activeUser },
      department: { selectedDepartment },
      dashboard: { customFiscalDate }
    } = getState()
    const dep = departmentName || selectedDepartment
    const dateRange = fiscalDate || customFiscalDate
    const queryStr = getDivisionDashboardQuery(
      dep,
      activeUser.bhId,
      dateRange,
      !goLive
    )
    const res = await sendGraphQLQuery(queryStr)
    const sendouts = res && res.data && res.data.divisionDashboardQuery
    if (!sendouts) {
      throw new Error(
        'Division Dashboard sendouts payload not found in response'
      )
    }
    dispatch(getDashboardSendoutsSuccess(sendouts))
    dispatch(setBoardLoadingObj('dashboard'))
  } catch (error) {
    logger({ error, stackError: error.message })
    dispatch(getDashboardSendoutsError())
    errorHandler(error, {
      message: 'Error fetching sendouts.',
      notifySentry: false
    })
  }
}

export const reloadDashboard = () => async (dispatch, getState) => {
  const {
    department: { selectedDepartment },
    router: { location }
  } = getState()
  if (selectedDepartment && location.pathname.includes('dashboard')) {
    dispatch(getDashboardSendouts(selectedDepartment))
  }
}

export const setDashboardCustomFiscalDate = date => ({
  type: SET_DASHBOARD_CUSTOM_FISCAL_DATE,
  payload: date
})

export const setDashboardGoLive = goLive => ({
  type: SET_DASHBOARD_GO_LIVE,
  payload: goLive
})
