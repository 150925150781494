import React, { useEffect, useRef, useState } from 'react'

const CollapseHeader = ({ children, isCollapsed }) => {
  const [maxHeight, setMaxHeight] = useState(100)
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [scrollable, setScrollable] = useState(false)
  const [elementCount, setElementCount] = useState(0)
  const [isMobile, setIsMobile] = useState(false)
  const listRef = useRef()

  useEffect(() => {
    const windowResizeFn = window.addEventListener('resize', () => {
      setWindowSize(window.innerWidth)
    })
    if (listRef && listRef.current) {
      setElementCount(
        listRef.current.getElementsByClassName('filter-header-input').length
      )
    }
    return () => {
      window.removeEventListener('resize', windowResizeFn)
      setElementCount(0)
    }
  }, [setWindowSize, setElementCount, listRef])

  useEffect(() => {
    if (windowSize && listRef && listRef.current) {
      const containerWidth = listRef.current.clientWidth
      const inputWidth = listRef.current.getElementsByClassName(
        'filter-header-input'
      )[0].clientWidth
      const totalWidth = inputWidth * elementCount
      const heightToSet = Math.ceil(totalWidth / containerWidth) * 100
      const isMobile = containerWidth < 768
      setMaxHeight(heightToSet)
      setIsMobile(isMobile)
    }
    return () => setMaxHeight(100)
  }, [setMaxHeight, windowSize, listRef, elementCount, setIsMobile])

  useEffect(() => {
    let timer
    if (!isCollapsed) {
      timer = setTimeout(() => {
        setScrollable(true)
      }, 400)
    } else {
      setScrollable(false)
    }
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [isCollapsed, setScrollable])
  return (
    <div
      className={`filter-header-collapse${!isCollapsed ? ' active' : ''}${
        scrollable ? ' scrollable' : ''
      }`}
      style={{
        maxHeight: isMobile ? 'initial' : !isCollapsed ? `${maxHeight}px` : 0
      }}
    >
      <div className='filter-header'>
        <div className='filter-header-input-list' ref={listRef}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default CollapseHeader
