import React from 'react'
import { useSelector } from 'react-redux'

const DepartmentCard = ({ displayName, color = '#0000', onClick, name }) => {
  const selectedDepartment = useSelector(state => state.department.selectedDepartment)
  return (
    <button className='c-department-card' onClick={onClick}>
      <div className={`c-department-card__inner ${selectedDepartment === name && 'is-active'}`}>{displayName}</div>
      <span
        style={{ backgroundColor: `#${color}` }}
        className='c-department-card__div-color'
      />
    </button>
  )
}

export default DepartmentCard
