import React, { useState, useEffect } from 'react'
import Select, { Option } from 'rc-select'
import { useSelector } from 'react-redux'
import { returnGradient } from '../../../theme/returnGradient'

const selectActiveUser = state => state.auth.activeUser
const selectCategories = state => state.department.categories

const CategoryFilter = ({ handleCategoryChange }) => {
  const activeUser = useSelector(selectActiveUser)
  const categories = useSelector(selectCategories)
  const options = categories.map(({ name, Id }) => {
    return { value: Id, label: name }
  })
  options.unshift({ value: null, label: 'All' })
  const { departmentCategoriesDealsAssignmentId = null, color } = activeUser
  const [selected, setSelected] = useState(departmentCategoriesDealsAssignmentId)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (`${selected}` === `${departmentCategoriesDealsAssignmentId}`) setLoading(false)
  }, [selected, departmentCategoriesDealsAssignmentId])
  const onChange = value => {
    if (value !== selected) {
      setLoading(true)
      setSelected(value)
      handleCategoryChange(value)
    }
  }
  const bg = returnGradient(`#${color}`)
  const selectValue = () => {
    const option = options.find(o => `${o.value}` === `${selected}`)
    return option ? option.label : 'N/A'
  }
  const value = selectValue()
  return (
    <div>
      {options.length > 1 && (
        <Select
          value={value}
          isSearchable={false}
          showSearch={false}
          loading={loading}
          onChange={onChange}
          className='c-category-filter'
          dropdownClassName='c-category-filter__dropdown'
          style={{ ...bg }}
        >
          {options.map((o, i) => {
            return <Option key={`${o.value}`}>{o.label}</Option>
          })}
        </Select>
      )}
    </div>
  )
}

export default CategoryFilter
