import { InteractionRequiredAuthError } from 'msal'
import { MsalAuthProvider, LoginType } from 'react-aad-msal'

const TENANT_ID = '740e0ba1-e4ed-4c5d-9a56-2559c8fb94a6'
const CLIENT_ID = 'b6f57e70-fe88-4baa-ace5-d3047d183842'

// Msal Configurations
const config = {
  auth: {
    authority: `https://login.microsoftonline.com/${TENANT_ID}`,
    clientId: CLIENT_ID,
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  }
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const authenticationParams = {
  scopes: [`${CLIENT_ID}/.default`]
}

// Authentication Parameters
const authenticationParameters = {
  scopes: [`${CLIENT_ID}/.default`]
}

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin
}

// export const msalInstance = new UserAgentApplication(config)

export const authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  options
)

export const getMsalAccesToken = async () => {
  let token
  await authProvider
    .acquireTokenSilent(authenticationParams)
    .then(tokenResponse => {
      token = tokenResponse.accessToken
    })
    .catch(error => {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return authProvider.acquireTokenRedirect(authenticationParams)
      }
    })
  return token
}
