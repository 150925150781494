import React from 'react'
import { toHSL } from '../helpers/Util'

const formatClientInformation = (
  jobOrder,
  interviewData,
  hiringManagerHistory
) => {
  const { jobs, placements } = hiringManagerHistory
  const {
    title,
    ClientContact: { firstName, lastName },
    ClientCorporation: { name: corpName }
  } = jobOrder

  let interviews = {
    items: [
      {
        label: 'Interviews',
        value: interviewData.length,
        children: (
          <ul className='columncount two'>
            {interviewData.length ? (
              interviewData.map(interview => {
                const {
                  Candidate: { firstName, lastName }
                } = interview
                return (
                  <li key={`${firstName}-${lastName}`}>
                    {firstName} {lastName}
                  </li>
                )
              })
            ) : (
              <li></li>
            )}
          </ul>
        )
      }
    ]
  }

  let history = {
    items: [
      {
        label: `Hiring Manager: ${firstName} ${lastName}`,
        children: (
          <ul>
            <li>
              <div className='item-label left'>Jobs</div>
              <div className='item-value'>{jobs}</div>
              <div className='item-children'></div>
            </li>
            <li>
              <div className='item-label left'>Hires</div>
              <div className='item-value'>{placements}</div>
              <div className='item-children'></div>
            </li>
          </ul>
        )
      }
    ]
  }

  const leftColor = toHSL(`#${jobOrder.Owner.color}`, 0)
  const rightColor = toHSL(`#${jobOrder.Owner.color}`, 25)
  const backgroundGradient = `linear-gradient(to right, ${leftColor} 0%, ${rightColor} 100%)`

  const data = [history, interviews]
  return (
    <div style={menuStyle(backgroundGradient)}>
      <div className='popover wide'>
        <div className='popover-block'>
          <h2>{`History: ${corpName} | ${title}`}</h2>
          {data.map((item, index) => {
            return (
              <ul className='list-items' key={index}>
                {item.items.map((listItem, index2) => {
                  return (
                    <li
                      className={
                        listItem.children
                          ? 'list-item has-children'
                          : 'list-item'
                      }
                      key={`popover-item-${index}-${index2}`}
                    >
                      <div
                        className={
                          listItem.children
                            ? 'item-label has-children'
                            : 'item-label'
                        }
                      >
                        {listItem.label}
                      </div>
                      <div
                        className={
                          listItem.children
                            ? 'item-value has-children'
                            : 'item-value'
                        }
                      >
                        {listItem.value}
                      </div>
                      <div className='item-children'>
                        {listItem.children ? listItem.children : null}
                      </div>
                    </li>
                  )
                })}
              </ul>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const menuStyle = backgroundGradient => {
  return {
    borderRadius: '1rem',
    background: backgroundGradient
  }
}

export default formatClientInformation
