import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  updateWebsocketStatus,
  removeWebsocket,
  setIsDisconnected
} from './redux/actions'
import BaseWebsocket from './network/websocket/BaseWebsocket'
import { checkIfHeaderless } from './helpers/Util'
import { ToastContainer } from 'react-toastify'
import { SOCKET_BOARDS } from 'constants'
import Routes from 'components/routes'

// Until 'eslint' understands (via 'globals' npm package) that 'AbortController'
// is a global variable in the 'browser' environment, we need this declaration
// as a workaround. See also:
// https://github.com/facebook/create-react-app/blob/master/packages/react-scripts/template/README.md#using-global-variables
// eslint-disable-next-line no-unused-vars
const AbortController = window.AbortController

/*
 * import logger from './requests/logger'
 * import { detect } from 'detect-browser'
 * const browser = detect()
 * const UNSUPPORTED_MESSAGE =
 *  'This browser version is unsupported! Please update to latest Chrome/Safari/Firefox for the best experience.'
 */

let previousHtml
class App extends Component {
  componentDidMount() {
    const { updateWebsocketStatus, removeWebsocket } = this.props

    BaseWebsocket.initialize({
      updateReduxWebsocketStatus: updateWebsocketStatus,
      removeReduxWebsocket: removeWebsocket
    })

    this.interval = setInterval(this.checkUpdatesInCode, 5000 * 60)
    window.addEventListener('beforeunload', this.rootComponentCleanup)

    this.handleConnectionChange()
    window.addEventListener('online', this.handleConnectionChange)
    window.addEventListener('offline', this.handleConnectionChange)
  }

  componentWillUnmount() {
    this.rootComponentCleanup()
  }

  rootComponentCleanup = event => {
    clearInterval(this.interval)
    BaseWebsocket.clearCheckToken()
    if (event) {
      window.removeEventListener('beforeunload', this.rootComponentCleanup)
    }
    this.closeUserSockets()
    window.removeEventListener('online', this.handleConnectionChange)
    window.removeEventListener('offline', this.handleConnectionChange)
  }

  closeUserSockets = () => {
    if (BaseWebsocket.getSocketInstance(SOCKET_BOARDS.USER_HUB)) {
      BaseWebsocket.closeSignalRSocket(SOCKET_BOARDS.USER_HUB)
    }
    if (BaseWebsocket.getSocketInstance(SOCKET_BOARDS.HOT_USER)) {
      BaseWebsocket.closeSignalRSocket(SOCKET_BOARDS.HOT_USER)
    }
  }

  componentDidCatch(error) {
    // logger({
    //   stackError: error.message
    // })
  }

  handleConnectionChange = () => {
    const { setIsDisconnected } = this.props
    const condition = navigator.onLine ? 'online' : 'offline'
    if (condition === 'online') {
      const webPing = setInterval(() => {
        fetch('//google.com', {
          mode: 'no-cors'
        })
          .then(() => {
            setIsDisconnected(false)
            return clearInterval(webPing)
          })
          .catch(() => {
            setIsDisconnected(true)
          })
      }, 2000)
      return
    }
    return setIsDisconnected(true)
  }

  checkUpdatesInCode = () => {
    return fetch('/index.html')
      .then(response => {
        if (response.status !== 200) {
          throw new Error('offline')
        }
        return response.text()
      })
      .then(html => {
        if (!previousHtml) {
          previousHtml = html
          return
        }
        if (previousHtml !== html) {
          previousHtml = html
          document.getElementById('update-available').style.display = 'flex'
          // Reload page for headerless mode after new version deployment
          const isHeaderless = checkIfHeaderless()
          if (isHeaderless) {
            window.location.reload(true)
          }
        }
      })
      .catch(error => {
        console.warn(error)
      })
  }

  updateUser = (userId, field, value) => {
    BaseWebsocket.send('user', {
      event: 'user_change',
      userId,
      field,
      value
    })
  }

  render() {
    return (
      <>
        <Routes />
        <ToastContainer />
      </>
    )
  }
}

export default connect(
  null,
  {
    removeWebsocket,
    updateWebsocketStatus,
    setIsDisconnected
  }
)(App)
