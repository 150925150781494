import React, { useCallback } from 'react'
import { Modal } from 'antd'
import { returnGradient } from '../../../../../theme/returnGradient'
import sortOnboardingPaperworkModal from '../../helpers/sortOnboardingPaperworkModal'
import Document from './Document'

const returnStatus = status =>
  status ? { check: true } : { emptyCircle: true }
const processPaperwork = paperwork => {
  const {
    title,
    isComplete,
    isNotApplicable,
    groupedPaperwork,
    answerId,
    answers
  } = paperwork
  const selectedAnswer = answers?.find(a => a.answerId === answerId)
  const subtitle = selectedAnswer?.answerName
  return {
    title,
    subtitle,
    status: !isNotApplicable ? returnStatus(isComplete) : null,
    groupedPaperwork: groupedPaperwork
      ? groupedPaperwork.map(processPaperwork)
      : null
  }
}

const OnboardingPaperworkModal = ({ placement, closeModal }) => {
  const processPlacementPaperwork = useCallback(pl => {
    if (pl && pl.paperworkJson) {
      return (pl.formattedPaperworkJson || pl.paperworkJson)
        .sort(sortOnboardingPaperworkModal)
        .map(processPaperwork)
    }
    return []
  }, [])
  const documents = processPlacementPaperwork(placement)
  const color = placement && placement.color
  return (
    <Modal
      className='c-starts-modal'
      visible={!!placement}
      footer={null}
      onCancel={closeModal}
    >
      <div className='c-starts-modal'>
        <div
          className='c-starts-modal__header'
          style={{
            ...returnGradient(`#${color}`, 90, 15),
            color: !color ? 'black' : undefined
          }}
        >
          <div>Onboarding Paperwork</div>
        </div>
        <div className='c-starts-modal__body'>
          <ul className='c-starts-modal__document-list'>
            {documents.map((document, index) => {
              return <Document key={index} {...document} />
            })}
          </ul>
          {placement && placement.notes ? (
            <div className='c-starts-modal__notes'>
              <div className='c-starts-modal__notes-header'>Notes</div>
              <div>{placement.notes}</div>
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  )
}

export default OnboardingPaperworkModal
