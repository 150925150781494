import React from 'react'

const Clipboard = () => {
  return (
    <svg width='16' height='18' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8 0C6.84 0 5.864.744 5.497 1.778h-3.72C.797 1.778 0 2.573 0 3.556V16c0 .982.796 1.778 1.778 1.778h12.444c.982 0 1.778-.796 1.778-1.778V3.556c0-.983-.796-1.778-1.778-1.778h-3.719A2.658 2.658 0 008 0zm0 1.778a.89.89 0 010 1.778h6.222V16H1.778V3.556H8a.89.89 0 010-1.778zM3.556 5.333v1.778h8.888V5.333H3.556zm0 3.556v1.778h7.11V8.889h-7.11zm0 3.555v1.778h8.888v-1.778H3.556z'
        fill='#666'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default Clipboard
