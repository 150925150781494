import React from 'react'
import StatusBtn from '../../../../../../shared/StatusBtn'

const DocumentField = ({ data }) => {
  return (
    <li className='c-consultant-card__document-bar'>
      {(data.length ? data : [data]).map((doc, index) => (
        <span
          key={index}
          className={`c-consultant-card__document ${
            data.length ? 'c-consultant-card__document--multi' : ''
          }`}
        >
          <StatusBtn
            statusProps={doc.status}
            onClick={doc.onClick}
            tooltip={doc.tooltip}
            btnClassname={`${
              !doc.onClick ? 'c-btn--auto-cursor' : 'c-btn--pointer-cursor u-p0'
            }`}
          />
          <span className='c-consultant-card__doc-label'>{doc.label}</span>
        </span>
      ))}
    </li>
  )
}

export default DocumentField
