import { UPDATE_WEBSOCKET_STATUS, REMOVE_WEBSOCKET } from '../../actionTypes'

export const updateWebsocketStatus = (board, status) => {
  return {
    type: UPDATE_WEBSOCKET_STATUS,
    payload: {
      board,
      status
    }
  }
}

export const removeWebsocket = board => {
  return {
    type: REMOVE_WEBSOCKET,
    payload: board
  }
}
