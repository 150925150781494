import React from 'react'

const DeleteIcon = ({ onClick }) => {
  return (
    <svg
      width='20'
      height='20'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        d='M8.4 1l-.9.9H3v1.8h.9v13.5c0 .47.172.95.512 1.288.339.34.818.512 1.288.512h9c.47 0 .95-.172 1.288-.512.34-.339.512-.818.512-1.288V3.7h.9V1.9h-4.5L12 1H8.4zM5.7 3.7h9v13.5h-9V3.7zm1.8 1.8v9.9h1.8V5.5H7.5zm3.6 0v9.9h1.8V5.5h-1.8z'
        fill='#8E8E8E'
        fillRule='evenodd'
      />
    </svg>
  )
}

export default DeleteIcon
