import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Menu, MenuItem } from 'components/shared'
import { setBasicMenuPopover } from 'redux/actions'
import { selectBasicMenuPopover } from 'redux/selectors/UI'

const BasicMenuPopover = () => {
  const dispatch = useDispatch()
  const basicMenuPopoverProps = useSelector(selectBasicMenuPopover)
  const closeModal = useCallback(() => {
    dispatch(setBasicMenuPopover(null))
  }, [dispatch])
  const onMenuItemClick = useCallback(
    fn => {
      return () => {
        if (fn) fn()
        closeModal()
      }
    },
    [closeModal]
  )
  if (
    !basicMenuPopoverProps ||
    !basicMenuPopoverProps.items ||
    !basicMenuPopoverProps.items.length
  ) {
    return null
  }

  const { anchorEl, items } = basicMenuPopoverProps

  return (
    <Menu
      className='candidate-history'
      anchorEl={anchorEl}
      onClose={closeModal}
    >
      {items.map(item => (
        <MenuItem
          key={item.primaryText}
          onClick={onMenuItemClick(item.onClick)}
          sx={menuItemStyles}
        >
          {renderMenuItemChildren(item)}
        </MenuItem>
      ))}
    </Menu>
  )
}

const menuItemStyles = {
  color: '#363636',
  fontSize: '1.2rem'
}

const renderMenuItemChildren = menuItem => {
  if (menuItem.url) {
    return (
      <a
        href={menuItem.url}
        style={{ color: '#363636' }}
        target='_blank'
        rel='noreferrer'
      >
        {menuItem.primaryText}
      </a>
    )
  } else {
    return menuItem.primaryText
  }
}

export default BasicMenuPopover
