import React, { useEffect, useRef } from 'react'
import { Form, Input } from 'antd'
import CharWarning from './CharWarning'
import createSimulateTabEvent from '../../../../helpers/createSimulateTabEvent'

const LinkForm = ({ form: { link, displayText }, setForm, onSubmit, maxTotalChars }) => {
  const formRef = useRef(null)
  useEffect(() => {
    const handleTab = createSimulateTabEvent(formRef)
    if (handleTab) {
      document.addEventListener('keydown', handleTab)
    }

    return () => {
      if (handleTab) {
        document.removeEventListener('keydown', handleTab)
      }
    }
  }, [formRef])
  const onChange = e => {
    const { name, value } = e.currentTarget
    setForm(f => ({
      ...f,
      [name]: value
    }))
  }

  const currAmount = link.length + displayText.length
  const onFormSubmit = e => {
    e.preventDefault()
    const emptyField = !link.length || !displayText.length
    if (!emptyField && maxTotalChars - currAmount >= 0) {
      onSubmit()
    } else {
      if (emptyField) {
        window.aler('Display Text and Link fields are requried!')
      } else {
        window.alert('Maximum character limit reached.')
      }
    }
  }

  return (
    <div ref={formRef}>
      <Form className='link-form' onSubmit={onFormSubmit}>
        <div className='link-form-item'>
          <label htmlFor='displayText'>Display Text</label>
          <Input
            autoFocus
            name='displayText'
            placeholder='Zoom'
            value={displayText}
            onChange={onChange}
            tabIndex='0'
          />
        </div>
        <div className='link-form-item'>
          <label htmlFor='link'>Link</label>
          <Input
            name='link'
            placeholder='https://alku.zoom.us/'
            value={link}
            onChange={onChange}
            tabIndex='0'
          />
          <CharWarning
            maxAmount={maxTotalChars}
            currAmount={currAmount}
            showWarningAt={10}
          />
        </div>
      </Form>
    </div>
  )
}

export default LinkForm
