import { UPDATE_WEBSOCKET_STATUS, REMOVE_WEBSOCKET } from '../actionTypes'
import { cloneDeep } from 'lodash'

const DEFAULT_STATE = {
  sockets: []
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case UPDATE_WEBSOCKET_STATUS: {
      let tempState = cloneDeep(state.sockets)
      let socket = tempState.find(
        socket => socket.board === action.payload.board
      )
      if (socket) {
        socket.status = action.payload.status
      } else {
        tempState.push({
          board: action.payload.board,
          status: action.payload.status
        })
      }
      return {
        ...state,
        sockets: tempState
      }
    }
    case REMOVE_WEBSOCKET:
      return {
        ...state,
        sockets: state.sockets.filter(socket => socket.board !== action.payload)
      }
    default:
      return state
  }
}
