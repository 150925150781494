import { ROTATION_ENUM } from './constants'

export const formatDisplayRotationValueToLabel = value => {
  switch (value) {
    case ROTATION_ENUM.NORMAL:
      return 'Normal'
    case ROTATION_ENUM.LEFT:
      return 'Left'
    case ROTATION_ENUM.RIGHT:
      return 'Right'
    default:
      return 'Unknown Rotation'
  }
}
