import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal } from 'antd'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import * as DOMPurify from 'dompurify'

import { DeleteIcon, Edit } from 'components/shared/Icons'
import {
  deleteInterviewPrepDetails,
  getInterviewPrepDetails,
  postInterviewPrepDetails
} from 'requests/activity'
import DeleteNoteConfirmationDialog from './DeleteNoteConfirmationDialog'
import { Skeleton } from 'components/shared'
import { setJobOrderActivityHasInterviewPrepDetails } from 'redux/actions'
import useReactQuillModules from './hooks/useReactQuillModules'

/**
 * @param {*} job - entity from jobOrders websocket
 * @param {function} closeModal
 * @returns {React.ReactElement}
 */
const InterviewPrepDetailsModal = ({ job, closeModal }) => {
  const dispatch = useDispatch()
  const [interviewPrepDetails, setInterviewPrepDetails] = useState('')
  const [defaultValue, setDefaultValue] = useState('')
  const [savedNotes, setSavedNotes] = useState({})
  const [editorMode, setEditorMode] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const modules = useReactQuillModules()

  useEffect(() => {
    // on mount, request notes if notes exist, else open in editor mode
    if (job?.bhId && job?.hasInterviewPrepDetail) {
      getInterviewPrepDetails(job.bhId)
        .then(response => {
          setInterviewPrepDetails(response.data)
          // if modal was closed without discarding notes, open editor with unsaved notes
          if (savedNotes[job.bhId]) {
            setDefaultValue(savedNotes[job.bhId])
            setEditorMode(true)
          } else {
            setDefaultValue(response.data)
          }
        })
        .catch(error => console.error(error))
    } else if (job) {
      if (savedNotes[job.bhId]) {
        setDefaultValue(savedNotes[job.bhId])
      }
      setEditorMode(true)
    }

    // cleanup state
    return () => {
      setInterviewPrepDetails(null)
      setEditorMode(false)
      setInterviewPrepDetails('')
      setDefaultValue('')
    }
    /** because savedNotes changes with every keypress in the editor
    to avoid useEffect running on every render, only use job as dependency
    realistically, this only needs to run once per modal open */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job?.bhId, job?.hasInterviewPrepDetail])
  const handleQuillValueChange = val => {
    // saving notes to a key value pair allows the component to keep track of multiple undiscarded notes
    setSavedNotes(notes => ({
      ...notes,
      [job.bhId]: val
    }))
  }

  const discardNotes = useCallback(() => {
    setSavedNotes(notes => {
      const notesCopy = Object.assign({}, notes)
      delete notesCopy[job.bhId]
      return notesCopy
    })
    setEditorMode(false)
  }, [job?.bhId])

  // discard changed notes for this job order
  const onDiscardChanges = useCallback(() => {
    discardNotes()
    setDefaultValue(interviewPrepDetails)
    if (job?.hasInterviewPrepDetail === false) {
      closeModal()
    }
  }, [
    closeModal,
    discardNotes,
    interviewPrepDetails,
    job?.hasInterviewPrepDetail
  ])

  const onDeleteNote = useCallback(() => {
    deleteInterviewPrepDetails(job?.bhId)
      .then(() => {
        dispatch(
          setJobOrderActivityHasInterviewPrepDetails({
            jobOrderBhId: job.bhId,
            hasInterviewPrepDetail: false
          })
        )
        setDeleteOpen(false)
        discardNotes()
        closeModal()
      })
      .catch(error => console.error(error))
  }, [closeModal, discardNotes, dispatch, job?.bhId])

  const onSaveChanges = useCallback(() => {
    const notes = savedNotes[(job?.bhId)]
    const isEmpty = notes.replace(/<(.|\n)*?>/g, '').trim().length === 0
    if (job && !isEmpty) {
      const cleanHtml = DOMPurify.sanitize(notes)
      postInterviewPrepDetails(job?.bhId, cleanHtml)
        .then(response => {
          setInterviewPrepDetails(response.data)
          setDefaultValue(response.data)
          discardNotes()
          if (!job.hasInterviewPrepDetail) {
            dispatch(
              setJobOrderActivityHasInterviewPrepDetails({
                jobOrderBhId: job.bhId,
                hasInterviewPrepDetail: true
              })
            )
          }
        })
        .catch(error => console.error(error))
    } else {
      onDeleteNote()
    }
  }, [discardNotes, dispatch, job, onDeleteNote, savedNotes])

  const onEditNotes = useCallback(() => {
    setSavedNotes(notes => ({
      ...notes,
      [job.bhId]: interviewPrepDetails
    }))
    setEditorMode(true)
  }, [interviewPrepDetails, job?.bhId])

  return (
    <Modal
      className='c-modal c-modal--interview-prep-details'
      visible={!!job}
      footer={null}
      width='auto'
      onCancel={closeModal}
    >
      <div className='c-modal'>
        <div className='c-modal__header'>
          <div>Interview Prep Notes</div>
        </div>
        <div className='c-modal__body'>
          <div className='c-modal__title-container'>
            <div className='c-modal__title'>{job?.title}</div>
            <button
              className={`c-btn c-btn--bare c-btn--edit-notes${
                editorMode ? ' hidden' : ' '
              }`}
              onClick={onEditNotes}
            >
              Edit Notes <Edit />
            </button>
          </div>
          <div className='c-wysiwyg ql-editor'>
            {editorMode ? (
              <ReactQuill
                theme='snow'
                placeholder={
                  !job?.hasInterviewPrepDetail
                    ? 'No interview notes have been saved yet'
                    : ''
                }
                defaultValue={defaultValue}
                modules={modules}
                onChange={handleQuillValueChange}
              />
            ) : (
              <Skeleton loading={!interviewPrepDetails} paragraph>
                <div
                  dangerouslySetInnerHTML={{ __html: interviewPrepDetails }}
                />
              </Skeleton>
            )}
          </div>
        </div>
        <div className={`c-modal__footer${editorMode ? '' : ' hidden'}`}>
          <div className='c-modal__actions--left'>
            <button
              className={`c-btn c-btn--bare c-btn--delete-note${
                job?.hasInterviewPrepDetail ? '' : ' hidden'
              }`}
              onClick={() => setDeleteOpen(true)}
            >
              Delete Note <DeleteIcon />
            </button>
          </div>
          <div className='c-modal__actions'>
            <button
              onClick={onDiscardChanges}
              className='c-btn--cancel-2 c-btn c-btn--bare'
            >
              Cancel
            </button>
            <button
              onClick={onSaveChanges}
              className='c-btn--save c-btn c-btn--bare'
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
      <DeleteNoteConfirmationDialog
        onConfirm={onDeleteNote}
        onCancel={() => setDeleteOpen(false)}
        open={deleteOpen}
      />
    </Modal>
  )
}

export default InterviewPrepDetailsModal
