import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useScrollDirection from 'components/hocs/hooks/useScrollDirection'
import { Close, Hamburger } from 'components/shared/Icons'
import { selectSideBarIsCollapsed } from 'redux/selectors'
import { setSideBarIsCollapsed } from 'redux/actions'

const getBoardName = board => {
  switch (board?.toLowerCase()) {
    case 'assigned-accounts':
      return 'Assigned Accounts'
    case 'dashboard':
      return 'Where We At'
    case 'starts-management':
      return 'Starts Management'
    default:
      return board
  }
}

const MobileHeader = ({ board }) => {
  const dispatch = useDispatch()
  const mobileMenuClosed = useSelector(selectSideBarIsCollapsed)
  const scrollDirection = useScrollDirection()
  const toggleMenuState = useCallback(() => {
    dispatch(setSideBarIsCollapsed(!mobileMenuClosed))
  }, [dispatch, mobileMenuClosed])
  return (
    <div
      className={`c-mobile-header__container ${
        mobileMenuClosed && scrollDirection === 'down' ? 'hide' : 'show'
      }`}
    >
      <div className='c-mobile-header'>
        <h1 className='c-mobile-header__title'>
          {mobileMenuClosed ? getBoardName(board) : 'Menu'}
        </h1>
        <button className='c-btn c-btn--bare' onClick={toggleMenuState}>
          {!mobileMenuClosed ? (
            <Close size={22} fill='#E00005' />
          ) : (
            <Hamburger />
          )}
        </button>
      </div>
    </div>
  )
}

export default MobileHeader
