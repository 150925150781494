import React, { useState, useCallback, useEffect } from 'react'

const ToggleBtn = ({ onChange, checked, activeColor, ...props }) => {
  const [_checked, _setChecked] = useState(false)
  useEffect(() => {
    _setChecked(checked)
  }, [checked])
  const _onChange = useCallback(
    value => {
      _setChecked(value)
      if (typeof onChange === 'function') {
        onChange(value)
      }
    },
    [onChange, _setChecked]
  )

  const toggleChecked = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      _setChecked(v => {
        const newValue = !v
        if (typeof onChange === 'function') {
          onChange(newValue)
        }
        return newValue
      })
    },
    [onChange, _setChecked]
  )

  return (
    <div
      className='c-toggle-btn'
      style={activeColor ? { '--active-color': activeColor } : undefined}
    >
      <input
        className='c-toggle-btn__input'
        type='checkbox'
        onChange={_onChange}
        checked={checked !== undefined ? checked : _checked}
        {...props}
      />
      <div className='c-toggle-btn__container' onClick={toggleChecked}>
        <div className='c-toggle-btn__tab' onClick={toggleChecked} />
      </div>
    </div>
  )
}

export default ToggleBtn
