import React from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'

const WeeklyLineChart = React.memo(({ width, data, hasSubBrand }) => {
  const tickProps = {
    tickMargin: 10,
    tickSize: 0,
    tick: { fill: '#B2B2B2', fontSize: 12 }
  }

  const dot = { strokeWidth: 2 }

  return (
    <LineChart width={width} height={214} data={data}>
      <CartesianGrid strokeDasharray='3 3' />
      <XAxis dataKey='name' {...tickProps} />
      <YAxis type='number' tickCount={6} {...tickProps} />
      <Tooltip />
      <Line dataKey='Division' stroke='#04060F' dot={dot} />
      {hasSubBrand ? (
        <Line dataKey='Sub-Brand' stroke='#7743A7' dot={dot} />
      ) : null}
      <Line dataKey='Brand' stroke='#0294A5' dot={dot} />
      <Line dataKey='ALKU' stroke='#C1403D' dot={dot} />
    </LineChart>
  )
})

WeeklyLineChart.displayName = 'WeeklyLineChart'

export default WeeklyLineChart
