import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'

import {
  CLIENT_REJECTED_STATE,
  CLIENT_SUBMISSION_STATE,
  INTERVIEW_SCHEDULED_STATE,
  PLACED_STATE,
  OFFER_EXT_STATE,
  OFFER_REJ_STATE
} from '../../../../../constants'
import BaseWebsocket from '../../../../../network/websocket/BaseWebsocket'
import {
  setBasicMenuPopover,
  setCandidateHistoryMenu,
  updateJOSubmission as updateJOSubmissionAction
} from 'redux/actions'
import renderCandidateMenuItems from './renderCandidateMenu'
import { ReferenceCheck, StatusIndicator } from 'components/shared/Icons'
import candidateIsPending from './candidateIsPending'

const JobCardCandidate = ({
  auth,
  job,
  boardType,
  submission,
  isHot,
  department,
  headerless
}) => {
  const dispatch = useDispatch()
  const setMenu = useCallback(
    v => {
      dispatch(setBasicMenuPopover(v))
    },
    [dispatch]
  )
  const setCandidateHistory = useCallback(
    v => {
      dispatch(setCandidateHistoryMenu(v))
    },
    [dispatch]
  )
  const updateJOSubmission = useCallback(
    (JOId, bhId, payload) => {
      dispatch(updateJOSubmissionAction(JOId, bhId, payload))
    },
    [dispatch]
  )
  const renderStatusMarkProps = (leftMessage, managerLikes, status) => {
    if (
      status === CLIENT_SUBMISSION_STATE ||
      status === INTERVIEW_SCHEDULED_STATE ||
      status === PLACED_STATE ||
      status === OFFER_EXT_STATE ||
      status === OFFER_REJ_STATE ||
      status === CLIENT_REJECTED_STATE
    ) {
      return { check: '#000' }
    }
    if (managerLikes) {
      return { check: true }
    }
    if (leftMessage) {
      return { dot: true }
    }
    return { emptyCircle: true }
  }

  const cycleStatus = (JOId, bhId, leftMessage, managerLikes) => {
    let payload = {}
    if (leftMessage && !managerLikes) {
      payload = { managerLikes: true }
    }
    if (leftMessage && managerLikes) {
      payload = {
        leftMessage: false,
        managerLikes: false
      }
    }
    if (!leftMessage && !managerLikes) {
      payload = {
        leftMessage: true
      }
    }

    _.forEach(payload, (value, key) => {
      changeSubmissionState(bhId, key, value)
    })
    updateJOSubmission(JOId, bhId, payload)
  }

  const changeSubmissionState = useCallback(
    (submissionId, field, value) => {
      BaseWebsocket.send(boardType, {
        event: 'submission_change',
        jobSubmissionId: submissionId,
        field: field,
        value: value
      })
    },
    [boardType]
  )

  const {
    candidate: candidateBhId,
    Candidate,
    Candidate: { firstName, lastName },
    leftMessage,
    managerLikes,
    status,
    bhId,
    SendingUser,
    securityClearance,
    interviewSlots,
    referenceCheck
  } = submission

  const candidateColor = () => {
    if (SendingUser) {
      if (SendingUser.bhId === auth.activeUser.bhId) {
        return `#${auth.activeUser.color}`
      }
      return `#${SendingUser.color}`
    } else {
      return '#000'
    }
  }

  const onCandidateClick = useCallback(
    e => {
      const items = renderCandidateMenuItems(
        submission,
        department,
        job,
        interviewSlots,
        bhId,
        securityClearance,
        referenceCheck,
        Candidate,
        candidateBhId,
        e.currentTarget,
        setCandidateHistory,
        changeSubmissionState,
        headerless
      )
      setMenu({
        anchorEl: e.currentTarget,
        items
      })
    },
    [
      submission,
      department,
      job,
      interviewSlots,
      bhId,
      securityClearance,
      referenceCheck,
      Candidate,
      candidateBhId,
      setCandidateHistory,
      changeSubmissionState,
      headerless,
      setMenu
    ]
  )

  return (
    <div key={`candidate-${bhId}`} className='submissions-row__entry'>
      <button
        className='submissions-row__entry__icon c-btn--responsive c-btn--pointer-cursor c-btn--bare'
        onClick={e => {
          e.preventDefault()
          cycleStatus(job.bhId, bhId, leftMessage, managerLikes)
        }}
      >
        <StatusIndicator
          className='status'
          {...renderStatusMarkProps(leftMessage, managerLikes, status)}
        />
      </button>
      {referenceCheck && (
        <ReferenceCheck className='submissions-row__entry__ref-check' />
      )}
      <span
        style={{
          color: `${candidateColor()}`
        }}
        onClick={onCandidateClick}
      >
        <span
          style={{
            borderBottom: securityClearance ? '2px solid blue' : 'none'
          }}
        >
          {firstName} {lastName}{' '}
          {candidateIsPending(interviewSlots) ? '(P)' : ''}
        </span>
        <br />
        {isHot ? (
          <span className='hotInfo'>
            {`(S: ${submission.SendingUser.firstName[0]}. ${
              submission.SendingUser.lastName
            } O: ${Candidate.Owner.firstName[0]}. ${Candidate.Owner.lastName})`}
          </span>
        ) : null}
      </span>
    </div>
  )
}

export default JobCardCandidate
