import React from 'react'

import { ArrowLeft, ArrowRight } from 'components/shared/Icons'

const ArrowContainer = props => {
  if (
    (props.onClick && typeof props.onClick !== 'function') ||
    (!props.onClick && typeof props.onClick !== 'undefined')
  ) {
    console.warn('typeof onClick for pagination arrow is not a function')
  }
  return (
    <button
      onClick={props.onClick}
      className='c-btn c-btn--bare c-pidb__pagination-arrow'
    >
      {props.children}
    </button>
  )
}

const renderPaginationItems = (
  page,
  type,
  originalElement,
  currentPage,
  totalPages,
  config = {}
) => {
  const { onPrevClick, onNextClick } = config
  if (type === 'jump-prev' || type === 'jump-next') {
    return '...'
  }

  if (type === 'prev') {
    let fill
    if (typeof currentPage === 'number') {
      fill = currentPage === 1 ? '#AAAAAA' : null
    }
    return (
      <ArrowContainer onClick={onPrevClick}>
        <ArrowLeft fill={fill} />
      </ArrowContainer>
    )
  }

  if (type === 'next') {
    let fill
    if (typeof currentPage === 'number' && typeof totalPages === 'number') {
      fill = currentPage === totalPages ? '#AAAAAA' : null
    }
    return (
      <ArrowContainer onClick={onNextClick}>
        <ArrowRight fill={fill} />
      </ArrowContainer>
    )
  }

  return originalElement
}

export default renderPaginationItems
