import { newRequest } from 'helpers/axios'

/**
 *
 * @param {number} jobOrderId
 * @returns
 */
export default function getInterviewPrepDetails(jobOrderId) {
  return newRequest(
    'GET',
    `/job-orders/interviewprepdetails/${jobOrderId}`,
    null,
    null
  )
}
