import { axiosRequest } from '../helpers/axios'

export default function getInterviews(jobOrderId) {
  return axiosRequest(
    'GET',
    `/job-submissions?jobOrder=${jobOrderId}&status[]=Interview%20Scheduled`,
    null,
    null
  )
}
