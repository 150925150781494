import React from 'react'

export default function CandidateVisibilityIcon({ title }) {
  return (
    <>
      <p>
        <svg width='16px' height='14px' viewBox='0 0 16 14' version='1.1'>
          <g id='Jobs' stroke='none' fill='none'>
            <g
              id='Jobs---Meta-data---AF-3'
              transform='translate(-1154.000000, -370.000000)'
              fill='#222222'
            >
              <g id='Job-Card' transform='translate(145.000000, 169.000000)'>
                <g id='Bar' transform='translate(0.000000, 192.000000)'>
                  <g id='Actions' transform='translate(703.000000, 8.000000)'>
                    <g
                      id='Show-candidate'
                      transform='translate(306.000000, 0.000000)'
                    >
                      <g
                        id='icons8-hide'
                        transform='translate(0.000000, 1.000000)'
                      >
                        <path
                          d='M2.37731188,-3.20413841e-16 L1.35705479,1.02025709 L3.00863123,2.67183367 C1.08448198,4.21897524 0.179458708,6.22172231 0.121190746,6.35828787 L-3.20413841e-16,6.64294507 L0.121190746,6.92760227 C0.217151287,7.15199121 2.5367426,12.4150077 8,12.4150077 C9.49088684,12.4150077 10.739713,12.0178443 11.7794606,11.4426633 L13.622688,13.28589 L14.6429448,12.2656332 L2.37731188,-3.20413841e-16 Z M7.99999986,0.870882458 C7.12625388,0.870882458 6.34055444,1.01563497 5.6240971,1.24290993 L6.81204877,2.43086131 C7.18795435,2.35943204 7.58080382,2.31389811 7.99999986,2.31389811 C11.8932561,2.31389811 13.8930052,5.62424384 14.4146554,6.64012686 C14.2025321,7.04633577 13.7376891,7.83484209 13.0096877,8.6285007 L14.0384006,9.65721285 C15.2505337,8.34551162 15.831911,7.03654996 15.8788091,6.92760227 L16,6.64294507 L15.8788091,6.35828787 C15.7828485,6.13389893 13.4632571,0.870882458 7.99999986,0.870882458 Z M4.04016202,3.70336432 L5.51981671,5.18301908 C5.26400402,5.61050994 5.11396856,6.1082349 5.11396856,6.64294507 C5.11396856,8.23459134 6.4083536,9.52897638 7.99999986,9.52897638 C8.53471003,9.52897638 9.03243499,9.37894099 9.45992622,9.12312822 L10.7042451,10.3674471 C9.92956215,10.7347797 9.03053454,10.971992 7.99999986,10.971992 C4.10674363,10.971992 2.1069945,7.6616463 1.58534434,6.64576328 C1.88522002,6.07147335 2.68987383,4.74020202 4.04016202,3.70336432 Z M8.15360239,3.77241493 L10.8705303,6.48934255 C10.7918859,5.02251743 9.6204278,3.85105928 8.15360239,3.77241493 Z M6.6105338,6.27373581 L8.36920913,8.03241113 C8.25104923,8.06417407 8.12836404,8.08596072 7.99999986,8.08596072 C7.20417673,8.08596072 6.55698421,7.4387682 6.55698421,6.64294507 C6.55698421,6.51458089 6.57877086,6.3918957 6.6105338,6.27373581 Z'
                          id='Shape'
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
        {` ${title}`}
      </p>
    </>
  )
}
