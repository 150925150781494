import React from 'react'

const Activity = () => {
  return (
    <svg width='13' height='22' xmlns='http://www.w3.org/2000/svg'>
      <path d='M7 0L0 13h6v9l7-13H7z' fill='#4E4E4E' fillRule='nonzero' />
    </svg>
  )
}

export default Activity
