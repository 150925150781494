import {
  SET_PLACEMENTS,
  ADD_PLACEMENT,
  REMOVE_PLACEMENT,
  CLEAR_PREVIOUS_DPEARTMENT_DATA
} from '../actionTypes'

const DEFAULT_STATE = []

const placements = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case CLEAR_PREVIOUS_DPEARTMENT_DATA:
      return DEFAULT_STATE
    case SET_PLACEMENTS:
      return action.placements
    case ADD_PLACEMENT:
      return state.concat(action.placement)
    case REMOVE_PLACEMENT:
      return state.filter(el => el.bhId === action.placement.bhId)
    default:
      return state
  }
}

export default placements
