import React, { useCallback } from 'react'
import { Modal } from 'antd'
import BaseWebsocket from 'network/websocket/BaseWebsocket'

/**
 *
 * @param {string} boardType
 * @param {number} jobSubmissionId
 * @param {number} slotId
 * @param {string} field
 * @param {number | string} value
 */
const updateSlot = (boardType, jobSubmissionId, slotId, field, value) => {
  BaseWebsocket.send(boardType, {
    event: 'slot_submission_change',
    jobSubmissionId,
    slotId,
    field,
    value
  })
}

/**
 * @param {{ jobSubmission: *, slotId: number }} interviewSlotMarginSpreadData
 * @param {string} boardType
 * @param {function} closeModal
 * @returns {React.ReactNode}
 */
const InterviewRateFieldsMarginModal = ({
  interviewSlotMarginSpreadData,
  boardType,
  closeModal
}) => {
  const handleChange = useCallback(
    (e, field) => {
      if (interviewSlotMarginSpreadData) {
        const { jobSubmission, slotId } = interviewSlotMarginSpreadData
        updateSlot(
          boardType,
          jobSubmission.bhId,
          slotId,
          field,
          e.target.value.replace(/[^\d.]/g, '')
        )
      }
    },
    [interviewSlotMarginSpreadData, boardType]
  )
  const renderInputBlock = useCallback(
    (title, defaultValue, field, blockText) => (
      <li className='c-modal__list-item'>
        <div className='c-modal__list-item-title'>{title}</div>
        <div className='c-input'>
          <div className='c-input__prefix-block'>{`${blockText || '$'}`}</div>
          <input
            type={'c-input__number'}
            className='number'
            onBlur={e => handleChange(e, field)}
            defaultValue={defaultValue}
          />
        </div>
      </li>
    ),
    [handleChange]
  )
  const { workingSpread, workingMargin, billRate, payRate } =
    interviewSlotMarginSpreadData?.jobSubmission?.InterviewSlotSubmission || {}
  const candidateName = `${interviewSlotMarginSpreadData?.jobSubmission?.Candidate.firstName} ${interviewSlotMarginSpreadData?.jobSubmission?.Candidate.lastName}`
  return (
    <Modal
      destroyOnClose
      className='c-modal c-modal--rate-fields'
      visible={!!interviewSlotMarginSpreadData}
      footer={null}
      onCancel={closeModal}
      style={{ maxWidth: '90vw' }}
    >
      <div className='c-modal'>
        <div className='c-modal__header'>
          <div>{`Candidate: ${candidateName}`}</div>
        </div>
        <div className='c-modal__body'>
          <ul className='c-modal__list'>
            {renderInputBlock('Bill Rate', billRate, 'billRate')}
            {renderInputBlock('Pay Rate', payRate, 'payRate')}
            {renderInputBlock('Spread Amount', workingSpread, 'workingSpread')}
            {renderInputBlock(
              'Spread Percentage',
              workingMargin,
              'workingMargin',
              '%'
            )}
          </ul>
        </div>
        <div className='c-modal__footer'>
          <div className='c-modal__actions'>
            <button
              onClick={closeModal}
              className='c-btn--save c-btn c-btn--bare'
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default InterviewRateFieldsMarginModal
