import { getFiscalMonthData } from 'requests'
import {
  SET_POPOVER_STATE,
  SET_MODAL_STATE,
  SET_SELECTED_AMS,
  SET_BACKGROUND_STRING,
  SET_STARTS_FROM_FILTER,
  SET_SIDEBAR_IS_COLLAPSED,
  SET_CANDIDATE_HISTORY_MENU,
  SET_BASIC_MENU_POPOVER,
  OPEN_DATE_PICKER,
  OPEN_TIME_PICKER,
  CLOSE_DATE_PICKER,
  CLOSE_TIME_PICKER,
  SET_BOARD_LOADING_OBJ,
  SET_TOUCHSCREEN_PAGINATION_TYPE,
  LOAD_FISCAL_MONTH_DATA_BEGIN,
  LOAD_FISCAL_MONTH_DATA_SUCCESS,
  LOAD_FISCAL_MONTH_DATA_FAIL
} from '../actionTypes'

export const setPopoverState = payload => {
  return {
    type: SET_POPOVER_STATE,
    payload
  }
}

export const setModalState = (openBool, content = null) => {
  return {
    type: SET_MODAL_STATE,
    openBool,
    content
  }
}

export const setSelectedAMs = accountManagerIdsArr => {
  return {
    type: SET_SELECTED_AMS,
    accountManagerIdsArr
  }
}

export const setBackground = (backgroundString, color) => {
  return {
    type: SET_BACKGROUND_STRING,
    backgroundString,
    color
  }
}

export const toggleAMSelectedState = bhId => (dispatch, getState) => {
  dispatch(setSelectedAMs([bhId]))
}

export const setStartsFromFilter = payload => {
  return {
    type: SET_STARTS_FROM_FILTER,
    payload
  }
}

export const setSideBarIsCollapsed = isCollapsed => ({
  type: SET_SIDEBAR_IS_COLLAPSED,
  payload: isCollapsed
})

export const setCandidateHistoryMenu = payload => ({
  type: SET_CANDIDATE_HISTORY_MENU,
  payload
})

export const setBasicMenuPopover = payload => ({
  type: SET_BASIC_MENU_POPOVER,
  payload
})

export const openDatePicker = payload => ({
  type: OPEN_DATE_PICKER,
  payload
})

export const closeDatePicker = payload => ({
  type: CLOSE_DATE_PICKER,
  payload
})

export const openTimePicker = payload => ({
  type: OPEN_TIME_PICKER,
  payload
})

export const closeTimePicker = payload => ({
  type: CLOSE_TIME_PICKER,
  payload
})

export const setBoardLoadingObj = payload => ({
  type: SET_BOARD_LOADING_OBJ,
  payload
})

export const setTouchscreenPaginationType = payload => ({
  type: SET_TOUCHSCREEN_PAGINATION_TYPE,
  payload
})

export const loadFiscalMonthData = () => (dispatch, getState) => {
  const {
    UI: {
      fiscalMonthDropdown: { fetching, data }
    }
  } = getState()
  if (!fetching && !data) {
    dispatch({ type: LOAD_FISCAL_MONTH_DATA_BEGIN })
    getFiscalMonthData()
      .then(response => {
        if (response?.data) {
          dispatch({
            type: LOAD_FISCAL_MONTH_DATA_SUCCESS,
            payload: response.data
          })
        } else {
          throw new Error('Could not load fiscal month data')
        }
      })
      .catch(() => {
        dispatch({ type: LOAD_FISCAL_MONTH_DATA_FAIL })
      })
  }
}
