import {
  SET_STARTS_PLACEMENTS,
  ADD_STARTS_PLACEMENT,
  REMOVE_STARTS_PLACEMENT,
  SET_STARTS_PLACEMENTS_COMPANY_FILTER,
  SET_STARTS_PLACEMENTS_OWNER_FILTER,
  SET_STARTS_PLACEMENTS_DATE_RANGE_FILTER,
  SET_STARTS_PLACEMENTS_VIEW_MODE,
  SET_STARTS_PLACEMENTS_PROGRAM_FILTER,
  UPDATE_STARTS_PLACEMENT_IS_CLEARED_FLAG,
  UPDATE_STARTS_PLACEMENT,
  REMOVE_STARTS_PLACEMENT_V2,
  SET_STARTS_PLACEMENTS_STATUS_FILTER
} from '../actionTypes'
import logger from '../../requests/logger'
import { cloneDeep } from 'lodash'
import { updateIsClearedFlag, patchStarts } from '../../requests'
import errorHandler from '../../helpers/errorHandler'

export const setStartsPlacements = placements => {
  return {
    type: SET_STARTS_PLACEMENTS,
    placements
  }
}

export const addStartsPlacement = placement => {
  return {
    type: ADD_STARTS_PLACEMENT,
    placement
  }
}

export const removeStartsPlacement = placement => {
  return {
    type: REMOVE_STARTS_PLACEMENT,
    placement
  }
}

export const updateStartsPlacement = (placement, updatedFields, event) => (
  dispatch,
  getState
) => {
  const { placementsStarts } = getState()
  let newList = cloneDeep(placementsStarts.data)
  let placementUpdated = newList.find(plObj => plObj.bhId === placement.bhId)
  if (placementUpdated) {
    let payload = {}
    updatedFields.map(field => (payload[field] = placement[field]))

    let updatedPl = {
      ...placementUpdated,
      ...payload
    }

    let plIndex = newList.findIndex(plObj => plObj.bhId === placement.bhId)
    if (plIndex > -1) {
      newList.splice(plIndex, 1, updatedPl)
      dispatch(setStartsPlacements(newList))
    } else {
      logger({
        stackError: `Placement not found for ${event} with placment bhId ${placement.bhId}`
      })
    }
  } else {
    logger({
      stackError: `Placement not found for ${event} with placment bhId ${placement.bhId}`
    })
  }
}

export const updateStartsCandidate = (placementIds, candidate, event) => (
  dispatch,
  getState
) => {
  const { placementsStarts } = getState()
  let newList = cloneDeep(placementsStarts)

  placementIds.forEach(plId => {
    let plIndex = newList.data.findIndex(plObj => plObj.bhId === plId)
    if (plIndex > -1) {
      newList[plIndex].Candidate.firstName = candidate.firstName
      newList[plIndex].Candidate.lastName = candidate.lastName
      dispatch(setStartsPlacements(newList))
    } else {
      logger({
        stackError: `Placement not found for ${event} with placment bhId ${plId}`
      })
    }
  })
}

export const setStartsPlacementsCompanyFilter = value => {
  return {
    type: SET_STARTS_PLACEMENTS_COMPANY_FILTER,
    payload: value
  }
}

export const setStartsPlacementsOwnersFilter = value => {
  return {
    type: SET_STARTS_PLACEMENTS_OWNER_FILTER,
    payload: value
  }
}

export const setStartsPlacementsDateRangeFilter = value => {
  return {
    type: SET_STARTS_PLACEMENTS_DATE_RANGE_FILTER,
    payload: value
  }
}

export const setStartsPlacementsProgramFilter = value => {
  return {
    type: SET_STARTS_PLACEMENTS_PROGRAM_FILTER,
    payload: value
  }
}

export const setStartsPlacementsStatusFilter = value => {
  return {
    type: SET_STARTS_PLACEMENTS_STATUS_FILTER,
    payload: value
  }
}

export const setStartsPlacementsViewMode = value => {
  return {
    type: SET_STARTS_PLACEMENTS_VIEW_MODE,
    payload: value
  }
}

const setStartsPlacementIsClearedFlag = value => {
  return {
    type: UPDATE_STARTS_PLACEMENT_IS_CLEARED_FLAG,
    payload: value
  }
}

export const updateStartsPlacementIsClearedFlag = (
  placementId,
  value
) => async dispatch => {
  try {
    const res = await updateIsClearedFlag(placementId, value)
    if (res && res.data && res.data.id) {
      dispatch(setStartsPlacementIsClearedFlag(res.data))
    }
  } catch (error) {
    errorHandler(error)
  }
}

export const updateStartsPlacementV2 = value => {
  return {
    type: UPDATE_STARTS_PLACEMENT,
    payload: value
  }
}

export const removeStartsPlacementV2 = value => {
  return {
    type: REMOVE_STARTS_PLACEMENT_V2,
    payload: value
  }
}

export const cycleConsultantStatus = placement => async dispatch => {
  try {
    if (placement && placement.id) {
      const { id, firstDayDetailsCall, expectationCall } = placement
      const expectationConfig = { attribute: 'expectationCall', value: false }
      const daysDetailConfig = {
        attribute: 'firstDayDetailsCall',
        value: false
      }
      if (!firstDayDetailsCall && !expectationCall) {
        expectationConfig.value = true
      }
      if (!firstDayDetailsCall && expectationCall) {
        daysDetailConfig.value = true
      }
      if (firstDayDetailsCall && !expectationCall) {
        expectationConfig.value = true
        daysDetailConfig.value = true
      }
      const config = [expectationConfig, daysDetailConfig]
      const res = await patchStarts(id, config)
      if (res && res.data && res.data.id) {
        dispatch(updateStartsPlacementV2(res.data))
      }
    }
  } catch (error) {
    errorHandler(error)
  }
}
