import React from 'react'

import Skeleton from 'components/shared/Skeleton'

const ActivityCardSkeleton = () => {
  return (
    <div style={{ marginBottom: 20, width: 596, display: 'inline-block' }}>
      <Skeleton.Input style={{ width: 596, height: 58 }} active />
      <div style={{ padding: 20 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 34
          }}
        >
          <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
            <Skeleton.Input
              style={{ width: 300, height: 20, marginBottom: 6 }}
              active
            />
            <Skeleton.Input
              style={{ width: 300, height: 20, marginBottom: 6 }}
              active
            />
            <Skeleton.Input style={{ width: 300, height: 20 }} active />
          </div>
          <div>
            <Skeleton.Input style={{ width: 148, height: 20 }} active />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 34
          }}
        >
          <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
            <Skeleton.Input
              style={{ width: 300, height: 20, marginBottom: 6 }}
              active
            />
            <Skeleton.Input style={{ width: 300, height: 20 }} active />
          </div>
          <div
            style={{
              display: 'inline-flex',
              flexDirection: 'column',
              alignItems: 'flex-end'
            }}
          >
            <Skeleton.Input
              style={{ width: 48, height: 20, marginBottom: 6 }}
              active
            />
            <Skeleton.Input style={{ width: 148, height: 20 }} active />
          </div>
        </div>
      </div>
    </div>
  )
}

const ActivityBoardSkeleton = () => {
  return (
    <React.Fragment>
      <div style={{ marginBottom: 56 }}>
        <div
          style={{
            display: 'inline-flex',
            marginRight: 20,
            flexDirection: 'column'
          }}
        >
          <div style={{ marginBottom: 6 }}>
            <Skeleton.Input style={{ width: 180, height: 20 }} active />
          </div>
          <Skeleton.Input style={{ width: 360, height: 40 }} active />
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', maxWidth: 1300 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ActivityCardSkeleton />
          <ActivityCardSkeleton />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ActivityCardSkeleton />
          <ActivityCardSkeleton />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ActivityCardSkeleton />
          <ActivityCardSkeleton />
        </div>
      </div>
    </React.Fragment>
  )
}

export default ActivityBoardSkeleton
