import errorHandler from '../../helpers/errorHandler'
import { getPiDBdata } from '../../requests'
import {
  SET_PI_DB_DATA,
  SET_PI_DB_DATA_LOADING,
  SET_PI_DB_SEARCH_FILTER,
  SET_PI_DB_BOARD_FILTER,
  SET_PI_DB_DIVISION_FILTER,
  SET_PI_TO_DELETE,
  EDIT_PI,
  REMOVE_PI,
  ADD_PI,
  SET_PI_DB_DETAILS_MODAL
} from '../actionTypes'

const setPiDBData = payload => {
  return {
    type: SET_PI_DB_DATA,
    payload
  }
}

const setPiDBDataLoading = payload => {
  return {
    type: SET_PI_DB_DATA_LOADING,
    payload
  }
}

export const setPiDBSearchFilter = payload => {
  return {
    type: SET_PI_DB_SEARCH_FILTER,
    payload
  }
}
export const setPiDBBoardFilter = payload => {
  return {
    type: SET_PI_DB_BOARD_FILTER,
    payload
  }
}
export const setPiDBDivisionFilter = payload => {
  return {
    type: SET_PI_DB_DIVISION_FILTER,
    payload
  }
}

export const setPiDToDelete = payload => {
  return {
    type: SET_PI_TO_DELETE,
    payload
  }
}

export const addPi = payload => {
  return {
    type: ADD_PI,
    payload
  }
}

export const editPi = payload => {
  return {
    type: EDIT_PI,
    payload
  }
}

export const removePi = payload => {
  return {
    type: REMOVE_PI,
    payload
  }
}

export const setPiDbDetailsModal = payload => {
  return {
    type: SET_PI_DB_DETAILS_MODAL,
    payload
  }
}

export const loadPiDBData = () => async dispatch => {
  try {
    dispatch(setPiDBDataLoading(true))
    const request = await getPiDBdata()
    const data = request && request.data
    if (data && data.length) {
      data.sort((a, b) => {
        if (
          (a.departmentName || b.departmentName) &&
          a.departmentName !== b.departmentName
        ) {
          return (a.departmentName || '').localeCompare(
            b.departmentName || ''
          )
        } else {
          return (a.board || '').localeCompare(b.board || '')
        }
      })
      dispatch(setPiDBData(data))
    }
    dispatch(setPiDBDataLoading(false))
  } catch (e) {
    errorHandler(e)
  }
}
