import React, { Component } from 'react'
import { Route, Switch } from 'react-router'
import { Layout } from 'antd'

import Popover from '../shared/popover'
import Modal from '../shared/Modal'
import NotFound from '../shared/NotFound'
import { connect } from 'react-redux'
import * as actions from '../../redux/actions'
import BaseWebsocket from '../../network/websocket/BaseWebsocket'
import { handleUserHubSocket, setUserCssVars } from '../../redux/actions'
import Header from './header'
import { getAccessToken } from 'helpers/Util'
import { configuredRoutes } from './helpers/routesConfig'
import logger from '../../requests/logger'
import Sidebar from './sidebar'
import CandidateHistoryMenu from 'components/shared/CandidateHistoryMenu'
import BasicMenuPopover from 'components/shared/BasicMenuPopover'
import { SOCKET_BOARDS } from 'constants'
import { ContentLayout } from 'components/shared'

const REFRESH_INTERVAL = process.env.REACT_APP_REFRESH_INTERVAL || 90

class BaseLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      token: false
    }
    this.userSocketType = props.match.params.department
      ? SOCKET_BOARDS.USER_HUB
      : SOCKET_BOARDS.HOT_USER
  }

  componentDidMount = async () => {
    const {
      search,
      credential,
      match: {
        params: { department, header }
      },
      setDepartmentSettings,
      setUserCssVars,
      handleUserHubSocket,
      setHeaderlessCredential,
      refreshHeaderlessToken
    } = this.props

    window.addEventListener('beforeunload', this.layoutCleanup)

    const isHeaderless = !header || header === 'headerless'
    if (isHeaderless) {
      const timestamp = Math.floor(Date.now() / 1000)
      const expiresOn = timestamp + 3540
      const urlParams = new URLSearchParams(search)
      const accessToken = urlParams.get('access_token')
      const clientId = urlParams.get('client_id')
      const clientSecret = urlParams.get('client_secret')
      const macAddress = urlParams.get('mac_address')
      this.interval = setInterval(
        this.refreshCredential,
        60000 * REFRESH_INTERVAL
      )
      if (
        accessToken &&
        clientId &&
        clientSecret &&
        macAddress &&
        !credential.expiresOn
      ) {
        setHeaderlessCredential({
          accessToken,
          clientId,
          clientSecret,
          macAddress,
          expiresOn
        })
      } else if (
        credential.expiresOn &&
        credential.expiresOn <= timestamp - 60
      ) {
        await refreshHeaderlessToken()
      } else if (!credential.expiresOn) {
        const token = await getAccessToken()
        this.setState({ token: !!token })
        logger(new Error('No credential info found'))
      }
    }

    if (header === 'header') {
      setUserCssVars()
    }

    if (!BaseWebsocket.getSocketInstance(this.userSocketType)) {
      BaseWebsocket.openSignalRSocket(
        this.userSocketType,
        handleUserHubSocket,
        department
      )
    }
    department && setDepartmentSettings(department)
  }

  componentWillUnmount() {
    this.layoutCleanup()
  }

  componentDidUpdate(preProps) {
    if (preProps.isDisconnected && !this.props.isDisconnected) {
      this.refreshCredential()
    }
  }

  layoutCleanup = event => {
    BaseWebsocket.closeSocket(this.userSocketType)
    if (this.interval) {
      clearInterval(this.interval)
    }
    if (event) {
      window.removeEventListener('beforeunload', this.layoutCleanup)
    }
  }

  refreshCredential = async () => {
    const { credential, refreshHeaderlessToken, isDisconnected } = this.props
    if (!isDisconnected) {
      if (credential.macAddress && credential.clientId) {
        await refreshHeaderlessToken()
      }
      BaseWebsocket.restartSockets()
    }
  }

  updateUser = (userId, field, value) => {
    BaseWebsocket.send(this.userSocketType, {
      event: 'user_change',
      userId,
      field,
      value
    })
  }

  render() {
    const {
      match: {
        params: { department, header, board },
        path
      },
      UI: { boardLoadingObj },
      departmentError,
      /* userError, */
      credential
    } = this.props
    const isHeaderless = !header || header === 'headerless'

    if (departmentError) {
      return (
        <NotFound
          message={`Error, ${department} department was not found on Alku Everywhere`}
        />
      )
    }

    // if (userError) {
    //   return (
    //     <NotFound
    //       message={`No users have been added to Alku Everywhere for the ${department} department yet`}
    //     />
    //   );
    // }

    // For avoid flesh for unauth headerless mode,
    // If login in header mode, then use that token to access data in headerless mode
    if (isHeaderless && !credential.accessToken && !this.state.token) {
      return null
    }

    return (
      <Layout>
        <Sidebar
          board={board}
          activeDepartment={department}
          hidden={isHeaderless || !boardLoadingObj[board]}
          updateUserWSFxn={this.updateUser}
        />
        <ContentLayout board={board}>
          <Header
            board={board}
            activeDepartment={department}
            headerless={isHeaderless}
            updateUserWSFxn={this.updateUser}
            path={path}
          />
          <Switch>
            {configuredRoutes.map(({ path, component }, index) => {
              return (
                <Route key={index} exact path={path} component={component} />
              )
            })}
          </Switch>
        </ContentLayout>
        <Popover {...this.props} updateUserWSFxn={this.updateUser} />
        <CandidateHistoryMenu />
        <BasicMenuPopover />
        <Modal {...this.props} updateUserWSFxn={this.updateUser} />
      </Layout>
    )
  }
}

const mapStateToProps = ({
  UI,
  auth: { credential, isDisconnected },
  router: {
    location: { search, pathname }
  },
  users: { error: userError },
  department: { error: departmentError }
}) => {
  return {
    UI,
    userError,
    departmentError,
    search,
    credential,
    isDisconnected,
    pathname
  }
}

export default connect(
  mapStateToProps,
  {
    ...actions,
    setUserCssVars,
    handleUserHubSocket
  }
)(BaseLayout)
