import React from 'react'
import SortIcon from './SortIcon'

const TableHeader = ({
  className,
  cellClassName,
  config,
  toggleSort,
  sort
}) => {
  return (
    <thead className={className}>
      <tr>
        {config.map(({ label, value, hideSort }) => (
          <th key={value} className={cellClassName}>
            {label}
            {hideSort && (
              <SortIcon
                sortDirection={sort.value === value && sort.direction}
                onClick={() => toggleSort(value)}
              />
            )}
          </th>
        ))}
      </tr>
    </thead>
  )
}

export default TableHeader
