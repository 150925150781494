import React from 'react'
import Icon from '@mui/material/Icon'

const FilterToggleBtn = ({ onClick, active }) => {
  return (
    <button
      onClick={onClick}
      className={`c-btn c-btn--bare c-filter-toggle-btn ${
        active ? 'active' : ''
      }`}
    >
      {`${active ? 'HIDE' : 'SHOW'} ALL FILTERS`}
      <span className='c-filter-toggle-btn__icon'>
        <Icon baseClassName='fas' className='fa-filter' />
      </span>
    </button>
  )
}

export default FilterToggleBtn
