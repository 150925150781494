import React from 'react'

const Deals = () => {
  return (
    <svg width='21' height='20' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10a9.937 9.937 0 00-.541-3.217l-1.62 1.62c.106.515.161 1.05.161 1.597 0 4.411-3.589 8-8 8s-8-3.589-8-8 3.589-8 8-8a7.95 7.95 0 014.42 1.338l1.432-1.432A9.94 9.94 0 0010 0zm9.293 1.293L9 11.586 5.707 8.293 4.293 9.707 9 14.414 20.707 2.707l-1.414-1.414z'
        fill='#4E4E4E'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default Deals
