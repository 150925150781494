import React, { useRef, useState, useMemo, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'

import BoardManager from 'components/hocs/BoardManager'
import JobCard from 'components/boards/jobsBoard/JobCard'
import { handleJobsData as handleJobsDataSocket } from 'redux/actions'
import {
  sortJobs,
  sortHotJobs,
  selectJobSortFn
} from 'components/boards/jobsBoard/sortJobs'
import filterProgram from 'helpers/filterProgram'
import filterByTags from 'helpers/filterJOsByTags'
import { SOCKET_BOARDS } from 'constants'

// ws
import BaseWebsocket from 'network/websocket/BaseWebsocket'
import verifyConnectionState from 'network/websocket/helpers/verifyConnectionState'

import Skeleton from 'components/shared/Skeleton'
import JobsBoardSkeleton from './JobsBoardSkeleton'
import OpenTimesModal from './OpenTimesModal'
import { useInterval } from 'components/hocs/hooks'

const Jobs = props => {
  const {
    selectedDepartment,
    isPaused,
    handleInternalPagination,
    useTouchscreenBoard,
    boardPage,
    setBoardPage
  } = props
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const board = useRef()
  const { department, header } = useParams()
  const isLoading = useSelector(state => state.UI.boardLoadingObj.jobs)
  const [openTimesModalData, setOpenTimesModalContent] = useState(null)
  const jobOrders = useSelector(state => state.jobOrders)
  const departmentState = useSelector(state => state.department)
  const auth = useSelector(state => state.auth)
  const isHotJobOrder = useMemo(() => {
    return useTouchscreenBoard ? false : pathname === '/hotJobOrder'
  }, [pathname, useTouchscreenBoard])
  const isHeaderless = useMemo(() => {
    return useTouchscreenBoard ? true : !header || header === 'headerless'
  }, [useTouchscreenBoard, header])
  const isHot = useMemo(() => {
    return useTouchscreenBoard ? false : !header && !department
  }, [useTouchscreenBoard, header, department])
  const boardType = useMemo(() => {
    return isHot ? SOCKET_BOARDS.HOT_JOBS_HUB : SOCKET_BOARDS.JOBS_HUB
  }, [isHot])

  const handleJobsData = useCallback(
    msg => {
      dispatch(handleJobsDataSocket(msg))
    },
    [dispatch]
  )

  const openSockets = useCallback(() => {
    if (isHot) {
      if (!verifyConnectionState(BaseWebsocket.sockets.HOT_JOBS_HUB)) {
        BaseWebsocket.openSignalRSocket(boardType, handleJobsData, department)
      }
    } else {
      if (!verifyConnectionState(BaseWebsocket.sockets.JOBS_HUB)) {
        BaseWebsocket.openSignalRSocket(boardType, handleJobsData, department)
      }
    }
  }, [boardType, department, handleJobsData, isHot])

  useEffect(() => {
    // touchscreen handles websockets in BoardManager as opposed to the regular routes
    if (!useTouchscreenBoard) {
      openSockets()
    }
    return () => {
      if (!useTouchscreenBoard) {
        BaseWebsocket.closeSignalRSocket(boardType)
      }
    }
  }, [boardType, department, handleJobsData, openSockets, useTouchscreenBoard])

  useInterval(openSockets, 60 * 1000)

  const filterJobOrders = useCallback(
    (
      jobOrders,
      filters,
      required_skill_sort,
      selectedCategoryName,
      isPresorted,
      sort
    ) => {
      if (filters.openTimes) {
        jobOrders = jobOrders.filter(jo => jo.hasOpenTime)
      }
      if (filters.multiDivisions) {
        jobOrders = jobOrders.filter(jo => jo.otherDivisions?.length)
      }

      if (filters.company) {
        jobOrders = jobOrders.filter(
          jo => jo.clientCorporation.toString() === filters.company
        )
      }
      if (filters.owner.length) {
        jobOrders = jobOrders.filter(jo => filters.owner.includes(jo.owner))
      }
      if (filters.program) {
        jobOrders = jobOrders.filter(jo => jo.program === filters.program)
      }
      if (filters.requiredSkill) {
        jobOrders = jobOrders.filter(
          jo => jo.requiredSkill === filters.requiredSkill
        )
      }
      if (filters.tag.length) {
        jobOrders = filterByTags(jobOrders, filters.tag)
      }
      jobOrders = filterProgram(jobOrders, selectedCategoryName, true)
      if (sort && sort.value) {
        jobOrders.sort(selectJobSortFn(sort.value, sort.direction))
      }
      return isHotJobOrder
        ? sortHotJobs(jobOrders)
        : isPresorted || (sort && sort.value)
          ? jobOrders
          : sortJobs(jobOrders, required_skill_sort)
    },
    [isHotJobOrder]
  )

  const renderSoloOrArray = datum => {
    if (!Array.isArray(datum)) {
      return renderJob(datum)
    } else {
      return datum.map((arr2, idx) => (
        <div key={`masonry-col-${idx}`} className='masonry-col'>
          {arr2.map(o => {
            return renderJob(o.id)
          })}
        </div>
      ))
    }
  }

  const renderJob = useCallback(
    bhId => {
      const showClearance = departmentState.settings.security_clearance
      return (
        <JobCard
          key={bhId}
          bhId={bhId}
          boardType={boardType}
          isHot={isHot}
          showClearance={showClearance}
          headerless={isHeaderless}
          useTouchscreenBoard={useTouchscreenBoard}
          auth={auth}
          department={departmentState}
          jobOrders={jobOrders}
          setOpenTimesModalContent={setOpenTimesModalContent}
          openTimesModalVisible={!!openTimesModalData}
        />
      )
    },
    [
      auth,
      boardType,
      departmentState,
      isHeaderless,
      isHot,
      jobOrders,
      openTimesModalData,
      useTouchscreenBoard
    ]
  )

  const data = filterJobOrders(
    jobOrders.data,
    jobOrders.meta.filters,
    departmentState.settings.required_skill_sort,
    auth.selectedCategoryName,
    jobOrders.meta.isPresorted,
    jobOrders.meta.sort
  )

  return (
    <Skeleton.Board
      loading={!isLoading}
      skeletonChildren={<JobsBoardSkeleton />}
    >
      <div
        id='jobs-board'
        className={`board-container ${isHeaderless ? 'headerless' : 'board-container--header'
          }`}
      >
        <BoardManager
          useTouchscreenBoard={useTouchscreenBoard}
          number
          department={department || selectedDepartment}
          customOffset={55}
          elementMargins={20}
          ref={board}
          key='jobsboard'
          data={data}
          headerless={isHeaderless}
          renderElement={renderSoloOrArray}
          numberOfColumns={1}
          isPaused={isPaused}
          handleInternalPagination={handleInternalPagination}
          boardPage={boardPage}
          setBoardPage={setBoardPage}
        />
        <OpenTimesModal
          job={openTimesModalData}
          closeModal={() => setOpenTimesModalContent(null)}
        />
      </div>
    </Skeleton.Board>
  )
}

export default Jobs
