/**
 * Helper to verify that the connectionState property exists on the socket object
 * and returns isConnected state as a boolean
 * @param {*} socket BaseWebsocket socket singleton
 * @returns {Boolean}
 */
export default function verifyConnectionState(socket) {
  if (socket) {
    let connectionState
    if (typeof socket.connectionState === 'string') {
      connectionState = socket.connectionState.toLowerCase()
    } else if (typeof socket['_connectionState'] === 'string') {
      connectionState = socket['_connectionState'].toLowerCase()
    }

    if (connectionState) {
      return connectionState === 'connected'
    } else {
      console.warn(
        'Could not find connectionState property for socket: ',
        socket
      )
      return false
    }
  }

  return false
}
