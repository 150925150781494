import { groupBy, sortBy } from 'lodash'

export const sortAssignedAccountsByCompany = data => {
  const groupedData = groupBy(data, account => account.clientCorporation.bhId)
  return sortBy(groupedData, account => account[0].clientCorporation.name)
}

export const sortAssignedAccountsByAccountManager = data => {
  const groupedData = groupBy(data, account => account.user.bhId)
  return sortBy(
    groupedData,
    user => `${user[0].user.firstName} ${user[0].user.lastName}`
  )
}

export const filterDataFromDropdownInput = (data, filters) => {
  return data.filter(account => {
    if (
      filters.company.length &&
      !filters.company.includes(account.clientCorporation.bhId)
    ) {
      return false
    } else if (
      filters.accountManager.length &&
      !filters.accountManager.includes(account.user.bhId)
    ) {
      return false
    } else if (
      filters.program &&
      filters.program.length &&
      !filters.program.includes(account.program)
    ) {
      return false
    } else if (
      filters.division.length &&
      !filters.division.includes(account.category.bhId)
    ) {
      return false
    }
    return true
  })
}

export const filterColumnsPerDepartment = (columnConfig, department) => {
  return columnConfig.filter(column => {
    if (column.hidden) {
      return false
    }
    return true
  })
}
