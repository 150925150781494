import { cloneDeep } from 'lodash'

const hotSort = AMs => {
  const AMsCopy = cloneDeep(AMs.filter(am => am.isAccountManager))

  return AMsCopy.sort((a, b) => {
    if (a.PrimaryDepartment.name && b.PrimaryDepartment.name) {
      return a.PrimaryDepartment.name.localeCompare(b.PrimaryDepartment.name)
    } else {
      return false
    }
  })
}

const grossSorting = (AMs, Jobs) => {
  const AMsCopy = cloneDeep(AMs.filter(am => am.isAccountManager))
  const jobsByAM = {}
  AMsCopy.forEach(am => {
    jobsByAM[am.bhId] = []
    Jobs.forEach(job => {
      if (job.owner === am.bhId) {
        jobsByAM[am.bhId].push(job)
      }
    })
  })
  const keys = Object.keys(jobsByAM)
  keys.sort((managerA, managerB) => {
    let jobsA = jobsByAM[managerA]
    let jobsB = jobsByAM[managerB]

    let tier1AJobs = 0
    let tier2AJobs = 0
    let tier3AJobs = 0

    let tier1BJobs = 0
    let tier2BJobs = 0
    let tier3BJobs = 0

    jobsA.forEach(j => {
      let allSlotsFilled = true
      let slotsFound = false
      j.InterviewSlots.forEach(s => {
        slotsFound = true
        if (!s.jobSubmissions.length) {
          allSlotsFilled = false
        }
      })
      if (!slotsFound) {
        tier3AJobs += 1
      } else if (slotsFound && allSlotsFilled) {
        tier2AJobs += 1
      } else if (slotsFound && !allSlotsFilled) {
        tier1AJobs += 1
      }
    })

    jobsB.forEach(j => {
      let allSlotsFilled = true
      let slotsFound = false
      j.InterviewSlots.forEach(s => {
        slotsFound = true
        if (!s.jobSubmissions.length) {
          allSlotsFilled = false
        }
      })
      if (!slotsFound) {
        tier3BJobs += 1
      } else if (slotsFound && allSlotsFilled) {
        tier2BJobs += 1
      } else if (slotsFound && !allSlotsFilled) {
        tier1BJobs += 1
      }
    })

    if (tier1AJobs || tier1BJobs) {
      return tier1AJobs < tier1BJobs
    }

    if (tier2AJobs || tier2BJobs) {
      return tier2AJobs < tier2BJobs
    }

    if (tier3AJobs || tier3BJobs) {
      return tier3AJobs < tier3BJobs
    }

    return true
  })

  return keys.map(id => AMs.find(u => u.bhId === Number(id))).map(AMM => AMM)
}

export { hotSort, grossSorting }
