import React from 'react'
import BoardCard from '../BoardCard'

const ScheduledSendouts = ({
  type,
  division,
  brand,
  total,
  subBrand,
  hasSubBrand
}) => {
  const list = [
    { label: 'Division', value: division },
    hasSubBrand ? { label: 'Sub-Brand', value: subBrand } : null,
    { label: 'Brand', value: brand },
    { label: 'ALKU', value: total }
  ].filter(v => v)
  return (
    <BoardCard>
      <div className='g-sendouts'>
        <div className='g-sendouts__title'>{type} Scheduled Sendouts</div>
        <div className='g-sendouts__data'>
          {list.map(({ label, value }) => (
            <div key={label}>
              <div className={`g-sendouts__number g-sendouts__number--${type}`}>
                {value}
              </div>
              <div className='g-sendouts__label'>{label}</div>
            </div>
          ))}
        </div>
      </div>
    </BoardCard>
  )
}

export default ScheduledSendouts
