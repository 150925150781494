import { axiosRequest } from '../helpers/axios'

export default function getSubmissions(jobOrderId) {
  return axiosRequest(
    'GET',
    `/job-submissions?jobOrder=${jobOrderId}&status[]=Submitted&status[]=Client%20Submission&status[]=Interview%20Scheduled&status[]=Offer%20Extended&status[]=Candidate%20Interested`,
    null,
    null
  )
}
