import React from 'react'

const MobileDashboardNav = () => {
  return (
    <div className='c-dash-nav'>
      <div className='c-dash-nav__header'>Jump To...</div>
      <ul>
        <li>
          <a className='c-dash-nav__hyperlink' href='#SendoutManagement'>
            Sendout Management
          </a>
        </li>
        <li>
          <a className='c-dash-nav__hyperlink' href='#SubmittalManagement'>
            Submittal Management
          </a>
        </li>
        <li>
          <a className='c-dash-nav__hyperlink' href='#JobsManagement'>
            Jobs Management
          </a>
        </li>
        <li>
          <a className='c-dash-nav__hyperlink' href='#LeadsManagement'>
            Leads Management
          </a>
        </li>
        <li>
          <a className='c-dash-nav__hyperlink' href='#DealWeek'>
            Deals this Week
          </a>
        </li>
        <li>
          <a className='c-dash-nav__hyperlink' href='#DealsMonth'>
            Deals this Month
          </a>
        </li>
        <li>
          <a className='c-dash-nav__hyperlink' href='#DealProgressMonth'>
            Deal Progress This Month By Division
          </a>
        </li>
        <li>
          <a className='c-dash-nav__hyperlink' href='#StackRank'>
            Deals This Month By Stack Rank
          </a>
        </li>
      </ul>
    </div>
  )
}

export default MobileDashboardNav
