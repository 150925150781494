import React, { useCallback, useState } from 'react'
import TableHeader from './subcomponents/TableHeader'
import TableBody from './subcomponents/TableBody'
import { useSelector } from 'react-redux'
import MobileStackRankTable from './subcomponents/MobileStackRankTable'

const config = [
  { value: 'rank', defaultValue: 0, label: 'Rank', hideSort: true },
  {
    value: 'divisionName',
    defaultValue: 'N/A',
    label: 'Division',
    hideSort: true
  },
  { value: 'spreadAdded', defaultValue: 0, fixLevel: 2, label: 'Spread Added' },
  { value: 'dealsAdded', defaultValue: 0, label: 'Deals Added' },
  {
    value: 'avarageMargin',
    defaultValue: 0,
    fixLevel: 2,
    label: 'Average Margin'
  },
  {
    value: 'avarageSpread',
    defaultValue: 0,
    fixLevel: 2,
    label: 'Average Spread'
  },
  { value: 'avarageDuration', defaultValue: 0, label: 'Average Duration' },
  { value: 'accountBreakers', defaultValue: 0, label: 'Account Breakers' }
]

const noRankConfig = config.slice(1)
const noDivisionConfig = config.slice(2)

const selectRankData = state => state.dashboard.sendouts.divisionFiscalMonthRank

const DealRankTable = ({ headerless }) => {
  const defaultSort = { value: 'spreadAdded', direction: 'asc' }
  const [sort, setSort] = useState(defaultSort)
  const data = useSelector(selectRankData)
  const toggleSort = useCallback(
    value => {
      if (value === sort.value) {
        setSort({
          value,
          direction: sort.direction === 'desc' ? 'asc' : 'desc'
        })
      } else {
        setSort({ value, direction: 'asc' })
      }
    },
    [sort, setSort]
  )
  const sortData = useCallback(
    (a, b) => {
      if (a[sort.value] > b[sort.value]) {
        return -1
      } else if (a[sort.value] < b[sort.value]) {
        return 1
      }
      return 0
    },
    [sort]
  )

  const configureRankOrder = useCallback(() => {
    return data && data.length
      ? data.sort(sortData).reduce((divisions, nextDiv, index) => {
        if (index === 0) {
          nextDiv.rank = 1
        } else {
          const prevDiv = divisions[index - 1]
          if (prevDiv[sort.value] > nextDiv[sort.value]) {
            nextDiv.rank = prevDiv.rank + 1
          } else {
            nextDiv.rank = prevDiv.rank
          }
        }
        divisions.push(nextDiv)
        if (index + 1 === data.length && sort.direction === 'desc') {
          return divisions.reverse()
        }
        return divisions
      }, [])
      : []
  }, [sort, data, sortData])

  const sorted = configureRankOrder()
  return (
    <>
      <div className='c-deal-division-table__container'>
        <table className='c-deal-division-table desktop'>
          <TableHeader
            className='c-deal-division-table__header'
            cellClassName='c-deal-division-table__cell c-deal-division-table__cell--header'
            config={config}
            toggleSort={toggleSort}
            sort={sort}
            headerless={headerless}
          />
          <TableBody
            className='c-deal-division-table__board'
            data={sorted}
            config={noRankConfig}
          />
        </table>
      </div>
      <div className='c-deal-division-table mobile'>
        <MobileStackRankTable data={sorted} config={noDivisionConfig} />
      </div>
    </>
  )
}

export default React.memo(DealRankTable)
