import * as React from 'react'
import SkeletonInput from './SkeletonInput'

export default function SkeletonBoard(props) {
  const { loading, children, skeletonChildren } = props
  if (typeof loading !== 'boolean') {
    return children
  }

  return loading ? (
    <div className='c-skeleton-board'>
      <div className='c-skeleton-board__header'>
        <SkeletonInput style={{ width: 223, height: 32 }} active />
      </div>
      {skeletonChildren}
    </div>
  ) : (
    children
  )
}
