import { uniqBy } from 'lodash'

export const jobsFilterConfig = (jobOrders, shouldShowProgramFilter) => [
  {
    filterLabel: 'company',
    type: 'search-select',
    match: 'exact',
    label: 'Company',
    placeholder: 'Select Company',
    options: uniqBy(
      jobOrders
        .map(jo => {
          return {
            value: jo.ClientCorporation.bhId,
            label: jo.ClientCorporation.name
          }
        })
        .filter(jo => jo.value || jo.name),
      option => option.value
    ),
    filterByLabel: true
  },
  {
    filterLabel: 'requiredSkill',
    type: 'search-select',
    match: 'exact',
    departments: ['government', 'bdm', 'gov-inteleng', 'gov-sysnet', 'gov-softwaretest'],
    hidden: !shouldShowProgramFilter, // using program field until we have a government specific flag
    label: 'Required Skill',
    placeholder: 'Select Skill',
    options: uniqBy(
      jobOrders
        .map(jo => {
          return {
            value: jo.requiredSkill,
            label: jo.requiredSkill
          }
        })
        .filter(jo => jo.value || jo.name),
      option => option.value
    )
  },
  {
    filterLabel: 'program',
    type: 'search-select',
    match: 'exact',
    hidden: !shouldShowProgramFilter,
    label: 'Program',
    placeholder: 'Select Program',
    options: uniqBy(
      jobOrders
        .map(jo => {
          return {
            value: jo.program,
            label: jo.program
          }
        })
        .filter(jo => jo.value || jo.name),
      option => option.value
    )
  },
  {
    filterLabel: 'owner',
    multiSelect: true,
    type: 'search-select',
    match: 'exact',
    label: 'Owners',
    placeholder: 'Select Owners',
    options: uniqBy(
      jobOrders
        .map(jo => {
          return {
            value: jo.Owner.bhId,
            label: `${jo.Owner.firstName} ${jo.Owner.lastName[0]}`
          }
        })
        .filter(jo => jo.value || jo.name),
      option => option.value
    ),
    filterByLabel: true
  },
  {
    filterLabel: 'tag',
    multiSelect: true,
    type: 'search-select',
    match: 'exact',
    label: 'Tags',
    placeholder: 'Select Tags',
    options: [
      { value: 'isRTC', label: 'TC' },
      { value: 'exclusive', label: 'Exclusive' },
      { value: 'submissionsNeeded', label: 'Unchecked' },
      { value: '_checked', label: 'Checked' },
      { value: '_fullTime', label: 'Full Time' },
      { value: '_partTime', label: 'Part Time' }
    ],
    filterByLabel: true
  }
]
