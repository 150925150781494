import React from 'react'
import PaperworkItem from './PaperworkItem'

const INVALID_RADIO_ANSWER_TYPE_NAMES = ['complete/na']

const Paperwork = ({
  type,
  onToggle,
  data,
  title,
  value,
  groupName,
  groupedPaperwork,
  answers,
  answerTypeName
}) => {
  const isValidRadioAnswerTypeName =
    answerTypeName &&
    !INVALID_RADIO_ANSWER_TYPE_NAMES.includes(answerTypeName.toLowerCase())
  const formattedAnswers =
    isValidRadioAnswerTypeName &&
    answers?.map(({ answerId, answerName }) => ({
      value: answerId,
      label: answerName
    }))
  return (
    <div className='c-onboarding-paperwork__row'>
      {type === 'text' ? (
        <div className='c-onboarding-paperwork__item'>
          <div className='c-onboarding-paperwork__col c-onboarding-paperwork__col--left'>
            {title}
          </div>
          <div className='c-onboarding-paperwork__col c-onboarding-paperwork__col--right'>
            <span className='text'>{value}</span>
          </div>
        </div>
      ) : (
        <PaperworkItem
          key={title}
          sectionHeader={groupName}
          onToggle={updateItem => onToggle(data, updateItem)}
          {...data}
        />
      )}
      {isValidRadioAnswerTypeName && answers?.length ? (
        <PaperworkItem
          key={`${title}-answers`}
          radioItems={formattedAnswers}
          radioValue={data.answerId}
          isNotApplicable={data.isNotApplicable}
          onToggle={updateValue => onToggle(data, 'answerId', updateValue)}
        />
      ) : null}
      {groupName && groupedPaperwork
        ? groupedPaperwork.map((item, index) => (
            <PaperworkItem
              key={item.title || index}
              onToggle={updateItem => onToggle(item, updateItem)}
              subItem
              {...item}
            />
          ))
        : null}
    </div>
  )
}

export default Paperwork
