import React from 'react'

const CaratDown = ({ color }) => {
  return (
    <svg width='13' height='8' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.5 0L0 1.5l6.07 6.07 6.071-6.07-1.5-1.5L6.07 4.57z'
        fill={color || '#010000'}
        fillRule='nonzero'
      />
    </svg>
  )
}

export default CaratDown
