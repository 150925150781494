import React, { useCallback, useState, useEffect } from 'react'
import { Layout } from 'antd'
import tinycolor from 'tinycolor2'

import { LinkMenu, UserMenuItem } from './components'
import menuConfig from './helpers/menuConfig'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectActiveUser,
  selectSideBarIsCollapsed,
  selectStyleGuideUrl
} from '../../../redux/selectors'
import { setSideBarIsCollapsed } from '../../../redux/actions'
import SidebarToggleIcon from 'components/shared/Icons/SidebarToggleIcon'
import { useWindowWidth } from 'components/hocs/hooks'

const { Sider } = Layout

const MOBILE_SIZE = {
  collapsedWidth: 0,
  width: '100%'
}

const DESKTOP_SIZE = {
  collapsedWidth: 60,
  width: 250
}

const ExpandBtn = ({ collapsed, setCollapsed }) => {
  const onClick = () => setCollapsed(!collapsed)

  return (
    <button className='c-sidebar__expand-btn' onClick={onClick}>
      <SidebarToggleIcon collapsed={collapsed} />
    </button>
  )
}

const Sidebar = ({ board, activeDepartment, hidden, updateUserWSFxn }) => {
  const [useMobile, setUseMobile] = useState(true)
  const dispatch = useDispatch()
  const windowWidth = useWindowWidth()
  const collapsed = useSelector(selectSideBarIsCollapsed)
  const styleGuideUrl = useSelector(selectStyleGuideUrl)
  const setCollapsed = useCallback(
    isCollapsed => {
      dispatch(setSideBarIsCollapsed(isCollapsed))
    },
    [dispatch]
  )
  const user = useSelector(selectActiveUser)
  const onRouteChange = useCallback(() => {
    if (useMobile) {
      dispatch(setSideBarIsCollapsed(true))
    }
  }, [useMobile, dispatch])
  const config = menuConfig(activeDepartment, styleGuideUrl)
  const fromColor = `#${user.color}`
  const toColor = tinycolor(fromColor)
    .lighten(15)
    .toString()
  useEffect(() => {
    if (windowWidth >= 1280 && useMobile) setUseMobile(false)
    if (windowWidth < 1280 && !useMobile) setUseMobile(true)
  }, [useMobile, windowWidth])
  if (hidden || (!useMobile && !activeDepartment)) return null
  return (
    <Sider
      className={`c-sidebar${collapsed ? ' c-sidebar--collapsed' : ''}`}
      collapsible
      collapsed={collapsed}
      collapsedWidth={
        useMobile ? MOBILE_SIZE.collapsedWidth : DESKTOP_SIZE.collapsedWidth
      }
      width={useMobile ? MOBILE_SIZE.width : DESKTOP_SIZE.width}
      onCollapse={setCollapsed}
      theme='light'
      trigger={null}
    >
      <div className='c-sidebar__items'>
        <UserMenuItem
          user={user}
          updateUserWSFxn={updateUserWSFxn}
          fromColor={fromColor}
          toColor={toColor}
          activeDepartment={activeDepartment}
          collapsed={collapsed}
        />
        <LinkMenu
          onRouteChange={onRouteChange}
          config={config}
          color={user && user.color}
          board={board}
          isCollapsed={collapsed}
        />
      </div>
      {!useMobile && (
        <ExpandBtn collapsed={collapsed} setCollapsed={setCollapsed} />
      )}
    </Sider>
  )
}

export default Sidebar
