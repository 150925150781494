import { uniqBy } from 'lodash'
import { PAPERWORK_LIST } from '../../../constants'

export default placements => [
  {
    filterLabel: 'status',
    type: 'search-select',
    match: 'exact',
    label: 'Status',
    placeholder: 'Select Status',
    options: uniqBy(
      placements
        .map(placement => {
          return {
            value: placement.status,
            label: placement.status
          }
        })
        .filter(placement => placement.value || placement.name),
      option => option.value
    ),
    filterByLabel: true
  },
  {
    filterLabel: 'brand',
    type: 'search-select',
    match: 'exact',
    label: 'Brand',
    placeholder: 'Select Brand',
    options: uniqBy(
      placements
        .map(placement => {
          return {
            value: placement.brand,
            label: placement.brand
          }
        })
        .filter(placement => placement.value || placement.name),
      option => option.value
    ),
    filterByLabel: true
  },
  {
    filterLabel: 'division',
    type: 'search-select',
    match: 'exact',
    label: 'Division',
    placeholder: 'Select Division',
    options: uniqBy(
      placements
        .map(placement => {
          return {
            value: placement.division,
            label: placement.division
          }
        })
        .filter(placement => placement.value),
      option => option.value
    ),
    filterByLabel: true
  },
  {
    filterLabel: 'empType',
    type: 'search-select',
    match: 'exact',
    label: 'Employee Type',
    placeholder: 'Select Type',
    options: uniqBy(
      placements
        .map(placement => {
          return {
            value: placement.employeeType,
            label: placement.employeeType
          }
        })
        .filter(placement => placement.value),
      option => option.value
    ),
    filterByLabel: true
  },
  {
    filterLabel: 'startDateRange',
    dateRange: true,
    match: 'exact',
    label: 'Start Date Range',
    placeholder: 'Select Date Range'
  },
  {
    filterLabel: 'owner',
    multiSelect: true,
    type: 'search-select',
    match: 'exact',
    label: 'Owners',
    placeholder: 'Select Owners',
    options: uniqBy(
      placements
        .map(placement => {
          if (placement) {
            return {
              value: placement.accountManagerName,
              label: placement.accountManagerName
            }
          } else {
            return {
              value: null
            }
          }
        })
        .filter(placement => placement.value || placement.name),
      option => option.value
    ),
    filterByLabel: true
  },
  {
    filterLabel: 'incompletePpw',
    multiSelect: true,
    type: 'search-select',
    match: 'exact',
    label: 'Incomplete Paperwork',
    placeholder: 'Select Paperwork',
    options: PAPERWORK_LIST.map(ppw => {
      return {
        value: ppw,
        label: ppw
      }
    }),
    filterByLabel: true,
    placement: 'bottomRight'
  }
]
