import React from 'react'
import BoardCard from '../BoardCard'
import NumberBlock from '../NumberBlock'

const NumberCard = ({ value, title, division, color }) => {
  return (
    <BoardCard>
      <div className={`g-numbercard g-numbercard--${color}`}>
        <NumberBlock value={value} />
        <div className='g-numbercard__labels'>
          <div className='g-numbercard__title'>{title}</div>
          {division && <div className='g-numbercard__division'>Division</div>}
        </div>
      </div>
    </BoardCard>
  )
}

export default NumberCard
