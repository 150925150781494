import {
  SET_JOB_ORDERS_ACTIVITY,
  SET_JOB_ORDERS_ACTIVITY_OPTIONS_TAB,
  SET_JOB_ORDER_ACTIVITY_HAS_INTERVIEW_PREP_DETAILS
} from '../actionTypes'
import logger from '../../requests/logger'
import { cloneDeep } from 'lodash'

export const setJobOrdersActivity = jobOrdersActivity => {
  return {
    type: SET_JOB_ORDERS_ACTIVITY,
    jobOrdersActivity: jobOrdersActivity
  }
}

export const slotSubmissionAdded = (
  jobOrderId,
  slotId,
  jobSubmission,
  event
) => {
  return function(dispatch, getState) {
    let {
      jobOrdersActivity: { data }
    } = getState()
    let newOrders = cloneDeep(data)
    let JO = newOrders.find(JO => JO.bhId === jobOrderId)
    if (!JO) {
      logger({
        stackError: `Job Order not found for ${event} event with the job id ${jobOrderId} and slotId ${slotId}`
      })
      return
    } else {
      let slot = JO.InterviewSlots.find(slot => slot.id === slotId)
      if (slot) {
        slot.jobSubmissions.push({
          ...jobSubmission
        })
        dispatch(setJobOrdersActivity(newOrders))
      } else {
        logger({
          stackError: `Interview Slot not found for ${event} event with the job id ${jobOrderId} and slotId ${slotId}`
        })
        return
      }
    }
  }
}

export const slotSubmissionUpdated = (
  jobOrderId,
  interviewSlotObj,
  payload,
  event
) => {
  return function(dispatch, getState) {
    let {
      jobOrdersActivity: { data }
    } = getState()
    let newOrders = cloneDeep(data)
    let JO = newOrders.find(JO => JO.bhId === jobOrderId)
    if (JO) {
      let slot = JO.InterviewSlots.find(
        slot => slot.id === interviewSlotObj.InterviewSlotId
      )
      if (slot) {
        let submissionIdx = slot.jobSubmissions.findIndex(
          jobSubmission =>
            jobSubmission.bhId === interviewSlotObj.JobSubmissionBhId
        )
        if (submissionIdx !== -1) {
          Object.assign(
            slot.jobSubmissions[submissionIdx].InterviewSlotSubmission,
            payload
          )
          dispatch(setJobOrdersActivity(newOrders))
        } else {
          logger({
            stackError: `Interview Slot Submission not found for ${event} event with the job id ${jobOrderId} and interviewSlotId ${interviewSlotObj.InterviewSlotId}`
          })
          return
        }
      } else {
        logger({
          stackError: `Interview Slot not found for ${event} event with the job id ${jobOrderId} and interviewSlotId ${interviewSlotObj.InterviewSlotId}`
        })
        return
      }
    } else {
      logger({
        stackError: `Job Order not found for ${event} event with the job id ${jobOrderId} and interviewSlotId ${interviewSlotObj.InterviewSlotId}`
      })
      return
    }
  }
}

// slot_submission_removed
export const slotSubmissionRemoved = (
  jobOrderId,
  slotId,
  jobSubmissionId,
  event
) => {
  return function(dispatch, getState) {
    let {
      jobOrdersActivity: { data }
    } = getState()
    let newOrders = cloneDeep(data)
    let JO = newOrders.find(JO => JO.bhId === jobOrderId)
    if (JO) {
      let slot = JO.InterviewSlots.find(slot => slot.id === slotId)
      if (slot) {
        let submissionIdx = slot.jobSubmissions.findIndex(
          jobSubmission => jobSubmission.bhId === jobSubmissionId
        )
        if (submissionIdx !== -1) {
          slot.jobSubmissions.splice(submissionIdx, 1)
          dispatch(setJobOrdersActivity(newOrders))
        } else {
          logger({
            stackError: `Job Slot Submission not found for ${event} event with the job id ${jobOrderId} and interviewSlotId ${slotId} and jobSubmissionId ${jobSubmissionId}`
          })
          return
        }
      } else {
        logger({
          stackError: `Job Slot not found for ${event} event with the job id ${jobOrderId} and interviewSlotId ${slotId} and jobSubmissionId ${jobSubmissionId}`
        })
        return
      }
    } else {
      logger({
        stackError: `Job Order not found for ${event} event with the job id ${jobOrderId} and interviewSlotId ${slotId} and jobSubmissionId ${jobSubmissionId}`
      })
      return
    }
  }
}

export const interviewSlotAdded = (
  jobOrderId,
  date,
  faceToFace,
  slotId,
  timezone,
  event
) => {
  return function(dispatch, getState) {
    let {
      jobOrdersActivity: { data }
    } = getState()
    let newOrders = cloneDeep(data)
    let JO = newOrders.find(JO => JO.bhId === jobOrderId)
    if (JO) {
      JO.InterviewSlots.push({
        date,
        faceToFace,
        timezone,
        jobSubmissions: [],
        id: slotId
      })
      dispatch(setJobOrdersActivity(newOrders))
    } else {
      logger({
        stackError: `Job Order not found for ${event} event with the job id ${jobOrderId} and interviewSlotId ${slotId}`
      })
      return
    }
  }
}

export const interviewSlotRemoved = (jobOrderId, slotId, event) => {
  return function(dispatch, getState) {
    let {
      jobOrdersActivity: { data }
    } = getState()
    let newOrders = cloneDeep(data)
    let JO = newOrders.find(JO => JO.bhId === jobOrderId)
    if (JO) {
      let slotIdx = JO.InterviewSlots.findIndex(slot => slot.id === slotId)
      if (slotIdx !== -1) {
        JO.InterviewSlots.splice(slotIdx, 1)
        dispatch(setJobOrdersActivity(newOrders))
      } else {
        logger({
          stackError: `Interview Slot not found for ${event} event with the job id ${jobOrderId} and interviewSlotId ${slotId}`
        })
        return
      }
    } else {
      logger({
        stackError: `Job Order not found for ${event} event with the job id ${jobOrderId} and interviewSlotId ${slotId}`
      })
      return
    }
  }
}

export const interviewSlotUpdated = (jobOrderId, slotId, payload, event) => {
  return function(dispatch, getState) {
    let {
      jobOrdersActivity: { data }
    } = getState()
    let newOrders = cloneDeep(data)
    let JO = newOrders.find(JO => JO.bhId === jobOrderId)
    if (JO) {
      let slot = JO.InterviewSlots.find(slot => slot.id === slotId)
      if (slot) {
        Object.assign(slot, payload)
        dispatch(setJobOrdersActivity(newOrders))
      } else {
        logger({
          stackError: `Interview Slot not found for ${event} event with the job id ${jobOrderId} and interviewSlotId ${slotId}`
        })
        return
      }
    } else {
      logger({
        stackError: `Job Order not found for ${event} event with the job id ${jobOrderId} and interviewSlotId ${slotId}`
      })
      return
    }
  }
}

export const jobOrderActivityChange = (jobOrderId, payload, event) => {
  return function(dispatch, getState) {
    let {
      jobOrdersActivity: { data }
    } = getState()
    let newOrders = cloneDeep(data)
    let JO = newOrders.find(JO => JO.bhId === jobOrderId)
    if (JO) {
      Object.assign(JO, payload)
      dispatch(setJobOrdersActivity(newOrders))
    } else {
      logger({
        type: 'data_error',
        stackError: `A jobOrder with id ${JO.bhId} ${event} event has been received. That job is not found from the list event.`
      })
      return
    }
  }
}

// candidate_change... array of jobs and slots
export const updateCandidateSlot = (instances, candidate, event) => {
  return function(dispatch, getState) {
    let {
      jobOrdersActivity: { data }
    } = getState()
    let newOrders = cloneDeep(data)

    instances.forEach(instance => {
      let JOIndex = newOrders.findIndex(
        orderObj => orderObj.bhId === instance.jobOrder
      )
      if (JOIndex > -1) {
        let slotIndex = newOrders[JOIndex].InterviewSlots.findIndex(
          jobSubObj => jobSubObj.id === instance.slot
        )
        if (slotIndex > -1) {
          let candidateIndex = newOrders[JOIndex].InterviewSlots[
            slotIndex
          ].jobSubmissions.findIndex(subObj => {
            return subObj.Candidate.bhId === candidate.bhId
          })
          if (candidateIndex > -1) {
            newOrders[JOIndex].InterviewSlots[slotIndex].jobSubmissions[
              candidateIndex
            ].Candidate = {
              ...newOrders[JOIndex].InterviewSlots[slotIndex].jobSubmissions[
                candidateIndex
              ].Candidate,
              ...candidate
            }
            dispatch(setJobOrdersActivity(newOrders))
          } else {
            logger({
              type: 'data_error',
              stackError: `Candidate not found for ${event} event with jobOrder id ${instance.jobOrder} and candidate id ${candidate.bhId} `
            })
            return
          }
        } else {
          logger({
            type: 'data_error',
            stackError: `Interview Slot not found for ${event} event with jobOrder id ${instance.jobOrder} and candidate id ${candidate.bhId} `
          })
          return
        }
      } else {
        logger({
          type: 'data_error',
          stackError: `JobOrder not found for ${event} event with jobOrder id ${instance.jobOrder} and candidate id ${candidate.bhId}`
        })
        return
      }
    })
  }
}

export const setJobOrdersActivityOptionsTab = value => dispatch => {
  dispatch({
    type: SET_JOB_ORDERS_ACTIVITY_OPTIONS_TAB,
    payload: value
  })
}

/**
 *
 * @param {{ jobOrderBhId: number, hasInterviewPrepDetail: boolean }} payload
 * @returns {void}
 */
export const setJobOrderActivityHasInterviewPrepDetails = payload => dispatch => {
  dispatch({
    type: SET_JOB_ORDER_ACTIVITY_HAS_INTERVIEW_PREP_DETAILS,
    payload
  })
}
