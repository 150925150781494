import React, { useState } from 'react'

export default function CandidateToggleIcon({ isRTC, title }) {
  const [hoverActive, setHoverActive] = useState(false)
  const toggleHover = function() {
    setHoverActive(currentState => !currentState)
  }
  return (
    <>
      <p onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
        <svg
          width='16px'
          height='16px'
          viewBox='0 0 16 16'
          version='1.1'
          onMouseEnter={toggleHover}
          onMouseLeave={toggleHover}
        >
          {(hoverActive || isRTC) && (
            <g id='Jobs' stroke='none' strokeWidth='1' fill='none'>
              <g id='icons8-star_filled' fill='#222222'>
                <polygon
                  id='Path'
                  points='16 5.79710145 10.0072464 5.79710145 8 0 5.99275362 5.79710145 0 5.81231884 4.75289855 9.46666667 3.0557971 15.2173913 8 11.8268116 12.9442029 15.2173913 11.2471014 9.4673913'
                ></polygon>
              </g>
            </g>
          )}
          {(hoverActive || !isRTC) && (
            <g id='Jobs' stroke='none' strokeWidth='1' fill='none'>
              <g id='icons8-star' fill='#222222'>
                <path
                  d='M16,5.81231884 L10.0072464,5.64927536 L8,0 L5.99275362,5.64927536 L0,5.81231884 L4.75289855,9.46666667 L3.0557971,15.2173913 L8,11.8268116 L12.9442029,15.2173913 L11.2471014,9.4673913 L16,5.81231884 Z M8,10.0695652 L5.6,11.7152174 L6.42391304,8.92463768 L4.1173913,7.15072464 L7.02608696,7.07173913 L8,4.32898551 L8.97391304,7.07101449 L11.8826087,7.15 L9.57608696,8.92391304 L10.4,11.7144928 L8,10.0695652 Z'
                  id='Shape'
                ></path>
              </g>
            </g>
          )}
        </svg>
        {` ${title}`}
      </p>
    </>
  )
}
