export default user => {
  if (!user) return { initial: '', isAM: false }
  const { firstName, lastName, isAccountManager, isAM } = user
  const first = firstName ? firstName.trim().substring(0, 1) : ''
  const last = lastName ? lastName.trim().substring(0, 1) : ''
  return {
    initial: `${first}${last}`,
    isAM: isAM !== undefined ? isAM : isAccountManager,
    fullName: `${firstName} ${lastName}`
  }
}

export const getUsersAM = commissions => {
  return commissions.map(({ User, role }) => {
    return {
      ...User,
      isAM: role ? role === 'Sales' : undefined
    }
  })
}
