import dayjs from 'dayjs'

export const defaultSendouts = {
  division: 0,
  brand: 0,
  total: 0
}

export const defaultWeekDays = {
  division: [],
  brand: [],
  total: []
}

const FISCAL_DATE_FORMAT = 'M/DD/YY'

export const formatFiscalDisplayDate = (startDate, endDate) => {
  const startDisplay = dayjs.utc(startDate).format(FISCAL_DATE_FORMAT)
  const endDisplay = dayjs.utc(endDate).format(FISCAL_DATE_FORMAT)
  return `(${startDisplay}-${endDisplay})`
}
