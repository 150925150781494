import React from 'react'

const SortIcon = ({ sortDirection, onClick, width = 8.5 }) => {
  return (
    <div className='c-deal-division-table__sort-icon' onClick={onClick}>
      <svg width={`${width}px`} height={`${width * 1.175}px`} viewBox='0 0 400 470' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M 10 210 L 390 210 L 200 10 z'
          fill={sortDirection === 'asc' ? '#D0021B' : '#9B9B9B'}
          stroke={sortDirection === 'asc' ? '#D0021B' : '#9B9B9B'}
          strokeWidth='1'
        />
        <path
          d='M 10 260 L 390 260 L 200 460 z'
          fill={sortDirection === 'desc' ? '#D0021B' : '#9B9B9B'}
          stroke={sortDirection === 'desc' ? '#D0021B' : '#9B9B9B'}
          strokeWidth='1'
        />
      </svg>
    </div>
  )
}

export default SortIcon