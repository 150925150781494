// even though they are -almost- the same data, these differ from jobs board jobOrders in subtle ways.

import { ACTIVITY_TABS_ENUM } from '../../constants'
import {
  SET_JOB_ORDERS_ACTIVITY,
  CLEAR_PREVIOUS_DPEARTMENT_DATA,
  SET_JOB_ORDERS_ACTIVITY_OPTIONS_TAB,
  SET_JOB_ORDER_ACTIVITY_HAS_INTERVIEW_PREP_DETAILS
} from '../actionTypes'

const DEFAULT_STATE = {
  data: [],
  activeTab: ACTIVITY_TABS_ENUM.ALL
}

const jobOrdersActivity = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case CLEAR_PREVIOUS_DPEARTMENT_DATA:
      return DEFAULT_STATE
    case SET_JOB_ORDERS_ACTIVITY:
      return {
        ...state,
        data: action.jobOrdersActivity
      }
    case SET_JOB_ORDERS_ACTIVITY_OPTIONS_TAB:
      return {
        ...state,
        activeTab: action.payload
      }
    case SET_JOB_ORDER_ACTIVITY_HAS_INTERVIEW_PREP_DETAILS: {
      const { jobOrderBhId, hasInterviewPrepDetail } = action.payload
      return {
        ...state,
        data: state.data.map(jobOrder => {
          if (jobOrderBhId === jobOrder.bhId) {
            return {
              ...jobOrder,
              hasInterviewPrepDetail
            }
          } else {
            return jobOrder
          }
        })
      }
    }
    default:
      return state
  }
}

export default jobOrdersActivity
