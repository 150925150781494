import React from 'react'

const Funnel = () => {
  return (
    <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0 0v2.222h1.111l5.556 8.89V20h6.666v-8.889l5.556-8.889H20V0H0zm3.733 2.222H16.27L11.406 10H8.594L3.733 2.222zm5.156 10h2.222v5.556H8.89v-5.556z'
        fill='#4E4E4E'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default Funnel
